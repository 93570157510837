import { apiRequest } from '../../utils'
import {
  ICots,
  ICotsCreate,
  ICotsDelete,
  ICotsList,
  ICotsResponse
} from '@/store/modules/cursor_on_target/types'
import { Commit, Dispatch } from 'vuex'
import omit from 'lodash/omit'

export default {
  FETCH_CURSOR_ON_TARGET: async (
    { commit }: { commit: Commit },
    site_id: number
  ) => {
    return await apiRequest(
      'get',
      `/api/sites/${site_id}/cursor_on_target_settings`,
      {},
      (data: ICotsList) => {
        commit('SET_STATUS', 'OK')
        commit(`LOAD_CURSOR_ON_TARGET_SETTINGS`, data)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        commit(`resetState`)
      }
    )
  },

  UPDATE_CURSOR_ON_TARGET: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    cursor_on_target_settings: ICots
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `/api/sites/${cursor_on_target_settings.site_id}/cursor_on_target_settings/${cursor_on_target_settings.id}`,
      { cot: omit(cursor_on_target_settings, ['id']) },
      (data: ICotsResponse) => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_CURSOR_ON_TARGET', cursor_on_target_settings.site_id)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', data.error)
      }
    )
  },

  CREATE_CURSOR_ON_TARGET: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: ICotsCreate
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/api/sites/${payload.site_id}/cursor_on_target_settings`,
      { cot: payload.cot },
      (_: ICotsResponse) => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_CURSOR_ON_TARGET', payload.site_id)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', data.error)
      }
    )
  },

  DELETE_CURSOR_ON_TARGET: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: ICotsDelete
  ) => {
    return await apiRequest(
      'delete',
      `/api/sites/${payload.site_id}/cursor_on_target_settings/${payload.cots_id}`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_CURSOR_ON_TARGET', payload.site_id)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', data.error)
      }
    )
  }
}
