import store from '@/store'

export default {
  setNotificationSort: async ({ commit }, payload) => {
    commit('SET_NOTIFICATION_SORT', payload)
  },
  setFusionNotificationSort: async ({ commit }, payload) => {
    commit('SET_FUSION_NOTIFICATION_SORT', payload)
  },
  dismissAlert: async ({ commit }, payload) => {
    commit('DISMISS_ALERT', payload)
  },
  addDetections: async ({ commit }, payload) => {
    commit('ADD_DETECTIONS', payload)
  },
  clearDetections: ({ commit }) => {
    commit('CLEAR_DETECTIONS')
  },
  addAlert: async ({ commit }, alert) => {
    commit('ADD_ALERT', alert)
  },
  removeAlert: async ({ commit }, event) => {
    commit('REMOVE_ALERT', event)
  },
  clearAlerts: ({ commit }) => {
    commit('CLEAR_ALERTS')
  },
  addIcon: async ({ commit }, { key, icon }) => {
    commit('ADD_ICON', { key, icon })
  },
  clearIcons: async ({ commit }) => {
    commit('CLEAR_ICONS')
  },
  setActiveAlert: async ({ commit }, alert) => {
    commit('SET_ACTIVE_ALERT', alert)
  },
  selectDetection: async ({ commit }, detection) => {
    commit('SELECT_DETECTION', detection)
  },
  setDeferredAlert: ({ commit }, alertId) => {
    commit('SET_DEFERRED_ALERT', alertId)
  }
}
