import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import { ISiteState } from './types'

const getDefaultState = (): ISiteState => {
  return {
    sitesSet: {},
    activeSiteId: localStorage.getItem('active-site-id') || '',
    status: '',
    error: '',
    simulationRunning: false,
    simulationStartLoading: true,
    newSiteOrigin: {
      latitude: 0,
      longitude: 0,
      timezone: 'UTC',
      zoom_level: 13
    }
  }
}

const state = getDefaultState()

const resetState = (state: ISiteState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
