import Module from './index'

class SensorModule extends Module {
  constructor(params) {
    super(params)
    this.delay = {
      warn: 5000,
      error: 10000
    }
  }

  getters() {
    return {
      ...super.getters(),
      [`${this.endpoint}InSentry`]: state => sentry =>
        Object.values(state[`${this.endpoint}Set`] || {}).filter(
          ({ sentry_id, rf_sensor_id }) => sentry && sentry_id === sentry.id && (this.endpoint !== 'cannons' || rf_sensor_id === null)
        ),
      [`${this.endpoint}InSite`]: state => (siteId, sentries) =>
        Object.values(state[`${this.endpoint}Set`] || {})
          .filter(({ sentry_id }) =>
            sentries.some(
              sentry => sentry.site_id == siteId && sentry_id == sentry.id
            )
          )
          .reduce((map, obj) => {
            map[obj.id + '-' + obj.sentry_id] = obj
            return map
          }, {})
    }
  }
}

export default SensorModule
