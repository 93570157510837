import socketManager from '@/plugins/websocket'

export interface IState {
  dataLoaded: boolean
  upgrades: string[]
  stats: IStats
  status: string
  error: string
  replay: boolean
  version: IVersion
  environment: string
  build: string
  user_invite_allowed: boolean
  simulation_enabled: boolean
  supportEmail: string
  apiUrl: string
  rubySocketUrl: string
  coreSocketUrl: string
  socket: ISocket
  alertsSocket: ISocket
  systemSetting: ISystemSetting
  dataWebsocket: socketManager
  alertsWebsocket: socketManager
  offline: boolean
  logo: string
}

export enum ELogo {
  NONE = 'none',
  BT = 'bt',
  TRAKKA = 'trakka',
  EPIRUS = 'epirus'
}

export interface ISocket {
  isConnected: boolean
  message: string
  reconnectError: boolean
}

export interface IStats {
  cpu_info: ICPUInfo
  memory_info: IMemoryInfo
  disk_usage: IDiskUsage
}

export interface ICPUInfo {
  total_cpu_usage: number
  per_cpu_usage: number[]
}

export interface IMemoryInfo {
  memory_total: number
  memory_free: number
  memory_used: number
  memory_used_percent: number
}

export interface IDiskUsage {
  size: number
  used: number
  available: number
  used_percent: number
}

export interface ISystemSetting {
  id: number
  static_ip: string
  static_subnet_mask: string
  show_trackers: boolean
  latency_radar_buffer: number
  display_unit: string
  ntp_url: string
  logo: string
}

export interface SystemSettingResponse {
  system_setting: ISystemSetting
}

export interface ISystemInfo {
  version: IVersion
  environment: string
  user_invite_allowed: boolean
  simulation_enabled: boolean
  logo: string
}

export interface IVersion {
  app: string
  agincourt_vanguard: string
  core_api: string
  sentry_api: string
  sentinel_api: string
  fluentd_computenode: string
  sentry_janus: string
}
