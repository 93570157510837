import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { ITrackerState } from './types'

const getDefaultState = (): ITrackerState => {
  return {
    trackers: []
  }
}

const state: ITrackerState = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
