import { apiRequest } from '@/store/utils'
import {IAGLMaskItem, IAGLMaskResponse, IAGLMaskResponseList} from "@/store/modules/agl_mask/types";
import {Commit} from "vuex";

export default {
  updateAGLMask: async ({ commit }: { commit: Commit }, args: { siteID: number, mask: IAGLMaskItem }) => {
    const { id, enabled, min, max, radar_id } = args.mask
    let url = `/api/sites/${args.siteID}/radar_agl_masks`
    let method = 'post'
    if (id) {
      url = url + `/${id}`
      method = 'patch'
    }
    return await apiRequest(
      method,
      url,
      { radar_agl_mask: { enabled, min, max, radar_id } },
      (data: IAGLMaskResponse) => {
        commit('UPDATE_AGLMASK', data.radar_agl_mask)
        return {
          success: true,
          maskId: data.radar_agl_mask.id
        }
      },
      (data, _) => {
        const error = new Error(data.error)
        return {
          success: false,
          data: error
        }
      }
    )
  },
  // Load all AGL masks
  fetchAGLMaskTotal: async ({ commit }: { commit: Commit }, siteID: number) => {
    return await apiRequest(
      'get',
      `/api/sites/${siteID}/radar_agl_masks`,
      {},
      (data: IAGLMaskResponseList) => {
        commit('SET_AGLMASKS', data)
      },
      (data, _) => {
        return null
      }
    )
  }
}
