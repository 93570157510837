import {ISiteWarning, ISiteWarningState} from "@/store/modules/site_warnings/types";
import {ISiteState} from "@/store/modules/sites/types";
import {forEach} from "lodash";

export default {
  SOCKET_SITE_WARNINGS_CREATE: (state: ISiteWarningState, payload: ISiteWarning) => {
    state.site_warnings = [
      ...state.site_warnings,
      payload[0] // site warnings message in socket is always an array
    ]
  },
  SET_SITE_WARNINGS: (state: ISiteWarningState, payload: ISiteWarning[]) => {
    state.site_warnings = [...payload]
  },
  SET_SITE_WARNINGS_HISTORY: (state: ISiteWarningState, payload: ISiteWarning[]) => {
    state.site_warnings_history = [...payload]
  },
  UPDATE_MARKED_AS_READ_SITE_WARNINGS: (state: ISiteWarningState, site_warning: ISiteWarning) => {
    state.site_warnings_history.push(site_warning)
    state.site_warnings = state.site_warnings.filter(s => s.id !== site_warning.id)
  },
  SET_STATUS: (state: any, status: any) => (state.status = status),
  SET_ERROR: (state: any, error: any) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  SET_CLEARING_SITE_WARNINGS: (state: ISiteWarningState, clearing: boolean) => {
    state.clearingSiteWarnings = clearing
  },
  SET_CLEAR_SITE_WARNINGS_LOADING: (state: ISiteWarningState, loading: boolean) => {
    state.clearSiteWarningsLoading = loading
  },
  UPDATE_CLEAR_ALL_SITE_WARNINGS: (state: ISiteWarningState, site_warnings: ISiteWarning[]) => {
    site_warnings.forEach((site_warning: ISiteWarning) => {
      state.site_warnings_history.push(site_warning)
      state.site_warnings = state.site_warnings.filter(s => s.id !== site_warning.id)
    })
  },
}
