import { apiRequest } from '../../utils'
import { Commit } from 'vuex'
import {
  ITrackerDeviceSetup,
  ITrackerDeviceDelete,
  ITrackerGps,
  ITrackerList,
  ITrackerMesssageResponse,
  ITrackerStreamPayload,
  ITrackerStreamUpdateResponse,
  ITrackerDeviceUpdate
} from '@/store/modules/trackers/types'

export default {
  updateTrackimoStreamingState: async (
    { dispatch },
    payload: ITrackerStreamPayload
  ) => {
    return await apiRequest(
      'patch',
      `/api/sites/${payload.site_id}/drone_gpses/${payload.drone_gps_id}/devices/${payload.id}`,
      {
        drone_gps_device: {
          stream: payload.state
        }
      },
      (data: ITrackerStreamUpdateResponse) => {
        dispatch('getTrackimoTrackers', payload.site_id)
        return {
          data: data,
          error: false
        }
      },
      (data, _) => {
        return {
          data: data.error,
          error: true
        }
      }
    )
  },
  getTrackimoTrackers: async (
    { commit }: { commit: Commit },
    site_id: number
  ) => {
    await apiRequest(
      'get',
      `/api/sites/${site_id}/drone_gpses`,
      {},
      (data: ITrackerList) => {
        commit('SET_TRACKERS', data.drone_gpses)
      },
      (data, _) => {
        return {
          data: data.error,
          error: true
        }
      }
    )
  },
  addTrackimoTracker: async (
    { commit }: { commit: Commit },
    payload: ITrackerDeviceSetup
  ) => {
    return await apiRequest(
      'post',
      `/api/sites/${payload.site_id}/drone_gpses`,
      { drone_gps: payload },
      (data: ITrackerGps) => {
        return {
          data: data,
          error: false
        }
      },
      (data, _) => {
        return {
          data: data.error,
          error: true
        }
      }
    )
  },
  deleteTrackimoTracker: async (
    { commit }: { commit: Commit },
    payload: ITrackerDeviceDelete
  ) => {
    return await apiRequest(
      'delete',
      `/api/sites/${payload.site_id}/drone_gpses/${payload.id}`,
      {},
      (data: ITrackerMesssageResponse) => {
        return {
          data: data.message,
          error: false
        }
      },
      (data, _) => {
        return {
          data: data.error,
          error: true
        }
      }
    )
  },
  updateTrackimoTracker: async (
    { commit }: { commit: Commit },
    input: ITrackerDeviceUpdate
  ) => {
    return await apiRequest(
      'patch',
      `/api/sites/${input.payload.site_id}/drone_gpses/${input.id}`,
      { drone_gps: input.payload },
      (data: ITrackerGps) => {
        return {
          data: data,
          error: false
        }
      },
      (data, _) => {
        return {
          data: data.error,
          error: true
        }
      }
    )
  }
}
