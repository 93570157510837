











































import { FormMixin } from '@/components/Mixins'
import { IData } from '@/store/modules/base/types'

const props = {
  status: String,
  error: String,
  includeCancel: {
    type: Boolean,
    default: false
  },
  dataCy: String
}

export default {
  name: 'd-form',
  mixins: [FormMixin],
  props,
  data: (): IData => ({
    valid: false,
    expanded: null
  }),
  computed: {
    hasGeneralSlot(): boolean {
      return !!this.$slots['general']
    },
    hasAdvancedSlot(): boolean {
      return !!this.$slots['advanced']
    }
  },
  methods: {
    onCancel(): void {
      this.$_resetValidation()
      this.$emit('onCancel')
    },
    onSave(): void {
      this.$emit('onSubmit')
    }
  },
  mounted(): void {
    this.$_resetValidation()
  },
  watch: {
    expanded: {
      handler: function(): void {
        this.$emit('advancedExpanded', this.expanded)
      }
    }
  }
}
