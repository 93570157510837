import { setPersistent } from '../../utils'

export default {
  ACTIVATE_ZONE: (state, id) => {
    state.activeZoneId = id
  },
  VISIBLE_ZONE_TYPES: (state, values) => {
    setPersistent(state, 'visibleZoneType', values)
  },
  ALERT_ZONE_FILL_PATTERN: (state, value) => {
    setPersistent(state, 'alertZoneFillPattern', value)
    if (value === true)
      state.zoneTypeColors.alert.fillPattern = require(`@/assets/patterns/alert.png`)
    else
      state.zoneTypeColors.alert.fillPattern = null
  },
  SET_STATUS: (state, status) => (state.status = status),
  SET_ERROR: (state, error) => (state.error = error),
  // Deactivate zone
  DEACTIVATE_ZONE: state => (state.activeZoneId = null),
  // Functionally update zones
  UPDATE_ZONE: (state, zones) => {
    if (!Array.isArray(zones)) {
      zones = [zones]
    }
    state.zonesList.forEach(existing => {
      const updated = zones.find(s => s.id == existing.id)
      return Object.assign(existing, updated)
    })
  },
  // Add a zone to list
  ADD_ZONE: (state, zone) => {
    const newZone = {
      ...zone,
      coordinate_list: zone.coordinates,
      coordinates: JSON.stringify(zone.coordinates),
    }
    state.zonesList.push(newZone)
  },

  // Remove a zone from the list
  REMOVE_ZONE: (state, id) =>
    (state.zonesList = state.zonesList.filter(s => s.id !== id)),

  // Load zones in their own list.
  LOAD_ZONES: (state, zones) => {
    const zonesList = zones.map(zone => ({
      ...zone,
      coordinate_list: zone.coordinates,
      coordinates: JSON.stringify(zone.coordinates),
    }))
    return (state.zonesList = zonesList)
  },
  SET_ACTIVE_ZONE: (state, zone) => {
    state.activeZone = zone
    state.activeZoneId = zone?.id || null
  }
}
