














































import { FormMixin } from '@/components/Mixins'

const props = {
  value: {
    type: Boolean,
    default: false
  },
  validForm: {
    type: Boolean,
    default: false
  },
  message: {
    type: String
  },
  title: {
    type: String
  },
  status: {
    type: String
  },
  error: {
    type: String
  },
  confirmOnceStatus: {
    type: String
  }
}

export default {
  name: 'BaseDialog2',
  props,
  mixins: [FormMixin],
  computed: {
    valid: {
      get() {
        return this.validForm
      },
      set(val) {
        this.$emit('onValidateForm', val)
      }
    }
  },
  methods: {
    confirm(): void {
      if (this.confirmOnceStatus && this.status && this.status.toUpperCase() == this.confirmOnceStatus) return
      if (this.valid && this.$refs.form.validate()) this.$emit('onConfirm')
    },
    closeDialog(): void {
      this.$emit('onClose')
      this.reset()
    },
    reset(): void {
      this.$refs.form.reset()
    },
    resetValidation(): void {
      this.$refs.form.resetValidation()
    }
  },
  watch: {
    status(s) {
      if (s === 'OK') {
        return setTimeout(() => this.closeDialog(), 500)
      }
    }
  }
}
