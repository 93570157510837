import colors from 'vuetify/es5/util/colors'
const FHSS_DASH_ARRAY = '2,16,24,16'
const themes = {
  DroneShield: {
    name: 'DroneShield',
    forceMap: false,
    map: 'ESRI World Imagery Satellite',
    detection: {
      sectorColor: colors.red.base,
      sectorFill: colors.red.lighten1,
      sectorColorWhitelisted: colors.lightBlue.base,
      sectorFillWhitelisted: colors.lightBlue.lighten2,
      lineWeight: {
        selected: 2,
        unselected: 0.8
      },
      opacity: {
        selected: 0.6,
        unselected: 0.4
      }
    },
    speedLeader: {
      alwaysShow: true,
      color: colors.red.lighten1,
      dashArray: '2,6',
      weight: 2,
      opacity: 0.9
    },
    fhss: {
      dashArray: FHSS_DASH_ARRAY
    },
    icons: {
      type: {
        sentry: 'dro',
        detection: 'dro'
      },
      size: [30, 30],
      anchor: [15, 15]
    }
  },
  MIL_STD_2525: {
    name: 'MIL-STD-2525',
    forceMap: true,
    map: 'ESRI World Imagery Topology',
    detection: {
      sectorColor: 'black',
      sectorFill: 'grey',
      sectorColorWhitelisted: colors.lightBlue.base,
      sectorFillWhitelisted: colors.lightBlue.lighten2,
      lineWeight: {
        selected: 2.0,
        unselected: 0.3
      },
      opacity: {
        selected: 0.7,
        unselected: 0.4
      }
    },
    fhss: {
      dashArray: FHSS_DASH_ARRAY
    },
    speedLeader: {
      alwaysShow: true,
      color: '#000',
      dashArray: '1',
      weight: 2,
      opacity: 1.0
    },
    icons: {
      type: {
        sentry: '2525',
        detection: '2525'
      },
      size: [10, 10],
      anchor: [5, 5]
    }
  },
  Test: {
    name: 'Test',
    show: false,
    forceMap: false,
    map: 'ESRI World Imagery Topology',
    detection: {
      sectorColor: '#546E7A',
      sectorFill: '#EEFF41',
      sectorColorWhitelisted: colors.lightBlue.base,
      sectorFillWhitelisted: colors.lightBlue.lighten2,
      lineWeight: {
        selected: 2.0,
        unselected: 0.3
      },
      opacity: {
        selected: 0.7,
        unselected: 0.4
      }
    },
    speedLeader: {
      alwaysShow: true,
      color: '#00897B',
      dashArray: '2,6',
      weight: 3,
      opacity: 0.8
    },
    icons: {
      type: {
        sentry: 'dro',
        detection: '2525'
      },
      size: [10, 10],
      anchor: [5, 5]
    }
  }
}

function getTheme(name) {
  return themes[name] || themes.DroneShield
}

function getThemesList() {
  let items = []
  const keys = Object.keys(themes)
  for (let x = 0; x < keys.length; x++) {
    const config = themes[keys[x]]
    if (config.show !== false) {
      items.push({
        value: keys[x],
        label: themes[keys[x]].name
      })
    }
  }
  return items
}

export { getTheme, getThemesList }
