import { setPersistent } from '../../utils'

export default {
  SET_NOTIFICATION_SORT: (state, payload) => {
    state.notificationSort = payload
    setPersistent(state, 'notificationSort', state.notificationSort)
  },
  SET_FUSION_NOTIFICATION_SORT: (state, payload) => {
    state.fusionNotificationSort = payload
    setPersistent(state, 'fusionNotificationSort', state.fusionNotificationSort)
  },
  DISMISS_ALERT: (state, payload) => {
    state.dismissedDetections.push(payload)
  },
  ADD_DETECTIONS: (state, payload) => {
    state.detections = { ...state.detections, ...payload }
  },
  CLEAR_DETECTIONS: state => {
    state.detections = {}
  },
  ADD_ALERTS: (state, alerts) => {
    state.alerts.push(...alerts)
  },
  ADD_ALERT: (state, alert) => {
    const index = state.alerts.findIndex(a => {
      return alert.key === a.key
    })
    const newAlertsObj = [...state.alerts]
    if (index < 0) {
      newAlertsObj.push(alert)
    } else {
      newAlertsObj[index] = alert
    }
    state.alerts = newAlertsObj
  },
  REMOVE_ALERT: (state, event) => {
    state.alerts = state.alerts.filter((alert) => alert.key !== event.key)
    if (state.activeAlert.key === event.key) state.activeAlert = null
  },
  CLEAR_ALERTS: (state) => {
    state.alerts = []
    state.activeAlert = null
  },
  ADD_ICON: (state, { key, icon }) => {
    state.icons[key] = icon
  },
  CLEAR_ICONS: state => {
    state.icons = {}
  },
  SET_ACTIVE_ALERT: (state, alert) => {
    state.activeAlert = alert
  },
  SELECT_DETECTION: (state, detection) => {
    state.selectedDetection = detection
  },
  SET_DEFERRED_ALERT: (state, alertId) => {
    state.deferredAlert = alertId
  }
}
