import { Commit, Dispatch } from 'vuex'
import { axiosInstance } from '../../utils'
import {IDroneCommandObject, IDroneFlightSettings, IDroneMCUUnitsState} from "./types";

export default {
  SOCKET_DRONE_MCU_UNITS_UPDATE: async (
    {
      commit,
      state,
      dispatch
    }: { commit: Commit; state: any; dispatch: Dispatch },
    drone_mcu_units: Partial<IDroneMCUUnitsState>
  ) => {
    commit('SET_DRONE_MCU_UNITS', drone_mcu_units)
  },

  SOCKET_DRONE_COMMAND_UPDATE: async (
    {
      commit,
      state,
      dispatch
    }: { commit: Commit; state: any; dispatch: Dispatch },
    commandUpdateBody: any
  ) => {
    const existingId = state?.commands?.last_command?.id
    if (existingId === commandUpdateBody.id) {
      commit('SET_DRONE_LAST_COMMAND', commandUpdateBody)
    }
  },
  SAVE_DRONE_FLIGHT_SETTINGS: async (
    {
      commit,
      state,
      dispatch
    }: { commit: Commit; state: any; dispatch: Dispatch },
    settings: IDroneFlightSettings
  ) => {
    // Create drone command payload to send to tethered drone.
    try {
      const body = await axiosInstance({
        method: 'patch',
        url: `/drone_mcu_units/${settings.ip.replace(/\./g, '-')}.json`,
        data: {
          drone_mcu_unit: {
            settings: settings.body
          }
        }
      })
      return body
    } catch (err) {
      console.error(err.message)
      throw err
    }
  },
  SEND_DRONE_MCU_UNITS_COMMAND: async (
    { commit, state }: { commit: Commit; state: any },
    commandPayload: Partial<IDroneCommandObject>
  ) => {
    // Create drone command payload to send to tethered drone.
    try {
      //make sure no command is being waited on.
      if (state?.commands?.last_command?.status === 'queued') {
        throw `still waiting on ${state?.commands?.last_command?.command} to finish processing`
      }

      const { data } = await axiosInstance({
        method: 'post',
        url: `/commands.json`,
        data: {
          command: {
            is_manual: true,
            device_type: 'drone_mcu_unit',
            ...commandPayload
          }
        }
      })

      // set last drone command sent
      commit('SET_DRONE_LAST_COMMAND', data)
      //set timeout for timed out command
      setTimeout(() => {
        //if after 10 seconds, set status to failed
        if (state?.commands?.last_command?.status === 'queued') {
          commit('SET_DRONE_LAST_COMMAND', {
            status: 'failed',
            status_message: 'timed out, try again'
          })
        }
      }, 15000)
    } catch (err) {
      console.error(err)
      throw err
    }
  }
}
