export default {
  methods: {
    $_dismiss() {
      this.$store.commit('sensors/SET_ERROR', null)
      this.$store.commit('sensors/SET_STATUS', null)
      this.$store.commit('sites/SET_ERROR', null)
      this.$store.commit('sites/SET_STATUS', null)
    },
    $_onClickSave() {
      return this.$emit('onClose')
    },
    $_onClickClose() {
      return this.$emit('onClose')
    },
    $_updateSensorCoord({ lat, lng }) {
      this.sensorData.latitude = lat
      this.sensorData.longitude = lng
    }
  },
  watch: {
    // prevent bug with leaflet not rendering all tiles
    dialog: () => {
      window.dispatchEvent(new Event('resize'))
    }
  }
}
