


























































































import Vue from 'vue'
import { deg2rad } from '@/utils/utils'
import { ISliderItem } from '@/components/Dialogs/Filters/OverviewComponents/SliderComponent.vue'
import { ISlice, TPathData } from '../types'

const props = {
  highlighted: {
    type: Array as () => number[],
    default: () => []
  },
  filters: {
    type: Array as () => ISliderItem[],
    default: () => []
  },
  width: {
    type: Number,
    default: 200
  },
  height: {
    type: Number,
    default: 200
  }
}

interface ILabelData {
  anchor: string
  dx: number
  dy: number
  label: string
  x: number
  y: number
}

interface ILineData {
  angle: number
  x1: number
  x2: number
  y1: number
  y2: number
}

export default Vue.extend({
  name: 'AnglePreviewBlock',
  props,
  data() {
    return {
      tooltipData: null,
      wedgeFill: '#676767',
      wedgeStroke: '#303030',
      selectedWedgeFill: '#A1743B',
      selectedWedgeStroke: '#303030'
    }
  },
  computed: {
    filterSlices(): ISlice[] {
      const offset = 90
      return this.filters.map((filter: ISliderItem) => {
        const startAngle = deg2rad(filter.value + offset - filter.tolerance)
        const endAngle = deg2rad(filter.value + offset + filter.tolerance)
        return {
          id: filter.id,
          startAngle,
          endAngle
        }
      })
    },
    radius(): number {
      return Math.min(this.width, this.height)
    },
    detectionAreaSlice(): ISlice {
      const angle = deg2rad(140)
      const startAngle = deg2rad(20)
      const endAngle = startAngle + angle
      return {
        startAngle,
        endAngle,
        color: 'transparent'
      }
    },
    labels(): ILabelData[] {
      const labels = [
        { label: '90°', angle: 0, anchor: 'start', dx: 5, dy: 0 },
        { label: '45°', angle: -45, anchor: 'start', dx: 5, dy: -5 },
        { label: '0°', angle: -90, anchor: 'middle', dx: 3, dy: -10 },
        { label: '-45°', angle: -135, anchor: 'end', dx: -5, dy: -5 },
        { label: '-90°', angle: -180, anchor: 'end', dx: -5, dy: 0 }
      ]
      return labels.map(label => {
        const x = this.width / 2 + this.radius * Math.cos(deg2rad(label.angle))
        const y = this.height / 2 + this.radius * Math.sin(deg2rad(label.angle))
        return {
          label: label.label,
          anchor: label.anchor,
          dx: label.dx,
          dy: label.dy,
          x,
          y
        }
      })
    },
    lines(): ILineData[] {
      const angles = [45, 90, 135]
      return angles.map(angle => {
        const x1 = this.width / 2
        const y1 = this.height / 2
        const x2 = this.width / 2 + this.radius * Math.cos(deg2rad(angle))
        const y2 = this.height / 2 + this.radius * Math.sin(deg2rad(angle))
        return {
          angle,
          x1,
          y1,
          x2,
          y2
        }
      })
    },
    slices(): ISlice[] {
      const angles = [
        { angle: 20, color: '#a92e31', tooltip: 'No Detections' },
        {
          angle: 25,
          color: 'transparent',
          tooltip: 'Peripheral Detection Area'
        },
        { angle: 45, color: 'transparent', tooltip: 'Detection Area' },
        { angle: 45, color: 'transparent', tooltip: 'Detection Area' },
        {
          angle: 25,
          color: 'transparent',
          tooltip: 'Peripheral Detection Area'
        },
        { angle: 20, color: '#a92e31', tooltip: 'No Detections' }
      ]
      const baseSlice = {
        startAngle: 0,
        endAngle: deg2rad(angles[0].angle),
        color: angles[0].color,
        tooltip: angles[0].tooltip
      }
      const slices = []
      angles.forEach((_, index) => {
        let start = 0
        if (index !== 0) {
          start = slices[index - 1].endAngle
        }
        const end = start + deg2rad(angles[index].angle)
        slices[index] = {
          ...baseSlice,
          startAngle: start,
          endAngle: end,
          color: angles[index].color,
          tooltip: angles[index].tooltip
        }
      })
      return slices
    }
  },
  methods: {
    deg2rad,
    isHighlighted(id: number): boolean {
      return this.highlighted.includes(id)
    },
    getSlicePath(slice: ISlice): TPathData {
      const { startAngle, endAngle } = slice
      const radius = this.radius
      const x1 = this.width / 2 + radius * Math.cos(startAngle)
      const y1 = this.height / 2 + radius * Math.sin(startAngle)
      const x2 = this.width / 2 + radius * Math.cos(endAngle)
      const y2 = this.height / 2 + radius * Math.sin(endAngle)
      const largeArc = endAngle - startAngle <= Math.PI ? 0 : 1
      return `M ${this.width / 2},${this.height /
        2} L ${x1},${y1} A ${radius},${radius} 0 ${largeArc} 1 ${x2},${y2} Z`
    },
    onMouseover(tooltip: string, event: MouseEvent): void {
      this.tooltipData = {
        tooltip: tooltip,
        x: event.pageX,
        y: event.pageY
      }
    },
    onMouseleave(): void {
      this.tooltipData = null
    }
  }
})
