import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const getDefaultState = () => {
  return {
    status: null,
    error: null,
    plansList: [],
    activePlan: null,
    activeInstallation: null,
    plansState: {
      coords: null,
      zoom: null
    },
    isDragging: false,
    isWizard: false,
    bounds: null
  }
}

const state = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
