











































import Vue from 'vue'
import { deg2rad, rad2deg } from '@/utils/utils'
import { ISliderItem } from '@/components/Dialogs/Filters/OverviewComponents/SliderComponent.vue'
import {
  IDsxPreviewObject,
  ISlice,
  TColor,
  TPathData
} from '@/components/Dialogs/Filters/types'

type TWedgeId = number

interface ILabelProp {
  label: string
  x: number
  y: number
}

interface IAdjacentWedges {
  before: TWedgeId
  after: TWedgeId
}

interface IWedgeData {
  angle: number
  index: TWedgeId
  label: string
}

enum ERadialOp {
  ADD = 'add',
  SUB = 'sub'
}

const props = {
  width: {
    type: Number,
    default: 200
  },
  height: {
    type: Number,
    default: 200
  },
  filters: {
    type: Array as () => ISliderItem[],
    default: () => []
  },
  highlighted: {
    type: Array as () => TWedgeId[],
    default: () => []
  },
  readOnly: {
    type: Boolean,
    default: false
  }
}

const INDEX_SECTOR_MAP = {
  0: { index: 0, angle: 0, label: 'FRONT' },
  1: { index: 1, angle: 45, label: 'FRONT-RHS' },
  2: { index: 2, angle: 90, label: 'RHS' },
  3: { index: 3, angle: 135, label: 'REAR-RHS' },
  4: { index: 4, angle: 180, label: 'REAR' },
  5: { index: 5, angle: 225, label: 'REAR-LHS' },
  6: { index: 6, angle: 270, label: 'LHS' },
  7: { index: 7, angle: 315, label: 'FRONT-LHS' }
}

const ANGLE_INDEX_MAP = {
  0: 0,
  45: 1,
  90: 2,
  135: 3,
  180: 4,
  225: 5,
  270: 6,
  315: 7
}

const WEDGE_ANGLE = 45
const HALF_OF_WEDGE = WEDGE_ANGLE / 2

export default Vue.extend({
  name: 'WedgeBlock',
  props,
  inject: ['providedData'],
  data() {
    return {
      filterWedgeData: {},
      selected: [],
      adjacents: {
        0: {
          before: 7,
          after: 1
        },
        1: {
          before: 0,
          after: 2
        },
        2: {
          before: 1,
          after: 3
        },
        3: {
          before: 2,
          after: 4
        },
        4: {
          before: 3,
          after: 5
        },
        5: {
          before: 4,
          after: 6
        },
        6: {
          before: 5,
          after: 7
        },
        7: {
          before: 6,
          after: 0
        }
      }
    }
  },
  mounted(): void {
    this.$emitter.on('update:dsx:wedge:data', this.updateFilterWedgeData)
    if (this.filters.length > 0) {
      this.processFilters(this.filters)
      this.$emit('updated:sector', this.responseObject)
    }
  },
  beforeDestroy(): void {
    this.$emitter.off('update:dsx:wedge:data', this.updateFilterWedgeData)
  },
  computed: {
    labelProps(): ILabelProp[] {
      return [
        {
          label: 'FRONT',
          x: this.width / 2,
          y: -15
        },
        {
          label: 'REAR',
          x: this.width / 2,
          y: this.height + 15
        },
        {
          label: 'LHS',
          x: -25,
          y: this.height / 2
        },
        {
          label: 'RHS',
          x: this.width + 25,
          y: this.height / 2
        }
      ]
    },
    highlightedWedges(): TWedgeId[] {
      let wedges = []
      this.highlighted.forEach(key => {
        wedges = wedges.concat(this.filterWedgeData[key])
      })
      return wedges
    },
    sensor(): unknown {
      return this.providedData['sensor']
    },
    slices(): ISlice[] {
      const baseSlice = {
        startAngle: 0,
        endAngle: Math.PI / 4
      }
      const slices = []
      for (let x = 0; x < 8; x++) {
        let start = 0
        if (slices.length !== 0) {
          start = slices[x - 1].endAngle
        }
        const end = start + Math.PI / 4
        slices[x] = {
          ...baseSlice,
          startAngle: start,
          endAngle: end,
          label: INDEX_SECTOR_MAP[x].label
        }
      }
      return slices
    },
    adjacentValues(): IAdjacentWedges {
      if (this.selected.length === 0) return null
      return {
        before: this.adjacents[this.selected[0]].before,
        after: this.adjacents[this.selected[this.selected.length - 1]].after
      }
    },
    startWedge(): IWedgeData {
      return INDEX_SECTOR_MAP[this.selected[0]] || null
    },
    endWedge(): IWedgeData {
      return INDEX_SECTOR_MAP[this.selected[this.selected.length - 1]] || null
    },
    startAngle(): number {
      if (this.startWedge === null) return null
      let angle = deg2rad(this.startWedge?.angle) - deg2rad(HALF_OF_WEDGE)

      return rad2deg(angle)
    },
    endAngle(): number {
      if (this.endWedge === null) return null

      let angle = deg2rad(this.endWedge?.angle) + deg2rad(HALF_OF_WEDGE)

      return rad2deg(angle)
    },
    centerAngle(): number {
      if (this.selected.length === 0) return null
      if (this.selected.length === 8) return 0
      if (this.selected.length % 2 === 0) {
        const middleIndex = this.selected.length / 2 - 1
        return (
          INDEX_SECTOR_MAP[this.selected[middleIndex]].angle + HALF_OF_WEDGE
        )
      } else {
        const middleIndex = Math.floor(this.selected.length / 2)
        return INDEX_SECTOR_MAP[this.selected[middleIndex]].angle
      }
    },
    centerTolerance(): number {
      const total = this.selected.length * WEDGE_ANGLE
      return total / 2 || null
    },
    responseObject(): IDsxPreviewObject {
      return {
        bearing: this.centerAngle,
        bearing_tolerance: this.centerTolerance
      }
    }
  },
  methods: {
    isHighlighted(id: TWedgeId): boolean {
      return this.highlightedWedges.includes(id)
    },
    isSelected(id: TWedgeId): boolean {
      return this.selected.includes(id)
    },
    getSectorData(): IDsxPreviewObject {
      return this.responseObject
    },
    selectWedge(index: TWedgeId): void {
      if (this.readOnly) return
      if (!this.selected.includes(index)) {
        if (this.selected.length === 0) {
          this.selected.push(index)
        } else if (Object.values(this.adjacentValues).includes(index)) {
          if (this.adjacentValues.before === index) {
            this.selected.unshift(index)
          } else {
            this.selected.push(index)
          }
        }
      } else {
        if (this.selected.length === 8) {
          if (this.selected[0] === index) {
            const newSelected = [...this.selected]
            const itemIndex = newSelected.findIndex(item => item === index)
            newSelected.splice(itemIndex, 1)
            this.selected = newSelected
          } else {
            // rearrange selection
            const newWedges = [...this.selected]
            while (newWedges[0] !== index) {
              const item = newWedges.shift()
              newWedges.push(item)
            }
            this.selected = newWedges
          }
        }
        if (
          this.selected[0] === index ||
          this.selected[this.selected.length - 1] === index
        ) {
          const newSelected = [...this.selected]
          const itemIndex = newSelected.findIndex(item => item === index)
          newSelected.splice(itemIndex, 1)
          this.selected = newSelected
        }
      }
      this.$emit('updated:sector', this.responseObject)
    },
    getSlicePath(slice: ISlice): TPathData {
      const { startAngle, endAngle } = slice
      const radius = Math.min(this.width, this.height) / 2
      const x1 = this.width / 2 + radius * Math.cos(startAngle)
      const y1 = this.height / 2 + radius * Math.sin(startAngle)
      const x2 = this.width / 2 + radius * Math.cos(endAngle)
      const y2 = this.height / 2 + radius * Math.sin(endAngle)
      const largeArc = endAngle - startAngle <= Math.PI ? 0 : 1
      return `M ${this.width / 2},${this.height /
        2} L ${x1},${y1} A ${radius},${radius} 0 ${largeArc} 1 ${x2},${y2} Z`
    },
    getWedgeFromAngle(angle: number): TWedgeId {
      return ANGLE_INDEX_MAP[angle]
    },
    radialOp(angle1: number, angle2: number, operation: ERadialOp): number {
      let result = angle1 + angle2
      if (operation === ERadialOp.ADD) {
        result = angle1 + angle2
        while (result >= 360) {
          result -= 360
        }
      } else if (operation === ERadialOp.SUB) {
        result = angle1 - angle2
        while (result < 0) {
          result += 360
        }
      }
      return result
    },
    convertAngleToWedges(item: ISliderItem): TWedgeId[] {
      const { value, tolerance } = item
      let selected = []
      if (value === null || tolerance === null) return []
      if (tolerance === 180) {
        selected = [0, 1, 2, 3, 4, 5, 6, 7]
      } else {
        const center = value
        const sideWedgeCount =
          tolerance % 1 === 0
            ? tolerance / WEDGE_ANGLE
            : (tolerance - HALF_OF_WEDGE) / WEDGE_ANGLE

        const wedgeIndex = this.getWedgeFromAngle(center)
        if (wedgeIndex || wedgeIndex === 0) {
          // center is middle of wedge
          selected = [this.getWedgeFromAngle(center)]
          for (let x = 1; x <= sideWedgeCount; x++) {
            const offset = WEDGE_ANGLE * x
            const leadingWedge = this.radialOp(center, offset, ERadialOp.SUB)
            const trailingWedge = this.radialOp(center, offset, ERadialOp.ADD)
            selected.push(this.getWedgeFromAngle(trailingWedge))
            selected.unshift(this.getWedgeFromAngle(leadingWedge))
          }
        } else {
          // center is between wedges
          for (let x = 0; x < sideWedgeCount; x++) {
            const offset = HALF_OF_WEDGE + WEDGE_ANGLE * x
            const leadingWedge = (center - offset) % 360
            const trailingWedge = (center + offset) % 360
            selected.push(this.getWedgeFromAngle(trailingWedge))
            selected.unshift(this.getWedgeFromAngle(leadingWedge))
          }
        }
      }
      return selected
    },
    processFilters(filters: ISliderItem[]): void {
      const filterData = {}
      filters.forEach((filter: ISliderItem) => {
        filterData[filter.id] = this.convertAngleToWedges(filter)
      })
      this.filterWedgeData = filterData
      this.selected = [].concat(...Object.values(filterData).map(data => data))
    },
    updateFilterWedgeData(): void {
      this.processFilters(this.filters)
    }
  }
})
