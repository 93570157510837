export default {
  token: state => state.token && state.token['token'],
  hasTokenExpired: state =>
    state.token && new Date(state.token.expires_at).valueOf() - Date.now() < 0,
  status: state => state.status,
  error: state => state.error,
  sessionId: state => state.sessionId,
  isAuthenticated: state => {
    return !!state.token
  }
}
