import actions from './actions'

import { IRfSensorState } from './types'
import getters from '@/store/modules/rf_sensors/getters'
import mutations from '@/store/modules/rf_sensors/mutations'

const getDefaultState = (): IRfSensorState => {
  return {
    rfSensorsSet: {},
    dsxSensorsSet: {},
    activeRfSensorId: null,
    status: '',
    error: ''
  }
}

const state = getDefaultState()

const resetState = (state: IRfSensorState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
