var END = {
  mousedown: 'mouseup',
  touchstart: 'touchend',
  pointerdown: 'touchend',
  MSPointerDown: 'touchend'
}

var MOVE = {
  mousedown: 'mousemove',
  touchstart: 'touchmove',
  pointerdown: 'touchmove',
  MSPointerDown: 'touchmove'
}

// function distance(a, b) {
//   var dx = a.x - b.x,
//     dy = a.y - b.y
//   return Math.sqrt(dx * dx + dy * dy)
// }

/**
 * Drag handler
 * @class L.Path.Drag
 * @extends {L.Handler}
 */
L.Handler.PathDrag = L.Handler.extend(
  /** @lends  L.Path.Drag.prototype */ {
    statics: {
      DRAGGING_CLS: 'leaflet-path-draggable'
    },

    /**
     * @param  {L.Path} path
     * @constructor
     */
    initialize: function(path) {
      this._path = path
      this._startPoint = null
      this._dragStartPoint = null
      this._mapDraggingWasEnabled = false
    },

    /**
     * Enable dragging
     */
    addHooks: function() {
      this._path.on('mousedown', this._onDragStart, this)

      this._path.options.className = this._path.options.className
        ? this._path.options.className + ' ' + L.Handler.PathDrag.DRAGGING_CLS
        : L.Handler.PathDrag.DRAGGING_CLS

      if (this._path._path) {
        L.DomUtil.addClass(this._path._path, L.Handler.PathDrag.DRAGGING_CLS)
      }
    },

    /**
     * @return {Boolean}
     */
    moved: function() {
      return this._path._dragMoved
    },

    /**
     * Start drag
     * @param  {L.MouseEvent} evt
     */
    _onDragStart: function(evt) {
      var eventType = evt.originalEvent._simulated
        ? 'touchstart'
        : evt.originalEvent.type

      this._mapDraggingWasEnabled = false
      this._startPoint = evt.containerPoint.clone()
      this._dragStartPoint = evt.containerPoint.clone()
      L.DomEvent.stop(evt.originalEvent)

      L.DomUtil.addClass(this._path._renderer._container, 'leaflet-interactive')
      L.DomEvent.on(document, MOVE[eventType], this._onDrag, this).on(
        document,
        END[eventType],
        this._onDragEnd,
        this
      )

      if (this._path._map.dragging.enabled()) {
        this._path._map.dragging.disable()
        this._mapDraggingWasEnabled = true
      }
      this._path._dragMoved = false

      if (this._path._popup) {
        this._path._popup._close()
      }
    },

    /**
     * Dragging
     * @param  {L.MouseEvent} evt
     */
    _onDrag: function(evt) {
      L.DomEvent.stop(evt)

      var first = evt.touches && evt.touches.length >= 1 ? evt.touches[0] : evt
      var containerPoint = this._path._map.mouseEventToContainerPoint(first)

      // skip taps
      if (evt.type === 'touchmove' && !this._path._dragMoved) {
        var totalMouseDragDistance = this._dragStartPoint.distanceTo(
          containerPoint
        )
        if (totalMouseDragDistance <= this._path._map.options.tapTolerance) {
          return
        }
      }

      var x = containerPoint.x
      var y = containerPoint.y

      var dx = x - this._startPoint.x
      var dy = y - this._startPoint.y

      // Send events only if point was moved
      if (dx || dy) {
        if (!this._path._dragMoved) {
          this._path._dragMoved = true
          this._path.fire('dragstart', evt)
          // we don't want that to happen on click
          this._path.bringToFront()
        }

        this._startPoint.x = x
        this._startPoint.y = y

        var pointXY = L.point(x, y)
        var pointlatlng = this._path._map.containerPointToLatLng(pointXY)
        this._path.fire('drag', pointlatlng)
      }
    },

    /**
     * Dragging stopped, apply
     * @param  {L.MouseEvent} evt
     */
    _onDragEnd: function(evt) {
      var containerPoint = this._path._map.mouseEventToContainerPoint(evt)
      var moved = this.moved()
      L.DomEvent.off(document, 'mousemove touchmove', this._onDrag, this)
      L.DomEvent.off(document, 'mouseup touchend', this._onDragEnd, this)

      var x = containerPoint.x
      var y = containerPoint.y
      var pointXY = L.point(x, y)
      var pointlatlng = this._path._map.containerPointToLatLng(pointXY)

      // consistency
      if (moved) {
        this._path.fire('dragend', pointlatlng)

        // hack for skipping the click in canvas-rendered layers
        var contains = this._path._containsPoint
        this._path._containsPoint = L.Util.falseFn
        L.Util.requestAnimFrame(function() {
          L.DomEvent.skipped({ type: 'click' })
          this._path._containsPoint = contains
        }, this)
      }

      this._startPoint = null
      this._dragStartPoint = null
      this._path._dragMoved = false

      if (this._mapDraggingWasEnabled) {
        if (moved) L.DomEvent.fakeStop({ type: 'click' })
        this._path._map.dragging.enable()
      }
    }
  }
)

L.Handler.PathDrag.makeDraggable = function(layer) {
  layer.dragging = new L.Handler.PathDrag(layer)
  return layer
}

L.Path.prototype.makeDraggable = function() {
  return L.Handler.PathDrag.makeDraggable(this)
}

L.Path.addInitHook(function() {
  if (this.options.className === 'draggableSector') {
    this.options.interactive = true
    if (this.dragging) {
      this.dragging.enable()
    } else {
      L.Handler.PathDrag.makeDraggable(this)
      this.dragging.enable()
    }
  }
})
