import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { EResponseStatus, IDroneMCUUnitsState } from './types'

const getDefaultState = () => {
  return {
    drone_mcu_unit: {
      flight_mode: 'Offline',
      flight_time: 0,
      gps_sats: 0,
      gps_status: '',
      status: 'Offline',
      status_message: null,
      state: '',
      voltage: 0,
      wattage: 0,
      id: null,
      altitude: 0,
      failures: []
    },
    commands: {
      last_command: null,
      status: EResponseStatus.WAITING
    },
    demo_mode: false
  }
}

const droneMcuUnitState = getDefaultState()

const resetState = (state: IDroneMCUUnitsState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state: droneMcuUnitState,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
