export const COLOR_THREAT = '#FA001B'
export const COLOR_FRIEND = '#0080F7'
export const NO_TRACKING_TARGET_ID = '00000000-0000-0000-0000-000000000000'
export const SF_DETECTION_TYPE = 'sensorFusion'
export const CONTROLLER_CLASSIFICATION = 'controller'

export interface IContributorHistoryItem {
  id: number
  type: string
  name?: string
  active?: boolean
}

export interface IContributionHistoryUpdate {
  contributor: IContributorHistoryItem
  target_id: string
}

export interface IDirectionPayload {
  id: string
  direction: number
}

export interface IDirections {
  [key: string]: number
}

export interface IDetections {
  [key: string]: IDetection
}

export interface ISeenDetectionContributors {
  [key: string]: IContributorHistoryItem[]
}

export interface IValueUnit {
  value: string | number
  unit: string
}

export enum EDetectionTrailStyle {
  LINE,
  DOTS,
  BOTH
}

export interface IDetectionPosition {
  lat: number
  lng: number
}

export interface IBeaconPosition {
  0: number
  1: number
}

export enum EDetectionState {
  Unfiltered = 'unfiltered',
  Filtered = 'filtered',
  Whitelisted = 'whitelisted'
}

export enum EContributionStateType {
  RADAR = 'radar',
  RF = 'rf',
  CAMERA = 'camera'
}

export interface IFirstContributionStatus {
  radar: boolean
  rf: boolean
  camera: boolean
}

export interface IDetectionState {
  enabled: boolean
  fusionDetections: IDetections
  selectedDetections: string[]
  seenContributors: ISeenDetectionContributors
  firstContributon: IFirstContributionStatus
  activeRfBeam: string
}

export interface IDetectionOnly {
  id: number
  created_at: string
  latitude: number
  longitude: number
  altitude: number
  frequency: number
  vendor: string
  protocol: string
  state: EDetectionState
  radar_confirmed: boolean
  rf_confirmed: boolean
  camera_confirmed: boolean
  discovair_confirmed: boolean
  probability: number
  target_id: string
  location_variance: number
  classification: string
  speed: number
  site_distance: number
  rcs: number
  heading: number
  speed_leader_latitude: number
  speed_leader_longitude: number
  time_on_target: number
  home_latitude: number
  home_longitude: number
  gcs_latitude: number
  gcs_longitude: number
  threat_level: number
}

export interface IDetection extends IDetectionOnly {
  DetectionContributions: IDetectionContribution[]
}

export interface IDetectionContribution {
  id: number
  detection_id: number
  serial_number: 'test1'
  created_at: string
  sensor_type: string
  weight: number
  aoa: number
  aoa_error: number
  classification: string
  rssi: number
  ssid: string
  signal_bars: number
  elevation: number
  candidate_identifier: string
  detector_identifier: string
  sensor_id: number
}
