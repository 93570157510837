








































import Vue from 'vue'
import { ISite } from '@/store/modules/sites/types'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isNullOrUndefined } from '@/utils/utils'

const props = {
  sites: {
    type: Array as () => ISite[],
    default: () => []
  },
  activeSiteId: {
    type: [Number, String],
    default: null
  },
  enabled: {
    type: Boolean,
    default: true
  }
}

export default Vue.extend({
  name: 'SiteSelector',
  props,
  computed: {
    ...mapGetters('auth', ['sessionId']),
    ...mapGetters('sites', {
      activeSite: 'activeSite',
      isCustomMapCenter: 'isCustomMapCenter',
      mapCenter: 'mapCenter'
    })
  },
  methods: {
    async onChangeSite(site: ISite): Promise<void> {
      const { id } = site

      if (!isNullOrUndefined(id)) {
        this.$emit('onChangeSite', id)
      }
    }
  }
})
