import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import moment from 'moment-timezone'

const getDefaultState = () => {
  return {
    showRFFiltered: false,
    showRFWhitelisted: false,
    showRadarRFWhitelisted: false,
    showFalsePositives: false,
    report: null,
    status: null,
    reportLink: null,
    error: null,
    timeframe: {
      lastUpdated: moment.utc(),
      start: moment.utc().subtract(1, 'days'),
      end: moment.utc()
    }
  }
}

const state = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
