









export default {
  name: 'd-panel-toolbar',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
