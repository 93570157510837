









import Vue from 'vue'
import QuickActionsSidebar from '@/components/QuickActions/QuickActionsSidebar.vue'
import NotificationSidebar from '@/components/Notifications/NotificationSidebar.vue'

export default Vue.extend({
  name: 'RightSidebar',
  components: {
    QuickActionsSidebar,
    NotificationSidebar
  }
})
