import { IDroneMCUUnitsState } from "./types";

export default {
  SET_DEMO_MODE: (state: IDroneMCUUnitsState, payload: boolean) =>
    (state.demo_mode = payload),
  SET_DRONE_MCU_UNITS: (state: IDroneMCUUnitsState, payload: any) =>
    (state.drone_mcu_unit = { ...state.drone_mcu_unit, ...payload[0] }),
  SET_DRONE_COMMAND_STATE: (state: IDroneMCUUnitsState, payload: any) =>
    (state.commands = payload),
  SET_DRONE_LAST_COMMAND: (state: IDroneMCUUnitsState, payload: any) =>
    (state.commands.last_command = {
      ...(state?.commands?.last_command || {}),
      ...payload
    })
}
