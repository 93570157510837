
import Vue from 'vue'

const HOME_SCREEN_RETURN_TIMEOUT_MS = 10 * 60 * 1000

export default Vue.extend({
  name: 'IdleListener',
  data() {
    return {
      homeScreenReturnTimeout: null
    }
  },
  computed: {
    currentPath() {
      return this.$route.path
    }
  },
  mounted() {
    this.setupEventListeners()
    this.resetIdleTimeout()
  },
  beforeDestroy() {
    this.stopIdleTimeout()
    this.tearDownEventListeners()
  },
  methods: {
    setupEventListeners() {
      document.addEventListener('touchmove', this.resetIdleTimeout)
      document.addEventListener('mousemove', this.resetIdleTimeout)
      document.addEventListener('keypress', this.resetIdleTimeout)
      document.addEventListener('click', this.resetIdleTimeout)
    },
    tearDownEventListeners() {
      document.removeEventListener('touchmove', this.resetIdleTimeout)
      document.removeEventListener('mousemove', this.resetIdleTimeout)
      document.removeEventListener('keypress', this.resetIdleTimeout)
      document.removeEventListener('click', this.resetIdleTimeout)
    },
    resetIdleTimeout() {
      if (this.currentPath !== '/') {
        clearTimeout(this.homeScreenReturnTimeout)
        this.homeScreenReturnTimeout = setTimeout(() => {
          this.$router.replace('/')
        }, HOME_SCREEN_RETURN_TIMEOUT_MS)
      }
    },
    stopIdleTimeout() {
      clearTimeout(this.homeScreenReturnTimeout)
      this.homeScreenReturnTimeout = null
    }
  },
  watch: {
    currentPath: {
      handler: function(path) {
        if (path === '/') {
          this.stopIdleTimeout()
        } else {
          this.resetIdleTimeout()
        }
      }
    }
  },
  render: () => null
})
