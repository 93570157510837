var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{staticClass:"mr-3 mb-3",attrs:{"color":"#303030","dark":"","slider-color":"transparent","hide-slider":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-tab',{key:"detection",attrs:{"disabled":_vm.alertsDisabled,"ripple":false}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("$vuetify.icons.detectionsIcon")])]}}])},[_c('span',[_vm._v("Alerts")])])],1),_c('v-tab',{key:"camera",attrs:{"disabled":_vm.cameraDisabled,"ripple":false}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("camera")])]}}])},[_c('span',[_vm._v("Optical Sensor")])])],1),_c('v-tab',{key:"effectors",attrs:{"disabled":_vm.effectorsDisabled,"ripple":false}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("report")])]}}])},[_c('span',[_vm._v("Effectors")])])],1),_c('v-tab',{key:"masks",attrs:{"disabled":_vm.masksDisabled,"ripple":false}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("track_changes")])]}}])},[_c('span',[_vm._v("Masks")])])],1),_c('v-tab-item',{key:"detection"},[(!_vm.alertsDisabled)?_c('AlertActions'):_vm._e()],1),_c('v-tab-item',{key:"camera"},[(!_vm.cameraDisabled)?_c('CameraActions'):_vm._e()],1),_c('v-tab-item',{key:"effectors"},[(!_vm.effectorsDisabled)?_c('EffectorActions'):_vm._e()],1),_c('v-tab-item',{key:"masks"},[(!_vm.masksDisabled)?_c('MaskActions'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }