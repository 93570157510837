import { axiosInstance, apiRequest } from '../../utils'

export default {
  FETCH_ZONES: async ({ commit }, siteId) => {
    try {
      const { statusText, status, data, headers } = await axiosInstance.get(
        `/api/sites/${siteId}/zones`
      )

      if (status !== 200) {
        throw new Error(statusText)
      }
      if (headers['content-type'] !== 'application/json') {
        throw new Error(`Zones response isn't JSON`)
      }

      return commit(`LOAD_ZONES`, data.zones)
    } catch (err) {
      commit('SET_STATUS', 'FAILED')
      commit('SET_ERROR', err.message)
      return commit(`LOAD_ZONES`, [])
    }
  },

  FETCH_ZONE: async ({ commit }, { siteID, id }) => {
    return await apiRequest(
      'get',
      `/api/sites/${siteID}/zones/${id}`,
      {},
      (data, headers) => {
        if (headers['content-type'] !== 'application/json') {
          throw new Error("zones response isn't JSON")
        }
        commit(`UPDATE_ZONE`, data)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },

  CREATE_ZONE: async ({ commit, dispatch }, zone) => {
    commit('SET_STATUS', 'LOADING')
    const { name, zone_type, coordinate_list, note } = zone
    const site_id = parseInt(zone.site_id)

    return await apiRequest(
      'post',
      `/api/sites/${site_id}/zones`,
      { name, zone_type, site_id, coordinates: coordinate_list, note },
      ({ zone }, _) => {
        commit(`ADD_ZONE`, zone)
        dispatch('SELECT_ZONE', zone.id)
        commit('SET_STATUS', 'OK')
        return zone.id
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },

  UPDATE_ZONE: async ({ commit }, zone) => {
    commit('SET_STATUS', 'LOADING')
    const { name, zone_type, site_id, coordinate_list, note, id } = zone

    return await apiRequest(
      'patch',
      `api/sites/${site_id}/zones/${id}`,
      { name, zone_type, site_id, coordinates: coordinate_list, note },
      (data, _) => {
        commit(`UPDATE_ZONE`, [data])
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },

  DELETE_ZONE: async ({ commit }, zone) => {
    const { site_id, id } = zone;
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'delete',
      `api/sites/${site_id}/zones/${id}`,
      {},
      (data, _) => {
        commit('REMOVE_ZONE', id)
        commit('SET_STATUS', 'OK')
        commit('SET_ACTIVE_ZONE', null)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },

  SELECT_ZONE: ({ commit }, id) => {
    return commit('ACTIVATE_ZONE', id)
  },

  setActiveZone: ({ commit }, zone) => {
    commit('SET_ACTIVE_ZONE', zone)
  }
}
