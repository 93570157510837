













const props = {
  persistent: {
    type: Boolean,
    default: true
  },
  dialog: Boolean
}

export default {
  name: 'BaseDialog',
  props,
  methods: {
    close(): void {
      this.$emit('onClickClose')
    }
  }
}
