
























import Vue from 'vue'
import ActionButtonGroup from './ActionButtonGroup.vue';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'CameraActions',
  components: {
    ActionButtonGroup
  },
  computed: {
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('cameras', ['camerasLength', 'currentCamera', 'currentCameraScanning', 'currentTrackedTargetId']),
    ...mapGetters('detection', ['hasDetections', 'selectedDetection', 'nextDetectionTargetId']),
    ...mapGetters('users', ['isAuthorized']),
    scanningEnabled(): boolean {
      return this.isAuthorized('admin') && this.camerasLength > 0
    },
    scanningDisabled(): boolean {
      return this.currentCamera.status_color !== 'green'
    },
    trackingDisabled(): boolean {
      if (this.selectedDetection) return false
      else if (this.currentTrackedTargetId) return false
      else return true
    },
    isCameraTracked(): boolean {
      return this.cameras.some(
        c => c.tracked_target_id === this.detection.target_id
      )
    }
  },
  methods: {
    ...mapActions('cameras', ['CAMERA_CONTROL', 'CAMERA_TRACK_FUSION', 'STOP_CAMERA_TRACK_FUSION']),
    ...mapActions('detection', ['selectDetection']),
    ...mapActions('selection', ['setActiveCamera']),
    toggleCameraScan() {
      this.CAMERA_CONTROL({
        camera_id: this.currentCamera.id,
        command: this.currentCamera.scanning ? 'stopScanning' : 'startScanning'
      })
    },
    handleCameraTracking() {
      if (this.currentTrackedTargetId) this.stopCameraTracking()
      else this.startCameraTracking(this.selectedDetection)
    },
    handleCameraTrackNext() {
      if (this.nextDetectionTargetId) 
        this.startCameraTracking(this.nextDetectionTargetId)
    },
    startCameraTracking(target_id: string) {
      this.CAMERA_TRACK_FUSION({
        siteId: this.activeSiteId,
        cameraId: this.currentCamera.id,
        tracked_target_id: target_id
      })
      this.setActiveCamera(this.currentCamera)
      this.selectDetection(target_id)
    },
    async stopCameraTracking() {
      this.STOP_CAMERA_TRACK_FUSION({
        siteId: this.activeSiteId,
        cameraId: this.currentCamera.id
      })
    }
  }
})
