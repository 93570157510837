<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    id="cancel-track-icon"
  >
    <path
      d="m12.116 13.734 1.0231 1.0231 0.8485-0.8485-11.866-11.867-0.84853 0.84852 0.9942 0.99422v2.5156h1.3832v-1.1324l1.9612 1.9612c-0.07385 0.24055-0.11078 0.49761-0.11078 0.77117 0 0.69974 0.2416 1.2915 0.7248 1.7752 0.48373 0.48317 1.0755 0.72477 1.7752 0.72477 0.27355 0 0.53059-0.0369 0.77113-0.1107l1.9612 1.9611h-1.1323v1.3832h2.5155zm1.6181-1.3832c0 0.0281-7e-4 0.0558-0.0021 0.0832l-1.3811-1.4292v-1.4044h1.3832v2.7504zm-3.2336-4.3504c0 0.33133-0.0542 0.63845-0.1625 0.92135l-1.2982-1.3434c-0.0541-0.1356-0.13653-0.25891-0.24729-0.36994-0.1014-0.10115-0.21305-0.17868-0.33494-0.23258l-1.2971-1.3423c0.26019-0.08873 0.54021-0.1331 0.84007-0.1331 0.69973 0 1.2915 0.2416 1.7752 0.7248 0.48318 0.48374 0.72478 1.0755 0.72478 1.7752zm-4.1-4.3504h-1.1568l-1.3366-1.3832h2.4934v1.3832zm-2.7504 10.084c-0.3888 0-0.71653-0.1333-0.9832-0.4-0.26667-0.2666-0.4-0.5944-0.4-0.9832v-2.7504h1.3832v2.7504h2.7504v1.3832h-2.7504zm8.7008-7.3336v-2.7504h-2.7504v-1.3832h2.7504c0.3888 0 0.7166 0.13334 0.9832 0.4 0.2667 0.26667 0.4 0.5944 0.4 0.9832v2.7504h-1.3832z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'CancelTrackingIcon'
}
</script>

<style>
#cancel-track-icon {
  fill: currentColor;
}
</style>
