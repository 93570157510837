import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const getDefaultState = () => {
  return {
    cred: {
      admin: 0,
      manager: 1,
      site_manager: 2,
      operator: 3,
      guest: 4,
      unknown: 5
    },
    role_map: {
      site_manager: 1,
      operator: 2,
      guest: 3
    },
    client: null,
    clientsList: [],
    user: {},
    usersList: [],
    sitesAssociations: [],
    recipientsSet: {},
    navItems: [
      {
        icon: 'home',
        title: 'Dashboard',
        cy: 'home',
        link: '/',
        role: 'guest'
      },
      {
        icon: 'settings',
        title: 'Site Settings',
        cy: 'installations',
        link: '/installations',
        role: 'site_manager'
      },
      {
        icon: 'camera',
        title: 'Cameras',
        link: '/stream',
        role: 'guest'
      },
      {
        icon: 'map',
        title: 'Maps & Zones',
        cy: 'zones',
        link: '/zones',
        role: 'guest'
      },
      {
        icon: 'pie_chart',
        title: 'Analytics',
        cy: 'report',
        link: '/report',
        role: 'guest'
      },
      {
        icon: 'widgets',
        title: 'Planning Tool',
        cy: 'plans',
        link: '/plans',
        role: 'guest'
      },
      {
        icon: 'network_check',
        title: 'Control Panel',
        cy: 'controlpanel',
        link: '/controlpanel',
        role: 'admin'
      }
    ],
    status: null,
    error: null
  }
}

const state = getDefaultState()

const resetState = state => Object.assign(state, getDefaultState())

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
