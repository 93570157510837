export default {
  data: () => ({
    isPasswordVisible: false,
    isConfirmationVisible: false,
    rules: {
      uniqueValue: (itemList, msg = 'Must be unique') => v => {
        const isUnique = !itemList.find(item => item === v)
        return isUnique || msg
      },
      validPort: v => (v >= 1 && v <= 65535 && v.toString().indexOf('.') === -1) || 'Invalid Port',
      serialNumberLength: v =>
        v.length == 13 ? true : 'Serial must be 13 characters',
      validLatitude: v => (v >= -90 && v <= 90) || 'Invalid Latitude',
      validLongitude: v => (v >= -180 && v <= 180) || 'Invalid Longitude',
      validLatLongLength: v => {
        if (!v) v = '0.0'
        return (
          (v.toString().split('.')?.length &&
            v.toString().split('.')[1]?.length <= 8) ||
          '8 or less digits must be after dot'
        )
      },
      required: v => !!v || 'Required',
      minName: v => (v && v.length >= 4) || 'Min 4 characters',
      validPassword: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Min 8 characters required',
        v => (v && v.length <= 128) || 'Max 128 characters required',
        v =>
          /^(?=.*[a-z])/.test(v) ||
          'Min 1 lowercase alphabetic character required',
        v =>
          /^(?=.*[A-Z])/.test(v) ||
          'Min 1 uppercase alphabetic character required',
        v => /^(?=.*[0-9])/.test(v) || 'Min 1 numeric character required',
        v =>
          /^(?=.*[^A-Za-z0-9\s])/.test(v) ||
          'Min 1 special character (.?!) required'
      ],
      validUnrequiredPassword: [
        v => !v || (v.length >= 8 || 'Min 8 characters required'),
        v => !v || (v.length <= 128 || 'Max 128 characters required'),
        v => !v ||
          (/^(?=.*[a-z])/.test(v) ||
          'Min 1 lowercase alphabetic character required'),
        v => !v ||
          (/^(?=.*[A-Z])/.test(v) ||
          'Min 1 uppercase alphabetic character required'),
        v => !v || (/^(?=.*[0-9])/.test(v) || 'Min 1 numeric character required'),
        v => !v ||
          (/^(?=.*[^A-Za-z0-9\s])/.test(v) ||
          'Min 1 special character (.?!) required'),
      ],
      confirm: password => v =>
        v === password || "New password and confirmation don't match",
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v =>
          (v && v.length > 1) || 'Name is too short (minimum is 2 characters)'
      ],
      latitudeRules: [
        v => !!v || 'Latitude is required',
        v =>
          /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,16})?))$/.test(
            v
          ) || 'Latitude must be valid'
      ],
      longitudeRules: [
        v => !!v || 'Longitude is required',
        v =>
          /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,16})?))$/.test(
            v
          ) || 'Longitude must be valid'
      ],
      macAddressRules: [
        v => !!v || 'Mac address is required',
        v =>
          /^([0-9A-F]{2}:){5}([0-9A-F]{2})$/.test(v) ||
          'Mac address must be valid'
      ],
      ipAddress: v =>
        !v ||
        /^(?:(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(\.(?!$)|$)){4}$/.test(
          v
        ) ||
        'IP address must be valid',
      cotsSymbolRule: v => /^(\w+(-\w+)*(;[^;]*)?)$/.test(v) || 'Invalid entry', // regex in https://www.mitre.org/sites/default/files/pdf/09_4937.pdf
      cotsHowRule: v => /^\w-\w$/.test(v) || 'Invalid entry',
      lessThanThreeDigits: v =>
        (v && v.length <= 2) || 'Number must be less than 3 Digits',
      lessThanTwelveCharaters: v =>
        (v && v.length <= 10) || 'Name must be less than 10 characters',
      positive: v => v > 0 || 'Number must be positive',
      validFieldOfView: [
        v => (v != null && v !== '') || 'Field of view is required',
        v => (v >= 0 && v <= 360) || 'Number must be between 0 and 360'
      ],
      validRadarConfirmedRadiusLimit: v => {
        if (v == null || v === '') {
          return true
        }
        return v >= 0 || 'Number must be positive'
      },
      validUrl: v => {
        return (
          /(http(s):\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
            v
          ) || 'URL must be valid'
        )
      },
      minValue: (val, msg = '') => v => {
        return v > val || msg
      },
      validDegree: (milsEnabled = false) => v => {
        const maxValue = milsEnabled ? 6400 : 360;
        const message = `Must be between 0 and ${maxValue} ${milsEnabled ? 'mil' : 'º'}`;
        if (milsEnabled) {
          return v >= 0 && v <= maxValue || message;
        } else {
          return v >= 0 && v < maxValue || message;
        }
      },
    },
    bannedCountries: [
      'AF',
      'BY',
      'MM',
      'CF',
      'CN',
      'CI',
      'CU',
      'CD',
      'GW',
      'IN',
      'IR',
      'LR',
      'LY',
      'ML',
      'KP',
      'RU',
      'SO',
      'SS',
      'SD',
      'SY',
      'UA',
      'VE',
      'YE',
      'ZW'
    ],
    tooltipDelay: 1000
  }),
  methods: {
    $_validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },
    $_reset() {
      this.$refs.form.reset()
      this.$_resetValidation()
    },
    $_resetValidation() {
      this.$refs.form.resetValidation()
    },
    $_selectName() {
      this.$nextTick(
        () =>
          this.$refs.nameField &&
          this.$refs.nameField.$el.querySelector('input').select()
      )
    },
    $_initName(index = 0) {
      if (!this.sensor.name) {
        // Enforce name index if model known from identity broadcast
        if (this.sensor.model) {
          index = this.sensorTypes.findIndex(
            ({ value }) => value === this.sensor.model
          )
        }
        this.sensor.name = this.sensorTypes[index].text
        this.$_selectName()
      }
    },
    $_autoName(newType) {
      if (newType && this.sensorTypes.some(t => this.sensor.name === t.text)) {
        this.sensor.name = this.sensorTypes[
          this.sensorTypes.findIndex(({ value }) => value === newType)
        ].text
        this.$_selectName()
      }
    }
  }
}
