export const SensorStatus = Object.freeze({
  NIL: 'NIL',
  GOOD: 'GOOD',
  WARN: 'WARN',
  ERROR: 'ERROR',
  DISABLED: 'DISABLED'
})
export const SensorColor = Object.freeze({
  NIL: 'grey',
  GOOD: 'green',
  WARN: 'orange',
  ERROR: 'red'
})

export const sentryTypes = Object.freeze([
  { text: 'Fixed', value: 'fixed' },
  { text: 'Vehicle', value: 'vehicle' },
  { text: 'Personnel', value: 'person' }
  // { text: 'Tethered Drone', value: 'drone' } - THIS HAS BEING REMOVED BECAUSE IT ACTUALLY CAUSES ISSUES, TEST IT AGAIN BEFORE ENABLING IT
])

export const roles = Object.freeze({
  admin: 'Admin',
  manager: 'Manager',
  site_manager: 'Site Manager',
  operator: 'Operator',
  guest: 'Guest',
  user: 'User',
})
