



















































import Vue from 'vue'
import {
  IContributorHistoryItem,
  IDetection,
  IDetectionContribution
} from '@/store/modules/detection/types'
//@ts-ignore
import { frequency, length, speed } from 'units-converter'
import DetailSection from '@/components/Notifications/SensorFusion/DetailSection.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { IDetailSectionItem } from './types'
import { isNullOrUndefined } from '@/utils/utils'
import moment from 'moment'
import { latLngToMgrs } from '@/utils/utils.js'

const props = {
  selected: {
    type: Boolean,
    default: false
  },
  detection: {
    type: Object as () => IDetection,
    default: () => {}
  },
  showLocation: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'DetectionNotificationDetails',
  components: { DetailSection },
  props,
  data() {
    return {}
  },
  computed: {
    ...mapGetters('sites', ['activeSite', 'activeSiteId']),
    ...mapState(['mgrsEnabled']),
    ...mapState('detection', ['cameraTracked', 'selectedDetections']),
    ...mapGetters('detection', [
      'receivedCameraContribution',
      'receivedRadarContribution',
      'receivedRfContribution',
      'getSeenContributors'
    ]),
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapGetters('sensors', ['radarsSet', 'discovair_sensorsSet', 'hasRadar']),
    ...mapGetters('rf_sensors', [
      'rfSensorsSet',
      'dsxSensorsSet',
      'rfSensorsLength',
      'dsxSensorsLength'
    ]),
    ...mapState('cameras', ['camerasSet']),
    ...mapGetters('system', ['displayUnits']),
    detectionContributions(): IDetectionContribution[] {
      return this.detection.DetectionContributions || []
    },
    seenContributors(): IContributorHistoryItem[] {
      return this.getSeenContributors(this.detection.target_id)
    },
    contributors(): IContributorHistoryItem[] {
      return this.seenContributors.map(contributor => {
        const active = this.detectionContributions.some(item => {
          return (
            item.sensor_id === contributor.id &&
            item.sensor_type === contributor.type
          )
        })
        return {
          ...contributor,
          active,
          name: this.getSensorName(contributor.id, contributor.type)
        }
      })
    },
    rfSectionDetails(): IDetailSectionItem[] {
      const details = [
        { label: 'Protocol', value: this.detection.protocol || '-' },
        { label: 'Frequency', value: this.frequency || '-' },
        { label: 'Vendor', value: this.detection.vendor || '-' },
        { label: 'SSID', value: this.detection.ssid || '-' }
      ]
      if (this.dsxContributors.length > 0) {
        details.push({ label: 'Sector', value: this.dsxSector })
      }
      return details
    },
    locationSectionDetails(): IDetailSectionItem[] {
      const details = []
      if (this.mgrsEnabled) {
        let mgrs = '-'
        if (this.latitude && this.longitude) {
          mgrs = latLngToMgrs(this.latitude, this.longitude)
        }
        details.push({ label: 'MGRS', value: mgrs, fullWidth: true })
      } else {
        details.push(
          { label: 'Latitude', value: this.latitude || '-' },
          { label: 'Longitude', value: this.longitude || '-' }
        )
      }
      details.push(
        { label: 'Height', value: this.altitude || '-' },
        { label: 'Site Distance', value: this.siteDistance || '-' },
        {
          label: 'Direction',
          value: isNullOrUndefined(this.direction)
            ? '-'
            : this.cardinalDirection
        },
        { label: 'Speed', value: this.speed || '-' },
        { label: 'Time on Target', value: this.time_on_target || '-' }
      )
      if (this.hasRadar)
        details.push({
          label: 'RCS',
          value: this.rcs ? this.rcs.toFixed(2) : '-'
        })
      return details
    },
    rfBeaconSectionDetails(): IDetailSectionItem[] {
      const details = []
      if (this.mgrsEnabled) {
        let mgrs: string = '-'
        if (this.detection.gcs_latitude && this.detection.gcs_longitude) {
          mgrs = latLngToMgrs(
            this.detection.gcs_latitude,
            this.detection.gcs_longitude
          )
        }
        details.push({ label: 'GCS MGRS', value: mgrs, fullWidth: true })
        mgrs = '-'
        if (this.detection.home_latitude && this.detection.home_longitude) {
          mgrs = latLngToMgrs(
            this.detection.home_latitude,
            this.detection.home_longitude
          )
        }
        details.push({ label: 'Home MGRS', value: mgrs, fullWidth: true })
      } else {
        details.push(
          {
            label: 'GCS Latitude',
            value: this.detection.gcs_latitude.toFixed(5) || '-'
          },
          {
            label: 'GCS Longitude',
            value: this.detection.gcs_longitude.toFixed(5) || '-'
          },
          {
            label: 'Home Latitude',
            value: this.detection.home_latitude.toFixed(5) || '-'
          },
          {
            label: 'Home Longitude',
            value: this.detection.home_longitude.toFixed(5) || '-'
          }
        )
      }
      details.push({
        label: 'Drone Serial Number',
        value: this.detection.drone_serial_number || '-'
      })
      return details
    },
    frequency(): string {
      if (!this.detection.frequency) return null
      const freq = frequency(this.detection.frequency)
        .from('Hz')
        .toBest()
      return `${freq.value.toFixed(2)} ${freq.unit}`
    },
    altitude(): string {
      if (!this.detection.altitude) return null
      const altitude = length(this.detection.altitude).from('m')
      const display =
        this.displayUnits === 'imperial'
          ? altitude.to('ft')
          : altitude.toBest({ exclude: ['mm', 'cm'] })
      return `${display.value.toFixed(2)} ${display.unit}`
    },
    rcs(): number | string {
      return this.detection.rcs
    },
    siteDistance(): string {
      if (!this.detection.site_distance) return null
      const distance = length(this.detection.site_distance).from('m')
      const display =
        this.displayUnits === 'imperial'
          ? distance.to('ft')
          : distance.toBest({ exclude: ['mm', 'cm'] })
      return `${display.value.toFixed(2)} ${display.unit}`
    },
    speed(): string {
      if (!this.detection.speed) return null
      const sp = speed(this.detection.speed).from('m/s')
      const display =
        this.displayUnits === 'imperial' ? sp.to('ft/s') : sp.toBest()
      return `${display.value.toFixed(2)} ${display.unit}`
    },
    direction(): number {
      if (isNullOrUndefined(this.detection?.heading)) return null
      return this.detection.heading
    },
    cardinalDirection(): string {
      if (isNullOrUndefined(this.direction)) return null
      return this.getCardinal(this.direction)
    },
    latitude(): string {
      if (!this.detection.latitude) return null
      return this.detection.latitude.toFixed(5)
    },
    longitude(): string {
      if (!this.detection.longitude) return null
      return this.detection.longitude.toFixed(5)
    },
    time_on_target(): string {
      if (
        typeof this.detection.time_on_target === 'number' &&
        this.detection.time_on_target > 0
      )
        return moment
          .utc(1000 * this.detection.time_on_target)
          .format('m[m] ss[s]')
      else return null
    },
    sentries() {
      return this.sentriesInSite(this.activeSiteId)
    },
    radarContributors(): IDetectionContribution[] {
      return this.detectionContributions.filter(contributor => {
        return contributor.sensor_type === 'radar'
      })
    },
    rfContributors(): IDetectionContribution[] {
      return this.detectionContributions.filter(contributor => {
        return contributor.sensor_type === 'rfSensor'
      })
    },
    dsxContributors(): IDetectionContribution[] {
      return this.detectionContributions.filter(contributor => {
        return contributor.sensor_type === 'dsx'
      })
    },
    discovairContributors(): IDetectionContribution[] {
      return this.detectionContributions.filter(contributor => {
        return contributor.sensor_type === 'discovair'
      })
    },
    radars(): any[] {
      const ids = this.radarContributors.map(
        contributor => contributor.sensor_id
      )
      return Object.values(this.getSensorSet('radar')).filter((item: any) => {
        return ids.includes(item.id)
      })
    },
    rfs(): any[] {
      const ids = this.rfContributors.map(contributor => contributor.sensor_id)
      return Object.values(this.getSensorSet('rfSensor')).filter(
        (item: any) => {
          return ids.includes(item.id)
        }
      )
    },
    dsxs(): any[] {
      const ids = this.dsxContributors.map(contributor => contributor.sensor_id)
      return Object.values(this.getSensorSet('dsx')).filter((item: any) => {
        return ids.includes(item.id)
      })
    },
    hasRfSensor(): boolean {
      return this.rfSensorsLength + this.dsxSensorsLength > 0
    },
    dsxSector(): string {
      const degrees =
        ((this.detection.DetectionContributions[0].aoa * 180) / Math.PI) % 360
      const directions = [
        'FWD',
        'FWD-RIGHT',
        'RIGHT',
        'REAR-RIGHT',
        'REAR',
        'REAR-LEFT',
        'LEFT',
        'FWD-LEFT'
      ]
      const index = Math.round(degrees / 45) % 8
      return directions[index]
    }
  },
  methods: {
    ...mapActions('detection', [
      'addSelectedDetection',
      'removeSelectedDetection'
    ]),
    ...mapActions('selection', [
      'activateGroup',
      'deactivateGroup',
      'activateSentry',
      'deactivateSentry'
    ]),
    getSensorName(id: number, type: string): string {
      const sensors = this.getSensorSet(type)
      return sensors[id]?.name || null
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        if (!this.selectedDetections.includes(this.detection.target_id)) {
          this.addSelectedDetection(this.detection.target_id)
        }
      } else {
        if (this.selectedDetections.includes(this.detection.target_id)) {
          this.removeSelectedDetection(this.detection.target_id)
        }
      }
    },
    getSensorSet(type: string): any {
      switch (type) {
        case 'rfSensor':
          return this.rfSensorsSet
        case 'dsx':
          return this.dsxSensorsSet
        case 'radar':
          return this.radarsSet
        case 'camera':
          return this.camerasSet
        case 'discovair':
          return this.discovair_sensorsSet
      }
    },
    getCardinal(angle) {
      const degree = 360 / 8
      angle = angle + degree / 2

      if (angle >= 0 && angle < degree) return 'N'
      if (angle >= degree && angle < 2 * degree) return 'NE'
      if (angle >= 2 * degree && angle < 3 * degree) return 'E'
      if (angle >= 3 * degree && angle < 4 * degree) return 'SE'
      if (angle >= 4 * degree && angle < 5 * degree) return 'S'
      if (angle >= 5 * degree && angle < 6 * degree) return 'SW'
      if (angle >= 6 * degree && angle < 7 * degree) return 'W'
      if (angle >= 7 * degree && angle < 8 * degree) return 'NW'
      //Should never happen:
      return 'N'
    },
    findSentryById(id) {
      return this.sentries.find(sentry => {
        return sentry.id === id
      })
    }
  }
})
