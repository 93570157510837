import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IDetectionLabelsState } from './types'

const getDefaultState = (): IDetectionLabelsState => {
  return {
    labels: []
  }
}

const state: IDetectionLabelsState = getDefaultState()

const resetState = (state: IDetectionLabelsState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
