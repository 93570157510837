import { apiRequest, axiosInstance } from '../../utils'
import omit from 'lodash/omit'
import { DsxSensorTypes, RfSensorTypes } from '../../modules/rf_sensors/types'

const toTitleCase = str => {
  return str?.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

const getEndpointUrlPrefix = endpoint => {
  if (endpoint === 'dsx_sensors') return 'rf_sensors'
  return endpoint
}

const getFormKeyBody = formkey => {
  if (formkey === 'dsx_sensor') return 'rf_sensor'
  return formkey
}

class Module {
  constructor({ endpoint = 'sensors', formKey = 'sensor' }) {
    this.endpoint = endpoint
    this.endpointUrlPrefix = getEndpointUrlPrefix(endpoint) //this will be used only in API calls. A bit hacky but only way to get around tight coupling
    this.formKeyBody = getFormKeyBody(formKey) //used for sending the key to he backend for create and update, have to overwrite dsx_sensors to be rf_sensors for it to work.
    this.formKey = formKey
    this.single = toTitleCase(this.formKey)
    this.plurial = toTitleCase(this.endpoint)
    this.name = this.formKey.toUpperCase()
    this.upperNames = this.endpoint.toUpperCase()
    this._token = null
    this.axiosInstance = axiosInstance
    this.module = this.getModule()
  }

  get token() {
    return this.getToken()
  }

  getToken() {
    const { token } = JSON.parse(localStorage.getItem('user-token') || '{}')
    return token
  }
  /* endpoint  url constructor for fetching sensors based on site_id, has special rules for Rf_Sensors */
  getFetchSensorsUrl(activeSiteId) {
    let url =
      this.endpoint !== 'SITES'
        ? `/${this.endpointUrlPrefix}.json?site_id=${activeSiteId}`
        : `/${this.endpointUrlPrefix}.json?`
    let model = ''

    switch (this.endpoint) {
      case 'rf_sensors':
        model = `&model=${RfSensorTypes.map(type => type.value).join(',')}`
        break
      case 'dsx_sensors':
        model = `&model=${DsxSensorTypes.map(type => type.value).join(',')}`
        break
    }
    return `${url}${model}`
  }

  getDefaultState() {
    return {
      [`${this.endpoint}Set`]: {},
      [`active${this.single}Id`]:
        localStorage.getItem(`active-${this.formKey}-id`) || null,
      status: null,
      error: null
    }
  }

  debounce(delay = 30000, id, commit) {
    if (!id || !commit) return
    return setTimeout(() => {
      commit(`UPDATE_${this.upperNames}`, [
        {
          id,
          status_message: 'Disconnected',
          status_color: 'red'
        }
      ])
    }, delay)
  }

  getters() {
    return {
      status: state => state.status,
      error: state => state.error,
      [`active${this.single}Id`]: state => state[`active${this.single}Id`],
      [`${this.endpoint}Set`]: state => state[`${this.endpoint}Set`],
      [`${this.endpoint}List`]: state =>
        Object.values(state[`${this.endpoint}Set`]),
      [`${this.endpoint}Length`]: (_, getters) =>
        (getters[`${this.endpoint}List`] &&
          getters[`${this.endpoint}List`].length) ||
        0,
      [`active${this.single}`]: state =>
        state[`${this.endpoint}Set`][state[`active${this.single}Id`]],
      [`nbOf${this.plurial}`]: state =>
        Object.keys(state[`${this.endpoint}Set`]),
      [`${this.endpoint}ById`]: state => id =>
        (state[`${this.endpoint}Set`] || {})[id],
      [`${this.formKey}ById`]: state => id =>
        (state[`${this.endpoint}Set`] || {})[id]
    }
  }
  mutations() {
    return {
      [`ACTIVATE_${this.name}`]: (state, id) => {
        state[`active${this.single}Id`] = id
      },
      SET_STATUS: (state, status) => (state.status = status),
      SET_ERROR: (state, error) => {
        if (error instanceof Error) {
          state.error = error.message
        } else if (Array.isArray(error)) {
          state.error = error[0]
        } else {
          state.error = error
        }
      },
      [`SET_SCOPED_ERROR_${this.name}`]: (state, { id, error }) => {
        if (error instanceof Error) {
          error = error.message
        } else if (Array.isArray(error)) {
          error = error[0]
        }
        let sensorTypeScopeError = state.scopedError[this.endpoint]
        sensorTypeScopeError = Object.assign({}, sensorTypeScopeError, {
          [id]: error
        })
        state.scopedError = Object.assign({}, state.scopedError, {
          [this.endpoint]: sensorTypeScopeError
        })
      },
      // deactivate sensor
      [`DEACTIVATE_${this.name}`]: state =>
        (state[`active${this.single}Id`] = null),
      // Functionnaly update sensors
      [`UPDATE_${this.upperNames}`]: (state, sensors) => {
        if (sensors && !Array.isArray(sensors)) {
          sensors = [sensors]
        }
        return sensors.forEach(({ id, ...update }) =>
          id && state[`${this.endpoint}Set`] && state[`${this.endpoint}Set`][id]
            ? Object.assign(state[`${this.endpoint}Set`][id], update)
            : null
        )
      },
      // Add a sensor to list
      [`ADD_${this.name}`]: (state, sensor) => {
        state[`${this.endpoint}Set`] = Object.assign(
          {},
          state[`${this.endpoint}Set`],
          { [sensor.id]: sensor }
        )
      },

      // Remove a sensor from the list
      [`REMOVE_${this.name}`]: (state, id) => {
        if (!id) return
        delete state[`${this.endpoint}Set`][id]
        // Prevent icon to remain on map after deletion
        if (state[`active${this.single}Id`] === id) {
          state[`active${this.single}Id`] = null
        }
      },

      // Load sensors in their own list.
      [`LOAD_${this.upperNames}`]: (state, sensors) => {
        state[`${this.endpoint}Set`] = sensors.reduce((acc, curr) => {
          return Object.assign(acc, { [curr.id]: curr })
        }, {})
      },

      [`SOCKET_${this.upperNames}_CREATE`]: (state, sensors) => {
        return sensors.forEach(sensor => {
          state[`${this.endpoint}Set`] = Object.assign(
            {},
            state[`${this.endpoint}Set`],
            { [sensor.id]: sensor }
          )
        })
      },

      [`SOCKET_${this.upperNames}_DELETE`]: (state, sensors) => {
        return sensors.forEach(({ id }) => {
          // uses es6 computed property names & destructuring assignment
          // GJ master piece
          // So good it requires an eslint exclusion
          // eslint-disable-next-line no-unused-vars
          const { [id]: dummy, ...withoutId } = state[`${this.endpoint}Set`]
          // Pretty good right?
          state[`${this.endpoint}Set`] = Object.assign({}, withoutId)
          // Prevent icon to remain on map after deletion
          if (state[`active${this.single}Id`] === id) {
            state[`active${this.single}Id`] = null
          }
        })
      },
      resetState: state => {
        Object.assign(state, this.getDefaultState())
      }
    }
  }
  actions() {
    return {
      CLEAR_STATUS: ({ commit }) => {
        commit('SET_STATUS', null)
        commit('SET_ERROR', null)
      },
      [`FETCH_${this.upperNames}`]: async ({ commit, rootState }) => {
        const { activeSiteId } = rootState.sites || {}
        await apiRequest(
          'get',
          this.getFetchSensorsUrl(activeSiteId),
          {},
          (data, headers) => {
            commit(`LOAD_${this.upperNames}`, data)
          },
          (data, _) => {
            commit(`LOAD_${this.upperNames}`, [])
            commit('SET_STATUS', 'FAILED')
            commit('SET_ERROR', new Error(data.error))
          }
        )
      },

      [`FETCH_${this.name}`]: async ({ commit }, id) => {
        await apiRequest(
          'get',
          `/${this.endpointUrlPrefix}/${id}.json`,
          {},
          (data, headers) => {
            commit(`UPDATE_${this.upperNames}`, data)
            commit('SET_STATUS', 'OK')
          },
          (data, _) => {
            commit(`LOAD_${this.upperNames}`, [])
            commit('SET_STATUS', 'FAILED')
            commit('SET_ERROR', new Error(data.error))
          }
        )
      },

      [`CREATE_${this.name}`]: async ({ commit, dispatch }, sensor) => {
        commit('SET_STATUS', 'LOADING')
        return await apiRequest(
          'post',
          `/${this.endpointUrlPrefix}.json`,
          { [this.formKeyBody]: sensor },
          (data, _) => {
            commit(`ADD_${this.name}`, data)
            commit('SET_STATUS', 'OK')
            if (data && data.id) {
              dispatch(`SELECT_${this.name}`, data.id)
            }
          },
          (data, _) => {
            commit('SET_STATUS', 'FAILED')
            commit('SET_ERROR', new Error(data.error))
          }
        )
      },

      [`UPDATE_${this.name}`]: async ({ commit, dispatch }, sensor) => {
        let { scopedError = false } = sensor
        commit('SET_STATUS', 'LOADING')
        return await apiRequest(
          'patch',
          `/${this.endpointUrlPrefix}/${sensor.id}.json`,
          { [this.formKeyBody]: omit(sensor, ['id', 'scopedError']) },
          (data, _) => {
            commit(`UPDATE_${this.upperNames}`, [data])
            commit('SET_STATUS', 'OK')
          },
          (data, _) => {
            commit('SET_STATUS', 'FAILED')
            if (scopedError) {
              commit(`SET_SCOPED_ERROR_${this.name}`, {
                id: sensor.id,
                error: new Error(data.error)
              })
            } else {
              commit(`SET_ERROR`, new Error(data.error))
            }
          }
        )
      },

      [`DELETE_${this.name}`]: async ({ commit, dispatch }, sensor) => {
        commit('SET_STATUS', 'LOADING')
        await apiRequest(
          'delete',
          `/${this.endpointUrlPrefix}/${sensor.id}.json`,
          { [this.formKeyBody]: sensor },
          (data, _) => {
            commit(`REMOVE_${this.name}`, sensor.id)
            commit('SET_STATUS', 'OK')
            dispatch(`UNSELECT_${this.name}`)
          },
          (data, _) => {
            commit('SET_STATUS', 'FAILED')
            commit('SET_ERROR', new Error(data.error))
          }
        )
      },

      [`SELECT_${this.name}`]: ({ commit, getters, dispatch }, id) => {
        commit(`ACTIVATE_${this.name}`, id)
        if (this.name === 'SENTRY' && id != null) {
          dispatch(
            'sensors/selectUniqueGroupForSentry',
            getters.sentriesSet[id],
            { root: true }
          )
        }
      },

      [`UNSELECT_${this.name}`]: ({ commit, getters }) => {
        if (getters[`active${this.single}Id`]) {
          commit(`DEACTIVATE_${this.name}`)
        }
      },

      // Simple alias
      [`UNSELECT_${this.upperNames}`]: ({ dispatch }) => {
        dispatch(`UNSELECT_${this.name}`)
      },

      // [`SOCKET_${this.upperNames}_UPDATE`]: ({ commit }, updates) => {
      //   return updates.forEach(({ id, ...update }) => {
      //     commit(`UPDATE_${this.upperNames}`, [{ id, ...update }])
      //   })
      // },

      [`SOCKET_${this.upperNames}_UPDATE`]: ({ commit, getters }, updates) => {
        return updates.forEach(({ id, ...update }) => {
          if (!['SITES', 'SENTRIES'].includes(this.upperNames)) {
            const sensor = getters[`${this.formKey}ById`](id)
            if (sensor) {
              // clearTimeout(sensor.timeoutWarn)
              clearTimeout(sensor.timeoutError)
            }
            // update.timeoutWarn = this.debounce(this.delay.warn, id, commit)
            update.timeoutError = this.debounce(this.delay.error, id, commit)
          }
          commit(`UPDATE_${this.upperNames}`, [{ id, ...update }])
        })
      }
    }
  }

  getModule() {
    return {
      state: this.getDefaultState(),
      getters: this.getters(),
      actions: this.actions(),
      mutations: this.mutations()
    }
  }
}

export default Module
