<template>
  <div class="white--text px-4 top-clock">{{ time }}</div>
</template>

<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'

const props = {
  timezone: {
    type: String,
    default: moment.tz.guess()
  },
  replay_time: {
    type: String
  },
  server_time: {
    type: String
  }
}

export default {
  name: 'TopNavClock',
  props,
  data() {
    return {
      localTime: moment().tz(this.timezone).format()
    }
  },
  timers: {
    clockUpdate: { autostart: true, immediate: true, repeat: true, time: 1000 }
  },
  methods: {
    clockUpdate() {
      this.localTime = moment().format()
    }
  },
  computed: {
    ...mapState('system', ['replay']),
    time() {
      return moment
        .tz(this.replay ? this.replay_time : this.localTime, this.timezone)
        .format('YYYY-MM-DD HH:mm:ss z')
    }
  }
}
</script>

<style scoped>
.top-clock {
    min-width: max-content;
}
</style>
