var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueSlider',{attrs:{"value":_vm.dataValues,"process":_vm.getRanges,"clickable":_vm.clickable,"enableCross":_vm.enableCross,"tooltip":_vm.tooltip,"processStyle":_vm.rangeStyle,"railStyle":_vm.railStyle,"stepStyle":_vm.stepStyle,"order":_vm.ordered,"marks":_vm.marks,"disabled":_vm.disabled},on:{"dragging":_vm.onChange},scopedSlots:_vm._u([{key:"process",fn:function(ref){
var style = ref.style;
var index = ref.index;
return [_c('div',{staticClass:"vue-slider-process",style:(_vm.updateStyle(index, style))},[_c('div',{key:index,staticClass:"merge-tooltip",staticStyle:{"color":"var(--dro-primary)","background-color":"transparent","top":"-1px"}},[_c('svg',{staticClass:"center-triangle",attrs:{"width":"14","height":"11","viewBox":"0 0 14 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 11L0.937821 0.500001L13.0622 0.5L7 11Z"}})])])])]}},{key:"dot",fn:function(){return [_c('div',{staticClass:"vue-slider-handle pa-3",class:_vm.disabled ? 'vue-slider-dot-hidden' : '',style:({
        color: 'var(--dro-primary)',
        backgroundColor: 'transparent',
        marginTop: '-18px',
        marginLeft: '-12px',
        zIndex: 100,
        borderRadius: '25px'
      })},[_c('svg',{staticClass:"slider-dot",attrs:{"width":20,"height":20,"viewBox":"0 0 15 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2.5 10.1667V12H3.5V10.1667L6 6L3.5 1.83333V0H2.5V1.83333L0 6L2.5 10.1667Z"}})])])]},proxy:true}])},[(_vm.label)?[_c('div',{staticClass:"text-center mt-4 pt-2 grey--text caption",staticStyle:{"width":"100%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.label)+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }