

















import DPanelToolbar from './DPanelToolbar.vue'

export default {
  name: 'd-view',
  props: {
    title: {
      type: String,
      default: ''
    },
    bottomMargin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DPanelToolbar
  }
}
