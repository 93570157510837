
































import Vue from 'vue'
import {
  COLOR_FRIEND,
  COLOR_THREAT,
  CONTROLLER_CLASSIFICATION,
  EDetectionState,
  IDetection
} from '@/store/modules/detection/types'
import DetectionNotificationHeader from '@/components/Notifications/SensorFusion/DetectionNotificationHeader.vue'
import DetectionNotificationDetails from '@/components/Notifications/SensorFusion/DetectionNotificationDetails.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { labelMappings } from '@/store/modules/detection_labels/types'

const props = {
  detection: {
    type: Object as () => IDetection,
    required: true
  },
  showLocation: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'DetectionNotificationItem',
  props,
  provide() {
    return { sensorSet: this.sensorSet }
  },
  components: {
    DetectionNotificationDetails,
    DetectionNotificationHeader
  },
  data() {
    return {
      showDetails: false
    }
  },
  computed: {
    ...mapState('detection', ['selectedDetections']),
    hasDetectionPosition(): boolean {
      return (
        ![0, null].includes(this.detection?.latitude) && ![0, null].includes(this.detection?.longitude)
      )
    },
    showIcon(): boolean {
      return !(
        this.detection?.DetectionContributions?.length === 1 &&
        ['rfSensor', 'dsx'].includes(
          this.detection.DetectionContributions[0].sensor_type
        ) &&
        !this.hasDetectionPosition
      )
    },
    confidence(): number {
      return this.detection.probability
    },
    isThreat(): boolean {
      return this.detection.state !== EDetectionState.Whitelisted
    },
    isController(): boolean {
      return this.detection.classification === CONTROLLER_CLASSIFICATION
    },
    color(): string {
      return this.detection.state === EDetectionState.Whitelisted
        ? COLOR_FRIEND
        : COLOR_THREAT
    },
    objectClass(): string {
      const classString = this.detection.classification.split('-').join(' ')
      let classArray = classString.split(' ')
      classArray = classArray.map(item => {
        if (Object.keys(labelMappings).includes(item.toLowerCase())) {
          return labelMappings[item.toLowerCase()]
        } else {
          return item
        }
      })
      return classArray.join(' ')
    },
    detectionKey(): string {
      return this.detection.target_id
    },
    isSelected(): boolean {
      return this.selectedDetections.includes(this.detectionKey)
    }
  },
  methods: {
    ...mapActions('detection', [
      'selectDetection',
      'clearSelectedDetections',
      'selectRFBeam'
    ]),
    setRfAndDsxSensors(enable: boolean): void {
      // this might not work if the detection has more than 1 rf contribution but if it does
      // the actual sector should not be shown since most luckily we have a dot
      this.detection.DetectionContributions.forEach(c => {
        if (['rfSensor', 'dsx'].includes(c.sensor_type)) {
          const key = enable ? `${this.detectionKey}:${c.sensor_id}` : null
          this.selectRFBeam(key)
        }
      })
    },
    detectionClicked(): void {
      if (this.isSelected) {
        this.clearSelectedDetections()
        this.setRfAndDsxSensors(false)
      } else {
        this.selectDetection(this.detectionKey)
        this.setRfAndDsxSensors(true)
      }
    }
  }
})
