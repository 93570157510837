import pick from 'lodash/pick'
import omit from 'lodash/omit'
import { apiRequest } from '../../utils'
import store from '@/store'
export default {
  DISMISS: ({ commit }) => {
    commit('SET_STATUS', null)
    commit('SET_ERROR', null)
  },
  CLEAR_ERROR: ({ dispatch }) => {
    dispatch('DISMISS')
  },
  HANDLE_ERROR: ({ commit }, err) => {
    commit('SET_STATUS', 'ERROR')
    commit('SET_ERROR', err && err.message)
  },
  FETCH_CLIENT: async ({ commit, dispatch }, clientId) => {
    return await apiRequest(
      'get',
      clientId ? `/api/clients/${clientId}` : `/api/clients`,
      {},
      (data, _) => {
        data = clientId ? data.client : data.clients
        commit('LOAD_CLIENT', data)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_USER: async ({ commit, dispatch, rootState }) => {
    const { id } = JSON.parse(localStorage.getItem('user-token') || '{}')

    const { activeSiteId } = rootState.sites || {}

    await apiRequest(
      'get',
      `/profiles/${id}.json`,
      {},
      (data, _) => {
        let savedMapLayerId = data.settings?.mapLayerId || null
        dispatch('maps/setActiveMapLayerId', savedMapLayerId, {
          root: true
        })
        commit('LOAD_USER', data)

        // restore zone settings
        const zoneSettings = { ...data?.settings?.zoneSettings } || {}

        const zoneSettingKeys = [
          'displayedSectors',
          'mgrsEnabled',
          'milsEnabled',
          'radiatingCircle',
          'showMarkers',
          'visibleZoneTypes',
          'alertZoneFillPattern'
        ]

        zoneSettingKeys.forEach(key => {
          switch (key) {
            case 'mgrsEnabled':
              commit('setMgrs', zoneSettings[key] || false, { root: true })
              break
            case 'milsEnabled':
              commit('setMils', zoneSettings[key] || false, { root: true })
              break
            case 'showMarkers':
              commit(
                'site_markers/TOGGLE_SHOW_SITE_MARKERS',
                zoneSettings[key] || false,
                { root: true }
              )
              break
            case 'radiatingCircle':
              commit('SET_RADIATING_CIRCLE', zoneSettings[key] || [], {
                root: true
              })
              break
            case 'displayedSectors':
              commit('setDisplayedSectors', zoneSettings[key] || [], {
                root: true
              })
              break
            case 'visibleZoneTypes':
              commit('zones/VISIBLE_ZONE_TYPES', zoneSettings[key] || [], {
                root: true
              })
              break
            case 'alertZoneFillPattern':
              commit(
                'zones/ALERT_ZONE_FILL_PATTERN',
                zoneSettings[key] || false,
                {
                  root: true
                }
              )
              break
            default:
              break
          }
        })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_USERS: async ({ commit }) => {
    return await apiRequest(
      'get',
      `/profiles.json`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        commit('loadUsersList', data)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_USERS_IN_SITE: async ({ commit, rootState }, site_id) => {
    // accept both site_id and state.activeSiteId
    const { activeSiteId } = rootState.sites || {}

    return await apiRequest(
      'get',
      `/api/sites/${site_id || activeSiteId}/users`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_USER_SITES_ASSOCIATION: async ({ state, commit, dispatch }, user) => {
    const { id } = user || state.user
    return await apiRequest(
      'get',
      `/api/users/${id}/sites`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        commit('LOAD_SITES_ASSOCIATIONS', data.site_users)
        return data.site_users
      },
      (data, _) => {
        const error = Error(data.error)
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', error)
        return error.message
      }
    )
  },
  SAVE_CLIENT: async ({ commit, dispatch }, client) => {
    await apiRequest(
      client.id ? 'patch' : 'post',
      `/api/clients${client.id ? '/' + client.id : ''}`,
      { client: omit(client, 'id') },
      (data, _) => {
        commit('UPDATE_CLIENT', data.client)
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_CLIENT')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  CREATE_USER: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'post',
      `/api/users`,
      { user: omit(user, 'id') },
      (data, _) => {
        commit('ADD_USER', data.user)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  // This will remain as the registration tokens are handled in
  // sentry-api & will soon be deprecated
  REGISTER_USER: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'post',
      `/profiles.json`,
      { user: omit(user, 'id') },
      (data, _) => {
        commit('ADD_USER', data)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  REGISTER: async ({ commit, dispatch }, email) => {
    await apiRequest(
      'post',
      `/registration_tokens.json`,
      { registration: { expires_in_days: 7, email } },
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  RESET_USER_PASSWORD: async ({ commit, dispatch }, { token, password, password_confirmation }) => {
    await apiRequest(
      'post',
      `/api/users/password_reset`,
      { user: { reset_password_token: token, password, password_confirmation } },
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_REGISTRATION: async ({ commit }, token) => {
    return await apiRequest(
      'get',
      `/registration_tokens/${token}.json`,
      { user: { reset_password_token: token } },
      (data, _) => {},
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_PROFILE: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'patch',
      `/api/users/${user.id}/profile`,
      { user: omit(user, 'id') },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        commit('LOAD_USER', data.user)
        dispatch('FETCH_USERS')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_SETTINGS: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'patch',
      `/api/users/${user.id}/settings`,
      { user: { settings: user.settings } },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        commit('LOAD_USER', data.user)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_USER: async ({ state, commit, dispatch }, user) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'put',
      `/api/users/${user.id}`,
      {
        user: pick(user, [
          'name',
          'email',
          'username',
          'company',
          'phone',
          'fax',
          'role',
          'client_id',
          'play_alarm_sounds',
          'default_site_id',
          'settings',
          'show_plan_part_lists'
        ])
      },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        if (state.user.id === data.user.id) commit('LOAD_USER', data.user)
        dispatch('FETCH_USERS')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_USER: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'delete',
      `/profiles/${user.id}.json`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_USERS')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_CLIENT: async ({ commit, dispatch }, client) => {
    await apiRequest(
      'delete',
      `/api/clients/${client.id}`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        // this will force Dataloader to pull all sites again
        store.commit('sites/LOAD_SITES', [])
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  // ############################################
  // NOTIFICATION RECIPIENTS (SMS & EMAIL)
  // ############################################
  FETCH_RECIPIENTS: async ({ commit, dispatch, rootState }) => {
    const { activeSiteId } = rootState.sites || {}
    await apiRequest(
      'get',
      `/api/sites/${activeSiteId}/notification_recipients`,
      {},
      response => {
        const data =
          response.notification_recipients ||
          [].reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
        commit('LOAD_RECIPIENTS', data)
      },
      data => {
        dispatch('HANDLE_ERROR', data.error)
      }
    )
  },
  CREATE_RECIPIENT: async ({ commit, dispatch, rootState }, recipient) => {
    const { activeSiteId } = rootState.sites || {}
    const { name, address, address_type, zone_ids, sensors_down } = recipient
    return await apiRequest(
      'post',
      `/api/sites/${activeSiteId}/notification_recipients`,
      {
        notification_recipient: {
          name,
          address,
          address_type,
          zone_ids,
          sensors_down
        }
      },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_RECIPIENTS')
        return {
          status: 'OK',
          error: null
        }
      },
      (data, _) => {
        const error = Error(data.error)
        if (data.error) {
          error.message = data.error
        } else {
          error.message = 'A problem occurred while doing the operation'
        }
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', error)
        return {
          status: 'FAILED',
          error: error.message
        }
      }
    )
  },
  UPDATE_RECIPIENT: async ({ commit, dispatch, rootState }, recipient) => {
    const { id, name, address, enabled, zone_ids, sensors_down } = recipient
    const { activeSiteId } = rootState.sites
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `/api/sites/${activeSiteId}/notification_recipients/${id}`,
      {
        notification_recipient: {
          name,
          address,
          enabled,
          zone_ids,
          sensors_down
        }
      },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_RECIPIENTS')
        return {
          status: 'OK',
          error: null
        }
      },
      (data, _) => {
        const error = Error(data.error)
        if (data.error) {
          error.message = data.error
        } else {
          error.message = 'A problem occurred while doing the operation'
        }
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', error)
        return {
          status: 'FAILED',
          error: error.message
        }
      }
    )
  },
  DELETE_RECIPIENT: async ({ commit, dispatch, rootState }, recipient) => {
    const { id } = recipient
    const { activeSiteId } = rootState.sites
    return await apiRequest(
      'delete',
      `/api/sites/${activeSiteId}/notification_recipients/${id}`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_RECIPIENTS')
        return {
          status: 'OK',
          error: null
        }
      },
      (data, _) => {
        const error = Error(data.error)
        if (data.error) {
          error.message = data.error
        } else {
          error.message = 'A problem occurred while doing the operation'
        }
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', error)
        return {
          status: 'FAILED',
          error: error.message
        }
      }
    )
  },
  TEST_RECIPIENT: async ({ commit, dispatch }, recipient) => {
    await apiRequest(
      'post',
      `/send_test_${recipient.address_type}.json?site_id=${recipient.site_id}&${recipient.address_type}_recipient_id=${recipient.id}`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  SET_THEME_NAME: async ({ commit }, { user_id, theme }) => {
    await apiRequest(
      'post',
      `/profiles/${user_id}/set_theme_name.json`,
      {
        user: {
          theme_name: theme
        }
      },
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  fetchListOfUsers: async ({ commit, state }, withDeleted: boolean = true) => {
    const { user } = state

    let url: string = `/api/users?with_deleted=${withDeleted}`

    if (user.role !== 'admin') {
      url = `/api/users?client_id=${user.client_id}&with_deleted=${withDeleted}`
    }

    await apiRequest(
      'get',
      url,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        commit('loadUsersList', data?.users || [])
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  deactivateUser: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'delete',
      `/api/users/${user.id}`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('fetchListOfUsers')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  deleteUser: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'delete',
      `/api/users/${user.id}?force_delete=true`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('fetchListOfUsers')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  reactivateUser: async ({ commit, dispatch }, user) => {
    await apiRequest(
      'patch',
      `/api/users/${user.id}/restore`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('fetchListOfUsers')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  }
}
