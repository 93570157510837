import {ObjectValue} from '@/utils/tsutils'

export const WARNING_DISPLAY_TYPE = {
  WARNING: 'warning',
  WARNING_HISTORY: 'warning-history'
} as const

export const WARNING_TYPES = {
  High_Throttling: 'high_throttling',
  Radar_Noise: 'radar_noise',
  Device_Version_Mismatch: 'device_version_mismatch',
  Drone_Opt_Id: 'drone_opt_id',
  High_Disk_Space_Usage: 'high_disk_space_usage',
  Radar_Channel_Clash: 'radar_channel_clash'
} as const

export type TWarningDisplayType = ObjectValue<typeof WARNING_DISPLAY_TYPE>
export type TWarningType = ObjectValue<typeof WARNING_TYPES>


export interface ISiteWarningState {
  site_warnings: ISiteWarning[]
  site_warnings_history: ISiteWarning[]
  clearSiteWarningsLoading: boolean
  clearingSiteWarnings: boolean
}

export interface ISiteWarning {
  content: string
  created_at: string
  id: number
  read: boolean
  site_id: number
  title: string
  updated_at: string
  warning_type: string
}

export interface ISiteWarningsListReq {
  site_warning: ISiteWarning[]
}

export interface ICreateSiteWarningInput {
  title: string
  content: string
  site_id: number
  warning_type: TWarningType
}

export interface IClearSiteWarning {
  site_warnings: ISiteWarning[]
  errors: String[]
}

