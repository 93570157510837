











































































import utils from '@/utils/utils'

const props = {
  step: {
    type: [Number, String],
    default: 1
  },
  ticksLabels: {
    type: Array,
    default: () => []
  },
  label: {
    type: String
  },
  value: {
    type: [Number, String]
  },
  max: {
    type: [Number, String],
    default: 100
  },
  min: {
    type: [Number, String],
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  textInput: {
    type: Boolean,
    default: true
  },
  sliderInput: {
    type: Boolean,
    default: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  unit: {
    type: String
  },
  siteValue: {
    type: Number,
    default: null
  },
  absoluteValue: {
    type: Number,
    default: 0
  }
}

export default {
  name: 'd-param-slider',
  props,
  computed: {
    isSiteValue(): boolean {
      return this.value == null && this.siteValue
    },
    textBoxValue(): number {
      const val = Number(this.isSiteValue || this.value)
      if (this.unit === 'mil') {
        if (!val) return 0
        return parseFloat(utils.degToMilsString(val))
      }
      return Number(this.isSiteValue || this.value)
    },
    absoluteVal(): number {
      const val = this.absoluteValue
      if (this.unit === 'mil') {
        if (!val) return 0
        return parseFloat(utils.degToMilsString(val))
      } else {
        return Math.round(100 * val) / 100
      }
    },
    inputVal: {
      get(): number {
        return Number(this.isSiteValue || this.value)
      },
      set(val): void {
        this.$emit('input', Number(val))
      }
    }
  },
  methods: {
    onTextBoxChange(val): void {
      if (this.unit === 'mil') {
        this.$emit('input', parseFloat(utils.milsToDegString(val)))
      } else {
        this.$emit('input', Number(val))
      }
    },
    unsetValue(): void {
      this.value = this.isSiteValue ? this.inputVal : null
      this.$emit('input', this.value)
    }
  }
}
