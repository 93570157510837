export default {
  AUTH_REQUEST: state => {
    state.status = 'loading'
  },
  AUTH_SUCCESS: (state, token) => {
    state.status = 'success'
    state.token = token
    state.hasLoadedOnce = true
  },
  AUTH_ERROR: (state, error) => {
    state.status = 'error'
    state.error = error
    state.hasLoadedOnce = true
  },
  CLEAR_ERROR(state) {
    state.status = null
    state.error = null
  }
}
