// import actions from "./actions";
// import getters from "./getters";
// import mutations from "./mutations";

import Sentry from '../../utils/Module/Sentry'

const module = new Sentry().module

export default {
  namespaced: true,
  ...module
}
