<script>
import {
  OptionsMixin,
  ControlMixin,
  propsBinder,
  optionsMerger
} from 'vue2-leaflet'
import {
  PluginOptions,
  SimpleMapScreenshoter
} from 'leaflet-simple-map-screenshoter'

export default {
  name: 'LeafletScreenshotTool',
  mixins: [ControlMixin, OptionsMixin],
  mounted() {
    const options = optionsMerger(
      {
        ...this.controlOptions,
        options: this.options
      },
      this
    )
    this.mapObject = new SimpleMapScreenshoter(options)
    propsBinder(this, this.mapObject, this.$options.props)
    this.mapObject.addTo(this.$parent.mapObject)
  },
  methods: {
    async takeScreenShot(format = 'image') {
      return await this.mapObject.takeScreen(format)
    }
  },
  render() {
    return null
  }
}
</script>

<style scoped></style>
