<template>
  <l-feature-group :visible="visible">
    <l-polygon
      :color="color"
      :weight="weight"
      :fill-color="fillColor"
      :opacity="1"
      :fill-opacity="fillOpacity"
      :lat-lngs="latLngs"
      :ref="refKey"
      :className="draggable ? 'draggableSector' : ''"
      @drag="draggable && !cancelDragHandler && $_dragSector($event)"
      @dragend="draggable && !cancelDragHandler && $_resetDragAngle($event)"
      @click="draggable && !cancelDragHandler && $_shuffleSector($event)"
    ></l-polygon>
    <template v-if="editMode">
      <polygon-fog-of-war
        fill-color="black"
        :opacity="0"
        :fill-opacity="0"
        :lat-lngs="range"
        mask="cannonSector"
      />
      <polygon-fog-of-war
        :fill-opacity="0"
        :mask-opacity="0.7"
        fill-color="black"
        :opacity="0"
        mask-id="cannonSector"
      />
    </template>
  </l-feature-group>
</template>

<script>
import utils from '../utils'
import { mapState, mapGetters } from 'vuex'
import { LPolygon, LFeatureGroup } from 'vue2-leaflet'
import { SensorMixin } from '@/components/Mixins'
import { PolygonFogOfWar } from 'vue2-leaflet-fogofwar'

const props = {
  active: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: true
  },
  sensor: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0,
      direction: 0,
      cannon_type: '3B',
      power_trigger_engaged: false
    })
  },
  draggable: {
    type: Boolean,
    default: false
  },
  cancelDragHandler: {
    type: Boolean,
    default: false
  },
  editMode: {
    type: Boolean,
    default: false
  },
  highlighted: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'CannonSectorCoverage',
  data: () => ({
    fillOpacity: 0.1,
    prev: 0
  }),
  props,
  components: { LPolygon, PolygonFogOfWar, LFeatureGroup },
  mixins: [SensorMixin],
  computed: {
    ...mapGetters('plans', ['activePlan']),
    powered() {
      const { sensor } = this
      return (
        (sensor && sensor.power_trigger_engaged) ||
        (sensor.auto_jam &&
          sensor.auto_jam_countdown &&
          sensor.auto_jam_countdown > 0)
      )
    },
    weight() {
      return this.active ? 3 : 1
    },
    fillColor() {
      if (this.powered || this.highlighted) {
        return 'red'
      } else if (this.active) {
        return 'orange'
      } else {
        return '#FF7E27'
      }
    },
    color() {
      return this.fillColor
    },
    latlng() {
      return this.sensor && [this.sensor.latitude, this.sensor.longitude]
    },
    latLngs() {
      return utils.drawCannonSector({ ...this.sensor, angle: 90 })
    },
    refKey() {
      return `cannon-sector-${this.sensor.id}`
    },
    range() {
      const { reach, direction = 0, angle = 90 } = this.sensor || {}
      return reach
        ? utils.createRange({
            reach,
            direction,
            angle,
            vertex: [this.sensor.latitude, this.sensor.longitude]
          })
        : []
    }
  },
  timers: {
    glow: { time: 30, autostart: false, repeat: true }
  },
  methods: {
    glow() {
      if (
        this.fillOpacity <= 0 ||
        (this.prev - this.fillOpacity < 0 && this.fillOpacity < 0.4)
      ) {
        this.prev = this.fillOpacity
        this.fillOpacity += 0.01
      } else {
        this.prev = this.fillOpacity
        this.fillOpacity = this.fillOpacity - 0.01
      }
    }
  },
  watch: {
    powered(value) {
      if (value) {
        this.$timer.start('glow')
      } else {
        this.$timer.stop('glow')
        this.fillOpacity = 0.2
        this.prev = 0
      }
    },
    active(isActive) {
      isActive && utils.bringSVGToTop(this.$refs[this.refKey])
    }
  },
  mounted() {
    if (this.powered) {
      this.$timer.start('glow')
    }
    this.$bus.$on('bringCannonToTop', () => {
      if (this.$refs[this.refKey]) utils.bringSVGToTop(this.$refs[this.refKey])
    })
  },
  beforeDestroy() {
    this.$bus.$off('bringCannonToTop')
  }
}
</script>
