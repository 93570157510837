import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import { IRfFilterState } from './types'

const getDefaultState = (): IRfFilterState => {
  return {
    rf_filters: {},
    status: '',
    error: '',
  }
}

const state = getDefaultState()

const resetState = (state: IRfFilterState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
