






import Vue from 'vue'
import ActionButtonGroup from './ActionButtonGroup.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'AlertActions',
  components: {
    ActionButtonGroup
  },
  computed: {
    ...mapGetters('notifications', ['alertsCount']),
    ...mapGetters('detection', ['selectedDetection']),
    ...mapState('notifications', ['activeAlert']),
    clearDisabled(): boolean {
      return !this.activeAlert
    },
    clearAllDisabled(): boolean {
      return this.alertsCount === 0
    }
  },
  methods: {
    ...mapActions('notifications', ['clearAlerts', 'removeAlert']),
    clearAlert(): void {
      this.removeAlert(this.activeAlert)
    }
  }
})
