






























import DConfirm from './DConfirm.vue'

export default {
  name: 'd-confirm-dialog',
  props: {
    level: {
      type: String,
      default: 'warning'
    },
    title: String,
    error: String,
    status: String,
    value: Boolean,
    detail: String
  },
  components: { DConfirm },
  computed: {
    dialog: {
      get(): boolean {
        return this.value
      },
      set(val): void {
        this.$emit('cancel', val)
      }
    }
  },
  methods: {
    onCancel(): void {
      this.$emit('cancel')
      // this.dialog = false
    },
    onConfirm(): void {
      this.$emit('confirm')
      // this.dialog = false
    }
  }
}
