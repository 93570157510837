import { apiRequest, axiosInstance } from '../../utils'
import {
  ISystemSetting,
  IStats,
  SystemSettingResponse,
  ISystemInfo
} from './types'
import omit from 'lodash/omit'
import socketManager from '@/plugins/websocket'

export default {
  FETCH_SYSTEM_STATS: async ({ commit }: { commit: any }) => {
    try {
      const data: IStats = await axiosInstance.get(`/api/system_stats`)
      return commit('LOAD_STATS', data)
    } catch (err) {
      commit('SET_STATUS', 'FAILED')
      commit('SET_ERROR', err.message)
    }
  },
  FETCH_SYSTEM_INFO: async ({ commit }: { commit: any }) => {
    await apiRequest(
      'get',
      '/api/system_stats/info',
      {},
      (data: ISystemInfo) => {
        commit('LOAD_SYS_INFO', data)
        commit('setOffline', data.environment === 'prem', { root: true })
      },
      (data: any, _: any) => {
        console.log(`failed to pull system stats info because: ${data.error}`)
      }
    )
  },
  UPDATE_SYSTEM_SETTINGS: async (
    { commit }: { commit: any },
    data: ISystemSetting
  ) => {
    return await apiRequest(
      'patch',
      `/api/system_settings/${data.id}`,
      { system_settings: omit(data, ['id', 'created_at', 'updated_at']) },
      (data: SystemSettingResponse) => {
        commit('SET_SYSTEM_SETTING', data.system_setting)
        commit('SET_STATUS', 'OK')
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_SYSTEM_SETTINGS: async ({ commit }: { commit: any }) => {
    return await apiRequest(
      'get',
      '/api/system_settings',
      {},
      (data: SystemSettingResponse) => {
        commit('SET_SYSTEM_SETTING', data.system_setting)
        return data.system_setting
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  setDataLoadedState: ({ commit }: { commit: any }, status: boolean) => {
    commit('SET_DATA_LOADED_STATE', status)
  },
  setDataWebsocket: ({ commit }: { commit: any }, socket: socketManager) => {
    commit('SET_DATA_WEBSOCKET', socket)
  },
  setAlertsWebsocket: ({ commit }: { commit: any }, socket: socketManager) => {
    commit('SET_ALERTS_WEBSOCKET', socket)
  }
}
