// @ts-ignore
import actions from './actions.ts'
// @ts-ignore
import getters from './getters.ts'
// @ts-ignore
import mutations from './mutations.ts'

import { IState, IStats, ISystemSetting, IVersion } from './types'

let envUrl = process.env.VUE_APP_ROOT_API || 'http://localhost'

if (envUrl === '/') {
  envUrl = window.location.origin.toString()
}

const url = new URL(envUrl)

const apiUrl = url.toString()

url.protocol = url.protocol === 'https:' ? 'wss:' : 'ws:'
url.pathname = '/cable'
const rubySocketUrl = url.toString().replace(':3380', ':3000')

const apiWsUrl = new URL(envUrl)
apiWsUrl.protocol = apiWsUrl.protocol === 'https:' ? 'wss:' : 'ws:'
apiWsUrl.pathname = '/api/ws'
const coreSocketUrl = apiWsUrl.toString()

const getDefaultState = (): IState => {
  return {
    dataLoaded: false,
    upgrades: [],
    stats: {} as IStats,
    status: null,
    error: null,
    replay: false,
    version: {} as IVersion,
    environment: null,
    build: null,
    supportEmail: `${process.env.VUE_APP_SUPPORT_EMAIL ||
      'support@droneshield.com'}`,
    user_invite_allowed: false,
    simulation_enabled: false,
    apiUrl,
    rubySocketUrl,
    coreSocketUrl,
    socket: {
      isConnected: false,
      message: '',
      reconnectError: false
    },
    alertsSocket: {
      isConnected: false,
      message: '',
      reconnectError: false
    },
    dataWebsocket: null,
    alertsWebsocket: null,
    systemSetting: {} as ISystemSetting,
    offline: false,
    logo: 'none'
  }
}

const state = getDefaultState()

const resetState = (state: any) => {
  Object.assign(state, { ...getDefaultState(), logo: state.logo })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
