import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { ICotsState } from '@/store/modules/cursor_on_target/types'

const getDefaultState = (): ICotsState => {
  return {
    error: '',
    status: '',
    settings: { cots: [] }
  }
}

const state = getDefaultState()

const resetState = (state: ICotsState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
