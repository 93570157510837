import { IRfFilterState } from "@/store/modules/rf_filters/types";

export default {
  status: (state: IRfFilterState) => state.status,
  error: (state: IRfFilterState) => state.error,
  rfActiveFilterCount: (state) => (sensor_id: number) => {
    return state.rf_filters[sensor_id]?.reduce((accumulator, filter) => {
      if (filter.rf_sensor_ids.includes(sensor_id) && filter.active === true && filter.whitelist === false)
        return accumulator + 1
      else
        return accumulator
    }, 0) ?? 0
  }
}