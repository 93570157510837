<template>
  <l-feature-group :visible="visible">
    <l-marker :icon="roseIcon" :lat-lng="latlng" />
    <v-rotated-marker
      v-if="rotatedMarker && (direction || direction === 0)"
      :icon="compassIcon"
      :lat-lng="latlng"
      :rotation-angle="rotationAngle"
    />
  </l-feature-group>
</template>

<script>
import { icon } from 'leaflet'
import { LMarker, LFeatureGroup } from 'vue2-leaflet'
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import { mapState } from 'vuex'

const props = {
  sentry: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0
    })
  },
  compass: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0,
      direction: null
    })
  },
  visible: {
    type: Boolean,
    default: true
  },
  rotatedMarker: {
    type: Boolean,
    default: true
  },
  sentryEditMode: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'CompassMarker',
  props,
  components: {
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    LMarker,
    LFeatureGroup
  },
  data: () => ({
    compassIcon: icon({
      iconUrl: require(`@/assets/compass.png`),
      iconSize: [400, 400],
      iconAnchor: [200, 200]
    }),
    roseIcon: icon({
      iconUrl: require(`@/assets/compass-rose.png`),
      iconSize: [600, 600],
      iconAnchor: [302, 300]
    })
  }),
  computed: {
    ...mapState('maps', {
      compassGraphicDirection: 'compassGraphicDirection'
    }),
    latlng() {
      const { compass, sentry } = this
      return {
        lat: compass.latitude || sentry.latitude || 0,
        lng: compass.longitude || sentry.longitude || 0
      }
    },
    rotationAngle() {
      return this.sentryEditMode ? this.compassGraphicDirection : this.direction
    },
    direction() {
      return (this.compass && this.compass.direction) || 0
    }
  }
}
</script>
