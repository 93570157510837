

























export default {
  name: 'd-confirm',
  props: ['status'],
  computed: {
    loading(): boolean {
      return this.status && this.status.toUpperCase() === 'LOADING'
    }
  }
}
