import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { RadarMaskState } from '@/store/modules/radar_mask/types'

const getDefaultState = (): RadarMaskState => {
  return {
    editing: false
  }
}

const state: RadarMaskState = getDefaultState()

const resetState = (state: RadarMaskState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
