<template>
    <v-alert
      v-if="show"
      :value="true"
      icon="error"
      color="white"
      outline
      class="browser-alert"
    >
      Error:
      Unable to add or edit filter due to sensor error. Please check connections and set up.
      Contact <a href="mailto:support@droneshield.com" style="color: white">support@droneshield.com</a> for further assistance.
    </v-alert>
</template>

<script>
export default {
  name: 'SensorAlert',
  props: {
    show: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped>

</style>