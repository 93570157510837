












































import Vue from 'vue'
import utils from '@/components/Map/utils/index.js'
import BaseMap from '@/components/Map/BaseMap.vue'
import { LMarker, LPolygon, LFeatureGroup } from 'vue2-leaflet'
import DronesentryxSectorCoverage from '@/components/Map/Coverage/DronesentryxSector.vue'
import RfSectorCoverage from '@/components/Map/Coverage/RFSector.vue'
import { capitalize, deg2rad, rad2deg } from '@/utils/utils'
import { IRfFilter } from '@/store/modules/rf_filters/types'
import { mapGetters, mapState } from 'vuex'
import { IValueToleranceData } from '@/components/Dialogs/Filters/FilterForm/AdvancedFilterForm.vue'
import CompassMarker from '@/components/Map/Marker/Compass.vue'

const props = {
  bearingData: {
    type: Object as () => IValueToleranceData,
    default: {
      value: 0,
      tolerance: 0
    }
  },
  filter: {
    type: Object as () => IRfFilter,
    default: null
  },
  zoom: {
    type: Number,
    default: 12
  },
  sentry: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0
    })
  },
  selectedSensors: {
    type: Array as () => number[],
    default: () => []
  },
  showBearing: {
    type: Boolean,
    default: true
  },
  allowReadWrite: {
    type: Boolean,
    default: false
  }
}
export default Vue.extend({
  name: 'AdvancedFilterFormMap',
  props,
  inject: ['providedData'],
  provide() {
    return {
      editItemType: this.editItemType
    }
  },
  components: {
    CompassMarker,
    RfSectorCoverage,
    DronesentryxSectorCoverage,
    BaseMap,
    LMarker,
    LPolygon,
    LFeatureGroup
  },
  data() {
    return {
      center: [0, 0]
    }
  },
  mounted() {
    setTimeout(() => {
      this.center = [this.midpoint.lat, this.midpoint.lon]
      this.refreshMap()
      this.centerMapFeatures()
    }, 250)
  },
  computed: {
    ...mapState('rf_sensors', {
      rfSensors: 'rfSensorsSet',
      dsxSensors: 'dsxSensorsSet'
    }),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sentries', ['sentriesInSite']),
    itemType(): string {
      return this.providedData.itemType
    },
    editItemType(): string {
      return `RF ${this.itemType}s`
    },
    sensor() {
      return this.providedData.sensor
    },
    maskOptions() {
      return {
        visible: true,
        color: this.fill,
        weight: 1,
        fillColor: this.fill,
        fillOpacity: 0.6,
        latLngs: this.range,
        stroke: !this.isDSX
      }
    },
    sentryIcon() {
      return L.icon({
        iconUrl: require(`@/assets/sentry/green.png`),
        iconSize: [40, 40],
        iconAnchor: [20, 40]
      })
    },
    isWhitelist() {
      return this.processedMask.whitelist
    },
    fill() {
      return this.isWhitelist ? '#ffffff' : '#000000'
    },
    isDSX() {
      return this?.currentSensor?.model.startsWith('dsx_')
    },
    processedMask() {
      const newMask = Object.assign({}, this.mask)

      if (!this.isDSX) {
        newMask.azimuth = [null, null]
      }

      return newMask
    },
    filterSensors() {
      return this.selectedSensors.map(id => {
        return this.allRfSensorsInSite[id]
      })
    },
    selectedSentries() {
      return this.filterSensors.map(sensor => {
        return sensor.sentry_id
      })
    },
    sentries() {
      return this.sentriesInSite(this.activeSiteId).filter(sentry => {
        return this.selectedSentries.includes(sentry.id)
      })
    },
    mask() {
      return {
        range: [0, this.currentSensor.reach],
        azimuth: this.filter?.azimuth || null,
        whitelist: this.filter?.whitelist || false
      }
    },
    currentSensor() {
      return this.sensor
    },
    otherSensorIds(): number[] {
      return (
        this.selectedSensors.filter(id => id !== this.currentSensor.id) || []
      )
    },
    allRfSensorsInSite(): unknown[] {
      return { ...this.rfSensors, ...this.dsxSensors }
    },
    otherSensors(): unknown[] {
      return this.otherSensorIds.map(id => this.allRfSensorsInSite[id])
    },
    currentSentry() {
      return this.sentries.find(sentry => {
        return sentry.id === this.currentSensor.sentry_id
      })
    },
    midpoint() {
      const coords_rad = this.filterSensors.map(sensor => {
        return {
          lat: deg2rad(sensor.latitude),
          lng: deg2rad(sensor.longitude)
        }
      })
      const cart_coords = coords_rad.map(obj => {
        return {
          x: Math.cos(obj.lat) * Math.cos(obj.lng),
          y: Math.cos(obj.lat) * Math.sin(obj.lng),
          z: Math.sin(obj.lat)
        }
      })
      let x = 0
      cart_coords.forEach(obj => {
        x += obj.x
      })
      let y = 0
      cart_coords.forEach(obj => {
        y += obj.y
      })
      let z = 0
      cart_coords.forEach(obj => {
        z += obj.z
      })

      const lon = rad2deg(Math.atan2(y, x))
      const hyp = Math.sqrt(x * x + y * y)
      const lat = rad2deg(Math.atan2(z, hyp))

      return {
        lat,
        lon
      }
    },
    range() {
      const { reach, direction = 0, angle = 90 } = this.currentSensor || {}
      return reach
        ? utils.createRange({
            reach,
            direction,
            min: this.bearingData.value - this.bearingData.tolerance, //this.processedMask.azimuth[0],
            max: this.bearingData.value + this.bearingData.tolerance, //this.processedMask.azimuth[1],
            vertex: [this.midpoint.lat, this.midpoint.lon],
            range360: this.bearingData.tolerance * 2 === 360
          })
        : []
    }
  },
  methods: {
    centerMapFeatures(): void {
      this.$refs.filterMap.mapObject.fitBounds(
        this.$refs['filter-map-feature-group'].mapObject.getBounds()
      )
      this.center = Object.values(
        this.$refs['filter-map-feature-group'].mapObject.getBounds().getCenter()
      )
    },
    getCenter(sentry) {
      return [sentry.latitude || 0, sentry.longitude || 0]
    },
    checkIfDSX(sensor): boolean {
      return sensor.model.includes('dsx')
    },
    refreshMap(): void {
      this.$refs.filterMap.invalidateSize()
    },
    zoomChanged(zoom) {}
  },
  watch: {
    selectedSensors: {
      handler: function() {
        this.$nextTick(() => {
          this.centerMapFeatures()
          this.refreshMap()
        })
      }
    }
  }
})
