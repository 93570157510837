




























import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import DetectionNotificationItem from '@/components/Notifications/SensorFusion/DetectionNotificationItem.vue'
import { IRfSensor } from '@/store/modules/rf_sensors/types'
import { IDetection } from '@/store/modules/detection/types'

export default Vue.extend({
  name: 'LiveNotificationPanel',
  components: {
    DetectionNotificationItem
  },
  computed: {
    ...mapState('notifications', ['fusionNotificationSort']),
    ...mapState('detection', ['fusionDetections', 'selectedDetections']),
    ...mapGetters('detection', ['fusionDetectionsSelectedFirst']),
    ...mapGetters('sensors', ['radarsSet']),
    ...mapGetters('rf_sensors', ['rfSensorsSet', 'dsxSensorsSet']),
    canProduceLocationIntersection() {
      if (
        Object.values(this.radarsSet).filter(
          (radar: any) => radar.status === 'transmitting'
        ).length > 0
      ) {
        return true
      } else
        return (
          [
            ...Object.values(this.rfSensorsSet).filter(
              (sensor: IRfSensor) => sensor.active === true
            )
          ].length > 1
        )
    }
  },
  methods: {
    showLocationPanel(detection: IDetection): boolean {
      return (
        this.canProduceLocationIntersection ||
        detection.latitude !== 0 ||
        detection.longitude !== 0
      )
    }
  }
})
