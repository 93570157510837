import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'
import 'vuetify/dist/vuetify.min.css'
// @ts-ignore
import HelpIcon from '@/components/Help/HelpIcon'
// @ts-ignore
import CancelTrackingIcon from '@/components/CustomIcons/CancelTrackingIcon'
// @ts-ignore
import CameraTrackIcon from '@/components/CustomIcons/CameraTrackIcon'
// @ts-ignore
import CameraTrackDisableIcon from '@/components/CustomIcons/CameraTrackDisableIcon'
// @ts-ignore
import DetectionsIcon from '@/components/CustomIcons/DetectionsIcon'

const PRIMARY_COLOR = 'orange'

const generateTheme = () => {
  const primaryKey = colors[PRIMARY_COLOR] ? PRIMARY_COLOR : 'orange'

  const theme: Record<string, string> = {
    primary: colors[primaryKey].lighten1,
    success: colors.green.base,
    error: colors.red.base,
    off: colors.grey.darken2,
    sdark: colors.grey.darken4,
    background: '#303030',
    droBlack: '#262626'
  }

  // add primary shades
  Object.keys(colors[primaryKey]).forEach((item) => {
    theme[`primary-${item}`] = colors[primaryKey][item]
  })

  // add colors
  Object.keys(colors).forEach((color) => {
    Object.keys(colors[color]).forEach((item) => {
      theme[`${color}-${item}`] = colors[color][item]
    })
  })

  return theme
}

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: generateTheme(),
  icons: {
    helpIcon: {
      component: HelpIcon
    },
    cancelTrackIcon: {
      component: CancelTrackingIcon
    },
    detectionsIcon: {
      component: DetectionsIcon
    },
    cameraTrackIcon: {
      component: CameraTrackIcon
    },
    cameraTrackDisableIcon: {
      component: CameraTrackDisableIcon
    }
  }
})
