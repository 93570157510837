<template>
  <l-feature-group :visible="visible">
    <l-polygon-fill-pattern
      v-for="(intersection, index) in intersections"
      :key="`int-${index}`"
      :color="colorIntersection"
      :weight="0.1"
      :fill-color="colorIntersection"
      :opacity="0.1"
      :fill-opacity="fillOpacity"
      :lat-lngs="intersection"
      :fill-pattern="require('@/assets/patterns/intersection.png')"
      :image-scale="0.5"
    />
    <div v-if="!visibleSectorType(activeSiteId, 'rf_one')">
      <l-polygon
        v-for="(intersection, index) in intersections"
        :key="`int-rfone-${index}`"
        color="#0080F7"
        :weight="1"
        fill-color="#0080F7"
        :opacity="1"
        :fill-opacity="0.2"
        :lat-lngs="intersection"
      />
    </div>
  </l-feature-group>
</template>

<script>
import utils from '../utils'
import { LPolygon, LFeatureGroup } from 'vue2-leaflet'
import intersect from '@turf/intersect'
import union from '@turf/union'
import { polygon } from '@turf/helpers'
import { mapState, mapGetters } from 'vuex'

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  sensors: {
    type: Object,
    default: () => ({})
  }
}

export default {
  name: 'IntersectionCoverage',
  props,
  components: {
    LPolygon,
    LFeatureGroup
  },
  data: () => ({
    // color: "#FF9800",
    fillOpacity: 0.1,
    prev: 0
  }),
  computed: {
    ...mapGetters(['displayedSectorsInSite']),
    ...mapGetters('sites', ['activeSiteId']),
    weight() {
      return this.active ? 3 : 1
    },
    fillColor() {
      return this.active ? 'orange' : 'white'
    },
    color() {
      return this.active ? 'orange' : 'white'
    },
    colorIntersection() {
      return 'transparent'
    },
    ranges() {
      return Object.values(this.sensors)
        .filter(s => s.model !== 'rf_zero')
        .map(s => {
          const { reach, direction = 0, latitude, longitude } = s || {}
          return reach
            ? utils.createRange({
              reach,
              direction,
              vertex: [latitude, longitude],
              range360: s.model === 'rf_zero'
            })
            : []
        })
    },
    intersections() {
      if (this.ranges.length < 2) return []
      let intersections = []
      const finished = []

      const ranges = this.ranges.map(range => {
        return range.map(r => {
          return [parseFloat(r[0]), parseFloat(r[1])]
        })
      })

      ranges.forEach((range, index) => {
        ranges.forEach((range2, index2) => {
          const min = Math.min(index, index2)
          const max = Math.max(index, index2)
          const key = `${min}_${max}`
          if (!finished.includes(key) && index !== index2) {
            finished.push(key)
            const intersection = intersect(polygon([range]), polygon([range2]))
            intersections.push(intersection)
          }
        })
      })

      return intersections.map(intersection => {
        return intersection?.geometry.coordinates || [[0, 0]]
      })
    }
  },
  methods: {
    visibleSectorType(activeSiteId, type) {
      return Object.values(this.displayedSectorsInSite(activeSiteId)).some(
        v => v.value === type
      )
    }
  },
  mounted() {
    if (this.visible) this.fillOpacity = 1
  },
  watch: {
    visible(value) {
      if (value) {
        this.fillOpacity = 1
      } else {
        this.fillOpacity = 0
      }
    }
  }
}
</script>
