export { default as axiosInstance } from './axios-instance'
export { default as Sensor } from './Module'

interface IAdditionalOptions {
  headers?: Object
  onUploadProgress?: any
  cancelToken?: CancelToken
}

import createInstance from './axios-instance'
import {
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  CancelToken
} from 'axios'

export function getSessionToken() {
  const { token } = JSON.parse(localStorage.getItem('user-token') || '{}')
  return token
}

function getPreferences() {
  return JSON.parse(localStorage.getItem('preferences') || '{}')
}

export function setPersistent(state: any, property: string, value: any) {
  const preferences = getPreferences()
  state[property] = preferences[property] = value
  localStorage.setItem('preferences', JSON.stringify(preferences))
}

export function getPersistent(property: string, defaultValue: any) {
  const preferences = getPreferences()
  return preferences.hasOwnProperty(property)
    ? preferences[property]
    : defaultValue
}

export async function apiRequest(
  method: string,
  url: string,
  payload: object | undefined,
  successCallback: any | undefined,
  errorCallback: any | undefined,
  additionalOptions: IAdditionalOptions = null
) {
  let options = {
    method: method,
    url: url
  } as AxiosRequestConfig

  if (payload) {
    options.data = payload
  }

  if (additionalOptions?.headers) {
    options.headers = additionalOptions.headers
  }

  if (additionalOptions?.onUploadProgress) {
    options.onUploadProgress = additionalOptions.onUploadProgress
  }

  if (additionalOptions?.cancelToken) {
    options.cancelToken = additionalOptions.cancelToken
  }

  try {
    const { data, headers, status } = await createInstance(options)
    let statuses = method === 'post' ? [201, 202] : [200]
    if (!statuses.includes(status)) {
      throw { response: { data: data, status: status } }
    }
    let successData
    if (successCallback) {
      successData = successCallback(data, headers)
    }
    return successData || data
  } catch (err) {
    let error = (err as any).response
    if (!(err as any).response) {
      console.log(url)
      console.log(err)
      error = { data: { error: (err as any).message }, status: 500 }
    }
    if (errorCallback) {
      return errorCallback(error.data, error.status)
    }
  }
}

export function constructUrl(baseUrl: string, options: any) {
  let constructedUrl = baseUrl

  if (options) {
    // add path option
    let pathToAppend = options.path
    if (pathToAppend !== undefined) {
      // if slash on end of baseUrl, remove slash
      if (constructedUrl[constructedUrl.length - 1] === '/')
        constructedUrl = constructedUrl.slice(0, -1)
      // if URL at start at path, remove URL
      if (pathToAppend[0] === '/')
        pathToAppend = pathToAppend.slice(1, pathToAppend.length)
      // add path
      constructedUrl = constructedUrl + '/' + pathToAppend
    }

    // construct query parameters
    let queryOptions = options.queryParams
    if (queryOptions !== undefined || typeof queryOptions === 'object') {
      let listOfQueries = new Array()
      // get all keys from object
      let allKeys = Object.keys(queryOptions)
      // iterate over every key
      for (let i = 0; i < allKeys.length; i++) {
        // get current query and value
        let currentQuery = allKeys[i]
        let currentValue = queryOptions[currentQuery]
        if (currentValue !== undefined) {
          if (typeof currentValue === 'string')
            currentValue = currentValue.trim()
          else currentValue = String(currentValue)
          let encodedParameter = encodeURIComponent(currentValue)
          listOfQueries.push(currentQuery + '=' + encodedParameter)
        }
      }

      // construct entire query parameters
      if (listOfQueries.length > 0) {
        let allQueries = '?' + listOfQueries.join('&')
        constructedUrl = constructedUrl + allQueries
      }
    }
  }

  return constructedUrl
}

/**
 * If display unit of system is imperial and current unit is:
 * m  -> value gets converted to feet
 * km -> value gets converted to miles
 * ft -> value gets converted to meters (we do this before storing in database)
 *
 * If display unit is metric then no change is made to the value
 *
 * @param systemSetting in order to get the display_unit of the system
 * @param value the value to convert
 * @param unit the unit the value is in CURRENTLY
 * @param toFixed (optional, Default: 0) number of decimal points. Pass -1 if you don't want to run this
 * @returns converted value
 */
export function convertValIfImperial(systemSetting: any, value: number, unit: string, toFixed: number = 0) {
  let multiplier = 1
  if (systemSetting.display_unit === 'imperial') {
    if (unit === 'm')
      multiplier = 3.28084
    else if (unit === 'km')
      multiplier = 0.621371
    else if (unit === 'ft')
      multiplier = 0.3048
  }
  return toFixed !== -1 ? parseFloat((value * multiplier).toFixed(toFixed)) : (value * multiplier)
}

export function convertUnitIfImperial(systemSetting: any, unit: string) {
  if (systemSetting.display_unit === 'imperial') {
    if (unit === 'm')
      return 'ft'
    else if (unit === 'km')
      return 'mi'
  }
  return unit
}

const agent = window.navigator.userAgent.toLowerCase()
const chrome = agent.indexOf('chrome') === -1 ? false : true
export function isChrome() {
  return chrome
}
