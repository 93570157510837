import { apiRequest } from '../../utils'

export default {
  AUTH_REQUEST: async (
    { commit, dispatch, rootState },
    { username, password }
  ) => {
    localStorage.clear()
    commit('AUTH_REQUEST')
    let loginErrorMsg = 'You have entered an invalid username or password'
    return await apiRequest(
      'post',
      `/api/tokens`,
      { user: { username, password, valid_hours: 24 } },
      async (data, _) => {
        const { user, token, token_expires_at } = data

        user.token = token
        user.expires_at = token_expires_at

        localStorage.setItem('user-token', JSON.stringify(user))

        const response = await dispatch(
          'users/FETCH_USER_SITES_ASSOCIATION',
          user,
          { root: true }
        )

        if (user.role !== 'user') {
          commit('AUTH_SUCCESS', user)
          return
        }

        if (Array.isArray(response)) {
          if (response.length > 0) {
            commit('AUTH_SUCCESS', user)
          } else {
            loginErrorMsg =
              'The user has no associated site. Contact your administrator.'
            commit('AUTH_ERROR', loginErrorMsg)
            localStorage.clear()
          }
        } else {
          loginErrorMsg = response
          if (response === '') {
            loginErrorMsg = 'A problem occurred, please try again.'
          }
          commit('AUTH_ERROR', loginErrorMsg)
          localStorage.clear()
        }
      },
      (data, _) => {
        if (data.error) {
          loginErrorMsg = data.error
        }
        commit('AUTH_ERROR', loginErrorMsg)
      }
    )
  },
  PASSWORD_RESET_REQUEST: async (
    { commit, dispatch, rootState },
    { email }
  ) => {
    commit('AUTH_REQUEST')
    await apiRequest(
      'post',
      `/api/users/password_reset_verify`,
      {
        user: { email }
      },
      () => {
        commit('CLEAR_ERROR')
      },
      err => {
        commit('AUTH_ERROR', err?.error || err.toString())
      }
    )
  },
  AUTH_LOGOUT: async ({ dispatch }) => {
    dispatch('_CLEAR_DATA', null, { root: true })
  },
  CLEAR_ERROR({ commit }) {
    commit('CLEAR_ERROR')
  }
}
