import { ISiteMarker, ISiteMarkerResponse } from './types'

export default {
  SET_STATUS: (state, status) => (state.status = status),
  SET_ERROR: (state, error) => (state.error = error),

  SET_SITE_MARKERS: (state: any, payload: ISiteMarker[]) => {
    state.site_markers = [...payload]
  },

  ADD_SITE_MARKER: (state: any, payload: ISiteMarkerResponse) => {
    state.site_markers = [...state.site_markers, payload.site_marker]
  },
  REMOVE_SITE_MARKER: (state: any, payload: ISiteMarker) => {
    (state.site_markers = state.site_markers.filter(m => m.id !== payload.id))
  },
  TOGGLE_SHOW_SITE_MARKERS: (state: any, value: boolean) => {
    state.showMarkers = value
  }
}
