





import Vue from 'vue'

export default Vue.extend({
  name: 'ActionButtonGroup',
  computed: {
    buttonWidth() {
      if (this.$slots.default === undefined) return 'quarter'
      switch (this.$slots.default.filter(slot => !slot.isComment).length) {
        case 0:
        case 1:
        case 2:
          return 'double'
        case 3:
          return 'thirds'
        default:
          return 'quarter'
      }
    }
  }
})
