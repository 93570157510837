import { ESiteMode, ISiteState } from './types'

export default {
  status: (state: ISiteState) => state.status,
  error: (state: ISiteState) => state.error,
  activeSiteId: (state: ISiteState) => state[`activeSiteId`],
  sitesSet: (state: ISiteState) => state[`sitesSet`],
  sitesList: (state: ISiteState) => Object.values(state[`sitesSet`]),
  sitesLength: (_: any, getters: any) =>
    (getters[`sitesList`] && getters[`sitesList`].length) || 0,
  activeSite: (state: ISiteState) => state[`sitesSet`][state[`activeSiteId`]],
  activeSiteMode: (state: ISiteState): ESiteMode =>
    state[`sitesSet`][state[`activeSiteId`]].mode,
  siteZoom: (_: any, getters: any) => {
    return (getters.activeSite && getters.activeSite.zoom_level) || 14
  },
  siteCenter: (_: any, getters: any) => {
    const { latitude = 0, longitude = 0 } = getters.activeSite || {}
    return [latitude, longitude]
  },
  isCustomMapCenter: (_: any, getters: any): boolean => {
    const { map_center_latitude, map_center_longitude } = getters.activeSite || {}
    return (map_center_latitude !== null && map_center_longitude !== null) 
  },
  mapCenter: (_: any, getters: any): number[] => {
    const { latitude = 0, longitude = 0, map_center_latitude = 0, map_center_longitude = 0 } = getters.activeSite || {}
    return (getters.isCustomMapCenter) 
      ? [map_center_latitude, map_center_longitude]
      : [latitude, longitude]
  },
  simulationRunning: (state: ISiteState) => state.simulationRunning,
  simulationStartLoading: (state: ISiteState) => state.simulationStartLoading
}
