<template>
  <div />
</template>

<script>
import {
  optionsMerger,
  propsBinder,
  findRealParent,
  TileLayerMixin,
  OptionsMixin
} from 'vue2-leaflet'
import { tileLayer, DomEvent, Util, Browser } from 'leaflet'

const props = {
  tileLayerClass: {
    type: Function,
    default: tileLayer
  },
  url: {
    type: String,
    default: ''
  },
  isOffline: {
    type: Boolean,
    default: false
  },
  maxZoom: {
    type: Number,
    default: 24
  },
  fallback: {
    type: String,
    default: null
  }
}

export default {
  name: 'SimpleLTileLayer',
  props,
  mixins: [TileLayerMixin, OptionsMixin],
  mounted() {
    // maxZoom is missing in tileLayerOptions
    this.tileLayerOptions.maxZoom = this.maxZoom
    const options = optionsMerger(this.tileLayerOptions, this)
    this.mapObject = this.tileLayerClass(this.url, options)
    this.mapObject.toQuadKey = function(x, y, z) {
      var index = ''
      for (var i = z; i > 0; i--) {
        var b = 0
        var mask = 1 << (i - 1)
        if ((x & mask) !== 0) b++
        if ((y & mask) !== 0) b += 2
        index += b.toString()
      }
      return index
    }

    this.mapObject.getTileUrl = (coords, urlTemplate = this.mapObject._url) => {
      var quadkey = this.mapObject.toQuadKey(coords.x, coords.y, coords.z)
      var url = Util.template(urlTemplate, {
        q: quadkey,
        s: this.mapObject._getSubdomain(coords),
        x: coords.x,
        y: coords.y,
        z: this.mapObject._getZoomForUrl()
      })
      if (typeof this.mapObject.options.style === 'string') {
        url += '&st=' + this.mapObject.options.style
      }

      if (!this.mapObject.hasOwnProperty('_loadedOfflineMapsCount'))
        this.mapObject._loadedOfflineMapsCount = 0
      if (this.mapObject._loadedTilesTimeout)
        clearTimeout(this.mapObject._loadedTilesTimeout)
      this.mapObject._loadedTilesTimeout = setTimeout(() => {
        this.mapObject._loadedTilesTimeout = null
        this.mapObject._loadedOfflineMapsCount = 0
      }, 500)

      return url
    }

    this.mapObject._tileOnError = (done, tile, coords, e) => {
      const offlineUrl = `${require('@/assets/no_map_tile_dark.png')}`
      let fallbackUrl = null

      if (this.fallback) {
        var quadkey = this.mapObject.toQuadKey(coords.x, coords.y, coords.z)
        fallbackUrl = Util.template(this.fallback, {
          q: quadkey,
          s: this.mapObject._getSubdomain(coords),
          x: coords.x,
          y: coords.y,
          z: this.mapObject._getZoomForUrl()
        })
      }

      if (tile.src === fallbackUrl || fallbackUrl === null) {
        if (offlineUrl && tile.getAttribute('src') !== offlineUrl) {
          tile.src = offlineUrl
          this.mapObject._loadedOfflineMapsCount += 1
        }
      } else {
        tile.src = fallbackUrl
      }
      done(e, tile)
    }

    this.mapObject.createTile = function(coords, done) {
      var tile = document.createElement('img')

      DomEvent.on(tile, 'load', Util.bind(this._tileOnLoad, this, done, tile))
      DomEvent.on(
        tile,
        'error',
        Util.bind(this._tileOnError, this, done, tile, coords)
      )

      if (this.options.crossOrigin || this.options.crossOrigin === '') {
        tile.crossOrigin =
          this.options.crossOrigin === true ? '' : this.options.crossOrigin
      }

      /*
      Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
      http://www.w3.org/TR/WCAG20-TECHS/H67
      */
      tile.alt = ''

      /*
      Set role="presentation" to force screen readers to ignore this
      https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
      */
      tile.setAttribute('role', 'presentation')

      tile.src = this.getTileUrl(coords)

      return tile
    }

    DomEvent.on(this.mapObject, this.$listeners)
    propsBinder(this, this.mapObject, this.$options.props)
    this.parentContainer = findRealParent(this.$parent)
    this.parentContainer.addLayer(this, !this.visible)
    this.$nextTick(() => {
      this.$emit('ready', this.mapObject)
    })
  }
}
</script>

<style scoped></style>
