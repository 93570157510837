import { setPersistent } from '../../utils'

const isSensorActive = (state, { sentry, sensor, type }) => {
  return !!state.activeSensors.find(item => {
    return (
      item.sentry === sentry && item.sensor === sensor && item.type === type
    )
  })
}

const isGroupActive = (state, { sentry, group }) => {
  return !!state.activeGroups.find(item => {
    return item.sentry === sentry && item.group === group
  })
}

const deactivateSensor = (state, { sentry, sensor, type }) => {
  const toRemove = state.activeSensors.findIndex(item => {
    return (
      item.sentry === sentry && item.sensor === sensor && item.type === type
    )
  })
  if (toRemove >= 0) {
    state.activeSensors.splice(toRemove, 1)
  }
}

const deactivateGroup = (state, { sentry, group }) => {
  const toRemove = state.activeGroups.findIndex(item => {
    return item.sentry === sentry && item.group === group
  })
  if (toRemove >= 0) {
    state.activeGroups.splice(toRemove, 1)
  }
}

const persistSentries = state => {
  setPersistent(state, 'activeSentries', state.activeSentries)
}

const persistGroups = state => {
  setPersistent(state, 'activeGroups', state.activeGroups)
}

const persistSensors = state => {
  setPersistent(state, 'activeSensors', state.activeSensors)
}

const persistCamera = state => {
  setPersistent(state, 'activeCamera', state.activeCamera)
}

export default {
  ACTIVATE_SENTRY: (state, sentry) => {
    if (!state.activeSentries.includes(sentry)) {
      state.activeSentries.push(sentry)
      persistSentries(state)
    }
  },
  SET_ACTIVE_SENTRY: (state, sentry) => {
    state.activeSentries = [sentry]
    persistSentries(state)
  },
  DEACTIVATE_SENTRY: (state, sentry) => {
    if (state.activeSentries.includes(sentry)) {
      const index = state.activeSentries.indexOf(sentry)
      if (index > -1) {
        state.activeSentries.splice(index, 1)
        persistSentries(state)
      }
    }
  },
  CLEAR_SENTRIES: state => {
    state.activeSentries = []
    persistSentries(state)
  },
  ACTIVATE_SENSOR: (state, { sentry, sensor, type }) => {
    if (!isSensorActive(state, { sentry, sensor, type })) {
      state.activeSensors.push({
        sentry,
        sensor,
        type
      })
      persistSensors(state)
    }
  },
  DEACTIVATE_SENSOR: (state, item) => {
    deactivateSensor(state, item)
    persistSensors(state)
  },
  CLEAR_SENSORS: state => {
    const pinned = {}
    Object.entries(sessionStorage).forEach(([key, entry]) => {
      if (key.includes('pinnedCards')) {
        const [prefix, site, sentry] = key.split('_')
        pinned[sentry] = JSON.parse(entry)
      }
    })

    state.activeSensors.splice(0, state.activeSensors.length)
    persistSensors(state)
  },
  ACTIVATE_GROUP: (state, { sentry, group }) => {
    if (!isGroupActive(state, { sentry, group })) {
      state.activeGroups.push({
        sentry,
        group
      })
      persistGroups(state)
    }
  },
  DEACTIVATE_GROUP: (state, item) => {
    deactivateGroup(state, item)
    persistGroups(state)
  },
  CLEAR_GROUPS: state => {
    state.activeGroups.splice(0, state.activeGroups.length)
    persistGroups(state)
  },
  SET_ACTIVE_CAMERA: (state, camera) => {
    state.activeCamera = camera
    persistCamera(state)
  }
}
