













import Vue from 'vue'
import SimpleConfirmationDialog from '@/components/Dialogs/SimpleConfirmationDialog.vue'

const props = {
  value: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'FallbackConfirmDialog',
  props,
  components: { SimpleConfirmationDialog },
  methods: {
    onYes(): void {
      this.$emit('onPositive')
    },
    onNo(): void {
      this.$emit('onNegative')
    }
  }
})
