











































































import Vue from 'vue'

// @ts-ignore
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { ESliderTypes } from '@/components/Dialogs/Filters/types'
import { scaleValue } from '@/utils/utils'
// @ts-ignore
import { frequency } from 'units-converter'

export enum ETooltipTypes {
  NONE = 'none',
  ALWAYS = 'always',
  HOVER = 'hover',
  FOCUS = 'focus',
  ACTIVE = 'active'
}

export interface ISliderUpdateData {
  min: number
  max: number
  diff: number
}

export interface ISliderItem {
  id?: number
  value: number
  tolerance: number
}

const props = {
  max: {
    type: Number,
    default: 0
  },
  min: {
    type: Number,
    default: 0
  },
  items: {
    type: Array as () => ISliderItem[],
    default: () => []
  },
  highlighted: {
    type: Array as () => number[],
    default: () => []
  },
  ordered: {
    type: Boolean,
    default: false
  },
  enableCross: {
    type: Boolean,
    default: true
  },
  clickable: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  railStyle: {
    type: Object,
    default: () => ({
      height: '50%',
      backgroundColor: '#656565',
      zIndex: 1,
      borderRadius: '0px'
    })
  },
  stepStyle: {
    type: Object,
    default: () => ({
      backgroundColor: '#656565',
      height: '600%',
      width: '50%',
      transform: 'translateX(50%)',
      borderRadius: '0px',
      zIndex: 5
    })
  },
  rangeStyle: {
    type: Object,
    default: () => ({
      height: '300%',
      transform: 'translateY(-35%)',
      backgroundColor: 'var(--dro-primary-darken1)',
      opacity: 0.5,
      borderRadius: '0px',
      zIndex: 100
    })
  },
  type: {
    type: String as () => ESliderTypes,
    default: ESliderTypes.FREQUENCY
  },
  label: {
    type: String,
    default: null
  },
  tooltip: {
    type: String as () => ETooltipTypes,
    default: ETooltipTypes.NONE
  }
}

export default Vue.extend({
  name: 'SliderComponent',
  props,
  components: {
    VueSlider
  },
  computed: {
    dataValues() {
      let values = []
      switch (this.type) {
        case ESliderTypes.FREQUENCY:
          values = this.items
            .map((item: ISliderItem) => {
              return this.getFilterMinMax(item.value, item.tolerance)
            })
            .flat()

          return values.map(val => {
            return scaleValue(val, this.min, this.max, 0, 100)
          })
        case ESliderTypes.BEARING:
          values = this.items
            .map((item: ISliderItem) => {
              return this.getFilterMinMax(item.value, item.tolerance)
            })
            .flat()

          return values.map(val => {
            return scaleValue(val, this.min, this.max, 0, 100)
          })
        default:
          return values
      }
    },
    groupedArrays() {
      return this.groupToArrays(this.scaledValues, 2)
    },
    scaledValues() {
      const values = []
      switch (this.type) {
        case ESliderTypes.FREQUENCY:
          this.items.forEach((item: ISliderItem) => {
            const min = item.value - item.tolerance
            const max = item.value + item.tolerance
            values.push(scaleValue(min, this.min, this.max, 0, 100))
            values.push(scaleValue(max, this.min, this.max, 0, 100))
          })
          break
        default:
          this.items.forEach((item: ISliderItem) => {
            let min = item.value - item.tolerance
            if (min > 180) {
              min -= 360
            }

            let max = item.value + item.tolerance
            if (max > 180) {
              max -= 360
            }

            values.push(scaleValue(min, this.min, this.max, 0, 100))
            values.push(scaleValue(max, this.min, this.max, 0, 100))
          })
      }

      return values
    },
    marks() {
      const MARK_COUNT = 10
      return this.getMarks(this.min, this.max, MARK_COUNT)
    }
  },
  methods: {
    onChange(val) {
      const scaledVal0 = scaleValue(val[0], 0, 100, this.min, this.max)
      const scaledVal1 = scaleValue(val[1], 0, 100, this.min, this.max)
      const min = Math.min(scaledVal0, scaledVal1)
      const max = Math.max(scaledVal0, scaledVal1)
      const diff = max - (max + min) / 2
      this.$emit('onChange', { min, max, diff })
    },
    getFilterMinMax(freq, tolerance) {
      return [freq - tolerance, freq + tolerance]
    },
    groupToArrays(valueArray, size) {
      const groups = []
      for (let i = 0; i < valueArray.length; i += size) {
        groups.push(valueArray.slice(i, i + size))
      }
      return groups
    },
    getRanges() {
      return this.groupedArrays
    },
    updateStyle(index, style) {
      const item = this.items[index]
      const highlighted = this.highlighted.includes(item.id)
      const newStyle = Object.assign({}, style)
      if (highlighted) {
        newStyle.backgroundColor = 'var(--dro-primary)'
        newStyle.boxShadow = `0px 0px 15px 1px var(--dro-primary)`
      }
      return newStyle
    },
    getMarks(min, max, count) {
      const marks = {}
      const markKeys = []
      let step = 100 / count

      switch (this.type) {
        case ESliderTypes.FREQUENCY:
          for (let x = 0; x <= count; x++) {
            markKeys.push(x * step)
          }

          markKeys.forEach(key => {
            const val = scaleValue(key, 0, 100, min, max).toFixed(3)
            const freq = frequency(val)
              .from('Hz')
              .toBest()
            marks[key] = {
              label: `${freq.value}`,
              style: {
                display: 'block',
                zIndex: 10
              },
              labelStyle: {
                color: '#858585'
              }
            }
          })
          break
        default:
          for (let x = 0; x <= count; x++) {
            markKeys.push(x * step)
          }

          markKeys.forEach(key => {
            const val = scaleValue(key, 0, 100, min, max).toFixed(0)
            marks[key] = {
              label: val,
              style: {
                display: 'block',
                zIndex: 10
              },
              labelStyle: {
                color: '#858585'
              }
            }
          })
      }

      return marks
    }
  }
})
