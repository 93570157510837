import {
  CoturnCredentials,
  ICamera,
  ICameraState
} from '@/store/modules/cameras/types'

export default {
  SET_STATUS: (state: ICameraState, status: string) => (state.status = status),
  SET_ERROR: (state: ICameraState, error: any) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  LOAD_CAMERAS: (state: ICameraState, cameras: ICamera[]) => {
    state.camerasSet =
      cameras?.reduce((acc, curr) => {
        return Object.assign(acc, { [curr.id]: curr })
      }, {}) || {}
  },
  SET_COTURN_CRED: (state: ICameraState, cred: CoturnCredentials) => {
    state.coturn_cred = cred
  },
  ADD_CAMERA: (state: ICameraState, camera: ICamera) => {
    const update = Object.assign({}, state.camerasSet)
    update[camera.id] = camera
    state.camerasSet = update
  },
  REMOVE_CAMERA: (state: ICameraState, id: number) => {
    if (!id) return
    const update = Object.assign({}, state.camerasSet)
    delete update[id]
    state.camerasSet = update
    // Prevent icon to remain on map after deletion
    if (state.activeCameraId === id) {
      state.activeCameraId = null
    }
  },
  UPDATE_CAMERAS: (state: ICameraState, sensors: ICamera[]) => {
    if (sensors && !Array.isArray(sensors)) {
      sensors = [sensors]
    }
    return sensors.forEach(({ id, ...update }) =>
      id && state.camerasSet && state.camerasSet[id]
        ? Object.assign(state.camerasSet[id], update)
        : null
    )
  },
  ACTIVATE_CAMERA: (state: ICameraState, id: number) => {
    state.activeCameraId = id
  },
  DEACTIVATE_CAMERA: (state: ICameraState) => {
    state.activeCameraId = null
  },
  CALIBRATE_CAMERA: (state: ICameraState, calibrating) => {
    state.calibratingCamera =
      calibrating === undefined ? !state.calibratingCamera : calibrating
  },
  SOCKET_CAMERAS_CREATE: (state: ICameraState, cameras: ICamera[]) => {
    return cameras.forEach(camera => {
      state.camerasSet = Object.assign({}, state.camerasSet, {
        [camera.id]: camera
      })
    })
  },
  SOCKET_CAMERAS_DELETE: (state: ICameraState, cameras: ICamera[]) => {
    return cameras.forEach(({ id }) => {
      // uses es6 computed property names & destructuring assignment
      // GJ master piece
      // So good it requires an eslint exclusion
      // eslint-disable-next-line no-unused-vars
      const { [id]: dummy, ...withoutId } = state.camerasSet
      // Pretty good right?
      state.camerasSet = Object.assign({}, withoutId)
      // Prevent icon to remain on map after deletion
      if (state.activeCameraId === id) {
        state.activeCameraId = null
      }
    })
  }
}
