import moment from 'moment-timezone'

export default {
  SET_STATUS: (state, status) => (state.status = status),
  SET_ERROR: (state, error) => (state.error = error),
  SET_REPORT: (state, data) => (state.report = data),
  UPDATE_REPORT: (state, data) =>
    Object.assign(state.report.events[data.id], data),
  SET_START_DATE: (state, start) =>
    (state.timeframe = {
      ...state.timeframe,
      start,
      lastUpdated: moment.utc()
    }),
  SET_END_DATE: (state, end) =>
    (state.timeframe = { ...state.timeframe, end, lastUpdated: moment.utc() }),
  SET_SHOW_RF_FILTERED: (state, value) => (state.showRFFiltered = value),
  SET_SHOW_RF_WHITELISTED: (state, value) => (state.showRFWhitelisted = value),
  SET_SHOW_RADAR_RF_WHITELISTED: (state, value) =>
    (state.showRadarRFWhitelisted = value),
  SET_SHOW_FALSE_POSITIVES: (state, status) =>
    (state.showFalsePositives = status)
}
