import store from '@/store'

export default {
  SET_STATUS: (state, status) => (state.status = status),
  SET_ERROR: (state, error) => (state.error = error),
  SET_PLANS: (state, data) => {
    state.plansList = data
  },
  ADD_PLAN: (state, plan) => {
    state.plansList.push(plan)
    state.activePlan = plan
  },
  UPDATE_PLAN: (state, plan) => {
    Object.assign(
      state.plansList.find(s => s.id == plan.id),
      plan
    )
  },
  REMOVE_PLAN: (state, id) =>
    (state.plansList = state.plansList.filter(s => s.id !== id)),
  SET_ACTIVE_PLAN: (state, plan) => {
    state.activePlan = plan
  },
  SET_ACTIVE_INSTALLATION: (state, data) => {
    state.activeInstallation = data
  },
  UPDATE_PLANS_STATE: (state, planState) => {
    for (let att of Object.keys(planState)) {
      state.plansState[att] = planState[att]
    }
  },
  STOP_PLANNING_TOOL: (state, page) => {
    const allowedPages = ['sensors.edit', 'sensors.new', 'Plans']
    if (state.activePlan && !allowedPages.includes(page.name)) {
      store.commit('setActiveGroup', {})
      store.dispatch('sentries/SELECT_SENTRY', null)
      store.dispatch('sensors/UNSELECT_ALL')
      store.commit('plans/resetState')
    }
  },
  SET_DRAGGING: (state, isDragging) => state.isDragging = isDragging,
  SET_WIZARD: (state, isWizard) => state.isWizard = isWizard,
  SET_BOUNDS: (state, bounds) => state.bounds = bounds
}
