














































const props = {
  step: {
    type: [Number, String],
    default: 1
  },
  ticksLabels: {
    type: Array,
    default: () => []
  },
  label: {
    type: String
  },
  value: {
    type: Array,
    default: () => []
  },
  max: {
    type: [Number, String],
    default: 100
  },
  min: {
    type: [Number, String],
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  textInput: {
    type: Boolean,
    default: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  hideTextInputs: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'd-range-slider',
  props,
  computed: {
    vMin: {
      get(): number {
        return this.value[0]
      },
      set(val): void {
        this.$emit('input', [Number(val), this.vMax])
      }
    },
    vMax: {
      get(): number {
        return this.value[1]
      },
      set(val): void {
        this.$emit('input', [this.vMin, Number(val)])
      }
    },
    range: {
      get(): Array<Number> {
        return this.value
      },
      set(val): void {
        this.$emit('input', val)
      }
    }
  }
}
