import { Commit } from 'vuex'
import {
  EContributionStateType,
  IContributionHistoryUpdate,
  IDetection
} from '@/store/modules/detection/types'

export default {
  addDetection: ({ commit }: { commit: Commit }, detection: IDetection) => {
    commit('ADD_DETECTION', detection)
  },
  removeDetection: ({ commit }: { commit: Commit }, target_id: string) => {
    commit('REMOVE_DETECTION', target_id)
  },
  selectDetection: ({ commit }: { commit: Commit }, detection: string) => {
    commit('SELECT_DETECTION', detection)
  },
  addSelectedDetection: ({ commit }: { commit: Commit }, detection: string) => {
    commit('ADD_SELECTED_DETECTION', detection)
  },
  removeSelectedDetection: (
    { commit }: { commit: Commit },
    detection: string
  ) => {
    commit('REMOVE_SELECTED_DETECTION', detection)
  },
  setSelectedDetections: (
    { commit }: { commit: Commit },
    detections: string[]
  ) => {
    commit('SET_SELECTED_DETECTIONS', detections)
  },
  clearSelectedDetections: ({ commit }: { commit: Commit }) => {
    commit('CLEAR_SELECTED_DETECTIONS')
  },
  gotFirstContribution: (
    { commit }: { commit: Commit },
    type: EContributionStateType
  ) => {
    commit('SET_FIRST_CONTRIBUTION', type)
  },
  addSeenContributor: (
    { commit }: { commit: Commit },
    update: IContributionHistoryUpdate
  ) => {
    commit('ADD_SEEN_CONTRIBUTOR', update)
  },
  deleteSeenContributors: ({ commit }: { commit: Commit }, id: string) => {
    commit('DELETE_SEEN_CONTRIBUTORS', id)
  },
  selectRFBeam: ({ commit }: { commit: Commit }, key: string) => {
    return commit('ACTIVATE_RF_BEAM', key)
  },
  resetDetections: ({ commit }: { commit: Commit }) => {
    commit('RESET_DETECTIONS')
  },
  enableNewFusionDetections: ({ commit }: { commit: Commit }) => {
    commit('SET_FUSION_DETECTION_ENABLED_STATE', true)
  },
  disableNewFusionDetections: ({ commit }: { commit: Commit }) => {
    commit('SET_FUSION_DETECTION_ENABLED_STATE', false)
    commit('RESET_DETECTIONS')
  }
}
