import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getPersistent } from '../../utils'

const getDefaultState = () => {
  return {
    activeSentries: getPersistent('activeSentries', []),
    activeSensors: getPersistent('activeSensors', []),
    activeGroups: getPersistent('activeGroups', []),
    activeCamera: getPersistent('activeCamera', null)
  }
}

const state = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
