import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { ISnackbarState } from './types'

const getDefaultState = (): ISnackbarState => {
  return {
    snackbarText: '',
    textClass: '',
    buttonClass: '',
    snackbarTimeout: 0
  }
}

const state: ISnackbarState = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
