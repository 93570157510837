import { SensorStatus, SensorColor, RadarStates } from './constants'

import { mapState, mapGetters } from 'vuex'
import utils from '@/components/Map/utils'
import throttle from 'lodash/throttle'

export default {
  data: () => ({
    initDragAngle: null,
    initSensorDirection: null
  }),
  // TODO: Extract status and colors to constants
  computed: {
    ...mapState('sensors', ['status', 'error']),
    ...mapGetters('sentries', ['sentriesSet']),
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('users', ['isAuthorized']),
    $_authoriseEdit() {
      return this.isAuthorized('site_manager')
    },
    $_hasCompass() {
      return (
        this.sensor &&
        !!this.sensor['override_gps_compass_id'] &&
        this.sensor['override_gps_compass_id'] !== 'None'
      )
    },
    $_dsxSensorStatus() {
      return this.sensor
        ? {
            gnss: this.sensor.gnss_trigger_engaged,
            '24_58': this.sensor.band_24_58_trigger_engaged
          }
        : {
            gnss: false,
            '24_58': false
          }
    },
    $_cannonSensorStatus() {
      return this.sensor
        ? {
            gnss: this.sensor.gnss_trigger_engaged,
            auto_jam: this.sensor.auto_jam,
            '433': this.sensor.band_433_trigger_engaged,
            '915': this.sensor.band_915_trigger_engaged,
            '433_915': this.sensor.band_433_915_trigger_engaged,
            '24_58': this.sensor.band_24_58_trigger_engaged
          }
        : {
            gnss: false,
            '433': false,
            '915': false,
            '433_915': false,
            '24_58': false,
            auto_jam: false
          }
    },
    $_dsxStatus() {
      return this.sensors && this.sensors['dsx_sensors']
        ? {
            gnss: !!this.sensors['dsx_sensors'].find(
              c => !!c.gnss_trigger_engaged
            ),
            '24_58': !!this.sensors['dsx_sensors'].find(
              c => !!c.band_24_58_trigger_engaged
            )
          }
        : {
            gnss: false,
            '24_58': false
          }
    },
    $_cannonsStatus() {
      return this.sensors && this.sensors['cannons']
        ? {
            gnss: !!this.sensors['cannons'].find(c => !!c.gnss_trigger_engaged),
            auto_jam: !!this.sensors['cannons'].find(c => !!c.auto_jam),
            '433': !!this.sensors['cannons'].find(
              c => !!c.band_433_trigger_engaged
            ),
            '915': !!this.sensors['cannons'].find(
              c => !!c.band_915_trigger_engaged
            ),
            '433_915': !!this.sensors['cannons'].find(
              c => !!c.band_433_915_trigger_engaged
            ),
            '24_58': !!this.sensors['cannons'].find(
              c => !!c.band_24_58_trigger_engaged
            )
          }
        : {
            gnss: false,
            '433': false,
            '915': false,
            '433_915': false,
            '24_58': false,
            auto_jam: false
          }
    },
    $_configureAllBandsCannonStatus() {
      return this.sensors && this.sensors['cannons']
        ? {
            gnss: !!this.sensors['cannons'].every(c => !!c.gnss_trigger_engaged),
            auto_jam: !!this.sensors['cannons'].every(c => !!c.auto_jam),
            '433': !!this.sensors['cannons'].every(
              c => !!c.band_433_trigger_engaged
            ),
            '915': !!this.sensors['cannons'].every(
              c => !!c.band_915_trigger_engaged
            ),
            '433_915': !!this.sensors['cannons'].every(
              c => !!c.band_433_915_trigger_engaged
            ),
            '24_58': !!this.sensors['cannons'].every(
              c => !!c.band_24_58_trigger_engaged
            )
          }
        : {
            gnss: false,
            '433': false,
            '915': false,
            '433_915': false,
            '24_58': false,
            auto_jam: false
          }
    },
    sentryDirection() {
      return this.sensor.sentry_id
        ? this.sentriesSet[this.sensor.sentry_id].direction
        : 0
    },
    offset() {
      return this.sensor?.direction_offset || 0
    },
    $_absoluteDirection() {
      return utils.circularBounds(this.sensor.direction, [0, 360])
    },
    $_isNewSensor() {
      return !this.sensor.hasOwnProperty('id')
    }
  },
  methods: {
    $_maxStatus(a, b) {
      if ([a, b].includes(SensorStatus.ERROR)) {
        return SensorStatus.ERROR
      } else if ([a, b].includes(SensorStatus.WARN)) {
        return SensorStatus.WARN
      } else if ([a, b].includes(SensorStatus.GOOD)) {
        return SensorStatus.GOOD
      } else {
        return SensorStatus.NIL
      }
    },
    $_sensorStatus: (sensor, sensorType) => {
      const { GOOD, WARN, ERROR, NIL } = SensorStatus
      let state = ERROR

      let enabled
      const color = sensor.status_color

      switch (sensorType) {
        case 'rf_sensors':
        case 'dsx_sensors':
          enabled = sensor.active
          break
        case 'radars':
          if (sensor.status) {
            enabled = sensor.status.toUpperCase() === 'TRANSMITTING'
          } else {
            enabled = false
          }
          break
        default:
          enabled = true
      }

      switch (color) {
        case 'red':
          state = ERROR
          break
        case 'orange':
          state = WARN
          break
        case 'green':
          if (enabled) {
            state = GOOD
          } else {
            state = NIL
          }
          break
      }

      return state
    },
    $_statusColor(sensor, sensorType) {
      const state = this.$_sensorStatus(sensor, sensorType)
      return this.$_statusColorByStatus(state)
    },
    $_statusColorByStatus: state => SensorColor[state],
    $_resetDragAngle() {
      this.initDragAngle = null
    },
    $_dragSector: throttle(function(coords) {
      if (!this.draggable || !this.sensor) return
      const newDirection = utils.sensorDragDirection(this.sensor, coords)
      if (this.initDragAngle == null) {
        this.initDragAngle = newDirection
        this.initSensorDirection = this.sensor.direction
      }
      const currentDirection =
        (this.initSensorDirection + (newDirection - this.initDragAngle)) % 360
      this.$bus.$emit('wizard/SENSOR_UPDATE', {
        sensor: this.sensor,
        direction:
          currentDirection > 0 ? currentDirection : 360 + currentDirection
      })
    }, 100),
    $_shuffleSector() {
      this.$bus.$emit('wizard/SENSOR_UPDATE', {
        sensor: this.sensor,
        shuffle_sector: true
      })
    },
    $_updateOffset() {
      if (this.activePlan) return
      this.sensor.direction = this.sentryDirection + this.offset
    },
    $_updateLocation() {
      const { latitude, longitude } =
        this.sentriesSet[this.sensor.sentry_id] || {}
      this.sensor.latitude = latitude
      this.sensor.longitude = longitude
    },
    $_updatedSentry() {
      this.$_updateOffset()
      this.$_updateLocation()

      this.selectInstallationError = false
      this.selecInstallationSuffix = ''
    }
  },
  watch: {
    offset() {
      this.$_updateOffset()
    }
  }
}
