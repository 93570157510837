import { ICamera, ICameraState } from './types'
import { ISentry } from '@/store/modules/sentries/types'

export default {
  status: (state: ICameraState): string => state.status,
  error: (state: ICameraState): string => state.error,
  activeCameraId: (state: ICameraState): number => state.activeCameraId,
  activeCamera: (state: ICameraState): ICamera =>
    state.camerasSet[state.activeCameraId],
  camerasSet: (state: ICameraState): Record<number, ICamera> =>
    state.camerasSet,
  allCameras: (state: ICameraState): ICamera[] => {
    return Object.values(state.camerasSet) || []
  },
  cameraTrackedTarget: (state: ICameraState): string | number => {
    const camera: Partial<ICamera> = Object.values(state.camerasSet || {}).find(
      (c: ICamera) => c.radar_tracked_target || c.rf_tracked_target
    )
    return camera?.radar_tracked_target || camera?.rf_tracked_target
  },
  janusCameraIds: (state: ICameraState): number[] =>
    Object.values(state.camerasSet).flatMap((c: ICamera) => [
      c.id,
      ...(c.is_thermal && !c.active_stream ? [c.id + 100] : [])
    ]),
  cameraTrackedIds: (state: ICameraState): Record<string | number, string> => {
    const ids = {}
    Object.values(state.camerasSet).forEach((item: ICamera) => {
      ids[item.id] = item.tracked_target_id
    })
    return ids
  },
  cameraById: (state: ICameraState) => (id: number): ICamera => {
    return (state.camerasSet || {})[id]
  },
  camerasInSite: (state: ICameraState) => (
    site: number,
    sentries: ISentry[]
  ): Record<string, ICamera> => {
    const siteSentryIds = sentries.map((sentry: ISentry) => sentry.id)
    const cameras = {}
    Object.values(state.camerasSet).forEach((camera: ICamera) => {
      if (siteSentryIds.includes(camera.sentry_id)) {
        cameras[`${camera.id}-${camera.sentry_id}`] = camera
      }
    })
    return cameras
  },
  allCamerasInSentry: (state: ICameraState) => (
    sentryId: number
  ): ICamera[] => {
    return Object.values(state.camerasSet).filter(
      (camera: ICamera) => camera.sentry_id === sentryId
    )
  },
  camerasLength: (state: ICameraState) => {
    return (state.camerasSet && Object.values(state.camerasSet).length) || 0
  },
  currentCamera: (state: ICameraState) => {
    return Object.values(state.camerasSet).length === 0
      ? null
      : Object.values(state.camerasSet)[0]
  },
  currentCameraScanning: (state: ICameraState) => {
    return Object.values(state.camerasSet).length === 0
      ? null
      : Object.values(state.camerasSet)[0].scanning
  },
  currentTrackedTargetId: (state: ICameraState, getters) => {
    if (
      getters.currentCamera &&
      getters.currentCamera.tracked_target_id !==
        '00000000-0000-0000-0000-000000000000'
    ) {
      return getters.currentCamera.tracked_target_id
    } else return null
  },
  getCoturnCred: (state: ICameraState) => {
    state.coturn_cred
  }
}
