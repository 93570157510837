import arcPath from './arcPath'

const MAX_LATITUDE = 90
const MAX_LONGITUDE = 180

const createRange = ({
  vertex,
  reach = 1500,
  direction = 0,
  min = -45,
  max = 45,
  range360 = false
}) => {
  const [lat = 0, lng = 0] = vertex || []
  return arcPath.makeArcPath(
    { lat, lng },
    reach,
    range360 ? -180 : direction + parseFloat(min),
    range360 ? 180 : direction + parseFloat(max),
    range360
  )
}

const createRadarMaskRange = ({
  latitude,
  longitude,
  range,
  direction,
  azimuth
}) => {
  const vertex = { lat: latitude, lng: longitude }
  const inner_radius = range[0]
  const outer_radius = range[1]
  const start_angle = (direction + azimuth[0]) % 360
  const end_angle = (direction + azimuth[1]) % 360

  const outer_arc = arcPath.getArcPath(
    vertex,
    outer_radius,
    start_angle,
    end_angle
  )
  const inner_arc = arcPath
    .getArcPath(vertex, inner_radius, start_angle, end_angle)
    .reverse()

  return outer_arc.concat(inner_arc)
}

const circularBounds = (value, [min, max]) => {
  const span = max - min
  if (value < min) {
    return value + span * Math.ceil((min - value) / span)
  } else if (value > max) {
    return value - span * Math.ceil((value - max) / span)
  }
  return value
}

const bringSVGToTop = refSVG => {
  if (refSVG) {
    const svgPath = refSVG.mapObject.getElement()
    if (svgPath && svgPath.parentElement) svgPath.parentElement.append(svgPath)
  }
}

const bringSVGToBottom = refSVG => {
  if (refSVG) {
    const svgPath = refSVG.mapObject.getElement()
    if (svgPath && svgPath.parentElement) svgPath.parentElement.prepend(svgPath)
  }
}

const normaliseLatitude = (latitude, decimals = 8) => {
  // Fix NaN issue when receives empty string
  if (latitude.length === 0) latitude = 0
  if (typeof latitude === 'string') {
    latitude = parseFloat(latitude)
  }
  while (latitude < -MAX_LATITUDE) {
    latitude += 2 * MAX_LATITUDE
  }
  while (latitude > MAX_LATITUDE) {
    latitude -= 2 * MAX_LATITUDE
  }
  return parseFloat(latitude.toFixed(decimals))
}

const normaliseLongitude = (longitude, decimals = 8) => {
  // Fix NaN issue when receives empty string
  if (longitude.length === 0) longitude = 0
  if (typeof longitude === 'string') {
    longitude = parseFloat(longitude)
  }
  while (longitude < -MAX_LONGITUDE) {
    longitude += 2 * MAX_LONGITUDE
  }
  while (longitude > MAX_LONGITUDE) {
    longitude -= 2 * MAX_LONGITUDE
  }
  return parseFloat(longitude.toFixed(decimals))
}

export default {
  createRange,
  createRadarMaskRange,
  circularBounds,
  bringSVGToTop,
  normaliseLatitude,
  normaliseLongitude,
  ...arcPath
}
