




































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { isChrome } from '@/store/utils'
import { ESiteMode } from '@/store/modules/sites/types'

const props = {
  showBrowserAlert: {
    type: Boolean,
    default: true
  },
  defaultMode: {
    type: String,
    default: ESiteMode.Operational
  },
  suppressModeWarning: {
    type: Boolean,
    default: false
  },
  searchBar: {
    type: Boolean,
    default: false
  },
  fullWidth: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'BrowserAlert',
  props,
  inject: {
    itemType: {
      from: 'editItemType',
      default: 'radar masks'
    }
  },
  computed: {
    ...mapGetters('sites', ['activeSite']),
    ...mapGetters('users', ['isAuthorized']),
    notChrome() {
      return !isChrome()
    },
    getMessage() {
      switch (this.defaultMode) {
        case ESiteMode.Calibration:
          return `Calibration mode required to add or edit ${this.itemType}.`
        case ESiteMode.Monitor:
        default:
          return 'Detections not saved.'
      }
    },
    authorized() {
      return this.isAuthorized('site_manager')
    }
  },
  methods: {
    ...mapActions('sites', ['switchSiteMode']),
    async switchTargetMode() {
      if (!this.authorized) return

      const activeSiteId: number = parseInt(this.activeSite.id)
      if (Number.isInteger(activeSiteId)) {
        await this.switchSiteMode({
          mode: this.defaultMode as ESiteMode, siteId: activeSiteId,
        })
      }
    },
    getClass() {
      if (this.fullWidth) return 'full-width-alerts-container'
      else if (this.searchBar)
        return 'map-alerts-container map-search-alerts-container'
      else return 'map-alerts-container'
    }
  }
})
