import { Commit, Dispatch } from 'vuex'
import { apiRequest } from '../../utils'
import moment from 'moment-timezone'
import {
  ISiteWarningsListReq,
  ICreateSiteWarningInput,
  ISiteWarningState,
  ISiteWarning, IClearSiteWarning
} from './types'

interface FetchWarningsArguments {
  site_id: number
  last_2_days: boolean
  read: boolean
  timezone?: string
}

interface PatchWarningsArguments {
  site_id: number
  id: number
}

interface PatchClearWarningsArguments {
  site_id: number
}

export default {
  FETCH_SITE_WARNINGS: async ({ commit }: { commit: any }, args: FetchWarningsArguments) => {
    let url = `/api/sites/${args.site_id}/site_warnings?read=${args.read}`

    if (args.last_2_days) {
      const now = moment.utc()
      const now_in_zone = moment.tz(now, args.timezone)
      const TWO_DAYS_AGO = now_in_zone.subtract(2, 'days')
      url = `${url}&end_time=${TWO_DAYS_AGO.toISOString()}`
    }
    await apiRequest(
      'get',
      url,
      {},
      (data: ISiteWarningsListReq) => {
        if (args.read) {
          commit('SET_SITE_WARNINGS_HISTORY', data.site_warning || [])
        } else {
          commit('SET_SITE_WARNINGS', data.site_warning || [])
        }
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  CREATE_SITE_WARNING: async (
    { commit, state }: { commit: Commit; state: ISiteWarningState },
    payload: ICreateSiteWarningInput
  ) => {
    await apiRequest(
      'post',
      `/site_warnings.json`,
      {
        site_warning: {
          title: payload.title,
          content: payload.content,
          site_id: payload.site_id,
          read: false,
          warning_type: payload.warning_type
        }
      },
      (data: any) => {
        commit('SET_STATUS', 'OK')
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  MARK_SITE_WARNING_AS_READ: async (
    { commit }: { commit: Commit; state: any; dispatch: Dispatch },
    args: PatchWarningsArguments
  ) => {
    await apiRequest(
      'patch',
      `/api/sites/${args.site_id}/site_warnings/${args.id}`,
      { site_warning: { read: true } },
      (data: {site_warning: ISiteWarning }) => {
        commit('SET_STATUS', 'OK')
        commit('UPDATE_MARKED_AS_READ_SITE_WARNINGS', data.site_warning)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  MARK_ALL_SITE_WARNING_AS_READ: async (
    { commit }: { commit: Commit; state: any; dispatch: Dispatch },
    site_id: string
  ) => {
    await apiRequest(
      'put',
      `/api/sites/${site_id}/site_warnings/clear`,
      null,
      (data: IClearSiteWarning) => {
          commit('SET_STATUS', 'OK')
          commit('UPDATE_CLEAR_ALL_SITE_WARNINGS', data.site_warnings)

        if (data.errors?.length > 0) {
          commit('SET_STATUS', 'FAILED')
          commit('SET_ERROR', new Error(data.errors.join("\n")))
        }
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  SOCKET_SITE_WARNINGS_UPDATE: (state: ISiteWarningState, payload: ISiteWarning) => {},
}
