export const ruleMinValue = min => value =>
  value >= min || `Must be at least ${min}`
export const ruleMinValueButNotZero = min => value => {
  if (value === 0 || value === '0') {
    return 'Cannot be zero'
  } else if (value < min) {
    return `Must be at least ${min}`
  } else {
    return true
  }
}
export const ruleMaxValue = max => value =>
  value <= max || `Must be at most ${max}`

export const ruleRequired = value => {
  return value === 0 || !!value || 'Required field'
}
