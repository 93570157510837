<template>
  <l-feature-group :visible="visible" @ready="ready">
    <l-circle
      v-if="['rf_zero', 'rf_patrol'].includes(type)"
      :visible="visible"
      :radius="sensor.reach"
      :color="color"
      :weight="weight"
      :fill-color="fillColor"
      :opacity="1"
      :fill-opacity="0.1"
      :lat-lng="latlng"
      :ref="`${refKey}-circle`"
      :className="draggable ? 'wizardSector' : ''"
    />
    <l-polygon
      v-else
      :visible="visible"
      :color="color"
      :weight="weight"
      :fill-color="fillColor"
      :opacity="1"
      :fill-opacity="0.1"
      :lat-lngs="range"
      :ref="refKey"
      :className="draggable ? 'draggableSector' : ''"
      @drag="draggable && !cancelDragHandler && $_dragSector($event)"
      @dragend="draggable && !cancelDragHandler && $_resetDragAngle($event)"
      @click="draggable && !cancelDragHandler && $_shuffleSector($event)"
    />
    <template v-if="editMode">
      <circle-fog-of-war
        v-if="['rf_zero', 'rf_patrol'].includes(type)"
        :radius="sensor.reach"
        fill-color="black"
        :opacity="0"
        :fill-opacity="0"
        :lat-lng="latlng"
        mask="rfSector"
      />
      <polygon-fog-of-war
        v-else
        fill-color="black"
        :opacity="0"
        :fill-opacity="0"
        :lat-lngs="range"
        mask="rfSector"
      />
      <polygon-fog-of-war
        :fill-opacity="0"
        :mask-opacity="0.7"
        fill-color="black"
        :opacity="0"
        mask-id="rfSector"
      />
    </template>
  </l-feature-group>
</template>

<script>
import utils from '../utils'
import { SensorMixin } from '@/components/Mixins'

import { LPolygon, LCircle, LFeatureGroup } from 'vue2-leaflet'
import { PolygonFogOfWar, CircleFogOfWar } from 'vue2-leaflet-fogofwar'

const props = {
  active: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: true
  },
  editMode: {
    type: Boolean,
    default: false
  },
  sensor: {
    type: Object,
    default: () => ({
      reach: 0,
      direction: 0,
      angle: 90,
      latitude: 0,
      longitude: 0
    })
  },
  draggable: {
    type: Boolean,
    default: false
  },
  cancelDragHandler: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'RfSectorCoverage',
  props,
  components: {
    LCircle,
    LPolygon,
    LFeatureGroup,
    PolygonFogOfWar,
    CircleFogOfWar
  },
  mixins: [SensorMixin],
  computed: {
    weight() {
      return this.active ? 3 : 1
    },
    fillColor() {
      return this.active ? 'orange' : '#0080F7'
      // return "white";
    },
    color() {
      return this.active ? 'orange' : '#0080F7'
    },
    range() {
      const { reach, direction = 0, angle = 90 } = this.sensor || {}
      return reach
        ? utils.createRange({
            reach,
            direction,
            angle,
            vertex: [this.sensor.latitude, this.sensor.longitude]
          })
        : []
    },
    type() {
      return this.sensor.model || 'rf_one'
    },
    latlng() {
      return this.sensor && [this.sensor.latitude, this.sensor.longitude]
    },
    refKey() {
      return `rf-sector-${this.sensor.id}`
    }
  },
  watch: {
    active(isActive) {
      isActive && utils.bringSVGToTop(this.$refs[this.refKey])
    }
  },
  mounted() {
    this.$bus.$on('bringRfToTop', () => {
      if (this.$refs[this.refKey]) utils.bringSVGToTop(this.$refs[this.refKey])
    })
  },
  beforeDestroy() {
    this.$bus.$off('bringRfToTop')
  },
  methods: {
    ready(obj) {
      obj.bringToBack()
    }
  }
}
</script>
