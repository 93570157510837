
import Vue, { VNode, CreateElement } from 'vue'
import { mapGetters } from 'vuex'
import { DetectionsMixin } from '@/components/Mixins/index.js'

interface IDetectionData {
  id: number
  radar_detection_id: number
  radar_id: number
  rf_detection_id: number
  rf_sensor_id: number
  rf_target_id: number
  time: string
}

interface IMessage {
  action: string
  data: IDetectionData[]
  space: string
}

interface ISocketData {
  identifier: string
  message: IMessage
}

export default Vue.extend({
  name: 'RadarDetector',
  mixins: [DetectionsMixin],
  render(h: CreateElement): VNode {
    return h('div', {})
  },
  computed: {
    ...mapGetters('sensors', ['radarsSet'])
  },
  mounted() {
    this.$bus.$on('SOCKET/RADAR_DETECTIONS_CREATE', (data: ISocketData) => {
      const message: IMessage = data.message
      message.data.forEach((d: IDetectionData) => {
        const { max_track_age, track_tail_length } =
          this.radarsSet[d.radar_id] || {}
        this.$_updateRadarDetections({
          ...d,
          level: 0,
          max_track_age,
          track_tail_length
        })
      })
      this.$emit('update', this.radarsDetections)
    })
    this.$bus.$on('SOCKET/RADAR_RF_DETECTIONS_CREATE', (data: ISocketData) => {
      const message: IMessage = data.message
      message.data.forEach((d: IDetectionData) =>
        this.$_updateRadarRfDetections(d)
      )
    })
  },
  beforeDestroy() {
    this.$_clearAllDetections()
    this.$bus.$off('SOCKET/RADAR_DETECTIONS_CREATE')
    this.$bus.$off('SOCKET/RADAR_RF_DETECTIONS_CREATE')
    this.$bus.$off('DOM/RADAR_DETECTION_DELETE')
  }
})
