export default {
  LOAD_USER: (state, user) => (state.user = user),
  LOAD_CLIENT: (state, client) => {
    if (Array.isArray(client)) {
      state.clientsList = client
    } else if (client) {
      state.client = client
    }
  },
  loadUsersList: (state, users) => (state.usersList = [...users]),
  LOAD_SITES_ASSOCIATIONS: (state, sitesAssociations) =>
    (state.sitesAssociations = sitesAssociations),
  LOAD_RECIPIENTS: (state, recipients) => (state.recipientsSet = recipients),
  REMOVE_RECIPIENT: (state, id) => delete state.recipientsSet[id],
  SET_STATUS: (state, status) => (state.status = status),
  SET_ERROR: (state, error) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  ADD_USER: (state, user) => state.usersList.push(user),
  UPDATE_CLIENT: (state, client) => {
    const clients = state.clientsList
    const index = clients.findIndex(c => c.id === client.id)
    if (index) {
      clients[index] = client
    } else {
      clients.push(client)
    }
    state.clientsList = clients
  }
}
