


























import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
// @ts-ignore
import { Howl } from 'howler'
import AlertCard from '@/components/Notifications/AlertCard.vue'
import { IDetection } from '@/store/modules/detection/types'

interface IEventTrack {
  id: string
  lat: number
  lng: number
  type: string
}

interface ICoordinate {
  0: number
  1: number
}

interface IEventZone {
  id: number
  coordinate_list: ICoordinate[]
  coordinates: string
  name: string
  note: string
  site_id: number
  zone_type: string
}

interface IEventAlert {
  track: IEventTrack
  zone: IEventZone
  detection?: IDetection
  key?: string
}

export default Vue.extend({
  name: 'AlertsPanel',
  components: { AlertCard },
  data() {
    return {
      shownAlert: null,
      checkedEvents: [],
      actionHovered: false,
      audio: new Howl({
        src: require(`@/assets/sounds/alarm1.mp3`),
        loop: true
      }),
      eventCorrelations: {}
    }
  },
  mounted() {
    this.$emitter.on('displayDeferredAlert', this.displayDeferredAlert)
  },
  beforeDestroy() {
    this.$emitter.off('displayDeferredAlert', this.displayDeferredAlert)
  },
  computed: {
    ...mapState('sensors', {
      events: 'newEvents',
      status: 'status'
    }),
    ...mapState('zones', ['visibleZoneType']),
    ...mapState('users', ['user']),
    ...mapGetters('sites', {
      sitesSet: 'sitesSet',
      site: 'siteCenter',
      activeSite: 'activeSite',
      activeSiteId: 'activeSiteId'
    }),
    ...mapState('notifications', ['detections', 'alerts', 'deferredAlert']),
    panelHeader(): string {
      return `Alerts (${this.eventAlerts.length})`
    },
    headerIconColor(): string {
      return this.eventAlerts.length
        ? 'primary'
        : this.actionHovered
        ? 'grey'
        : 'grey darken-3'
    },
    eventAlerts(): IEventAlert[] {
      return this.alerts
        .filter(e => this.eventCorrelations[e.id] !== 'no_key')
        .sort((a, b) => {
          let a_timestamp, b_timestamp
          if (a.created_at) {
            a_timestamp = a.created_at
          } else {
            a_timestamp = a.track.updated_at
          }

          if (b.created_at) {
            b_timestamp = b.created_at
          } else {
            b_timestamp = b.track.updated_at
          }

          if (a_timestamp > b_timestamp) return -1
          if (a_timestamp < b_timestamp) return 1
          return 0
        })
    },
    hasEvents(): boolean {
      return Array.isArray(this.eventAlerts) && this.eventAlerts.length > 0
    }
  },
  methods: {
    ...mapActions('sensors', ['selectDetection']),
    ...mapActions('detection', { fusionSelectDetection: 'selectDetection' }),
    ...mapActions('sites', {
      activateSite: 'ACTIVATE_SITE'
    }),
    ...mapActions('notifications', {
      removeAlert: 'removeAlert',
      setDeferredAlert: 'setDeferredAlert'
    }),
    displayDeferredAlert(): void {
      const alert = this.alerts.find(a => a.key === this.deferredAlert)
      this.acknowledge(alert)
      this.setDeferredAlert(null)
    },
    eventID(event: IEventAlert): string {
      return event.key
    },
    zoneID(event: IEventAlert): number {
      return event.zone.id
    },
    eventSiteID(event: IEventAlert): number  {
      if (!event) {
        return null
      }
      return event.zone.site_id
    },
    zoneBounds(coordinates: ICoordinate[]): ICoordinate[] {
      const bounds = coordinates.reduce(
        (acc, val) => {
          acc.lat.min = Math.min(acc.lat.min, val[0])
          acc.lat.max = Math.max(acc.lat.max, val[0])
          acc.lon.min = Math.min(acc.lon.min, val[1])
          acc.lon.max = Math.max(acc.lon.max, val[1])
          return acc
        },
        { lat: { min: 200, max: -200 }, lon: { min: 200, max: -200 } }
      )
      return [
        [bounds.lat.min, bounds.lon.min],
        [bounds.lat.max, bounds.lon.max]
      ]
    },
    acknowledge(e: IEventAlert) {
      const key = e.key
      const eventSiteId: number = this.eventSiteID(e)
      if (eventSiteId === this.activeSite.id) {
        // switch to main view
        this.$router.replace('/')
        this.enableAlertZonesDisplay()
        this.$nextTick(async () => {
          const coords: ICoordinate[] = [...e.zone.coordinate_list]
          const bounds: ICoordinate[] = this.zoneBounds(coords)
          this.activateSite(eventSiteId)
          this.$nextTick(() => {
            // TODO: remove this once we fully migrate to sensor fusion
            if (e?.track?.type === 'fusion') {
              this.fusionSelectDetection(key)
            } else {
              this.selectDetection(key)
            }
            setTimeout(() => {
              this.$bus.$emit('mapFitBounds', 'homeMap', bounds, 50)
              setTimeout(() => {
                this.$bus.$emit('highlightZone', this.zoneID(e))
              }, 500)
            }, 500)
          })
        })
      } else {
        this.setDeferredAlert(key)
        this.$router.replace({
          name: 'DataLoader',
          params: { id: eventSiteId }
        })
      }
    },
    enableAlertZonesDisplay(): void {
      if (!this.visibleZoneType.includes('alert')) {
        this.$store.commit('zones/VISIBLE_ZONE_TYPES', [
          ...this.visibleZoneType,
          'alert'
        ])
      }
    }
  },
  watch: {
    hasEvents(v: boolean): void {
      if (v) {
        if (this.user.play_alarm_sounds && !this.audio.playing()) {
          this.audio.play()
        }
      } else {
        this.audio.stop()
      }
    }
  }
})
