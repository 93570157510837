import {
  ICots,
  ICotsList,
  ICotsState
} from '@/store/modules/cursor_on_target/types'

export default {
  SET_STATUS: (state: ICotsState, status: string) => (state.status = status),
  SET_ERROR: (state: ICotsState, error: string) => (state.error = error),
  LOAD_CURSOR_ON_TARGET_SETTINGS: (
    state: ICotsState,
    cursor_on_target_settings: ICotsList
  ) => {
    state.settings = cursor_on_target_settings
  }
}
