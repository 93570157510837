<template>
  <v-layout row >
    <v-card flat class="fusion-detection-popup">
      <v-card-text>

        <div class="caption grey--text text-uppercase">
          ELEVATION
        </div>
        <div class="caption">
          {{ elevation }}
        </div>
        
        <div class="caption grey--text text-uppercase">
          RCS
        </div>
        <div class="caption">
          {{ rcs === 0 ? 0 : rcs.toFixed(2) }}
        </div>

        <div class="caption grey--text text-uppercase">
          GROUP ID
        </div>
        <div class="caption">
          {{ groupId }}
        </div>

      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  name: 'FusionDetectionPopup',
  props: {
    elevation: {
      type: [Number],
      default: 0
    },
    rcs: {
      type: [Number],
      default: 0
    },
    groupId: {
      type: [String],
      default: ''
    }
  }
}
</script>
<style>
.fusion-detection-popup {
  min-width: 8rem;
}
</style>
