























































































































































































































































import Vue from 'vue'
import { IRfFilter } from '@/store/modules/rf_filters/types'
import { mapGetters, mapState } from 'vuex'
import { capitalize, isNullOrUndefined } from '@/utils/utils'
import {
  EItemTypes,
  ESliderTypes,
  FREQUENCY_DECIMALS,
  FREQUENCY_LIMITS,
  IDsxPreviewObject,
  ILimitItem,
  ISupportedDetection,
  ISupportedDetectionProtocol,
  IVendorProtocolListItem
} from '@/components/Dialogs/Filters/types'
// @ts-ignore
import { frequency } from 'units-converter'
import SliderComponent, {
  ISliderItem,
  ISliderUpdateData
} from '@/components/Dialogs/Filters/OverviewComponents/SliderComponent.vue'
import {
  ruleMaxValue,
  ruleMinValue,
  ruleRequired,
  ruleMinValueButNotZero
} from '@/utils/rules'
import WedgeBlock from '@/components/Dialogs/Filters/OverviewComponents/WedgeBlock.vue'
import AnglePreviewBlock from '@/components/Dialogs/Filters/OverviewComponents/AnglePreviewBlock.vue'
import SensorAlert from "@/components/Base/SensorAlert.vue";

interface IDropdownItem {
  value: any
  label: string
}

const props = {
  filter: {
    type: Object as () => IRfFilter,
    default: null
  },
  type: {
    type: String as () => EItemTypes,
    default: EItemTypes.FILTER
  },
  allowReadWrite: {
    type: Boolean,
    default: false
  }
}

export interface IValueToleranceData {
  value: number
  tolerance: number
  visible?: boolean
}

const ANGLE_MAX = 70

export default Vue.extend({
  name: 'AdvancedFilterForm',
  components: { SensorAlert, AnglePreviewBlock, WedgeBlock, SliderComponent },
  props,
  inject: ['providedData'],
  data() {
    return {
      sectorData: {
        aoa: null,
        aoa_error: null,
        bearing: null,
        bearing_tolerance: null
      },
      selectedSensor: null,
      formValid: false,
      bearingSliderType: ESliderTypes.BEARING,
      bearingLimits: {
        min: -ANGLE_MAX,
        max: ANGLE_MAX
      },
      filterBand: null,
      filterClone: null,
      frequencyUnit: null,
      frequencyValue: null,
      frequencyToleranceValue: null,
      vendorValue: null,
      protocolValue: null,
      selectedFilterBand: '2400MHz',
      showFrequencyFilter: false,
      showBearingFilter: false,
      showVendorProtocolFilter: false,
      frequencyLimits: {
        generic: {
          min: 0,
          max: 0
        },
        specific: {
          min: 0,
          max: 0
        }
      }
    }
  },
  created() {
    if (this.filter) {
      const clone = this.createFilterClone(this.filter)
      this.filterClone = clone
      // populate fields with filter data

      if (this.filter.vendor) {
        this.vendorValue = clone.vendor
      }

      if (this.filter.protocol) {
        this.protocolValue = clone.protocol
      }

      if (this.filter.frequency) {
        // set selected band
        this.filterBand = `${this.findBandFromFilter(clone)}`
        this.selectedFilterBand = `${this.findBandFromFilter(clone)}MHz`
        this.frequencyUnit = this.getFrequencyUnit()

        // get frequency limits
        this.getFrequencyLimits()
      }

      if (this.filter?.frequency) {
        this.showFrequencyFilterModel = true
      }
      if (this.filter?.bearing !== null) {
        this.showBearingFilterModel = true
        this.emitData(
          {
            value: clone.bearing,
            tolerance: clone.bearing_tolerance,
            visible: true
          },
          'bearing'
        )
      } else {
        // preset default bearing value if not DSX
        if (!this.isDsx) {
          clone.bearing = 0
          clone.bearing_tolerance = 10
        }
        this.emitData(
          {
            value: clone.bearing,
            tolerance: clone.bearing_tolerance,
            visible: false
          },
          'bearing'
        )
      }

      if (this.filter?.vendor || this.filter?.protocol) {
        this.showVendorProtocolFilter = true
      }
      this.filterClone = clone
    }
  },
  mounted() {
    this.$refs.filterForm.validate()
  },
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sentries', ['sentriesInSite']),
    showBearingFilterModel: {
      get(): boolean {
        return this.showBearingFilter
      },
      set(val): void {
        if ([null, undefined].includes(this.bearingValueModel)) {
          this.bearingValueModel = 0
          this.bearingToleranceValueModel = this.isDsx ? 22.5 : 10
        }

        this.emitData(
          {
            value: this.bearingValueModel,
            tolerance: this.bearingToleranceValueModel,
            visible: val
          },
          'bearing'
        )
        this.showBearingFilter = val
      }
    },
    showFrequencyFilterModel: {
      get(): boolean {
        return this.showFrequencyFilter
      },
      set(val): void {
        if (!this.frequencyValueModel) {
          this.frequencyUnit = this.getFrequencyUnit()
          this.filterBand = /[0-9]+/.exec(this.selectedFilterBand)[0]
          this.getFrequencyLimits()
          this.filterClone.frequency = this.getCenterFrequencyForBand(
            this.selectedFilterBand
          )

          this.filterClone.frequency_tolerance = this.getFrequencyToleranceForBand(
            this.selectedFilterBand
          )
          this.vendorValue = this.vendorList[0].vendor
          this.protocolValue = this.protocolList[0].protocol
        }
        this.showFrequencyFilter = val
      }
    },
    frequencyBandModel: {
      get(): string {
        return this.selectedFilterBand
      },
      set(val): void {
        this.selectedFilterBand = val
        this.frequencyUnit = this.getFrequencyUnit()
        this.filterBand = /[0-9]+/.exec(this.selectedFilterBand)[0]
        this.getFrequencyLimits()
        this.filterClone.frequency = null
        this.filterClone.frequency_tolerance = null
        this.frequencyValueModel = frequency(
          this.getCenterFrequencyForBand(val)
        )
          .from('Hz')
          .to(this.frequencyUnit).value
        this.frequencyToleranceValueModel = frequency(
          this.getFrequencyToleranceForBand(val)
        )
          .from('Hz')
          .to(this.frequencyUnit).value
        this.vendorValue = this.vendorList[0].vendor
        this.protocolValue = this.protocolList[0].protocol
      }
    },
    maxFrequencyTolerance(): number {
      return parseFloat(
        (
          (this.frequencyLimits.specific.max - this.frequencyValueModel) *
          1.1
        ).toFixed(FREQUENCY_DECIMALS)
      )
    },
    frequencyValueModel: {
      get(): number {
        if (!this.filterClone?.frequency) return null
        return parseFloat(
          this.convertFrequencyToBestUnit(this.filterClone.frequency).toFixed(
            FREQUENCY_DECIMALS
          )
        )
      },
      set(val: number): void {
        if (
          this.frequencyToleranceValueModel + val >
          this.frequencyLimits.specific.max
        )
          return
        if (
          this.frequencyToleranceValueModel + val <
          this.frequencyLimits.specific.min
        )
          return
        if (this.filterClone) {
          this.filterClone.frequency = this.convertFrequencyToHz(val)
        }
      }
    },
    frequencyToleranceValueModel: {
      get(): number {
        if (!this.filterClone?.frequency_tolerance) return null
        return parseFloat(
          this.convertFrequencyToBestUnit(
            this.filterClone.frequency_tolerance
          ).toFixed(FREQUENCY_DECIMALS)
        )
      },
      set(val: number): void {
        if (val <= 0) return
        if (this.frequencyValueModel + val > this.frequencyLimits.specific.max)
          return
        if (this.frequencyValueModel + val < this.frequencyLimits.specific.min)
          return
        if (this.filterClone) {
          this.filterClone.frequency_tolerance = this.convertFrequencyToHz(val)
        }
      }
    },
    bearingValueModel: {
      get(): number {
        if (isNullOrUndefined(this.filterClone?.bearing)) return null
        return this.filterClone.bearing
      },
      set(val: number): void {
        if ([null, undefined].includes(this.filterClone?.bearing)) return
        if (
          Math.abs(val) + Math.abs(this.filterClone.bearing_tolerance) <=
          ANGLE_MAX
        ) {
          this.filterClone.bearing = val
          this.emitData(
            {
              value: this.filterClone.bearing,
              tolerance: this.filterClone.bearing_tolerance
            },
            'bearing'
          )
        }
      }
    },
    bearingToleranceValueModel: {
      get(): number {
        if (isNullOrUndefined(this.filterClone?.bearing_tolerance)) return null
        return this.filterClone.bearing_tolerance
      },
      set(val: number): void {
        if ([null, undefined].includes(this.filterClone?.bearing_tolerance))
          return
        if (Math.abs(val) + Math.abs(this.filterClone.bearing) <= ANGLE_MAX) {
          this.filterClone.bearing_tolerance = val
          this.emitData(
            {
              value: this.filterClone.bearing,
              tolerance: this.filterClone.bearing_tolerance
            },
            'bearing'
          )
        }
      }
    },
    hasFrequencyTolerance(): boolean {
      return !!this.filterClone?.frequency_tolerance
    },
    hasAngleTolerance(): boolean {
      return !!this.filterClone?.bearing_tolerance
    },
    negativeButtonProps() {
      return {
        color: 'primary',
        flat: this.allowReadWrite,
        outline: !this.allowReadWrite
      }
    },
    selectedSensorModel: {
      get() {
        return this.selectedSensor
      },
      set(val) {
        this.selectedSensor = val
        this.$emitter.emit('updated:selectedSensor', val)
      }
    },
    sensor(): unknown {
      return this.providedData.sensor
    },
    itemType(): EItemTypes {
      return this.providedData.itemType
    },
    supportedDetections(): ISupportedDetection[] {
      const supportedDetections = { ...this.providedData.supportedDetections }

      delete supportedDetections['Multidrone']

      return supportedDetections
    },
    isNewFilter(): boolean {
      return this.filter.id === null
    },
    isDsx(): boolean {
      return this.currentSensor.model.toLowerCase().includes('dsx')
    },
    disableSaveButton(): boolean {
      return !this.formValid || !this.hasFilterSelected
    },
    hasFilterSelected(): boolean {
      return (
        this.showFrequencyFilterModel ||
        this.showBearingFilterModel ||
        this.showVendorProtocolFilter
      )
    },
    vendorProtocolList(): IVendorProtocolListItem[] {
      let items = new Set([])
      Object.values(this.supportedDetections).forEach(
        (item: ISupportedDetection) => {
          item.protocols.forEach((protocol: ISupportedDetectionProtocol) => {
            items.add(
              `${protocol.vendor}_${protocol.protocol}_${item.rfai_sub_band}`
            )
          })
        }
      )

      const itemArray = Array.from(items).map(item => {
        const data = item.split('_')
        return {
          vendor: data[0],
          protocol: data[1],
          band: data[2]
        }
      })

      if (this.showFrequencyFilterModel) {
        return itemArray.filter(protocol => {
          return protocol.band === this.selectedFilterBand
        })
      } else {
        return itemArray
      }
    },
    vendorList(): IVendorProtocolListItem[] {
      return (
        [...this.vendorProtocolList].sort((a, b) => {
          if (a.vendor > b.vendor) return 1
          if (a.vendor < b.vendor) return -1
          return 0
        }) || []
      )
    },
    protocolList(): IVendorProtocolListItem[] {
      const protocols = [...this.vendorProtocolList].filter(item => {
        return item.vendor === this.vendorValue
      })
      return [
        ...(protocols.sort((a, b) => {
          if (a.protocol > b.protocol) return 1
          if (a.protocol < b.protocol) return -1
          return 0
        }) || [])
      ]
    },
    defaultFilterData(): IRfFilter {
      return {
        frequency: null,
        frequency_tolerance: null,
        bearing: null,
        bearing_tolerance: null,
        name: null,
        active: false,
        id: null,
        protocol: null,
        vendor: null,
        whitelist: this.type === EItemTypes.WHITELIST,
        rf_sensor_ids: [this.currentSensor.id]
      }
    },
    bearingLimit(): number {
      return ANGLE_MAX - parseFloat(this.bearingToleranceValueModel)
    },
    bearingToleranceLimit(): number {
      return ANGLE_MAX - Math.abs(this.bearingValueModel)
    },
    dsxSectorPreviewData(): ISliderItem {
      return {
        id: this.filter.id,
        value: this.bearingValueModel,
        tolerance: this.bearingToleranceValueModel
      }
    },
    bearingPreviewData(): ISliderItem {
      return {
        id: this.filterClone?.id,
        value: this.bearingValueModel,
        tolerance: this.bearingToleranceValueModel
      }
    },
    bearingFilterAvailable(): boolean {
      if (this.providedData.itemType === 'whitelist') { // Hide bearing for whitelist
        return false
      } else {
        return !['rf_zero', 'rf_patrol'].includes(this.currentSensor.model)
      }
    },
    filterName: {
      get(): string {
        return this.filterClone?.name || null
      },
      set(val): void {
        this.filterClone.name = val
      }
    },
    currentSensor(): unknown {
      return this.sensor || null
    },
    currentSensorName(): string {
      return this.currentSensor?.name || null
    },
    allRfSensorsInSite(): unknown[] {
      return { ...this.rfSensors, ...this.dsxSensors }
    },
    allRfSensorInSiteIds(): number[] {
      return Object.keys(this.allRfSensorsInSite).map(key => parseInt(key))
    },
    otherRfSensorIds(): number[] {
      return this.allRfSensorInSiteIds.filter(
        id => id !== this.currentSensor.id
      )
    },
    otherSensors(): Record<string, unknown> {
      const otherSensors = {}
      this.otherRfSensorIds.forEach((id: number) => {
        otherSensors[id] = this.allRfSensorsInSite[id]
      })
      return otherSensors
    },
    otherSensorsArray(): unknown[] {
      return Object.values(this.otherSensors).filter(
        //@ts-ignore
        sensor => sensor.model === this.currentSensor.model
      )
    },
    frequencyBandsList(): IDropdownItem[] {
      return Object.values(this.supportedDetections)
        .sort((a: ISupportedDetection, b: ISupportedDetection) => {
          const val1 = parseInt(a.rfai_sub_band.replace('MHz', ''))
          const val2 = parseInt(b.rfai_sub_band.replace('MHz', ''))
          return val1 - val2
        })
        .map((band: ISupportedDetection) => {
          return {
            label: this.getBandText(band.rfai_sub_band),
            value: band.rfai_sub_band
          }
        })
    }
  },
  methods: {
    ruleMinValueButNotZero,
    capitalize,
    ruleMinValue,
    ruleMaxValue,
    ruleRequired,
    getCenterFrequencyForBand(band: string): number {
      const frequencyValue = parseInt(band.replace('MHz', ''))
      const { min, max } = FREQUENCY_LIMITS[frequencyValue]
      return min + (max - min) / 2
    },
    getFrequencyToleranceForBand(band: string): number {
      const frequencyValue = parseInt(band.replace('MHz', ''))
      const { min, max } = FREQUENCY_LIMITS[frequencyValue]
      return (max - min) * 0.2
    },
    getFrequencyUnit(): string {
      let unit = /[A-Za-z]+$/.exec(this.selectedFilterBand)
      let freq = this.selectedFilterBand.replace(unit[0], '')
      let f = frequency(freq)
        .from(unit)
        .toBest()
      return f.unit
    },
    findBandFromFilter(filter: IRfFilter): string {
      return Object.keys(FREQUENCY_LIMITS).find(key => {
        const limits: ILimitItem = FREQUENCY_LIMITS[key]
        return filter.frequency >= limits.min && filter.frequency <= limits.max
      })
    },
    getBandText(band): string {
      let unit = /[A-Za-z]+$/.exec(band)
      let freq = band.replace(unit, '')

      let f = frequency(freq)
        .from(unit)
        .toBest()

      return `${f.value} ${f.unit}`
    },
    convertFrequencyToBestUnit(freq: number): number {
      return frequency(freq)
        .from('Hz')
        .to(this.frequencyUnit).value
    },
    convertFrequencyToHz(freq: number): number {
      return frequency(freq)
        .from(this.frequencyUnit)
        .to('Hz').value
    },
    getFrequencyLimits(): void {
      const generic = {
        min: FREQUENCY_LIMITS[this.filterBand].min,
        max: FREQUENCY_LIMITS[this.filterBand].max
      }
      const specific = {
        min: frequency(FREQUENCY_LIMITS[this.filterBand].min)
          .from('Hz')
          .to(this.frequencyUnit).value,
        max: frequency(FREQUENCY_LIMITS[this.filterBand].max)
          .from('Hz')
          .to(this.frequencyUnit).value
      }
      this.frequencyLimits = {
        generic,
        specific
      }
    },
    onFrequencyChange(payload: ISliderUpdateData) {
      const { diff, max } = payload
      this.frequencyValueModel = max - diff
      this.frequencyToleranceValueModel = diff
    },
    onBearingChange(payload: ISliderUpdateData) {
      const { diff, max } = payload
      this.updateBearingData(
        parseFloat((max - diff).toFixed(3)),
        parseFloat(diff.toFixed(3))
      )
    },
    emitData(data: IValueToleranceData, type: string) {
      this.$emit(`update:${type}`, data)
    },
    onVendorChange(): void {
      this.protocolValue = this.protocolList[0]
    },
    updateBearingData(bearing: number, tolerance: number): void {
      this.bearingValueModel = bearing
      this.bearingToleranceValueModel = tolerance
      this.emitData(
        {
          value: this.bearingValueModel,
          tolerance: this.bearingToleranceValueModel,
          visible: this.showBearingFilterModel
        },
        'bearing'
      )
    },
    createFilterClone(filter: IRfFilter): IRfFilter {
      const filterClone = { ...filter }
      const wanted_keys = [
        'frequency',
        'frequency_tolerance',
        'bearing',
        'bearing_tolerance',
        'rf_sensor_ids',
        'whitelist'
      ]

      wanted_keys.forEach(key => {
        if (filterClone[key] === null) {
          filterClone[key] = this.defaultFilterData[key]
        }
      })

      return filterClone
    },
    onCancel(): void {
      this.$emit('onCancel')
    },
    onVendorProtocolShowChange(): void {
      this.vendorValue = this.vendorList[0]?.vendor || null
      this.protocolValue = this.protocolList[0]?.protocol || null
    },
    onDsxSectorUpdate(data: IDsxPreviewObject): void {
      this.filterClone = {
        ...this.filterClone,
        bearing: data.bearing,
        bearing_tolerance: data.bearing_tolerance
      }
      this.$emit('update:sectors', this.filterClone)
    },
    isInstanceOfVendorProtocolListItem(
      item: any
    ): item is IVendorProtocolListItem {
      if (typeof item !== 'object') return false
      return 'protocol' in item
    },
    getProtocolValue(value: any | IVendorProtocolListItem): string {
      if (this.isInstanceOfVendorProtocolListItem(value)) {
        return value.protocol
      }
      return value
    },
    getVendorValue(value: any | IVendorProtocolListItem): string {
      if (this.isInstanceOfVendorProtocolListItem(value)) {
        return value.vendor
      }
      return value
    },
    doSave(): void {
      // prepare data
      const frequencyHz = this.showFrequencyFilterModel
        ? this.convertFrequencyToHz(this.frequencyValueModel)
        : null
      const frequencyToleranceHz = this.showFrequencyFilterModel
        ? this.convertFrequencyToHz(this.frequencyToleranceValueModel)
        : null

      const bearing = this.showBearingFilterModel
        ? this.bearingValueModel
        : null
      const bearing_tolerance = this.showBearingFilterModel
        ? this.bearingToleranceValueModel
        : null

      const filter = Object.assign(this.filterClone, {
        bearing,
        bearing_tolerance,
        vendor: this.showVendorProtocolFilter
          ? this.getVendorValue(this.vendorValue)
          : null,
        protocol: this.showVendorProtocolFilter
          ? this.getProtocolValue(this.protocolValue)
          : null,
        frequency: this.showFrequencyFilterModel ? frequencyHz : null,
        frequency_tolerance: this.showFrequencyFilterModel
          ? frequencyToleranceHz
          : null,
        frequency_band: this.showFrequencyFilterModel
          ? this.selectedFilterBand
          : null,
        active: this.isNewFilter ? true : this.filterClone.active,
        rf_sensor_ids: [this.sensor.id]
      })
      this.$emit('onSave', filter)
    }
  },
  watch: {
    selectedSensors: {
      handler: function(val) {
        if (val) {
          this.emitData(val, 'selectedSensors')
        }
      },
      immediate: false
    },
    filter: {
      handler: function(val) {
        const clone = this.createFilterClone(val)
        this.$set(this, 'filterClone', clone)
      }
    }
  }
})
