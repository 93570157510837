export default {
  status: state => state.status,
  error: state => state.error,
  report: state => state.report,
  reportLink: state => state.reportLink,
  startDate: state => state.timeframe.start,
  endDate: state => state.timeframe.end,
  timeFrameLastUpdated: state => state.timeframe.lastUpdated,
  timeframe: (state, getters) =>
    Math.abs(getters.startDate.diff(getters.endDate, 'days')),
  range: state => ({
    start_time: state.timeframe.start.utc().format('YYYY-MM-DD HH:mm'),
    end_time: state.timeframe.end.utc().format('YYYY-MM-DD HH:mm')
  }),
  rfShowFiltered: state => state.showRFFiltered,
  rfShowWhitelisted: state => state.showRFWhitelisted,
  radarRfShowWhitelisted: state => state.showRadarRFWhitelisted,
  showFalsePositives: state => state.showFalsePositives
}
