<template>
  <l-feature-group>
    <l-polygon
      v-for="(range, i) in ranges"
      :key="`radar-range-${i}`"
      :visible="visible"
      :color="'orange'"
      :weight="0.1"
      :fill-color="'orange'"
      :opacity="1"
      :fill-opacity="fillOpacity"
      :lat-lngs="range"
    ></l-polygon>
  </l-feature-group>
</template>

<script>
import utils from '../utils'
import { LPolygon, LFeatureGroup } from 'vue2-leaflet'

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  sensors: {
    type: Object,
    default: () => ({})
  },
  sentries: {
    type: Array,
    default: () => []
  }
}

export default {
  name: 'RadarRfCoverage',
  props,
  data: () => ({
    // color: "#FF9800",
    fillOpacity: 0.2,
    prev: 0
  }),
  components: {
    LPolygon,
    LFeatureGroup
  },
  computed: {
    weight() {
      return this.active ? 3 : 1
    },
    fillColor() {
      return this.active ? 'orange' : 'white'
      // return "white";
    },
    color() {
      return this.active ? 'orange' : 'white'
    },
    sentriesId() {
      return this.sentries.map(s => s.id)
    },
    ranges() {
      return Object.values(this.sensors)
        .filter(s => this.sentriesId.includes(s.sentry_id))
        .map(s => {
          const {
            reach = 850,
            direction = 0,
            angle = 90,
            latitude,
            longitude
          } = s || {}
          return reach
            ? utils.createRange({
                reach,
                direction,
                angle,
                vertex: [latitude, longitude]
              })
            : []
        })
    }
  },
  timers: {
    glow: { time: 10, autostart: false, repeat: true }
  },
  methods: {
    glow() {
      if (
        this.fillOpacity <= 0 ||
        (this.prev - this.fillOpacity < 0 && this.fillOpacity < 0.5)
      ) {
        this.prev = this.fillOpacity
        this.fillOpacity += 0.01
      } else {
        this.prev = this.fillOpacity
        this.fillOpacity = this.fillOpacity - 0.01
      }
    }
  },
  watch: {
    visible(value) {
      if (value) {
        this.$timer.start('glow')
      } else {
        this.$timer.stop('glow')
        this.fillOpacity = 0.3
        this.prev = 0
      }
    }
  }
}
</script>
