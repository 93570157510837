import { ELogo, IState } from '@/store/modules/system/types'

const assetsPathMapping = (logo: string) => {
  return logo !== ELogo.NONE ? `customers/${logo}/` : ''
}

export default {
  hasDataLoaded: (state: IState) => state.dataLoaded,
  isSocketConnected: (state: IState) => state.dataWebsocket?.connected || false,
  isAlertsSocketConnected: (state: IState) =>
    state.alertsWebsocket?.connected || false,
  assetsPath: (state: IState) => assetsPathMapping(state.logo),
  systemSetting: (state: IState) => state.systemSetting,
  simulation_enabled: (state: IState) => state.simulation_enabled,
  useBranding: (state: IState) => state.logo !== ELogo.NONE,
  displayUnits: (state: IState) => state.systemSetting.display_unit
}
