import {
  IStats,
  ISocket,
  IState,
  ISystemSetting,
  ISystemInfo,
  SystemSettingResponse
} from './types'
import socketManager from '@/plugins/websocket'

export default {
  LOAD_STATS: (state: IState, stats: IStats) => {
    state.stats = stats
  },
  LOAD_SYS_INFO: (state: IState, data: ISystemInfo) => {
    state.version = data.version
    state.build = data.environment
    state.user_invite_allowed = data.user_invite_allowed
    state.simulation_enabled = data.simulation_enabled
    state.environment = data.environment
    state.offline = state.environment === 'prem'
    state.logo = data.logo
  },
  SET_LOGO: (state: IState, data: SystemSettingResponse) => {
    state.logo = data.system_setting.logo
  },
  SET_STATUS: (state: IState, status: string) => (state.status = status),
  SET_ERROR: (state: IState, error: Error | Array<string> | string) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  UPDATE_SOCKET: (state: IState, socket: ISocket) =>
    Object.assign(state.socket, socket),
  UPDATE_ALERTS_SOCKET: (state: IState, socket: ISocket) =>
    Object.assign(state.alertsSocket, socket),
  SET_SYSTEM_SETTING: (state: IState, systemSetting: ISystemSetting) => {
    state.systemSetting = systemSetting
    state.logo = systemSetting.logo
  },
  SET_DATA_LOADED_STATE: (state: IState, status: boolean) =>
    (state.dataLoaded = status),
  SET_DATA_WEBSOCKET: (state: IState, socket: socketManager) => {
    state.dataWebsocket = socket
  },
  SET_ALERTS_WEBSOCKET: (state: IState, socket: socketManager) => {
    state.alertsWebsocket = socket
  }
}
