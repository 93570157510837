<template>
  <l-polygon
    :weight="weight"
    :opacity="0.8"
    :fillOpacity="0.7"
    :color="outline"
    :fillColor="fill"
    :lat-lngs="latLngs"
    @ready="ready"
  ></l-polygon>
</template>

<script>
import utils from '../utils'
import { LPolygon } from 'vue2-leaflet'

const props = {
  zIndexOffset: {
    type: Number,
    default: 0
  },
  weight: {
    type: Number,
    default: 0.1
  },
  outline: {
    type: String,
    default: 'black'
  },
  fill: {
    type: String,
    default: 'black'
  },
  mask: {
    type: Object,
    default: () => ({ range: [], azimuth: [] })
  },
  sensor: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0,
      direction: 0
    })
  }
}

export default {
  name: 'MaskSectorCoverage',
  data: () => ({
    // color: "#FF9800",
    fillOpacity: 0.1,
    prev: 0
  }),
  components: { LPolygon },
  props,
  computed: {
    latLngs() {
      try {
        return utils.drawMaskSector({ ...this.sensor, ...this.mask })
      } catch (err) {
        console.error(err)
        return []
      }
    }
  },
  methods: {
    ready(payload) {
      this.$emit('ready', payload)
    }
  }
}
</script>
