import { apiRequest, axiosInstance } from '../../utils'
import store from '@/store'

export default {
  FETCH_PLANS: async ({ commit }) => {
    commit('SET_STATUS', 'LOADING')
    try {
      const { status, data, statusText } = await axiosInstance.get(
        `/site_plans.json`
      )
      if (status !== 200) {
        throw new Error(statusText)
      }

      data.map(plan => (plan.site_plan = JSON.parse(plan.site_plan)))
      commit('SET_STATUS', 'OK')
      return commit(`SET_PLANS`, data)
    } catch (err) {
      commit('SET_STATUS', 'ERROR')
      commit('SET_ERROR', err.message)
    }
  },

  CREATE_PLAN: async ({ commit, state }, plan) => {
    commit('SET_STATUS', 'LOADING')
    try {
      let { name, site_plan } = plan || {}
      site_plan = JSON.stringify(site_plan)

      // Check duplicate name
      name = name.replace(/ \((\d)\)$/, '')
      const regexp = new RegExp(`${name} \\((\\d)\\)$`)
      const planNames = state.plansList.map(p => p.name)
      const duplicate =
        planNames
          .filter(n => n.match(regexp))
          .sort()
          .pop() || null

      if (duplicate) {
        name = duplicate.replace(/\((\d)\)$/, (_, i) => `(${parseInt(i) + 1})`)
      } else if (planNames.includes(name)) {
        name = `${name} (1)`
      }

      await apiRequest(
        'post',
        `/site_plans.json`,
        { site_plan: { name, site_plan } },
        (data, _) => {
          data.site_plan = JSON.parse(data.site_plan)
          commit(`ADD_PLAN`, data)
          commit('SET_STATUS', 'OK')
        },
        (data, _) => {
          commit('SET_STATUS', 'FAILED')
          commit('SET_ERROR', new Error(data.error))
        }
      )
    } catch (err) {
      commit('SET_STATUS', 'FAILED')
      commit('SET_ERROR', err.messages)
    }
  },

  UPDATE_PLAN: async ({ commit }, plan) => {
    commit('SET_STATUS', 'LOADING')
    try {
      let { name, site_plan } = plan || {}
      site_plan = JSON.stringify(site_plan)

      await apiRequest(
        'patch',
        `/site_plans/${plan.id}.json`,
        { site_plan: { name, site_plan } },
        (data, _) => {
          commit(`UPDATE_PLAN`, plan)
          commit('SET_STATUS', 'OK')
        },
        (data, _) => {
          commit('SET_STATUS', 'FAILED')
          commit('SET_ERROR', new Error(data.error))
        }
      )
    } catch (err) {
      commit('SET_STATUS', 'FAILED')
      commit('SET_ERROR', err.messages)
    }
  },

  DELETE_PLAN: async ({ commit }, plan) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/site_plans/${plan.id}.json`,
      {},
      (_data, _) => {
        commit('REMOVE_PLAN', plan.id)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },

  CREATE_INSTALLATION: ({ dispatch, state }, installation) => {
    let installations = state.activePlan.site_plan.installations || []
    installation.id = installations.length
      ? installations.slice(-1)[0].id + 1
      : 0
    installations.push(installation)
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      installations
    })
    dispatch('UPDATE_PLAN', state.activePlan)
    store.dispatch('sentries/SELECT_SENTRY', installation.id)
  },

  UPDATE_INSTALLATION: ({ dispatch, state }, installation) => {
    let installations = state.activePlan.site_plan.installations
    if (installation.sensors)
      Object.keys(installation.sensors).forEach(k => {
        for (let i = 0; i < installation.sensors[k].length; i++) {
          ;[
            installation.sensors[k][i].latitude,
            installation.sensors[k][i].longitude
          ] = installation.coordinates
        }
      })
    installations[
      installations.findIndex(v => v.id == installation.id)
    ] = installation
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      installations
    })
    dispatch('UPDATE_PLAN', state.activePlan)
  },

  DELETE_INSTALLATION: ({ dispatch, state }, installation) => {
    let installations = state.activePlan.site_plan.installations
    installations.splice(
      installations.findIndex(v => v.id == installation.id),
      1
    )
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      installations
    })
    dispatch('UPDATE_PLAN', state.activePlan)
  },

  CREATE_SENSOR: ({ dispatch, state }, sensor) => {
    let installations = state.activePlan.site_plan.installations
    let indexInstallation = installations.findIndex(
      v => v.id == sensor.sentry_id
    )
    let sensors = installations[indexInstallation].sensors || {}
    sensors[sensor.type] = sensors[sensor.type] || []
    sensor.id = sensors[sensor.type].length
      ? sensors[sensor.type].slice(-1)[0].id + 1
      : 0
    sensors[sensor.type].push(sensor)
    installations[indexInstallation] = Object.assign(
      {},
      installations[indexInstallation],
      { sensors }
    )
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      installations
    })
    dispatch('UPDATE_PLAN', state.activePlan)
  },

  UPDATE_SENSOR: ({ dispatch, state }, sensor) => {
    let installations = state.activePlan.site_plan.installations
    let indexInstallation = installations.findIndex(
      v => v.id == sensor.sentry_id
    )
    let sensors = installations[indexInstallation].sensors[sensor.type]
    sensors[sensors.findIndex(v => v.id == sensor.id)] = sensor
    installations[indexInstallation].sensors[sensor.type] = sensors
    installations[indexInstallation].sensors = Object.assign(
      {},
      installations[indexInstallation].sensors,
      { [sensor.type]: sensors }
    )
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      installations
    })
    dispatch('UPDATE_PLAN', state.activePlan)
  },

  DELETE_SENSOR: ({ dispatch, state }, sensor) => {
    let installations = state.activePlan.site_plan.installations
    let indexInstallation = state.activePlan.site_plan.installations.findIndex(
      v => v.id == sensor.sentry_id
    )
    let sensors =
      state.activePlan.site_plan.installations[indexInstallation].sensors[
        sensor.type
      ]
    sensors.splice(
      sensors.findIndex(v => v.id == sensor.id),
      1
    )
    installations[indexInstallation].sensors = Object.assign(
      {},
      installations[indexInstallation].sensors,
      { [sensor.type]: sensors }
    )
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      installations
    })
    dispatch('UPDATE_PLAN', state.activePlan)
  },

  CREATE_ZONE: ({ dispatch, state }, zone) => {
    let zones = state.activePlan.site_plan.zones || []
    zone.id = zones.length ? zones.slice(-1)[0].id + 1 : 0
    zones.push(zone)
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      zones
    })
    dispatch('UPDATE_PLAN', state.activePlan)
    return zone
  },

  UPDATE_ZONE: ({ dispatch, state }, zone) => {
    let zones = state.activePlan.site_plan.zones || []
    zones[zones.findIndex(v => v.id == zone.id)] = zone
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      zones
    })
    dispatch('UPDATE_PLAN', state.activePlan)
  },

  DELETE_ZONE: ({ dispatch, state }, zone) => {
    let zones = state.activePlan.site_plan.zones || []
    zones.splice(
      zones.findIndex(v => v.id == zone.id),
      1
    )
    state.activePlan.site_plan = Object.assign({}, state.activePlan.site_plan, {
      zones
    })
    dispatch('UPDATE_PLAN', state.activePlan)
  },

  ACTIVATE_INSTALLATION: ({ commit }, installation) => {
    return commit('SET_ACTIVE_INSTALLATION', installation)
  },

  ACTIVATE_PLAN: ({ commit }, plan) => {
    return commit('SET_ACTIVE_PLAN', plan)
  },

  SET_PLANS_STATE: ({ commit }, value) => {
    return commit('UPDATE_PLANS_STATE', value)
  },

  START_DRAGGING: ({ commit }) => {
    return commit('SET_DRAGGING', true)
  },

  STOP_DRAGGING: ({ commit }) => {
    return commit('SET_DRAGGING', false)
  },

  TOGGLE_PLANNING_WIZARD: ({ commit }, value) => {
    return commit('SET_WIZARD', value)
  },
  setBounds: ({commit}, bounds) => {
    commit('SET_BOUNDS', bounds)
  }
}
