import { Commit, Dispatch } from 'vuex'
import { apiRequest } from '../../utils'

import {
  ICreateSiteMarkerInput,
  IEditSiteMarkerInput,
  IGetSiteMarkersResponse,
  ISiteMarker,
  ISiteMarkerResponse
} from './types'
import { ISiteMarkersState } from './index'

export default {
  FETCH_SITE_MARKERS: async ({ commit }: { commit: Commit }, site_id: number) => {
    await apiRequest(
      'get',
      `/api/site_markers/${site_id}`,
      {},
      (data: IGetSiteMarkersResponse) => {
        commit('SET_SITE_MARKERS', data.site_markers || [])
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },

  CREATE_SITE_MARKER: async (
    {
      commit
    }: { commit: Commit; state: ISiteMarkersState; dispatch: Dispatch },
    payload: ICreateSiteMarkerInput
  ) => {
    const { nickname, lat, lng, number, siteId, colour, abbreviation } = payload

    await apiRequest(
      'post',
      `/api/site_markers/create`,
      { site_marker: {
          nickname: nickname,
          number: number || '',
          siteId: siteId.toString(),
          lat: lat.toString(),
          lng: lng.toString(),
          colour: colour || '#225e97',
          abbreviation: abbreviation || 'DRO'
        }
      },
      (data: any) => {
        commit('SET_STATUS', 'OK')
        commit('ADD_SITE_MARKER', data)

      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },

  REMOVE_SITE_MARKER: async (
    { commit }: { commit: Commit; state: any; dispatch: Dispatch },
    payload: ISiteMarker
  ) => {
    try {
      commit('REMOVE_SITE_MARKER', payload)
      await apiRequest(
        'delete',
        `/api/site_markers/${payload.id}`,
        {
          id: payload.id
        },
        (data: ISiteMarkerResponse) => {
          commit('SET_STATUS', 'OK')
          commit('REMOVE_SITE_MARKER', data)
        },
        (data: any, _: any) => {
          commit('SET_STATUS', 'FAILED')
          commit('SET_ERROR', new Error(data.error))
          return null
        }
      )
    } catch (err) {
      commit('SET_STATUS', 'ERROR')
      commit('SET_ERROR', err.message)
    }
  },

  EDIT_SITE_MARKER: async (
    { commit, dispatch }: { commit: Commit; state: any; dispatch: Dispatch },
    payload: IEditSiteMarkerInput
  ) => {
    const {
      id,
      lat,
      lng,
      colour,
      abbreviation,
      nickname,
      number,
      siteId
    } = payload

    await apiRequest(
      'patch',
      `/api/site_markers/${id}`,
      { site_marker: {
          nickname,
          number,
          lat: lat.toString(),
          lng: lng.toString(),
          colour: colour || '#FFFFFF',
          abbreviation: abbreviation
        }
      },
      (data: any) => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_SITE_MARKERS', siteId)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  }
}
