<template>
  <div>
    <l-feature-group :visible="visible">
      <template v-if="activePlan">
        <template v-if="!sensor.field_of_view || sensor.field_of_view === 360">
          <l-circle
            color="orange"
            fill-color="rgb(170, 178, 65)"
            :fill-opacity="0.15"
            :opacity="0.8"
            :weight="1"
            :radius="reach"
            :lat-lng="[sensor.latitude, sensor.longitude]"
            :ref="`${refKey}-circle`"
            :className="draggable ? 'wizardSector' : ''"
          />
          <l-circle
            v-if="sensor.camera_type === 'droneopt2'"
            color="rgb(97, 86, 130)"
            fill-color="rgb(126, 111, 168)"
            :fill-opacity="0.35"
            :opacity="0.8"
            :weight="1"
            :radius="500"
            :lat-lng="[sensor.latitude, sensor.longitude]"
          />
        </template>
        <template v-else>
          <l-polygon
            color="orange"
            fill-color="rgb(170, 178, 65)"
            :fill-opacity="0.15"
            :opacity="0.8"
            :weight="1"
            :lat-lngs="directionalRange()"
          />
          <l-circle
            color="orange"
            fill-color="transparent"
            :opacity="0.8"
            :weight="1"
            :radius="reach"
            :lat-lng="[sensor.latitude, sensor.longitude]"
          />
          <template v-if="sensor.camera_type === 'droneopt2'">
            <l-polygon
              color="rgb(155, 128, 229)"
              fill-color="rgb(126, 111, 168)"
              :fill-opacity="0.35"
              :opacity="0.8"
              :weight="1"
              :lat-lngs="directionalRange(500)"
            />
            <l-circle
              color="rgb(155, 128, 229)"
              fill-color="transparent"
              :opacity="0.8"
              :weight="1"
              :radius="500"
              :lat-lng="[sensor.latitude, sensor.longitude]"
            />
          </template>
        </template>
      </template>
      <template v-if="editMode">
        <polygon-fog-of-war
          :fill-opacity="0"
          :mask-opacity="0.5"
          fill-color="black"
          :opacity="0"
          mask-id="panSectorEdit"
          :visible="visible"
        />
        <circle-fog-of-war
          :lat-lng="[sensor.latitude, sensor.longitude]"
          :radius="reach"
          :stdDeviation="30"
          :opacity="0"
          :fill-opacity="0"
          fill-color="black"
          mask="panSectorEdit"
          :visible="visible"
        />
        <template v-if="!activePlan && !calibratingCamera">
          <l-polygon
            v-for="(range, i) in allRanges"
            :key="`hfov-${sensor.id}-${i}`"
            color="transparent"
            fill-color="orange"
            :fill-opacity="opacity"
            :lat-lngs="range"
          ></l-polygon>
          <l-polygon
            v-for="(range, i) in allRangesHuge"
            :key="`afov-${sensor.id}-${i}`"
            color="transparent"
            fill-color="orange"
            :fill-opacity="0.15"
            :lat-lngs="range"
          ></l-polygon>
        </template>
      </template>
      <template v-else>
        <template v-if="activePlan">
          <l-circle
            v-for="t in [0.8, 0.9, 1]"
            :key="`c1-${t}`"
            :lat-lng="[sensor.latitude, sensor.longitude]"
            :radius="800 + t ** 2 * 200"
            color="rgb(170, 178, 65)"
            class="spinningCircle"
            dashArray="150,100"
            :opacity="t ** 2 * 0.8"
            fill-color="transparent"
            :weight="t ** 5 * 3"
          />
          <template v-if="sensor.camera_type === 'droneopt2'">
            <l-circle
              v-for="t in [0.8, 0.9, 1]"
              :key="`c2-${t}`"
              :lat-lng="[sensor.latitude, sensor.longitude]"
              :radius="300 + t ** 2 * 200"
              color="rgb(97, 86, 130)"
              class="spinningCircle"
              dashArray="150,100"
              :opacity="t ** 2 * 0.8"
              fill-color="transparent"
              :weight="t ** 5 * 3"
            />
          </template>
        </template>
        <template v-else>
          <l-polygon
            color="orange"
            fill-color="transparent"
            :weight="active ? 3 : 1"
            :lat-lngs="realTimeRange(direction)"
            :className="`draggableSector`"
            @drag="dragSector"
            @dragend="endDrag"
            :ref="`${refKey}-drag`"
          ></l-polygon>
          <l-polygon
            v-if="dragDirection"
            color="orange"
            fill-color="transparent"
            :weight="1"
            :lat-lngs="realTimeRange(dragDirection)"
            :ref="refKey"
          ></l-polygon>
        </template>
      </template>
    </l-feature-group>
  </div>
</template>

<script>
import utils from '../utils'
import { icon } from 'leaflet'
import { mapState, mapGetters } from 'vuex'
import { LCircle, LPolygon, LFeatureGroup } from 'vue2-leaflet'
import { PolygonFogOfWar, CircleFogOfWar } from 'vue2-leaflet-fogofwar'

const props = {
  active: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: true
  },
  editMode: {
    type: Boolean,
    default: false
  },
  sensor: {
    type: Object,
    default: () => {}
  },
  draggable: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'CameraSectorCoverage',
  data: () => ({
    roseIcon: icon({
      iconUrl: require(`@/assets/compass-rose.png`),
      iconSize: [700, 692],
      iconAnchor: [353, 346]
    }),
    dragDirection: null,
    dragTimer: null,
    dragInterval: null
  }),
  props,
  components: {
    LCircle,
    LPolygon,
    LFeatureGroup,
    PolygonFogOfWar,
    CircleFogOfWar
  },
  methods: {
    rangeWithFactor(factor, AFOVforReach) {
      if (!this.sensor) return
      const AFOV =
        this.sensor.min_fov_angle +
        (this.sensor.max_fov_angle - this.sensor.min_fov_angle) * factor
      return utils.createRange({
        reach: utils.calculateWD(AFOVforReach || AFOV),
        direction: this.sensor.direction,
        min: -AFOV / 2,
        max: +AFOV / 2,
        vertex: [this.sensor.latitude, this.sensor.longitude]
      })
    },
    clearDragInterval() {
      if (this.dragInterval) {
        clearTimeout(this.dragInterval)
        this.dragInterval = null
      }
    },
    dragSector(coords) {
      if (!this.sensor) return
      this.dragDirection = utils.sensorDragDirection(this.sensor, coords)
      this.clearDragInterval()
      this.dragInterval = setInterval(this.rotateCamera, 200)
    },
    endDrag() {
      this.clearDragInterval()
      setTimeout(this.rotateCamera, 500)
      setTimeout(() => (this.dragDirection = null), 1500)
    },
    rotateCamera() {
      this.$bus.$emit('rotateCamera', [
        this.sensor.id,
        this.dragDirection,
        this.position,
        this.config
      ])
    },
    realTimeRange(direction) {
      if (!this.sensor) return
      const { zoom } = this.sensor || {}
      const s = this.sensor
      const AFOV = utils.calculateAFOV(zoom, s.min_fov_angle, s.max_fov_angle)
      return utils.createRange({
        reach: 700 + 500 * zoom,
        direction: direction,
        min: -AFOV / 2,
        max: +AFOV / 2,
        vertex: [s.latitude, s.longitude]
      })
    }
  },
  computed: {
    ...mapGetters('plans', ['activePlan']),
    ...mapState('cameras', ['calibratingCamera']),
    config() {
      return {
        min: this.sensor.min_tilt_angle,
        max: this.sensor.max_tilt_angle,
        bearing: this.sensor.direction,
        coords: {
          x: this.sensor.latitude,
          y: this.sensor.longitude,
          z: this.sensor.altitude
        }
      }
    },
    position() {
      return { y: this.sensor.tilt, z: this.sensor.zoom }
    },
    latlng() {
      return this.sensor && [this.sensor.latitude, this.sensor.longitude]
    },
    opacity() {
      return Math.min(
        (this.sensor.max_fov_angle - this.sensor.min_fov_angle) / 25,
        0.5
      )
    },
    allRanges() {
      return [0, 0.2, 0.4, 0.6, 0.8, 1].map(factor =>
        this.rangeWithFactor(factor)
      )
    },
    allRangesHuge() {
      return [0, 0.2, 0.4, 0.6, 0.8, 1].map(factor =>
        this.rangeWithFactor(factor, this.sensor.min_fov_angle)
      )
    },
    directionalRange() {
      return (reach = null) => {
        const { direction = 0 } = this.sensor || {}
        return utils.createRange({
          reach: reach || this.reach,
          direction,
          min: -this.sensor.field_of_view / 2,
          max: this.sensor.field_of_view / 2,
          vertex: [this.sensor.latitude, this.sensor.longitude]
        })
      }
    },
    reach() {
      return (
        this.sensor.forcedReach ||
        utils.calculateWD(this.sensor.min_fov_angle) * 1.05
      )
    },
    direction() {
      if (!this.sensor) return
      const s = this.sensor
      return (s.direction + s.pan * 180) % 360
    },
    refKey() {
      return `camera-sector-${this.sensor.id}`
    }
  },
  watch: {
    active(isActive) {
      if (isActive && this.$refs[this.refKey]) {
        utils.bringSVGToTop(this.$refs[this.refKey])
        utils.bringSVGToTop(this.$refs[`${this.refKey}-drag`])
      }
    }
  },
  mounted() {
    this.$bus.$on('bringCameraToTop', () => {
      utils.bringSVGToTop(this.$refs[`${this.refKey}-circle`])
    })
  },
  beforeDestroy() {
    this.$bus.$off('bringCameraToTop')
  }
}
</script>
<style>
.spinningCircle:nth-child(odd) {
  -webkit-animation: spin 200s linear infinite;
  -moz-animation: spin 200s linear infinite;
  animation: spin 200s linear infinite;
}
.spinningCircle:nth-child(even) {
  -webkit-animation: spin2 170s linear infinite;
  -moz-animation: spin2 170s linear infinite;
  animation: spin2 170s linear infinite;
}
@-moz-keyframes spin {
  100% {
    stroke-dashoffset: 100%;
  }
}
@-webkit-keyframes spin {
  100% {
    stroke-dashoffset: 100%;
  }
}
@keyframes spin {
  100% {
    stroke-dashoffset: 100%;
  }
}

@-moz-keyframes spin2 {
  100% {
    stroke-dashoffset: -100%;
  }
}
@-webkit-keyframes spin2 {
  100% {
    stroke-dashoffset: -100%;
  }
}
@keyframes spin2 {
  100% {
    stroke-dashoffset: -100%;
  }
}
</style>
