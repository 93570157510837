// @ts-ignore
import actions from './actions.ts'
// @ts-ignore
import getters from './getters.ts'
// @ts-ignore
import mutations from './mutations.ts'

export interface ISimulationState {
  id: string
  drone_id: string
  latitude: number | undefined
  longitude: number | undefined
  speed: number | undefined
  altitude: number | undefined
  type: ESimulationType
}

export enum ESimulationType {
  DRONE = 'drone',
  FIXED_EMITTER = 'fixed_emitter',
  NOISE = 'noise'
}

export interface ISimulationDetectionsState {
  detections: Array<ISimulationState>
}

const getDefaultState = () => {
  return {
    detections: []
  }
}

const state = getDefaultState()

const resetState = (state: ISimulationState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
