import { IRadar } from '@/store/modules/radars/types'
import { ISentry } from '@/store/modules/sentries/types'

export const ELEVATION_TOLERANCE = 2
export const RCS_TOLERANCE = 5
export const RANGE_TOLERANCE_METRES = 30
export const MIN_RANGE = 0
export const MAX_RANGE = 3000
export const MIN_AZIMUTH = -60
export const MIN_ELEVATION = -40
export const MAX_ELEVATION = 40
export const MIN_RCS = -50
export const MAX_RCS = 100

export interface RadarMaskState {
  editing: boolean
}

export interface IMask {
  id: number
  azimuth: number[]
  elevation: number[]
  range: number[]
  rcs: number[]
  hard_mask: boolean
  name: string
}

export interface IMaskRawData {
  altitude: number
  elevation: number
  latitude: number
  longitude: number
  rcs: number
  azimuth: number
  speed: number
}

export interface IMaskRequest {
  isFusion: boolean
  sensor: IRadar
  sentry: ISentry
  data: IMaskRawData
}
