import {
  TWarningType,
  WARNING_TYPES
} from '../../store/modules/site_warnings/types'
import moment from 'moment'
import { unitOfTime } from 'moment'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SiteWarningsLogic',
  computed: {
    ...mapState(['systemStats']),
    ...mapState('site_warnings', ['site_warnings', 'site_warnings_history']),
    ...mapState('sensors', ['radarZMasks']),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('sensors', ['allRadarsInSite']),
    ...mapGetters('cameras', ['allCameras']),
    ...mapGetters('sites', ['activeSite'])
  },
  methods: {
    ...mapActions('site_warnings', ['CREATE_SITE_WARNING']),
    // return true if a not read site warning is present or not enough time passed (15 minutes)
    skipIfExistingWarningOrDeltaTime(warningType: TWarningType): boolean {
      const existingWarningInCurrentWarnings =
        this.site_warnings.filter(
          warning => warning.warning_type === warningType
        ).length > 0

      if (existingWarningInCurrentWarnings) return true

      //check for existing warning
      const allWarnings = [...this.site_warnings, ...this.site_warnings_history]
      //find first warning of type in all warnings
      const existingWarningObj = allWarnings.find(
        warning => warning.warning_type === warningType
      )
      if (!existingWarningObj) return false

      //set time between creation of new warnings
      const warningInterval = {
        delta: 15,
        time_metric: 'minutes'
      }
      // post warning if last warning.created_at was over x time ago
      const diff = moment().diff(
        existingWarningObj.created_at,
        warningInterval.time_metric as unitOfTime.DurationConstructor
      )
      return diff < warningInterval.delta
    },
    checkRadarMaskWarning(): boolean {
      return false // disable this for now

      // ignore if no radars in site
      var totalZMasks = 0
      for (var key in this.radarZMasks) {
        totalZMasks += this.radarZMasks[key].length
      }

      if (
        this.allRadarsInSite.length === 0 ||
        totalZMasks !== 0 ||
        this.skipIfExistingWarningOrDeltaTime(WARNING_TYPES.Radar_Noise)
      )
        return false

      this.CREATE_SITE_WARNING({
        title: `No Radar Mask Set`,
        content: `Consider adding a radar mask to your radars for the best experience`,
        site_id: this.activeSiteId,
        warning_type: WARNING_TYPES.Radar_Noise
      })
      return true
    },
    checkRadarClashWarning(): boolean {
      return false // disable this for now

      // do not check unless there is more than 1 radar
      if (
        this.allRadarsInSite.length <= 1 ||
        this.skipIfExistingWarningOrDeltaTime(WARNING_TYPES.Radar_Channel_Clash)
      )
        return false

      const arrayByDirection = this.allRadarsInSite.sort(
        (a, b) => a.direction - b.direction
      )
      let prev = null
      const clashingRadars = []
      for (let i = 0; i < arrayByDirection.length; i++) {
        if (
          prev?.channel === arrayByDirection[i].channel &&
          arrayByDirection[i].direction - prev?.direction < 120
        ) {
          clashingRadars.push(prev.name)
        }
        prev = arrayByDirection[i]
      }

      if (clashingRadars.length == 0) return false

      this.CREATE_SITE_WARNING({
        title: `${clashingRadars.join(' and ')} ${
          clashingRadars.length > 1 ? 'are' : 'is'
        } clashing with the nearby radars`,
        content: `Consider changing radar channel to prevent frequency clash`,
        site_id: this.activeSiteId,
        warning_type: WARNING_TYPES.Radar_Channel_Clash
      })
      return true
    },
    checkWarnings(): void {
      if (!this.activeSite?.system_warning_enabled) return

      this.checkRadarMaskWarning()
      this.checkRadarClashWarning()
    }
  }
}
