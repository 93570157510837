import { apiRequest } from '../../utils'
import get from 'lodash/get'
import pick from 'lodash/pick'

const sanitizeZMaskParams = mask => {
  return pick(mask, [
    'azimuth_min',
    'azimuth_max',
    'elevation_min',
    'elevation_max',
    'range_min',
    'range_max',
    'rcs_min',
    'rcs_max',
    'hard_mask',
    'active',
    'name'
  ])
}

export default {
  selectDetection: ({ commit }, key) => {
    return commit('activateDetection', key)
  },
  selectRFBeam: ({ commit }, key) => {
    return commit('activateRFBeam', key)
  },
  toggleDisruptor: async (
    { state, dispatch },
    { sensorId, command = undefined }
  ) => {
    const sensor = state.cannonsSet[sensorId]
    if (command === undefined) command = !sensor.power_trigger_engaged
    await dispatch('UPDATE_CANNON', {
      id: sensorId,
      power_trigger_command: command
    })
  },
  toggleAllDisruptors: async ({ state, dispatch }, { command = undefined }) => {
    const sensors = Object.values(state.cannonsSet).filter(cannon => (
      !!cannon.gnss_trigger_engaged ||
      !!cannon.band_24_58_trigger_engaged ||
      !!cannon.band_433_trigger_engaged ||
      !!cannon.band_915_trigger_engaged ||
      !!cannon.band_433_915_trigger_engaged
    ))
    Promise.all(
      sensors.map(sensor => {
        if (command === undefined) command = !sensor.power_trigger_engaged
        dispatch('UPDATE_CANNON', {
          id: sensor.id,
          power_trigger_command: command
        })
      })
    )
  },
  TOGGLE_CANNON: async ({ commit }, { sensor, command, deviceType }) => {
    commit('SET_STATUS', 'LOADING')
    const dataCannon = {
      device_type: deviceType || 'cannon',
      device_id: sensor.id,
      command: command,
      is_manual: true
    }

    return await apiRequest(
      'post',
      '/commands.json',
      { command: dataCannon },
      data => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_SCOPED_ERROR_CANNON`, {
          id: sensor.id,
          error: new Error(data.error)
        })
      }
    )
  },
  TOGGLE_RADAR: async ({ commit }, { sensor, state }) => {
    commit('SET_STATUS', 'LOADING')
    const newStatus = sensor.status == 'transmitting' ? 'idle' : 'transmitting'
    return await apiRequest(
      'patch',
      `/radars/${sensor.id}.json`,
      {
        radar: {
          status: newStatus,
          manual: 'true'
        }
      },
      _ => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_SCOPED_ERROR_RADAR`, {
          id: sensor.id,
          error: new Error(data.error)
        })
      }
    )
  },
  CALIBRATE_RADAR: async ({ commit, dispatch }, { id }) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'path',
      `/calibrate_radars/${id}.json`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
        dispatch('FETCH_RADAR', id)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_RF_FILTER_DATA: async ({ commit }, payload) => {
    return await apiRequest(
      'get',
      `/rf_filter_data.json?site_id=${payload.site_id}`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  FETCH_DSX_JAMMERS: async ({ commit }, payload) => {
    return await apiRequest(
      'get',
      `/cannons.json?site_id=${payload.site_id}&only_dsx=true`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  RESTART_SENSOR: async ({ commit, dispatch }, { sensor_type, site_id }) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'post',
      `/api/sites/${site_id}/restart_sensors`,
      { sensor_type: sensor_type },
      (data, _) => {
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_ERROR`, new Error(data.error))
      }
    )
  },
  // Load Radar Masks
  FETCH_RMASK: async ({ commit }, radar) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'get',
      `/radars/${radar.id}/radar_masks.json`,
      {},
      data => {
        commit('SET_STATUS', 'OK')
        commit('SET_RMASK', { radar, mask: data })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },

  FETCH_ZMASK: async ({ commit }, radar) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'get',
      `/radars/${radar.id}/radar_mask_zones.json`,
      {},
      data => {
        commit('SET_STATUS', 'OK')
        commit('SET_ZMASKS', { radar, masks: data })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },

  // Load Radar zone mask total
  FETCH_ZMASK_TOTAL: async ({ commit }, siteID) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'get',
      `/sites/${siteID}/radar_mask_zones.json`,
      {},
      data => {
        commit('SET_STATUS', 'OK')
        commit('SET_ZMASKS', { masks: data })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  UPDATE_RMASK: async ({ commit }, { radar, mask }) => {
    commit('SET_STATUS', 'LOADING')

    delete mask.created_at
    delete mask.updated_at
    delete mask.radar_id
    return await apiRequest(
      'patch',
      `/radars/${radar.id}/radar_masks/${mask.id}.json`,
      { radar_mask: mask },
      _ => {
        commit('SET_STATUS', 'OK')
        commit('UPDATE_RMASK', { radar, mask })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },

  UPDATE_ZMASK: async ({ commit }, { radar, mask }) => {
    commit('SET_STATUS', 'LOADING')

    const { start_time, end_time } = mask
    let url
    if (radar.id && mask.id) {
      url = `/radars/${radar.id}/radar_mask_zones/${mask.id}.json`
    } else if (start_time && end_time) {
      url = `/radars/${radar.id}/radar_mask_zones.json?start_time=${start_time}&end_time=${end_time}`
    } else {
      url = `/radars/${radar.id}/radar_mask_zones.json`
    }

    // Masks from API have inverted azimuth
    if (
      mask.hasOwnProperty('azimuth_min') &&
      mask.hasOwnProperty('azimuth_max')
    ) {
      ;[mask.azimuth_min, mask.azimuth_max] = [
        -mask.azimuth_max,
        -mask.azimuth_min
      ]
    }

    return await apiRequest(
      radar.id && mask.id ? 'patch' : 'post',
      url,
      { radar_mask_zone: sanitizeZMaskParams(mask) },
      data => {
        commit('SET_STATUS', 'OK')
        const { radar_mask_zone, radar_mask_zones } = data
        if (radar_mask_zone) {
          commit('UPDATE_ZMASK', radar_mask_zone)
        } else if (radar_mask_zones) {
          radar_mask_zones.forEach(m => commit('UPDATE_ZMASK', m))
        }
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },

  DELETE_ZMASK: async ({ commit }, { radar, mask }) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'delete',
      `/radars/${radar.id}/radar_mask_zones/${mask.id}.json`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
        commit('DELETE_ZMASK', { radar, mask })
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return null
      }
    )
  },
  RF_PERF_ESTIMATION: async ({ commit }, rf_perf) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/rf_perf_estimations.json`,
      { rf_perf },
      data => {
        commit('SET_STATUS', 'OK')
        commit('UPDATE_RF_PERF_ESTIMATION', data)
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        commit('UPDATE_RF_PERF_ESTIMATION', [])
      }
    )
  },

  UNSELECT_ALL: ({ dispatch }) => {
    dispatch('UNSELECT_RADAR')
    dispatch('UNSELECT_CANNON')
    dispatch('UNSELECT_GPS_COMPASS')
    // TODO: somehow this is required
    // dispatch('UNSELECT_RF_SENSOR')
    // dispatch('UNSELECT_DSX_SENSOR')

    // Do not unselect camera
  },

  ACTIVE_SENSOR_ID: ({ dispatch }, sensorType = 'radar') => {
    return dispatch(`active${sensorType}Id`)
  },
  SELECT_SENSOR: ({ dispatch }, { id, sensorType = 'radar' }) => {
    return dispatch(`SELECT_${sensorType.toUpperCase()}`, id)
  },

  FETCH_AND_LOAD_RECORDING: async ({ state, dispatch }, recordingId) => {
    if (state.recordingsSet[recordingId]) {
      const exp = state.recordingsSet[recordingId].url_expiration
      const expiredUrl = !exp || Date.now() > Date.parse(exp)
      if (!expiredUrl) return state.recordingsSet[recordingId]
    }
    state.recordingsSet[recordingId] = {}
    await dispatch('FETCH_RECORDING', recordingId)
    return state.recordingsSet[recordingId]
  },
  // TODO: review this func, it seems not really used anymore
  selectUniqueGroupForSentry: ({ dispatch, getters }, sentry) => {
    const autoUnfoldGroups = [
      'cannons',
      'radars'
      // 'rf_sensors',
      // 'dsx_sensors'
    ]
    const expandableGroups = autoUnfoldGroups.filter(
      k => getters['allSensorsInSentry'](sentry)[k].length > 0
    )

    if (expandableGroups.length === 1) {
      setTimeout(
        () =>
          dispatch(
            'SET_ACTIVE_GROUP',
            { [expandableGroups[0]]: true },
            { root: true }
          ),
        200
      )
    }
  },

  selectUniqueSensorForGroup: ({ dispatch, getters, rootGetters }, group) => {
    const sentry = rootGetters['sentries/activeSentry']
    const sensors = getters['allSensorsInSentry'](sentry)[group]
    if (sensors.length === 1) {
      setTimeout(
        () =>
          dispatch(
            `sensors/SELECT_${group.slice(0, -1).toUpperCase()}`,
            sensors[0].id,
            { root: true }
          ),
        400
      )
    }
  },

  setCannonQuickPanelState: ({ commit }, state) => {
    commit('SET_CANNON_QUICKPANEL_STATE', state)
  },
  setHighlightedAllCannons: ({ commit }, state) => {
    commit('SET_HIGHLIGHTED_ALL_CANNONS', state)
  },
  setHighlightedCannon: ({ commit }, state) => {
    commit('SET_HIGHLIGHTED_CANNON', state)
  },
  setFilterRetroApplyStatus: ({ commit }, status) => {
    commit('SET_FILTER_RETRO_APPLY', status)
  },
  triggerFilterRetroApply: async ({ commit }, payload) => {
    return await apiRequest(
      'get',
      `/rf_sensors/${payload.sensor}/rf_filters/${payload.filter}/apply_retrospectively.json`,
      {},
      data => {
        commit('SET_STATUS', 'OK')
        return { data: data, status: 200 }
      },
      (data, status) => {
        return { data: data.error, status: status }
      }
    )
  },

  UPDATE_IDENTITIES: ({ commit }, identity) => {
    commit('UPDATE_IDENTITIES', identity)
  },
  CLEAR_IDENTITIES: ({ commit }) => {
    commit('CLEAR_IDENTITIES')
  }
}
