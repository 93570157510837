<template>
  <l-marker :icon="controllerIcon" :lat-lng="center" />
</template>

<script>
import { icon } from 'leaflet'
import { LMarker } from 'vue2-leaflet'
import { getTheme } from '@/components/Map/utils/themes'

const props = {
  center: {
    type: Array
  },
  threat: {
    type: Boolean,
    default: true
  }
}

// Beacon Controller Marker - beacon-decoded drone controller position
export default {
  name: 'BeaconControllerMarker',
  props,
  components: {
    LMarker
  },
  computed: {
    themeName() {
      return this.$store.state.theme
    },
    theme() {
      return getTheme(this.themeName)
    },
    controllerIcon() {
      return icon({
        iconUrl: require(`@/assets/drone/beacon/controller-${
          this.threat ? 'threat' : 'whitelisted'
        }.svg`),
        iconSize: this.theme.icons.size,
        iconAnchor: this.theme.icons.anchor
      })
    }
  }
}
</script>
