













































import Vue from 'vue'
import chunk from 'lodash/chunk'
import { mapActions, mapGetters, mapState } from 'vuex'
const AddOnlineMapLayer = () =>
  import('@/components/Widgets/MapsAndZones/AddOnlineMapLayer.vue')
const AddOfflineMapLayer = () =>
  import('@/components/Widgets/MapsAndZones/AddOfflineMapLayer.vue')
import FallbackConfirmDialog from '@/components/Widgets/MapsAndZones/FallbackConfirmDialog.vue'
import { IMapLayer } from '@/store/modules/maps/types'

export default Vue.extend({
  name: 'AddMapLayerDialog',
  components: {
    FallbackConfirmDialog
  },
  data() {
    return {
      mapLayerBuffer: null,
      showFallbackConfirm: false,
      snackbar: false as boolean,
      snackbarText: '' as string,
      active: 0 as number,
      apiCallInProgress: false as boolean,
      tabs: [
        {
          type: 'Online',
          component: AddOnlineMapLayer
        },
        {
          type: 'Offline',
          component: AddOfflineMapLayer
        }
      ]
    }
  },
  computed: {
    ...mapState('maps', {
      showDialog: 'showAddMapLayerDialog',
      allMapLayers: 'allMapLayers',
      selectedMapLayers: 'selectedMapLayers'
    }),
    ...mapState('sites', ['activeSiteId']),
    ...mapState('maps', ['error']),
    ...mapGetters('maps', ['fallbackMapLayer']),
    allMapNames() {
      return this.allMapLayers.map(layer => layer.name)
    }
  },
  methods: {
    ...mapActions('maps', [
      'addMapLayer',
      'fetchAllMapLayers',
      'addOfflineMapLayer',
      'uploadOfflineChunk',
      'initOfflineChunkUpload',
      'createCancelToken',
      'clearCancelToken',
      'setUploadingMapState'
    ]),
    ...mapActions('snackbar', ['showSnackbar', 'closeSnackbar']),
    ...mapActions('maps', [
      'showAddMapLayerDialog',
      'hideAddMapLayerDialog',
      'addSiteMapLayerAssociation',
      'fetchSiteAndLayerMapping',
      'setSelectedMapLayers',
      'selectMapLayer',
      'setActiveMapLayerId',
      'setUploadFileId',
      'updateMapLayer'
    ]),
    onClose() {
      this.hideAddMapLayerDialog()
    },
    async uploadMapChunks(chunks: Blob[], fileID: string) {
      await this.createCancelToken()
      const responses = []
      let chunkID = 0
      let orderedChunks = []

      chunks.forEach((blob: Blob) => {
        orderedChunks.push({
          id: chunkID,
          data: blob
        })
        chunkID += 1
      })

      const batchUploads = chunk(orderedChunks, 3)
      for (const batchUpload of batchUploads) {
        const resp = await Promise.all(
          batchUpload.map(orderedChunk => {
            let chunkID = orderedChunk.id
            let chunk = orderedChunk.data

            return this.uploadOfflineChunk({
              fileID,
              chunkID,
              chunk
            })
          })
        )
        responses.push(resp)
      }

      await this.clearCancelToken()
    },
    async replaceFallbackAndDoAction() {
      const fallback = Object.assign({}, this.fallbackMapLayer)
      fallback.fallback = false
      await this.updateMapLayer(fallback)
      this.showFallbackConfirm = false
      await this.onSubmit(this.mapLayerBuffer)
    },
    checkFallback(data: IMapLayer): void {
      if (this.fallbackMapLayer) {
        this.mapLayerBuffer = data
        this.showFallbackConfirm = true
      } else {
        this.onSubmit(data)
      }
    },
    async onSubmit(data): Promise<void> {
      this.$store.commit('maps/SET_ERROR', null)
      this.apiCallInProgress = true
      if (data.offline) {
        const size = 2_097_152
        let chunkQuantity = Math.ceil(data.file.size / size)
        const chunks = []

        for (let i = 0; i < chunkQuantity; i++) {
          const start = i * size
          const end = start + size
          const chunk = data.file.slice(start, end)
          chunks.push(chunk)
        }
        const { fileID } = await this.initOfflineChunkUpload({
          maplayerName: data.name,
          attribution: data.attribution,
          fallback: data.fallback,
          siteID: this.activeSiteId,
          size: data.file.size,
          filename: data.file.name,
          quantity: chunks.length
        })

        if (this.error) {
          this.apiCallInProgress = false
          return
        }

        // store upload file id
        await this.setUploadFileId(fileID)

        // close dialog box, while uploading in the background
        this.apiCallInProgress = false
        this.onClose()

        await this.showSnackbar({
          snackbarText:
            'The upload will continue in the background.<br/>Please do not close the tab or the browser.<br/>You will be notified when the upload succeeds or fails.',
          snackbarTimeout: 7000,
          textClass: '',
          buttonClass: ''
        })

        // indicate that C2 is currently uploading a map
        await this.setUploadingMapState(true)

        await this.uploadMapChunks(chunks, fileID)
      } else {
        const { map_layer } = await this.addMapLayer(data)
        this.apiCallInProgress = false
        if (this.error) return

        // add site/layer mapping
        await this.addSiteMapLayerAssociation({
          siteId: this.activeSiteId,
          layerId: map_layer.id
        })
        await this.fetchSiteAndLayerMapping(this.activeSiteId)

        this.selectMapLayer(map_layer)
        await this.setActiveMapLayerId(map_layer.id)

        this.onClose()
      }
      await this.fetchAllMapLayers()
    }
  }
})
