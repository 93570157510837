import { setPersistent } from '@/store/utils'
import { IMapLayer, IMapLayerMapping, IMapState } from './types'
import { ISite } from "@/store/modules/sites/types";

export default {
  ACTIVATE_MAP: (state: IMapState, id: number): void => {
    state.activeMapId = id
  },
  SET_STATUS: (state: IMapState, status: string | null) =>
    (state.status = status),
  SET_EXPORTING_MAP: (state: IMapState, exporting: boolean) =>
    (state.exporting = exporting),
  SET_ERROR: (state: IMapState, error: string | null) => (state.error = error),
  // deactivate map
  DEACTIVATE_MAP: (state: IMapState): void => (state.activeMapId = null),

  LOAD_SEARCH_RESULT: (state: IMapState, result): void =>
    (state.searchResult = result),

  SET_ACTIVE_MAP_LAYERS: (
    state: IMapState,
    activeMapLayers: Array<String>
  ): void => setPersistent(state, 'activeMapLayers', activeMapLayers),
  INC_MAP_LAYER_ID: (state: IMapState, v: number): void =>
    setPersistent(state, 'mapLayerID', state.mapLayerID + 1),
  SET_MAP_LAYER: (state: IMapState, layer: String): void => {
    let index = state.activeMapLayers.indexOf(layer)
    if (index < 0) index = 0 // indexOf returns -1 if not found, we must default to 0 if that is the case
    setPersistent(state, 'mapLayerID', index)
  },
  SET_COMPASS_GRAPHIC_DIRECTION: (state: IMapState, dir: number): void => {
    state.compassGraphicDirection = dir
  },
  SET_ALL_MAP_LAYERS: (state: IMapState, layers: Array<IMapLayer>): void => {
    setPersistent(state, 'allMapLayers', layers)
  },
  SWITCH_ACTIVE_MAP_LAYER: (state: IMapState): void => {
    const mapLayers = state.siteMapLayerMapping.map(mapping => {
      return state.allMapLayers.find(l => l.id === mapping.map_layer_id)
    })
    const currentIndex = mapLayers.findIndex(
      (layer: IMapLayer): boolean => layer.id === state.activeMapLayerId
    )
    const nextMapLayer = mapLayers[(currentIndex + 1) % mapLayers.length]

    setPersistent(state, 'activeMapLayerId', nextMapLayer.id)
  },
  SET_SITE_LAYER_MAPPING: (
    state: IMapState,
    mapping: IMapLayerMapping
  ): void => {
    setPersistent(state, 'siteMapLayerMapping', mapping)
  },
  SET_ACTIVE_MAP_LAYER_ID: (state: IMapState, id: number): void => {
    setPersistent(state, 'activeMapLayerId', id)
  },
  SET_SELECTED_MAP_LAYERS: (
    state: IMapState,
    mapLayers: Array<IMapLayer>
  ): void => {
    state.selectedMapLayers = mapLayers
  },
  SET_CANCEL_TOKEN: (state: IMapState, token: string): void => {
    state.mapCancelToken = token
  },
  SHOW_ADD_MAP_LAYER_DIALOG: (state: IMapState): void => {
    state.showAddMapLayerDialog = true
  },
  HIDE_ADD_MAP_LAYER_DIALOG: (state: IMapState): void => {
    state.showAddMapLayerDialog = false
  },
  SET_UPLOADING_MAP_STATE: (state: IMapState, uploading: boolean): void => {
    state.uploadingMap = uploading
  },
  SELECT_MAP_LAYER: (state: IMapState, layer: IMapLayer): void => {
    const selected = Object.values(state.selectedMapLayers)
    selected.push(layer)
    state.selectedMapLayers = selected
  },
  UNSELECT_MAP_LAYER: (state: IMapState, layer: IMapLayer): void => {
    const selected = state.selectedMapLayers.filter(l => {
      return l.id !== layer.id
    })
    state.selectedMapLayers = selected
  },
  SET_MAP_ZOOM: (state: IMapState, {zoom, siteZoom}: {
    zoom: IMapState['mapZoom'],
    siteZoom: ISite['zoom_level']
  }): void => {
    const {allMapLayers, activeMapLayerId} = state;
    state.mapZoom = allMapLayers.reduce((acc, layer) => {
      if (layer.id === activeMapLayerId && layer.max_zoom) {
        return Math.min(layer.max_zoom, siteZoom);
      }
      return acc;
    }, zoom);
  },
  SET_UPLOAD_FILE_ID: (state: IMapState, fileId: string): void => {
    state.uploadFileId = fileId
  }
}
