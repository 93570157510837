const toTitleCase = str =>
  str?.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )

export default {
  titleZoneType: state => state.zoneTypes.map(toTitleCase),
  zonesList: state => state.zonesList.filter(z => state.zoneTypes.includes(z.zone_type)),
  alertZonesList: state => state.zonesList.filter(z => z.zone_type === 'alert')
}
