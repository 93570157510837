export default {
  activateSentry: async ({ commit }, payload) => {
    commit('ACTIVATE_SENTRY', payload)
  },
  setActiveSentry: async ({ commit }, payload) => {
    commit('SET_ACTIVE_SENTRY', payload)
  },
  deactivateSentry: async ({ commit }, payload) => {
    commit('DEACTIVATE_SENTRY', payload)
  },
  clearSentries: async ({ commit }) => {
    commit('CLEAR_SENTRIES')
  },
  activateSensor: async ({ commit }, payload) => {
    commit('ACTIVATE_SENSOR', payload)
  },
  deactivateSensor: async ({ commit }, payload) => {
    commit('DEACTIVATE_SENSOR', payload)
  },
  clearSensors: async ({ commit }) => {
    commit('CLEAR_SENSORS')
  },
  activateGroup: async ({ commit }, payload) => {
    commit('ACTIVATE_GROUP', payload)
  },
  deactivateGroup: async ({ commit }, payload) => {
    commit('DEACTIVATE_GROUP', payload)
  },
  clearGroups: async ({ commit }) => {
    commit('CLEAR_GROUPS')
  },
  setActiveCamera: async ({ commit }, camera) => {
    commit('SET_ACTIVE_CAMERA', camera)
  }
}
