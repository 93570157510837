import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { INoteState } from './types'

const getDefaultState = () : INoteState => {
  return {
    notes: []
  }
}

const state: INoteState = getDefaultState()

const resetState = (state : INoteState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
