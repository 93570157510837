

































































































import Vue from 'vue'
import AdvancedFilterFormMap from '@/components/Dialogs/Filters/FilterForm/AdvancedFilterFormMap.vue'
import { IRfFilter, IRfFilterResponse } from '@/store/modules/rf_filters/types'
import AdvancedFilterForm, {
  IValueToleranceData
} from '@/components/Dialogs/Filters/FilterForm/AdvancedFilterForm.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EItemTypes } from '@/components/Dialogs/Filters/types'
import { deg2rad, rad2deg } from '@/utils/utils'

const props = {
  dialog: {
    type: Boolean,
    default: false
  },
  filter: {
    type: Object as () => IRfFilter,
    default: null
  },
  allowReadWrite: {
    type: Boolean,
    default: false
  }
}
export default Vue.extend({
  name: 'AdvancedFilterFormDialog',
  components: { AdvancedFilterForm, AdvancedFilterFormMap },
  props,
  inject: ['providedData'],
  data() {
    return {
      bearing: 0,
      bearingTolerance: 0,
      showBearing: true,
      showRetroDialog: false,
      newFilter: null,
      applyRetroResponse: null,
      retroError: null,
      activeFilter: null,
      apiCallInProgress: false
    }
  },
  computed: {
    ...mapGetters('sites', ['activeSiteId']),
    ...mapState('rf_filters', ['error']),
    selectedSensors(): number[] {
      return [this.sensor.id]
    },
    isDsx(): boolean {
      return this.sensor?.model.toLowerCase().includes('dsx') || false
    },
    itemType(): EItemTypes {
      return this.providedData.itemType
    },
    sensor() {
      return this.providedData.sensor
    },
    retroApplyTitle(): string {
      return `Do you want to apply the ${this.itemType} retrospectively?`
    },
    retroApplyMessage(): string {
      return `This process will apply the ${this.itemType} to previous analytics items that match.<br/><br/>This is a <span class="font-weight-bold red--text text--lighten-2">slow and demanding</span> process and can only be triggered one filter or whitelist at a time - <span class="font-weight-bold red--text text--lighten-2">this cannot be undone</span>.`
    },
    mapFilterBearingData() {
      return {
        value: this.bearing,
        tolerance: this.bearingTolerance
      }
    },
    applyRetroResponseMessage() {
      let message = 'An error occurred, please try again in a few minutes'

      if (this.applyRetroResponse.data) {
        message = this.applyRetroResponse?.data.message
      } else if (this.applyRetroResponse?.status === 422) {
        message =
          'Another Filter or Whitelist is elaborating the data, please try again in a few minutes'
      }

      if (this.applyRetroResponse?.status === 200) {
        return `<span class="font-weight-bold green--text text--lighten-2">${message}</span>`
      }
      return `<span class="font-weight-bold red--text text--lighten-2">${message}</span>`
    }
  },
  methods: {
    ...mapActions('rf_filters', {
      fetchFilters: 'FETCH_RF_FILTERS',
      updateFilter: 'UPDATE_FILTER',
      createFilter: 'CREATE_FILTER',
      triggerFilterRetroApplyForFusion: 'TRIGGER_FILTER_RETRO_APPLY_FOR_FUSION'
    }),
    onCancel(): void {
      this.$emit('onCancel')
    },
    onBearingChanged(payload: IValueToleranceData) {
      this.bearing = payload.value
      this.bearingTolerance = payload.tolerance
      this.showBearing = payload.visible
    },
    onSelectedSensorsChanged(payload: number[]) {
      this.$set(this, 'selectedSensors', payload)
    },
    onSectorsChanged(payload: IRfFilter) {
      this.bearing = payload.bearing
      this.bearingTolerance = payload.bearing_tolerance
    },
    onSaveClicked(filter): void {
      this.newFilter = {
        ...filter
      }
      this.showRetroDialog = true
    },
    doRetroApply(): void {
      this.doSave(this.newFilter, this.sensor.id, true)
    },
    dontRetroApply(): void {
      this.doSave(this.newFilter, null, false)
    },
    async doSave(
      rawFilter: IRfFilter,
      sensorId: number,
      retroApply: boolean = false
    ): Promise<void> {
      this.apiCallInProgress = true
      const filter = { ...rawFilter }

      let response = {} as IRfFilterResponse
      if (filter.id) {
        response = await this.updateFilter({
          id: filter.id,
          site_id: this.activeSiteId,
          filter: filter
        })
      } else {
        response = await this.createFilter({
          site_id: this.activeSiteId,
          filter: filter
        })
      }

      const { rf_filter, error } = response

      if (!error) {
        this.$emitter.emit('filtersUpdated')
        // fetch updated filters
        await this.fetchFilters({
          sensor_id: this.sensor.id,
          site_id: this.activeSiteId
        })
        this.$emitter.emit('update:dsx:wedge:data')

        if (retroApply) {
          this.apiCallInProgress = true
          this.applyRetroResponse = await this.triggerFilterRetroApplyForFusion(
            {
              id: rf_filter.id,
              site_id: this.activeSiteId
            }
          )

          if (this.applyRetroResponse.status === 200) {
            setTimeout(() => {
              this.onCancel()
            }, 500)
          } else {
            this.retroError =
              this.applyRetroResponse?.data ||
              'An error occurred while trying to apply retrospectively'
            this.apiCallInProgress = false
          }
        } else {
          this.onCancel()
          this.apiCallInProgress = false
        }
      } else {
        // error trying to create/update filter
        this.retroError = error
        this.apiCallInProgress = false
      }
    },
    cancelRetroDialog(): void {
      this.retroError = null
      this.applyRetroResponse = null
      this.showRetroDialog = false
    }
  },
  watch: {
    filter: {
      handler: function(val: IRfFilter) {
        this.$set(this, 'activeFilter', { ...val })
      },
      immediate: true
    }
  }
})
