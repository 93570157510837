






















































// @ts-nocheck
import Vue from 'vue'
import RadarZMaskForm from '@/components/Forms/Sensors/RadarZMaskForm.vue'
import MaskMap from '@/components/Map/MaskMap'
import RadarDetector from '@/components/Map/Detection/RadarDetector'
import { LatLonSpherical } from 'geodesy'
import { mapActions, mapGetters, mapState } from 'vuex'
import {ESiteMode} from "@/store/modules/sites/types";
import {
  ELEVATION_TOLERANCE,
  IMask,
  IMaskRequest,
  MAX_ELEVATION, MAX_RANGE, MAX_RCS, MIN_AZIMUTH,
  MIN_ELEVATION, MIN_RANGE, MIN_RCS, RANGE_TOLERANCE_METRES, RCS_TOLERANCE
} from '@/store/modules/radar_mask/types'

export default Vue.extend({
  name: 'RadarMaskFormDialog',
  components: {
    RadarZMaskForm,
    MaskMap,
    RadarDetector
  },
  data: (): any => {
    return {
      isFusion: false,
      apiRequest: false,
      buttonDisabled: false,
      maskDialog: false,
      sensor: null,
      sentry: null,
      radarDetections: {},
      data: null,
      zmask: null
    }
  },
  mounted(): void {
    this.$emitter.on('showRadarMaskDialog', this.showDialog)
    this.$emitter.on('hideRadarMaskDialog', this.hideDialog)
  },
  beforeDestroy(): void {
    this.$emitter.off('showRadarMaskDialog', this.showDialog)
    this.$emitter.off('hideRadarMaskDialog', this.hideDialog)
  },
  computed: {
    ...mapGetters('sites', ['activeSiteId', 'activeSite']),
    ...mapGetters('system', ['systemSetting']),
    ...mapState('sensors', ['status', 'radarZMasks']),
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapGetters('sensors', ['radarsById']),
    sentries() {
      return this.sentriesInSite(this.activeSiteId)
    },
    showMaskDialog: {
      get(): boolean {
        return this.maskDialog
      },
      set(val: boolean): void {
        this.maskDialog = val
        if (!val) {
          this.resetData()
        }
      }
    },
    additionalMaskNames(): string[] {
      if (Object.keys(this.radarZMasks).length === 0 || this.sensor == null) {
        return []
      }

      return this.radarZMasks[this.sensor.id]?.map((mask: IMask) => {
        return mask.name
      })
    },
    disableForm() {
      return this.activeSite.mode !== ESiteMode.Calibration
    },
    invalidForm() {
      return !this.zmask.name
    }
  },
  methods: {
    ...mapActions('sensors', ['UPDATE_ZMASK']),
    async saveMask(): void {
      const mask = { id: this.zmask['id'], name: this.zmask['name'] }

      Object.assign(mask, {
        azimuth_min: this.zmask.azimuth[0],
        azimuth_max: this.zmask.azimuth[1],
        elevation_min: this.zmask.elevation[0],
        elevation_max: this.zmask.elevation[1],
        range_min: this.zmask.range[0],
        range_max: this.zmask.range[1],
        rcs_min: this.zmask.rcs[0],
        rcs_max: this.zmask.rcs[1],
        hard_mask: this.zmask.hard_mask
      })

      this.buttonDisabled = true
      this.apiRequest = true
      await this.UPDATE_ZMASK({ radar: this.sensor, mask })

      if (this.status === 'OK') {
        setTimeout(() => {
          this.buttonDisabled = false
          this.apiRequest = false
          this.hideDialog()
        }, 250)
      }
    },
    resetData(): void {
      this.sensor = null
      this.sentry = null
      this.zmask = this.generateMask()
      this.apiRequest = false
      this.buttonDisabled = false
    },
    updateRadarDetections(detections): void {
      this.radarDetections = detections
    },
    findSentryById(id) {
      return this.sentries.find(sentry => {
        return sentry.id === id
      })
    },
    generateMask(): IMask {
      return {
        id: null,
        azimuth: [0, 0],
        elevation: [0, 0],
        range: [MIN_RANGE, MAX_RANGE],
        rcs: [MIN_RCS, MAX_RCS],
        hard_mask: false,
        name: ''
      }
    },
    updateMask() {
      const zmask = this.generateMask()
      // update zmask
      if (this.data.rcs != null) {
        let minRcs = this.data.rcs - RCS_TOLERANCE
        if (minRcs < MIN_RCS) {
          minRcs = MIN_RCS
        }
        let maxRcs = this.data.rcs + RCS_TOLERANCE
        if (maxRcs > MAX_RCS) {
          maxRcs = MAX_RCS
        }
        zmask.rcs = [Math.ceil(minRcs), Math.ceil(maxRcs)]
      }

      if (this.data.latitude != null && this.data.longitude != null) {
        const sensorPosition = LatLonSpherical(
          this.sensor.latitude,
          this.sensor.longitude
        )
        const detectionPosition = LatLonSpherical(
          this.data.latitude,
          this.data.longitude
        )

        const distance = Math.round(
          sensorPosition.distanceTo(detectionPosition)
        )

        let minRange = distance - RANGE_TOLERANCE_METRES
        if (minRange < MIN_RANGE) {
          minRange = MIN_RANGE
        }
        let maxRange = distance + RANGE_TOLERANCE_METRES
        if (maxRange > MAX_RANGE) {
          maxRange = MAX_RANGE
        }
        zmask.range = [minRange, maxRange]

        let azimuth = sensorPosition.bearingTo(detectionPosition)
        azimuth -= this.sensor.direction
        azimuth = ((azimuth + 180) % 360) - 180

        const azimuthTolerance =
          (Math.atan(RANGE_TOLERANCE_METRES / distance) * 180) / Math.PI

        let minAzimuth = azimuth - azimuthTolerance
        if (minAzimuth < MIN_AZIMUTH) {
          minAzimuth = MIN_AZIMUTH
        }

        let maxAzimuth = azimuth + azimuthTolerance
        if (maxAzimuth < MIN_AZIMUTH) {
          maxAzimuth = MIN_AZIMUTH
        }
        zmask.azimuth = [Math.floor(minAzimuth), Math.ceil(maxAzimuth)]
      }

      if (this.data.elevation != null) {
        let minElevation = this.data.elevation - ELEVATION_TOLERANCE
        if (minElevation < MIN_ELEVATION) {
          minElevation = MIN_ELEVATION
        }
        let maxElevation = this.data.elevation + ELEVATION_TOLERANCE
        if (maxElevation > MAX_ELEVATION) {
          maxElevation = MAX_ELEVATION
        }
        zmask.elevation = [Math.floor(minElevation), Math.ceil(maxElevation)]
      }
      this.zmask = zmask
    },
    showDialog(data: IMaskRequest): void {
      this.data = data.data
      this.sensor = this.radarsById(data.sensor[0].sensor_id)
      this.sentry = this.findSentryById(this.sensor.sentry_id)
      this.updateMask()
      this.showMaskDialog = true
    },
    hideDialog(): void {
      this.resetData()
      this.showMaskDialog = false
    }
  }
})
