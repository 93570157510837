import {
  IRfFilter,
  IRfFilterState,
  IRfFilterResponseList
} from '@/store/modules/rf_filters/types'
import Vue from 'vue';

export default {
  SET_STATUS: (state: IRfFilterState, status: string) =>
    (state.status = status),
  SET_ERROR: (state: IRfFilterState, error: any) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  SET_RF_FILTERS: (state: IRfFilterState, payload: IRfFilterResponseList) => {
    const sensor_id: number = payload.sensor_id
    if (payload.rf_filters.length > 0) {
      state.rf_filters = Object.assign({}, state.rf_filters, { [sensor_id]: payload.rf_filters as IRfFilter[]})
    }
    else {
      Vue.delete(state.rf_filters, sensor_id)
    }
  },
  CLEAR_RF_FILTERS: (state: IRfFilterState) => {
    state.rf_filters = {}
  }
}
