











import Vue from 'vue'
import { TPositionTuple } from '@/store/modules/maps/types'
import { LPolyline } from 'vue2-leaflet'
import {
  COLOR_FRIEND,
  COLOR_THREAT,
  EDetectionState,
  IDetection
} from '@/store/modules/detection/types'
import { isNullOrUndefined } from '@/utils/utils'

const props = {
  detection: {
    type: Object as () => IDetection,
    required: true
  },
  dashArray: {
    type: String,
    default: '5 3 1 3'
  },
  lineWeight: {
    type: Number,
    default: 1
  }
}
export default Vue.extend({
  name: 'Speedleader',
  props,
  components: {
    LPolyline
  },
  computed: {
    isThreat(): boolean {
      return this.detection.state !== EDetectionState.Whitelisted
    },
    color(): string {
      return this.isThreat ? COLOR_THREAT : COLOR_FRIEND
    },
    showSpeedleader(): boolean {
      if ((isNullOrUndefined(this.detection.speed_leader_latitude) || isNullOrUndefined(this.detection.speed_leader_longitude))) return false
      return (
        this.detection.speed_leader_latitude !== 0 &&
        this.detection.speed_leader_longitude !== 0
      )
    },
    speedLeaderLatLngs(): TPositionTuple[] {
      return [
        [this.detection.latitude, this.detection.longitude],
        [
          this.detection.speed_leader_latitude,
          this.detection.speed_leader_longitude
        ]
      ]
    }
  }
})
