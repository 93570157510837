import { LatLonSpherical } from 'geodesy'
import { point } from '@turf/helpers'
import bearing from '@turf/bearing'

const bearingLine = ({ vertex = [0, 0], reach, direction = 0 }) => {
  const origin = new LatLonSpherical(vertex[0], vertex[1])
  const { lat, lon: lng } = origin.destinationPoint(reach, direction)
  return [
    [vertex[0], vertex[1]],
    [lat, lng]
  ]
}

const fixLongitudeOverflow = (computedLng, initialLng) => {
  return (
    360 *
      (Math.trunc((initialLng + 180) / 360) -
        (initialLng < -180 && initialLng > -540 ? 1 : 0)) +
    computedLng
  )
}

const getArcPath = (vertex, radiusMeters, startAngle, endAngle) => {
  const points = []
  startAngle %= 360
  endAngle %= 360

  let numDegrees = endAngle - startAngle

  if (numDegrees < 0) {
    numDegrees += 360
  }

  const center = new LatLonSpherical(vertex.lat, vertex.lng)

  for (let i = 0; i <= numDegrees; i++) {
    const a = (startAngle + i) % 360
    const { lat, lon } = center.destinationPoint(radiusMeters, a)
    const lng = fixLongitudeOverflow(lon, parseFloat(vertex.lng))
    points.push({ lat, lng })
  }

  return points
}

const makeArcPath = (vertex, reach, startAngle, endAngle, range360) => {
  const path = getArcPath(vertex, reach, startAngle, endAngle) || []
  if (!range360) {
    path.unshift(vertex)
  }
  path.push(path[0])
  return path.map(({ lat, lng }) => [parseFloat(lat), parseFloat(lng)])
}

const makeArcPathFromRfDetection = ({
  azimuth_std_rad,
  azimuth_rad,
  vertex,
  show_sector_as_line,
  reach
}) => {
  const delta = show_sector_as_line ? 0.001 : azimuth_std_rad / 2

  const startAngle = ((180 * (azimuth_rad - delta)) / Math.PI) % 360
  const endAngle = ((180 * (azimuth_rad + delta)) / Math.PI) % 360

  const arcPath = makeArcPath(vertex, reach, startAngle, endAngle)
  return arcPath
}

const makeArcPathFromDiscovairDetection = ({
  azimuth,
  vertex,
  show_sector_as_line,
  reach
}) => {
  const detectionAngle = 10
  const delta = show_sector_as_line ? 0.001 : detectionAngle / 2

  const startAngle = azimuth - (delta % 360)
  const endAngle = azimuth + (delta % 360)

  const arcPath = makeArcPath(vertex, reach, startAngle, endAngle)
  return arcPath
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

function rad2deg(rad) {
  return rad * (180 / Math.PI)
}

const earthDistanceInMeters = (lat1, lng1, lat2, lng2) => {
  var R = 6371 // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lng2 - lng1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // distance in km
  return d * 1000 // distance in meters
}

const drawCannonSector = cannon => {
  const vertex = { lat: cannon.latitude, lng: cannon.longitude }
  const inner_radius = 0
  const outer_radius = cannon.reach
  const start_angle = (cannon.direction - cannon.angle / 2) % 360
  const end_angle = (cannon.direction + cannon.angle / 2) % 360

  const outer_arc = getArcPath(vertex, outer_radius, start_angle, end_angle)
  const inner_arc = getArcPath(
    vertex,
    inner_radius,
    start_angle,
    end_angle
  ).reverse()

  return outer_arc.concat(inner_arc)
}

const drawMaskSector = ({ latitude, longitude, direction, azimuth, range }) => {
  const vertex = { lat: latitude, lng: longitude }
  const inner_radius = range[0]
  const outer_radius = range[1]
  const start_angle = (direction - azimuth[1]) % 360
  const end_angle = (direction - azimuth[0]) % 360

  const outer_arc = getArcPath(vertex, outer_radius, start_angle, end_angle)
  const inner_arc = getArcPath(
    vertex,
    inner_radius,
    start_angle,
    end_angle
  ).reverse()

  return outer_arc.concat(inner_arc)
}

const calculateWD = (AFOV, HFOV = 50) => {
  return HFOV / (2 * Math.tan(deg2rad(AFOV / 2)))
}

const calculateZoom = (AFOVmin, AFOVmax) => {
  return Math.tan(deg2rad(AFOVmax / 2)) / Math.tan(deg2rad(AFOVmin / 2))
}

const calculateAFOV = (z, AFOVmin, AFOVmax) => {
  const b = Math.tan(deg2rad(AFOVmin / 2))
  const a = Math.tan(deg2rad(AFOVmax / 2))
  return rad2deg(2 * Math.atan((a * b) / (z * z * a - z * b + b)))
}

const sensorDragDirection = (sensor, coords) => {
  const { latitude, longitude } = sensor || {}
  const { lat, lng } = coords || {}
  const turfAngle = bearing(point([longitude, latitude]), point([lng, lat]))
  return turfAngle >= 0 ? turfAngle : 360 + turfAngle
}

export default {
  bearingLine,
  getArcPath,
  makeArcPath,
  makeArcPathFromRfDetection,
  makeArcPathFromDiscovairDetection,
  earthDistanceInMeters,
  drawCannonSector,
  drawMaskSector,
  calculateWD,
  calculateZoom,
  calculateAFOV,
  sensorDragDirection
}
