import { IDetection } from '@/store/modules/detection/types'

export type TColor = string
export type TPathData = string

export const FREQUENCY_DECIMALS = 6

export enum EItemTypes {
  FILTER = 'filter',
  WHITELIST = 'whitelist'
}

export enum ESliderTypes {
  FREQUENCY = 'frequency',
  BEARING = 'bearing'
}

export interface IFilterSelectItem {
  label: string
  value: string
  band?: string
}

export interface ISlice {
  id?: number
  startAngle: number
  endAngle: number
  color?: TColor
  tooltip?: string
  label?: string
}

export interface IDsxPreviewObject {
  bearing: number
  bearing_tolerance: number
}

export interface ILimitItem {
  min: number
  max: number
}

export type FrequencyLimits = Record<number, ILimitItem>
export const FREQUENCY_LIMITS: FrequencyLimits = {
  433: {
    min: 427280000,
    max: 447680000
  },
  868: { min: 856280000, max: 869690000 },
  915: { min: 900280000, max: 938680000 },
  1200: { min: 1003270000, max: 1153240000 },
  2400: {
    min: 2363280000,
    max: 2516670000
  },
  5200: { min: 5093770000, max: 5355190000 },
  5800: { min: 5638280000, max: 5951690000 }
}

export interface ISliderDataValue {
  min: number
  max: number
  highlighted: boolean
}

export interface ISupportedDetectionProtocol {
  enabled: boolean
  protocol: string
  vendor: string
}

export interface ISupportedDetection {
  enabled: boolean
  protocols: ISupportedDetectionProtocol[]
  rfai_sub_band: string
}

export interface IDetectionDataForFilter {
  detection: IDetection
  sensorSelection: unknown[]
  itemType: EItemTypes
}

export interface IVendorProtocolListItem {
  band: string
  protocol: string
  vendor: string
}
