































import Vue from 'vue'
import { ESiteMode } from '@/store/modules/sites/types'
import { mapActions, mapGetters } from 'vuex'

const props = {
  enabled: {
    type: Boolean,
    default: true
  }
}

export default Vue.extend({
  name: 'SiteModeSwitcher',
  props,
  data() {
    return {
      apiSuccess: true,
      currentSiteMode: null,
      previousSiteMode: null,
      siteModes: [
        { value: ESiteMode.Operational, text: 'Operational' },
        { value: ESiteMode.Monitor, text: 'Monitor' },
        { value: ESiteMode.Calibration, text: 'Calibration' }
      ]
    }
  },
  mounted() {
    this.currentSiteMode = this.siteMode
  },
  computed: {
    ...mapGetters('sites', { activeSite: 'activeSite' }),
    iconStyle() {
      return {
        visibility: this.apiSuccess ? 'hidden' : 'visible'
      }
    },
    siteMode(): string {
      return this.activeSite?.mode
    }
  },
  methods: {
    ...mapActions('sites', {
      switchSiteMode: 'switchSiteMode',
      FETCH_SITES: 'FETCH_SITES'
    }),
    async onModeChange() {
      let result = true
      try {
         result = await this.switchSiteMode({
          siteId: this.activeSite.id,
          mode: this.currentSiteMode
        })
        if (!result) {
          this.currentSiteMode = this.previousSiteMode
        }
      } catch (e) {
        this.currentSiteMode = this.previousSiteMode
      } finally {
        this.apiSuccess = result
      }
    }
  },
  watch: {
    siteMode: {
      handler: function(mode: string) {
        this.previousSiteMode = this.siteMode
        this.currentSiteMode = mode
      },
      immediate: true
    }
  }
})
