import { v4 as uuidv4 } from 'uuid'
const parseMessage = ({ message }) => {
  const { space = '', action = '', serial = null } = message || {}
  return serial ? 'IDENTITY' : `${space.toUpperCase()}_${action.toUpperCase()}`
}

export interface IConfig {
  url: string
  eventPrefix?: string
  pingInterval?: number
  reconnect?: boolean
  protocols?: any
}

interface IWebsocketMessage {
  command: string
  identifier?: IIdentifier | string
  token?: string
}

interface IIdentifier {
  channel: string
  site_id?: number
}

let defaultConfig: IConfig = {
  url: 'ws://localhost/cable',
  eventPrefix: 'SOCKET',
  pingInterval: 5000, //5 seconds
  reconnect: true
}

class socketManager {
  config: IConfig
  $bus: any
  subscribed: boolean = false
  socket: WebSocket
  reconnectTimeout: number = null
  pingTimeout: number = null
  connected: boolean = false
  id: string
  token: string = null
  subscribed_site: number = null
  channel: string
  site_id: number = null

  constructor(config = defaultConfig, bus, token: string) {
    try {
      this.id = uuidv4()
      this.config = { ...defaultConfig, ...config }
      this.$bus = bus
      this.setSocket()
      this.subscribed = false
      this.token = token
    } catch (err) {
      console.error(err)
    }
  }

  setSocket(): void {
    clearTimeout(this.reconnectTimeout)
    this.socket = new WebSocket(this.config.url, this.config.protocols)
    this.socket.addEventListener('message', event => {
      let data = JSON.parse(event.data)
      if (data.type === 'ping') {
        return this.heartbeat()
      }

      if (data.type === 'confirm_subscription') {
        this.subscribed = true
        let identifier = {} as IIdentifier
        if (this.config.eventPrefix === 'ALERTS') {
          identifier = data.identifier
        } else {
          identifier = JSON.parse(data.identifier)
        }
        if (identifier.channel === 'SitesChannel') {
          this.subscribed_site = identifier.site_id
        }
      }

      const endPoint = parseMessage(data)
      this.$bus.$emit(`${this.config.eventPrefix}/${endPoint}`, data)
    })

    this.socket.onclose = e => {
      this.connected = false
      this.subscribed = false
      this.subscribed_site = null
      this.reconnectTimeout = setTimeout(() => {
        this.setSocket()
      }, 1000)
      return this.$bus.$emit(`${this.config.eventPrefix}/CLOSE`, event)
    }

    this.socket.onerror = err => {
      this.connected = false
      this.subscribed = false
      this.subscribed_site = null
      this.socket.close()
      this.$bus.$emit(`${this.config.eventPrefix}/ERROR`, err)
    }

    this.socket.onopen = event => {
      this.connected = true
      clearTimeout(this.reconnectTimeout)
      if (this.config.eventPrefix === 'ALERTS') this.authenticate()
      if (!this.subscribed && this.channel) this.subscribe(this.channel, this.site_id)
      this.$bus.$emit(`${this.config.eventPrefix}/OPEN`, event)
    }
  }

  heartbeat(): void {
    clearTimeout(this.pingTimeout)
    this.pingTimeout = setTimeout(() => {
      this.socket.close()
      this.$bus.$emit(
        `${this.config.eventPrefix ||
          defaultConfig.eventPrefix}/CONNECTION_CLOSED`
      )
      if (this.config.reconnect) this.setSocket()
    }, this.config.pingInterval)
  }

  private generateWebsocketData(
    type: string,
    channel: string,
    id: number = null,
  ): IWebsocketMessage {
    switch (channel) {
      case 'AlertsChannel':
        return {
          command: type, identifier: {channel: channel}
        }
      case 'SitesChannel':
        let identifier: IIdentifier
        id != null ?
          identifier = {channel: channel, site_id: id} :
          identifier = {channel: channel}

        // Must stringify the identifier because we are still using ruby websocket for SitesChannel
        return {
          command: type, identifier: JSON.stringify(identifier)
        }
      default:
        console.error(`Channel ${channel} not supported`)
    }
  }

  authenticate(): void {
    let authMessage: IWebsocketMessage = {
      command: 'authenticate',
      token: this.token,
    }

    this.socket.send(JSON.stringify(authMessage))
  }

  subscribe(channel: string, id: number = null): void {
    this.channel = channel
    this.site_id = id
    this.socket.send(
      JSON.stringify(this.generateWebsocketData('subscribe', channel, id))
    )
  }

  unsubscribe(channel: string, id: number = null): void {
    this.socket.send(
      JSON.stringify(this.generateWebsocketData('unsubscribe', channel, id))
    )
    this.subscribed = false
    this.subscribed_site = null
  }
}

export default socketManager
