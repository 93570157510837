// @ts-ignore
import actions from './actions.ts'
// @ts-ignore
import getters from './getters.ts'
// @ts-ignore
import mutations from './mutations.ts'
import {ISiteWarningState} from "@/store/modules/site_warnings/types";

const getDefaultState = (): ISiteWarningState => {
  return {
    site_warnings: [],
    site_warnings_history: [],
    clearSiteWarningsLoading: false,
    clearingSiteWarnings: false
  }
}

const state = getDefaultState()

//todo add corect state
const resetState = (state: any) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
