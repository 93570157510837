import { ISentry } from '@/store/modules/sentries/types'
import {
  buildSensorObject,
  IRfSensor,
  IRfSensorState,
  isDsxModelJamming
} from '@/store/modules/rf_sensors/types'
import { ICameraState } from '@/store/modules/cameras/types'

export default {
  status: (state: IRfSensorState): string => state.status,
  error: (state: IRfSensorState): string => state.error,
  activeRfSensorId: (state: IRfSensorState): number => state.activeRfSensorId,
  rfSensorsSet: (state: IRfSensorState): Record<number, IRfSensor> =>
    state.rfSensorsSet,
  dsxSensorsSet: (state: IRfSensorState): Record<number, IRfSensor> =>
    state.dsxSensorsSet,
  rfSensorById: (state: IRfSensorState) => (id: number): IRfSensor => {
    return (state.rfSensorsSet || {})[id]
  },
  dsxSensorById: (state: IRfSensorState) => (id: number): IRfSensor => {
    return (state.dsxSensorsSet || {})[id]
  },
  rfSensorsInSite: (state: IRfSensorState) => (
    site: number,
    sentries: ISentry[]
  ): Record<string, IRfSensor> => {
    return buildSensorObject(site, sentries, state.rfSensorsSet)
  },
  dsxSensorsInSite: (state: IRfSensorState) => (
    site: number,
    sentries: ISentry[]
  ): Record<string, IRfSensor> => {
    return buildSensorObject(site, sentries, state.dsxSensorsSet)
  },
  allRfSensorsInSentry: (state: IRfSensorState) => (
    sentryId: number
  ): IRfSensor[] => {
    return Object.values(state.rfSensorsSet).filter(
      (rfSensor: IRfSensor) => rfSensor.sentry_id === sentryId
    )
  },
  allDsxSensorsInSentry: (state: IRfSensorState) => (
    sentryId: number
  ): IRfSensor[] => {
    return Object.values(state.dsxSensorsSet).filter(
      (rfSensor: IRfSensor) => rfSensor.sentry_id === sentryId
    )
  },
  hasRfSensor: (state: IRfSensorState): boolean => {
    return (
      (state.rfSensorsSet && Object.keys(state.rfSensorsSet).length > 0)
      || (state.dsxSensorsSet && Object.keys(state.dsxSensorsSet).length > 0)
    )
  },
  rfSensorsLength: (state: IRfSensorState): number => {
    return (state.rfSensorsSet && Object.keys(state.rfSensorsSet).length) || 0
  },
  dsxSensorsLength: (state: IRfSensorState): number => {
    return (state.dsxSensorsSet && Object.keys(state.dsxSensorsSet).length) || 0
  },
  allProtocols: (state: IRfSensorState): string[] => {
    const defaults = [
      'No Protocol',
      'OS OFDM',
      'WBFM',
      'WiFi',
      'LB OFDM',
      'ACCESS ACCST',
      'DJI Ocusync',
      'LightBridge FHSS',
      'LightBridge OFDM'
    ]
    const protocols = Array.from(
      new Set(
        Object.values(state.rfSensorsSet)
          .concat(Object.values(state.dsxSensorsSet))
          .map((sensor: IRfSensor) => {
            return Object.values(sensor?.supported_detections || {})
              .flatMap(freq => freq.protocols)
              .flatMap(protocol => (protocol ? protocol.protocol : []))
          })
          .flat()
          .sort()
      )
    )
    return protocols.length > 0 ? protocols : defaults
  },
  allVendors: (state: IRfSensorState): string[] => {
    const defaults = [
      'No Vendor',
      'FPV',
      'DJI',
      'DJI AUT XIA',
      'Skydio Inc',
      'Yuneec',
      'Parrot',
      'Xiaomi'
    ]
    const vendors = Array.from(
      new Set(
        Object.values(state.rfSensorsSet)
          .concat(Object.values(state.dsxSensorsSet))
          .map((sensor: IRfSensor) => {
            return Object.values(sensor?.supported_detections || {})
              .flatMap(freq => freq.protocols)
              .flatMap(protocol => (protocol ? protocol.vendor : []))
          })
          .flat()
          .sort()
      )
    )
    return vendors.length > 0 ? vendors : defaults
  },
  dsxJammers: (state: IRfSensorState): Record<number, IRfSensor> => {
    return Object.values(state.dsxSensorsSet).filter(s =>
      isDsxModelJamming(s.model)
    )
  }
}
