import actions from './actions'
import mutations from './mutations'

import { ISiteMarker } from './types'

export interface ISiteMarkersState {
  site_markers: ISiteMarker[],
  showMarkers: Boolean
}

const getDefaultState = (): ISiteMarkersState => {
  return {
    site_markers: [],
    showMarkers: true
  }
}

const state = getDefaultState()

const resetState = (state: ISiteMarkersState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: { ...mutations, resetState }
}
