export enum EDroneCommandStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  QUEUED = 'queued',
  IDLE = 'Idle'
}
export enum EZATFlightMode {
  Unknown = 'unknown',
  Ready = 'ready',
  Takeoff = 'takeoff',
  Hold = 'hold',
  Mission = 'mission',
  ReturnToLaunch = 'rtl',
  Land = 'land',
  OffBoard = 'off_board',
  FollowMe = 'follow_me',
  Manual = 'manual',
  AltitudeControl = 'altitude',
  PositionControl = 'posctl',
  Acro = 'acro',
  Stabilized = 'stabilized',
  Rattitude = 'rattitude'
}

export enum EZATFlightErrors {
  LowVolt = 'Too Low In Volt',
  HighVolt = 'Too High In Volt',
  MotorFail = 'Motor Fail',
  LoadCellFail = 'Load Cell Fail',
  CableLimit = 'Cable Limit'
}

export enum EZATFlightState {
  OnGround = 'on_ground',
  InAir = 'in_air',
  TakingOff = 'taking_off',
  Landing = 'landing',
  Rotating = 'rotating',
  Moving = 'moving'
}

export const flightStateMapping: any = {
  on_ground: 'On Ground',
  in_air: 'In Air',
  taking_off: 'Taking Off',
  landing: 'Landing',
  moving: 'Moving',
  rotating: 'Rotating'
}

export enum EZATFlightCommands {
  Takeoff = EZATFlightMode.Takeoff,
  Land = EZATFlightMode.Land,
  ReturnToLaunch = EZATFlightMode.ReturnToLaunch,
  AltitudeControl = EZATFlightMode.AltitudeControl
}

export enum EZATGPSStatuses {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected'
}

export interface ITelemetryData {
  flight_mode: EZATFlightMode | string
  flight_time: number
  gps_sats: number
  wattage: number
  voltage: number
  altitude: number
  heading?: number
  gps_status: string
  status: string
  errors: EZATFlightErrors[]
}

export interface IData {
  imperialUnit: boolean
  flightSettingsDialogOpen: boolean
  command_status_data: {
    status: EDroneCommandStatus
    color: string
  }
}

export enum EResponseStatus {
  SUCCESS = 'success',
  QUEUED = 'queued',
  FAIL = 'failed',
  WAITING = 'waiting'
}

export interface IDroneMCUUnit {
  flight_mode: string
  flight_time: number
  gps_sats: number
  gps_status: string | null
  status: string | null
  status_message: string | null
  state: EZATFlightState
  voltage: number | null
  wattage: number | null
  id: number | null
  altitude: number | null
  failures: Array<string>
}

export interface ICommand {
  last_command: any
  status: string
}

export interface IDroneMCUUnitsState {
  drone_mcu_unit: IDroneMCUUnit
  commands: ICommand
  demo_mode: boolean
}

export enum EDroneMCUUnitsMutations {
  setUnitValues = 'SET_DRONE_MCU_UNITS',
  setCommandStatus = 'SET_DRONE_COMMAND_STATE'
}

export interface IDroneCommandObject {
  device_type: string
  device_id: number //drone_mcu_id,
  command: string | undefined
  is_manual?: boolean | undefined
  parameters: { key: string; value: string | number }[]
}

export enum EDeviceType {
  Drone = 'drone_mcu_unit',
  Cannon = 'cannon'
}

export interface IDroneFlightSettings {
  ip: string
  body: Object
}
