import { Commit, Dispatch } from 'vuex'
import { apiRequest } from '@/store/utils'
import {
  IRfFilter,
  IRfFilterListResponse,
  IRfFilterResponse,
  IRfApplyRetroResponse,
  IErrorResponse
} from '@/store/modules/rf_filters/types'

interface FetchRfFiltersArguments {
  site_id: number
  sensor_id: number
}

interface CreateRfFiltersArguments {
  site_id: number
  filter: IRfFilter
}

interface UpdateRfFiltersArguments {
  site_id: number
  id: number
  filter: IRfFilter
}

interface DeleteRfFiltersArguments {
  site_id: number
  id: number
}

interface RfFilterIdAndSiteIdsArguments {
  site_id: number
  id: number
}

export default {
  CLEAR_STATUS: ({ commit }: { commit: Commit }) => {
    commit('SET_STATUS', null)
    commit('SET_ERROR', null)
  },
  FETCH_RF_FILTERS: async (
    { commit }: { commit: Commit },
    args: FetchRfFiltersArguments
  ): Promise<IRfFilterListResponse> => {
    return await apiRequest(
      'get',
      `/api/sites/${args.site_id}/rf_sensors/${args.sensor_id}/rf_filters`,
      {},
      (data, _) => {
        data.sensor_id = args.sensor_id
        commit('SET_RF_FILTERS', data)
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return { rf_filters: [], sensor_id: args.sensor_id } as IRfFilterListResponse
      }
    )
  },
  CREATE_FILTER: async (
    { commit }: { commit: Commit },
    args: CreateRfFiltersArguments
  ): Promise<IRfFilterResponse> => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      `/api/sites/${args.site_id}/rf_filters`,
      { rf_filter: args.filter },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        return { ...data, error: null } as IRfFilterResponse
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return { rf_filter: null, error: data.error } as IRfFilterResponse
      }
    )
  },
  UPDATE_FILTER: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: UpdateRfFiltersArguments
  ): Promise<IRfFilterResponse> => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `/api/sites/${args.site_id}/rf_filters/${args.id}`,
      { rf_filter: args.filter },
      (data, _) => {
        commit('SET_STATUS', 'OK')
        return { ...data, error: null } as IRfFilterResponse
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return { rf_filter: null, error: data.error } as IRfFilterResponse
      }
    )
  },
  DELETE_FILTER: async (
    { commit }: { commit: Commit },
    args: DeleteRfFiltersArguments
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'delete',
      `/api/sites/${args.site_id}/rf_filters/${args.id}`,
      {},
      _ => {
        commit('SET_STATUS', 'OK')
        return {
          success: true,
          error: null
        }
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return {
          success: false,
          error: data.error
        }
      }
    )
  },
  TRIGGER_FILTER_RETRO_APPLY_FOR_FUSION: async (
    { commit },
    args: RfFilterIdAndSiteIdsArguments
  ) => {
    return await apiRequest(
      'post',
      `/api/sites/${args.site_id}/rf_filters/${args.id}/apply_retrospectively`,
      {},
      (data: IRfApplyRetroResponse, status) => {
        commit('SET_STATUS', 'OK')
        return { data: data.message, status: status }
      },
      (data: IErrorResponse, status) => {
        return { data: data.error, status: status }
      }
    )
  },
  clearFilters: ({ commit }) => {
    commit('CLEAR_RF_FILTERS')
  }
}
