










































import Vue from 'vue'

const props = {
  title: {
    type: String,
    default: null
  },
  text: {
    type: String,
    default: null
  },
  negativeLabel: {
    type: String,
    default: 'No'
  },
  positiveLabel: {
    type: String,
    default: 'Yes'
  },
  value: {
    type: Boolean,
    default: false
  },
  positiveClass: {
    type: String,
    default: 'positive-confirmation-button'
  },
  negativeClass: {
    type: String,
    default: 'negative-confirmation-button'
  },
  error: {
    type: String,
    default: null
  },
  info: {
    type: String,
    default: null
  },
  disableButtons: {
    type: Boolean,
    default: false
  },
  apiCallInProgress: {
    type: Boolean,
    default: false
  }
}

export default Vue.extend({
  name: 'SimpleConfirmationDialog',
  props,
  methods: {
    positiveClicked(): void {
      this.$emit('positive')
    },
    negativeClicked(): void {
      this.$emit('negative')
    }
  }
})
