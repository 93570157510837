import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import jwt_decode from 'jwt-decode'
import moment from 'moment-timezone'

Vue.use(Router)

const checkTokenExpiration = () => {
  const userData = JSON.parse(localStorage.getItem('user-token')) || null
  const token = userData?.token
  if (token) {
    // check if token is expired
    const decoded = jwt_decode(token)
    const tokenExp = moment.unix(decoded.exp)
    if (moment().isAfter(tokenExp)) {
      store.dispatch('auth/AUTH_LOGOUT')
    }
  }
}

const ifAuthenticated = (to, from, next) => {
  checkTokenExpiration()
  store.commit('plans/STOP_PLANNING_TOOL', to)
  const isAuthenticated = store.getters['auth/isAuthenticated']
  if (isAuthenticated) {
    const hasDataLoaded = store.getters['system/hasDataLoaded']
    if (hasDataLoaded) {
      return next()
    } else {
      return next('/dataloader')
    }
  } else {
    return next('/login')
  }
}

const ifAuthorised = credential => (to, from, next) => {
  checkTokenExpiration()
  store.commit('plans/STOP_PLANNING_TOOL', to)
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const isAuthorized = store.getters['users/isAuthorized'](credential)
  return isAuthenticated && isAuthorized ? next() : next('/')
}

const DataLoader = () =>
  import(
    /* webpackChunkName: "group-data-loader" */ /* webpackPrefetch: true */ '../views/DataLoaderView.vue'
  )

const Dashboard = () =>
  import(
    /* webpackChunkName: "group-dashboard" */ /* webpackPrefetch: true */ '../views/Dashboard'
  )

const Sites = () => import(/* webpackChunkName: "sites" */ '../views/Sites.vue')

const NewSite = () =>
  import(/* webpackChunkName: "sites" */ '../components/Sites/addSite.vue')

const EditSite = () =>
  import(/* webpackChunkName: "sites" */ '../components/Sites/editSite.vue')

const Sensors = () =>
  import(/* webpackChunkName: "group-sensors" */ '../views/Sensors.vue')

const EditSensor = () =>
  import(
    /* webpackChunkName: "group-sensors" */ '../components/Sensors/editSensor.vue'
  )

const InstallationSettings = () =>
  import(
    /* webpackChunkName: "group-installations" */ '../components/Installations/InstallationSettings.vue'
  )

const LoginView = () =>
  import(/* webpackChunkName: "group-login" */ '../views/Login')

const InstallationsView = () =>
  import(/* webpackChunkName: "group-installations" */ '../views/Installations')

const CamerasView = () =>
  import(/* webpackChunkName: "group-cameras" */ '../views/Cameras')

const ZonesView = () =>
  import(/* webpackChunkName: "group-zones" */ '../views/Zones')

const ReportsView = () =>
  import(/* webpackChunkName: "group-report" */ '../views/Report')

const PlansView = () =>
  import(/* webpackChunkName: "group-plan" */ '../views/Plan')

const ControlPanelView = () =>
  import(/* webpackChunkName: "control-panel" */ '../views/ControlPanel')

const UsersView = () =>
  import(/* webpackChunkName: "group-users" */ '../views/Users')

const NotificationsView = () =>
  import(/* webpackChunkName: "group-notifications" */ '../views/Notifications')

const RegistrationView = () =>
  import(/* webpackChunkName: "group-registration" */ '../views/Registration')

const ResetPasswordView = () =>
  import(/* webpackChunkName: "group-res-passd" */ '../views/ResetPassword')

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Dashboard,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView,
      beforeEnter: (to, from, next) => {
        const isAuthenticated = store.getters['auth/isAuthenticated']
        return isAuthenticated ? next('/') : next()
      }
    },
    {
      path: '/installations',
      name: 'installations',
      component: InstallationsView,
      props: true,
      beforeEnter: ifAuthorised('operator')
    },
    {
      path: '/stream',
      name: 'Cameras',
      component: CamerasView,
      beforeEnter: ifAuthorised('guest')
    },
    {
      path: '/zones',
      name: 'Zones',
      component: ZonesView,
      beforeEnter: ifAuthorised('guest')
    },
    {
      path: '/report',
      name: 'Report',
      component: ReportsView,
      beforeEnter: ifAuthorised('guest')
    },
    {
      path: '/sites',
      name: 'sites',
      component: Sites,
      beforeEnter: ifAuthorised('site_manager'),
      children: [
        {
          path: 'new',
          component: NewSite,
          name: 'sites.new'
        },
        {
          path: ':id',
          component: EditSite,
          name: 'sites.edit',
          props: true
        }
      ]
    },
    {
      path: '/sensors',
      name: 'sensors',
      component: Sensors,
      beforeEnter: ifAuthorised('site_manager'),
      children: [
        {
          path: ':sensorType/new',
          component: EditSensor,
          name: 'sensors.new',
          props: true
        },
        {
          path: ':sensorType/:id',
          component: EditSensor,
          name: 'sensors.edit',
          props: true
        }
      ]
    },
    {
      path: '/installations/:id',
      component: InstallationSettings,
      name: 'installations.edit',
      props: true
    },
    {
      path: '/plans',
      name: 'Plans',
      component: PlansView,
      beforeEnter: ifAuthorised('guest')
    },
    {
      path: '/controlpanel',
      name: 'Control Panel',
      component: ControlPanelView,
      beforeEnter: ifAuthorised('admin')
    },
    {
      path: '/users-management',
      component: UsersView,
      beforeEnter: ifAuthorised('site_manager')
    },
    {
      path: '/notifications',
      component: NotificationsView,
      beforeEnter: ifAuthorised('site_manager')
    },
    {
      path: '/registration/:token',
      name: 'Registration',
      props: true,
      component: RegistrationView
    },
    {
      path: '/reset_password/:token',
      name: 'ResetPassword',
      props: true,
      component: ResetPasswordView
    },
    {
      path: '/dataloader/:id?',
      name: 'DataLoader',
      props: true,
      component: DataLoader
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  mode: 'history'
})

export default router
