import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IDetectionState } from './types'

export const getDefaultState = (): IDetectionState => {
  return {
    enabled: false,
    fusionDetections: {},
    selectedDetections: [],
    seenContributors: {},
    activeRfBeam: "",
    firstContributon: {
      radar: false,
      rf: false,
      camera: false
    }
  }
}

const state: IDetectionState = getDefaultState()

const resetState = (state: IDetectionState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
