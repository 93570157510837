import { Howl } from 'howler'
import { mapState, mapGetters } from 'vuex'
import arcPath from '@/components/Map/utils/arcPath'
import { isDsxModelDirectional } from '@/store/modules/rf_sensors/types'

export default {
  data: () => ({
    isDsxModelDirectional,
    radarsDetections: {},
    rfDetections: {},
    discovairDetections: {},
    rfIntersections: {},
    radarTargetIds: {},
    rfTargetIds: {},
    cameraDetections: {},
    cameraTargetIds: {},
    realDroneDetections: {},
    simulationDetections: {},
    beep: new Howl({
      src: require(`@/assets/sounds/beep1.mp3`),
      loop: false
    })
  }),
  computed: {
    ...mapState('users', ['user']),
    ...mapState('rf_sensors', ['dsxSensorsSet']),
    ...mapGetters('sites', ['siteCenter']),
    ...mapGetters('rf_sensors', ['rfSensorById', 'dsxSensorById']),
    ...mapGetters('cameras', ['cameraTrackedTarget']),
    $_detections() {
      return [
        this.rfDetections,
        this.radarsDetections,
        this.dsxDetections,
        this.rfIntersections,
        this.discovairDetections,
        this.cameraDetections,
        this.realDroneDetections,
        this.simulationDetections
      ]
    },
    isCameraDetecting() {
      return Object.keys(this.cameraDetections).length !== 0
    },
    // radar currently detecting what camera tracks
    isCameraDetectingRadarTrack() {
      return (
        this.isCameraDetecting &&
        this.radarsDetections.hasOwnProperty(this.cameraTrackedTarget)
      )
    },
    // rf intersection currently detecting what camera tracks
    isCameraDetectingRfIntersection() {
      return (
        this.isCameraDetecting &&
        this.rfIntersections.hasOwnProperty(this.cameraTrackedTarget)
      )
    },
    // rf currently detecting what camera tracks
    cameraDetectedRF() {
      return (
        this.isCameraDetecting &&
        Object.entries(this.rfDetections || {}).find(
          ([k, _]) => k.split(':')[0] === this.cameraTrackedTarget
        )
      )
    },
    $_isCameraDetectingTrack() {
      return {
        radar: this.isCameraDetectingRadarTrack,
        rfInt: this.isCameraDetectingRfIntersection,
        rf: this.cameraDetectedRF
      }
    }
  },
  methods: {
    $_clearRfSensorDetection(data) {
      const { rf_sensor_id, type } = data
      let detections =
        type === 'rf_sensors' ? this.rfDetections : this.dsxDetections
      const keys =
        Object.keys(detections).filter(
          k => k.split(':')[1] === rf_sensor_id.toString()
        ) || []
      keys.forEach(key => this.$delete(detections, key))
    },
    $_updateRadarRfDetections(detection) {
      // radar_rf detection do not update lat/lng
      // create an issue with the marker if a radar_rf
      // comes before radar detection
      delete detection.latitude
      delete detection.longitude

      const target_id = detection.radar_target_id // matches radar_target_stat_id for the related RadarDetection
      if (!target_id) return

      const history = this.radarsDetections[target_id]

      if (!history) return

      this.$set(this.radarsDetections, target_id, {
        ...history,
        ...detection,
        level: 1,
        association_timestamp: Date.now()
      })
    },
    $_updateRadarDetections(detection) {
      const {
        id,
        // target_id,
        radar_target_stat_id: target_id,
        latitude,
        longitude,
        rf_confirmed,
        radar_confirmed,
        max_track_age
      } = detection || {}
      if (!target_id) return
      const history = this.radarsDetections[target_id]

      this.radarTargetIds[target_id] = this.radarTargetIds[target_id]
        ? [...this.radarTargetIds[target_id], id]
        : [id]

      if (history) {
        let { timeout } = history

        if (timeout) {
          clearTimeout(timeout)
        }

        if (rf_confirmed) {
          detection.level = 1
        } else if (radar_confirmed) {
          detection.level = 4
        } else {
          delete history.rf
        }
      } else if (rf_confirmed || radar_confirmed) {
        if (this.user.play_alarm_sounds) this.beep.play()
      }

      if (latitude && longitude) {
        detection.latitude = parseFloat(latitude.toFixed(8))
        detection.longitude = parseFloat(longitude.toFixed(8))
      }

      this.$set(this.radarsDetections, target_id, {
        ...history,
        ...detection,
        target_id,
        timestamp: Date.now(),
        timeout: setTimeout(() => {
          this.$delete(this.radarsDetections, target_id)
          this.$delete(this.radarTargetIds, target_id)
        }, max_track_age * 1000 || 50000)
      })
    },
    $_updateDiscovairDetections(detection) {
      const { candidate_identifier, detector_identifier } = detection
      const key = `${candidate_identifier}:${detector_identifier}`
      const { sector_display_timeout, show_sector_as_line } =
        this.discovair_sensorById(detector_identifier) || {}

      const history = this.discovairDetections[key]

      let tail = [detection.id]
      let count = 1

      if (history) {
        // Some buffer to save some memory
        if (Date.now() - history.timestamp < 100) return
        if (history.timeout) {
          clearTimeout(history.timeout)
        }
        if (history.tail && Array.isArray(history.tail)) {
          tail = tail.concat(history.tail)
        }
        if (history.count) {
          count += history.count
        }
      }
      this.$set(this.discovairDetections, key, {
        ...history,
        ...detection,
        count,
        tail,
        show_sector_as_line,
        timestamp: Date.now(),
        timeout: setTimeout(() => {
          this.$delete(this.discovairDetections, key)
        }, sector_display_timeout * 1000 || 5000)
      })
    },
    $_updateDsxDetections(detection) {
      const { correlation_key, rf_sensor_id } = detection
      //the key user  std rad to distinguish front back left back
      const dsxSensor = this.dsxSensorsSet[rf_sensor_id]
      const isDirectional = this.isDsxModelDirectional(dsxSensor.model)
      const key = `${correlation_key}:${rf_sensor_id}`
      const { sector_display_timeout, show_sector_as_line } =
        this.dsxSensorById(rf_sensor_id) || {}
      const history = this.dsxDetections[key]

      let tail = [detection.id]
      let count = 1

      if (history) {
        // Some buffer to save some memory
        if (Date.now() - history.timestamp < 100) return
        if (history.timeout) {
          clearTimeout(history.timeout)
        }
        if (history.tail && Array.isArray(history.tail)) {
          tail = tail.concat(history.tail)
        }
        if (history.count) {
          count += history.count
        }
      }
      this.$set(this.dsxDetections, key, {
        ...history,
        ...detection,
        isDirectional,
        count,
        tail,
        show_sector_as_line,
        timestamp: Date.now(),
        timeout: setTimeout(() => {
          this.$delete(this.dsxDetections, key)
        }, sector_display_timeout * 1000 || 4000)
      })
    },
    $_updateRFDetections(detection) {
      const { correlation_key, rf_sensor_id } = detection
      const key = `${correlation_key}:${rf_sensor_id}`
      const { sector_display_timeout, show_sector_as_line } =
        this.rfSensorById(rf_sensor_id) || {}
      const history = this.rfDetections[key]

      let tail = [detection.id]
      let count = 1

      if (history) {
        // Some buffer to save some memory
        if (Date.now() - history.timestamp < 100) return
        if (history.timeout) {
          clearTimeout(history.timeout)
        }
        if (history.tail && Array.isArray(history.tail)) {
          tail = tail.concat(history.tail)
        }
        if (history.count) {
          count += history.count
        }
      }
      this.$set(this.rfDetections, key, {
        ...history,
        ...detection,
        count,
        tail,
        show_sector_as_line,
        timestamp: Date.now(),
        timeout: setTimeout(() => {
          this.$delete(this.rfDetections, key)
        }, sector_display_timeout * 1000 || 5000)
      })
    },
    $_updateRFIntersections(intersection) {
      const key = `${intersection.correlation_key}`
      const index = Object.keys(this.rfIntersections).indexOf(key)

      const details =
        Object.values(this.rfDetections).find(
          d => d.correlation_key === intersection.correlation_key
        ) || {}

      if (this.rfIntersections[key] && this.rfIntersections[key].timeout) {
        clearTimeout(this.rfIntersections[key].timeout)
      }

      this.$set(this.rfIntersections, key, {
        ...this.rfIntersections[key],
        ...intersection,
        ...details,
        markerNumber: index + 1,
        timeout: setTimeout(() => {
          this.$delete(this.rfIntersections, key)
        }, 5000)
      })
    },
    $_updateCameraDetections(detection) {
      const { camera_id, target_id, active_track } = detection
      const key = `${camera_id}:${target_id}`

      const history = this.cameraDetections[key]

      let tail = [detection.id]
      let count = 1

      if (history) {
        // Some buffer to save some memory
        if (Date.now() - history.timestamp < 100) return
        if (history.timeout) {
          clearTimeout(history.timeout)
        }
        if (history.tail && Array.isArray(history.tail)) {
          tail = tail.concat(history.tail)
        }
        if (history.count) {
          count += history.count
        }
      }

      detection.distance = arcPath
        .earthDistanceInMeters(
          this.siteCenter[0],
          this.siteCenter[1],
          detection.latitude,
          detection.longitude
        )
        .toFixed(0)

      detection.w = Math.max(3, detection.w)
      detection.h = Math.max(3, detection.h)
      detection.target_id = `${detection.camera_id}:${detection.target_id}`

      this.$set(this.cameraDetections, key, {
        ...history,
        ...detection,
        count,
        tail,
        timestamp: Date.now(),
        timeout: setTimeout(() => {
          this.$delete(this.cameraDetections, key)
        }, 750)
      })
    },
    $_updateRealDroneDetections(detection) {
      const { drone_id } = detection
      const history = this.realDroneDetections[drone_id]

      let tail = [detection.id]
      let count = 1

      if (history) {
        // Some buffer to save some memory
        if (Date.now() - history.timestamp < 100) return
        if (history.timeout) {
          clearTimeout(history.timeout)
        }
        if (history.tail && Array.isArray(history.tail)) {
          tail = tail.concat(history.tail)
        }
        if (history.count) {
          count += history.count
        }
      }
      this.$set(this.realDroneDetections, drone_id, {
        ...history,
        ...detection,
        count,
        tail,
        timestamp: Date.now(),
        timeout: setTimeout(() => {
          this.$delete(this.realDroneDetections, drone_id)
        }, 5000)
      })
    },
    $_updateSimulationDetections(detection) {
      const { drone_id } = detection
      const history = this.simulationDetections[drone_id]
      let tail = [detection.id]
      let count = 1
      if (history) {
        // Some buffer to save some memory
        // CAN'T DO THIS BECAUSE: game master sends detection every 100 ms so this will make the icon flickering
        // if (Date.now() - history.timestamp < 100) return
        if (history.tail && Array.isArray(history.tail)) {
          tail = tail.concat(history.tail)
        }
        if (history.count) {
          count += history.count
        }
      }
      this.$set(this.simulationDetections, drone_id, {
        ...history,
        ...detection,
        count,
        tail,
        timestamp: Date.now(),
        timeout: setTimeout(() => {
          this.$delete(this.simulationDetections, drone_id)
        }, 5000)
      })
    },
    $_clearAllDetections() {
      // clear potential remaining
      this.$_detections.forEach(detectionGroup => {
        detectionGroup &&
          Object.entries(detectionGroup).forEach(([k, d]) => {
            if (d.timeout) clearTimeout(d.timeout)
            this.$delete(detectionGroup, k)
          })
      })
    }
  }
}
