import { IMapLayer, IMapState } from './types'

export default {
  exporting: (state: IMapState) => state.exporting,
  activeSiteMapLayer: (state: IMapState) => {
    if (!state.activeMapLayerId || state.siteMapLayerMapping.length === 0) {
      console.warn(
        `active map layer ID doesn't match any value in the siteMapLayerMapping array: the problem ID , if any is: -> ${state.activeMapLayerId}`
      )
      return null
    }
    return state.allMapLayers.find(layer => layer.id === state.activeMapLayerId)
  },
  siteMapLayers: (state: IMapState) => {
    const activeSiteMapLayerIds = state.siteMapLayerMapping.map(
      layer => layer.map_layer_id
    )
    return state.allMapLayers.filter(layer =>
      activeSiteMapLayerIds.includes(layer.id)
    )
  },
  fallbackMapLayer: (state, getters): IMapLayer => {
    return getters.siteMapLayers.find((layer: IMapLayer) => layer.fallback)
  },
  mapZoom: (state: IMapState) => {
    return state.mapZoom;
  },
}
