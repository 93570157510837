import {apiRequest} from '../../utils'
import { Commit } from 'vuex'
import { INote } from './types'

export default {
  CREATE_NOTE: async ({ commit }: { commit: Commit }, note: INote) => {
    await apiRequest(
      'post',
      '/api/notes',
      { note: note },
      (_) => {},
      (data, _) => {
        commit('notes/SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_NOTES: async ({ commit }: { commit: Commit }, activeSiteId : number) => {
    await apiRequest(
      'get',
      `/api/notes?site_id=${activeSiteId}`,
      {},
      (data) => {
        commit('SET_NOTES', data.notes || [])
      },
      (data, _) => {
        commit('notes/SET_ERROR', new Error(data.error))
      }
    )
  }
}
