















import Vue from 'vue'
import {mapGetters} from 'vuex'

const props = {
  enabled: {
    type: Boolean,
    default: true
  }
}
export default Vue.extend({
  name: 'CenterMapButton',
  props,
  computed: {
    ...mapGetters('plans', ['activePlan']),
    ...mapGetters('sites', ['activeSite', 'isCustomMapCenter', 'mapCenter']),
    centerMapLabel(): string {
      return this.isCustomMapCenter
        ? 'Center to Custom Map Center'
        : 'Center Map to Site Location'
    }
  },
  methods: {
    onMapCenter(): void {
      if (!this.activeSite) return
      this.$bus.$emit(
        'mapCenter',
        this.activePlan ? 'planMap' : 'homeMap',
        this.mapCenter,
        this.activeSite.zoom_level
      )
    }
  }
})
