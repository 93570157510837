



















import Vue from 'vue'
import { IDetailSectionItem } from '@/components/Notifications/SensorFusion/types'

const props = {
  items: {
    type: Array as () => IDetailSectionItem[],
    default: () => []
  },
  id: {
    type: [String, Number],
    default: ''
  },
  color: {
    type: String,
    default: '#303030'
  }
}

export default Vue.extend({
  name: 'DetailSection',
  props,
  computed: {
    columnSize() {
      const sizes = []
      this.items.forEach((item, index) => {
        if (item.fullWidth === true || (index === this.items.length - 1 && this.items.length % 2 !== 0)) {
          sizes.push(12)
        } else {
          sizes.push(6)
        }
      })
      return sizes
    }
  }
})
