import {
  EContributionStateType,
  IContributionHistoryUpdate,
  IDetection,
  IDetectionState,
  IDirectionPayload,
  NO_TRACKING_TARGET_ID
} from '@/store/modules/detection/types'
import { getDefaultState } from '@/store/modules/detection/index'

export default {
  ADD_DETECTION: (state: IDetectionState, detection: IDetection) => {
    if (!state.enabled) return
    const newDetections = Object.assign({}, state.fusionDetections)
    newDetections[detection.target_id] = detection
    state.fusionDetections = newDetections
  },
  REMOVE_DETECTION: (state: IDetectionState, target_id: string) => {
    const newDetections = Object.assign({}, state.fusionDetections)
    delete newDetections[target_id]
    if (state.selectedDetections.includes(target_id)) {
      state.selectedDetections = []
    }
    state.fusionDetections = newDetections
  },
  SELECT_DETECTION: (state: IDetectionState, detection: string) => {
    if (Object.keys(state.fusionDetections).includes(detection))
      state.selectedDetections = [detection]
    else if (detection === null)
      state.selectedDetections = null
  },
  ADD_SELECTED_DETECTION: (state: IDetectionState, detection: string) => {
    state.selectedDetections = [...state.selectedDetections, detection]
  },
  REMOVE_SELECTED_DETECTION: (state: IDetectionState, detection: string) => {
    state.selectedDetections = state.selectedDetections.filter(item => {
      return item !== detection
    })
  },
  SET_SELECTED_DETECTIONS: (state: IDetectionState, detections: string[]) => {
    state.selectedDetections = detections
  },
  CLEAR_SELECTED_DETECTIONS: (state: IDetectionState) => {
    state.selectedDetections = []
  },
  SET_FIRST_CONTRIBUTION: (
    state: IDetectionState,
    type: EContributionStateType
  ) => {
    state.firstContributon[type] = true
  },
  ADD_SEEN_CONTRIBUTOR: (
    state: IDetectionState,
    update: IContributionHistoryUpdate
  ) => {
    if (!Array.isArray(state.seenContributors[update.target_id])) {
      state.seenContributors[update.target_id] = []
    }
    state.seenContributors[update.target_id].push(update.contributor)
  },
  DELETE_SEEN_CONTRIBUTORS: (state: IDetectionState, id: string) => {
    const contributors = Object.assign({}, state.seenContributors)
    delete contributors[id]
    state.seenContributors = contributors
  },
  ACTIVATE_RF_BEAM: (state: IDetectionState, key: string) => {
    state.activeRfBeam = key
  },
  RESET_DETECTIONS: (state: IDetectionState) => {
    const newState = {...getDefaultState()}
    state.fusionDetections = newState.fusionDetections
    state.selectedDetections = newState.selectedDetections
    state.activeRfBeam = newState.activeRfBeam
    state.seenContributors = newState.seenContributors
    state.firstContributon = newState.firstContributon
  },
  SET_FUSION_DETECTION_ENABLED_STATE: (state: IDetectionState, enabled: boolean) => {
    state.enabled = enabled
  }
}
