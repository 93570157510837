<template>
  <v-snackbar
    v-model="showSnackbar"
    bottom
    right
    multiLine
    :timeout="timeout"
    auto-height
  >
    <div v-html="snackbarText" :class="textClass" />
    <v-btn color="primary" flat @click="resetSnackbar" :class="buttonClass"
      >Close</v-btn
    >
  </v-snackbar>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  name: 'GlobalSnackbar',
  data() {
    return {
      timeout: 0,
      showSnackbar: false
    }
  },
  computed: {
    ...mapState('snackbar', [
      'snackbarText',
      'snackbarTimeout',
      'textClass',
      'buttonClass'
    ])
  },
  methods: {
    ...mapActions('snackbar', ['closeSnackbar']),
    async resetSnackbar() {
      this.showSnackbar = false
      await this.closeSnackbar()
    }
  },
  watch: {
    snackbarText: {
      handler: async function(text) {
        if (text) {
          this.showSnackbar = true
          setTimeout(async () => {
            await this.resetSnackbar()
          }, this.snackbarTimeout)
        } else {
          await this.resetSnackbar()
        }
      },
      immediate: true
    }
  }
})
</script>

<style scoped></style>
