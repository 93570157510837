





















































import Vue from 'vue'
//@ts-ignore
import MarqueeText from 'vue-marquee-text-component'
import { COLOR_THREAT } from '@/store/modules/detection/types'
import { mapGetters, mapState } from 'vuex'
import { humanReadableTargetID } from '@/utils/utils'

interface IMarqueeTextProps {
  paused: boolean
  repeat: number
  duration: number
  reverse: boolean
}

const props = {
  objectClass: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: COLOR_THREAT
  },
  confidence: {
    type: Number,
    default: 0
  },
  id: {
    type: String,
    required: true
  },
  isThreat: {
    type: Boolean,
    default: true
  },
  useIcon: {
    type: Boolean,
    default: true
  },
  isController: {
    type: Boolean,
    default: false
  },
  threatLevel: {
    type: Number,
    default: 1
  }
}

const BAR_THRESHOLD_1 = 0.2
const BAR_THRESHOLD_2 = 0.4
const BAR_THRESHOLD_3 = 0.6
const BAR_THRESHOLD_4 = 0.8

export default Vue.extend({
  name: 'DetectionNotificationHeader',
  components: { MarqueeText },
  props,
  computed: {
    ...mapState(['theme']),
    ...mapGetters('detection_labels', ['formattedLabel']),
    marqueeTextProps(): IMarqueeTextProps {
      return {
        paused: this.classification.length <= 8,
        repeat: this.classification.length <= 8 ? 1 : 2,
        duration: 15,
        reverse: false
      }
    },
    useMilStd2525Icons(): boolean {
      return this.theme === 'MIL_STD_2525'
    },
    shortId(): string {
      return humanReadableTargetID(this.id)
    },
    bars(): number {
      let val = 0
      if (this.confidence >= BAR_THRESHOLD_1) {
        val = 1
      }
      if (this.confidence >= BAR_THRESHOLD_2) {
        val = 2
      }
      if (this.confidence >= BAR_THRESHOLD_3) {
        val = 3
      }
      if (this.confidence >= BAR_THRESHOLD_4) {
        val = 4
      }
      return val
    },
    iconFilename(): string {
      if (this.useMilStd2525Icons) {
        return this.isThreat ? 'detection-uav-threat.svg' : 'detection-uav-friendly.svg'
      }

      return `${Math.round(this.threatLevel * 10)}/fusion_${this.bars}.svg`
    },
    iconFolder(): string {
      if (this.useMilStd2525Icons) return 'mil-std-2525'
      return 'sensorfusion'
    },
    icon() {
      if (this.useIcon) {
        return require(`@/assets/${this.iconFolder}/${this.iconFilename}`)
      } else {
        const iconName = `${this.isThreat ? '' : 'whitelist_'}${
          this.isController ? 'fhss_' : ''
        }lob`
        return require(`@/assets/mapLegend/${iconName}.svg`)
      }
    },
    classification(): string {
      return this.formattedLabel(this.objectClass)
    }
  },
  methods: {
    headerClicked() {
      this.$emit('click')
    }
  }
})
