import {
  IPartialSite,
  ISite,
  ISiteOrigin,
  ISiteState
} from '@/store/modules/sites/types'

export default {
  SET_STATUS: (state: ISiteState, status: string) => (state.status = status),
  SET_ERROR: (state: ISiteState, error: any) => {
    if (error instanceof Error) {
      state.error = error.message
    } else if (Array.isArray(error)) {
      state.error = error[0]
    } else {
      state.error = error
    }
  },
  LOAD_SITES: (state: ISiteState, data: ISite[]) => {
    let sites = {}
    data?.forEach((site: ISite) => {
      sites[site.id] = site
    })
    state.sitesSet = sites
  },
  ADD_SITE: (state: ISiteState, site: ISite) => {
    if (site.id) {
      state.sitesSet = Object.assign({}, state.sitesSet, { [site.id]: site })
    }
  },
  REMOVE_SITE: (state: ISiteState, id: number) => {
    delete state.sitesSet[id]
    // Prevent icon to remain on map after deletion
    if (state.activeSiteId === `${id}`) {
      state.activeSiteId = ''
    }
  },
  UPDATE_SITE: (state: ISiteState, site: ISite) => {
    if (site.id && state.sitesSet && state.sitesSet[site.id]) {
      Object.assign(state.sitesSet[site.id], site)
    }
  },
  ACTIVATE_SITE: (state: ISiteState, id: number) => {
    state.activeSiteId = `${id}`
  },
  DEACTIVATE_SITE: (state: ISiteState) => {
    state.activeSiteId = ''
  },
  SET_SIMULATION_RUNNING: (state: ISiteState, sim: boolean) => {
    state.simulationRunning = sim
  },
  SET_SIMULATION_START_LOADING: (state: ISiteState, start: boolean) => {
    state.simulationStartLoading = start
  },
  SET_NEW_SITE_ORIGIN: (state: ISiteState, data: ISiteOrigin) => {
    state.newSiteOrigin = { ...state.newSiteOrigin, ...data }
  },
  UPDATE_SITE_MODE: (state: ISiteState, site: ISite) => {
    if (site.id && state.sitesSet && state.sitesSet[site.id]) {
      state.sitesSet[site.id].mode = site.mode
    }
  },
  UPDATE_AUTO_JAM: (state: ISiteState, site: ISite) => {
    if (site.id && state.sitesSet && state.sitesSet[site.id]) {
      state.sitesSet[site.id].auto_jam = site.auto_jam
    }
  },
  UPDATE_SITE_ALERT_SETTINGS: (state: ISiteState, site: ISite) => {
    if (site.id && state.sitesSet && state.sitesSet[site.id]) {
      state.sitesSet[site.id].zone_trigger_probability =
        site.zone_trigger_probability
      state.sitesSet[site.id].zone_trigger_detection_count =
        site.zone_trigger_detection_count
      state.sitesSet[site.id].alert_suppression_time_window =
        site.alert_suppression_time_window
      state.sitesSet[site.id].trigger_sensors_offline_alerts =
        site.trigger_sensors_offline_alerts
    }
  },
  PARTIAL_UPDATE_SITE: (state: ISiteState, site: IPartialSite) => {
    if (site.id && state.sitesSet && state.sitesSet[site.id]) {
      if (site.auto_jam != null) {
        state.sitesSet[site.id].auto_jam = site.auto_jam
      }

      if (site.mode != null) {
        state.sitesSet[site.id].mode = site.mode
      }
    }
  }
}
