


















import Vue from 'vue'
import AlertsPanel from '@/components/Notifications/AlertsPanel.vue'
import LiveNotificationsPanel from '@/components/Notifications/SensorFusion/LiveNotificationsPanel.vue'
import { mapGetters, mapState } from 'vuex'

export default Vue.extend({
  name: 'NotificationSidebar',
  components: {
    AlertsPanel,
    LiveNotificationsPanel
  },
  data() {
    return {
      detections: {},
      refreshIndex: 0
    }
  },
  computed: {
    ...mapGetters('sites', ['activeSiteId']),
    ...mapState('detection', ['fusionDetections']),
  },
  watch: {
    activeSiteId: {
      handler: function(newId: number, oldId: number) {
        if (newId !== oldId) {
          this.refreshIndex++
        }
      },
      immediate: true
    }
  }
})
