import { apiRequest } from '@/store/utils'
import axios from 'axios'
import { IMapLayer, IMapState } from './types'
import { ISite } from "@/store/modules/sites/types";

export default {
  SELECT_MAP: ({ commit }, id: number): Promise<void> => {
    return commit('ACTIVATE_MAP', id)
  },

  UNSELECT_MAP: ({ commit }): Promise<void> => {
    return commit('DEACTIVATE_MAP')
  },

  SET_EXPORTING: ({ commit }, exporting: boolean): Promise<void> => {
    return commit('SET_EXPORTING_MAP', exporting)
  },

  SEARCH_MAP: async ({ commit }, address: string) => {
    commit('LOAD_SEARCH_RESULT', null)
    commit('SET_STATUS', 'LOADING')
    try {
      const { status, data = [] } = await axios.get(
        `https://nominatim.openstreetmap.org/?format=json&limit=1&q=${address}`
      )
      if (status == 200 && data[0]) {
        commit('SET_STATUS', 'OK')
        const loc = data[0]
        commit('LOAD_SEARCH_RESULT', loc.display_name)
        const bb = loc.boundingbox.map(v => parseFloat(v))
        if (bb[2] * bb[3] < 0) bb[3] = bb[3] - (180 - bb[2])
        return {
          loc: [loc.lat, loc.lon],
          bb: [
            [bb[0], bb[2]],
            [bb[1], bb[3]]
          ]
        }
      } else {
        throw new Error('No data returned or bad status')
      }
    } catch (e) {
      commit('LOAD_SEARCH_RESULT', null)
      commit('SET_STATUS', 'FAILED')
      return null
    }
  },

  CLEAR_SEARCH_MAP_RESULT: async ({ commit }): Promise<void> => {
    commit('LOAD_SEARCH_RESULT', null)
  },

  setActiveMapLayers: ({ commit }, v): void => {
    commit('SET_ACTIVE_MAP_LAYERS', v)
  },
  incMapLayerID: ({ commit }): void => commit('INC_MAP_LAYER_ID'),
  setMapLayer: ({ commit }, map): void => {
    commit('SET_MAP_LAYER', map)
  },
  setCompassGraphicDirection: ({ commit }, direction): void => {
    commit('SET_COMPASS_GRAPHIC_DIRECTION', direction)
  },
  initOfflineChunkUpload: async (
    { commit },
    { maplayerName, attribution, fallback, siteID, size, filename, quantity }
  ) => {
    return await apiRequest(
      'post',
      '/api/maps/upload/init',
      {
        maplayerName: maplayerName,
        attribution: attribution,
        fallback: fallback,
        siteID: siteID
      },
      (response, _) => {
        commit('SET_STATUS', 'OK')
        return response
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return data
      },
      {
        headers: {
          'X-Content-Length': size,
          'X-Content-Name': filename,
          'X-Chunks-Quantity': quantity
        }
      }
    )
  },
  createCancelToken: async ({ commit }): Promise<void> => {
    const cancelToken = axios.CancelToken
    commit('SET_CANCEL_TOKEN', cancelToken.source())
  },
  clearCancelToken: async ({ commit }): Promise<void> => {
    commit('SET_CANCEL_TOKEN', null)
  },
  uploadOfflineChunk: async ({ commit, state }, payload): Promise<Boolean> => {
    const { chunk, fileID, chunkID } = payload

    return await apiRequest(
      'post',
      `/api/maps/upload`,
      chunk,
      () => {
        return true
      },
      () => {
        state.mapCancelToken.cancel(
          'chunk upload failed, cancelling map upload!'
        )
        return false
      },
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          'X-Content-Id': fileID,
          'X-Chunk-Id': chunkID
        },
        cancelToken: state.mapCancelToken.token
      }
    )
  },
  addMapLayer: async ({ commit, dispatch }, mapLayer) => {
    return await apiRequest(
      'post',
      `/api/map_layers`,
      {
        map_layer: { ...mapLayer, max_zoom: parseInt(mapLayer.max_zoom) }
      },
      (layer, _) => {
        commit('SET_STATUS', 'OK')
        dispatch('fetchAllMapLayers')
        return layer
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return data
      }
    )
  },
  updateMapLayer: async ({ commit, dispatch }, mapLayer) => {
    return await apiRequest(
      'patch',
      `/api/map_layers/${mapLayer.id}`,
      {
        map_layer: { ...mapLayer, max_zoom: parseInt(mapLayer.max_zoom) }
      },
      (layers, _) => {
        commit('SET_STATUS', 'OK')
        return { success: true }
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return { success: false, error: data.error }
      }
    )
  },
  fetchAllMapLayers: async ({ commit }): Promise<void> => {
    await apiRequest(
      'get',
      `/api/map_layers`,
      {},
      (layers, _) => {
        commit('SET_ALL_MAP_LAYERS', layers?.map_layers || [])
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  deleteMapLayer: async (
    { commit, dispatch },
    layerId: number
  ): Promise<Boolean> => {
    return await apiRequest(
      'delete',
      `/api/map_layers/${layerId}`,
      {},
      (response, _) => {
        commit('SET_STATUS', 'OK')
        return true
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
        return false
      }
    )
  },
  addSiteMapLayerAssociation: async ({ commit }, { siteId, layerId }) => {
    return await apiRequest(
      'post',
      `/api/sites/${siteId}/map_layers`,
      {
        site_map_layer: {
          map_layer_id: layerId
        }
      },
      (mapping, _) => {
        commit('SET_STATUS', 'OK')
        return mapping
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  removeSiteMapLayerAssociation: async ({ commit }, { siteId, layerId }) => {
    return await apiRequest(
      'delete',
      `/api/sites/${siteId}/map_layers/${layerId}`,
      {},
      (response, _) => {
        commit('SET_STATUS', 'OK')
        return response
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  fetchSiteAndLayerMapping: async ({ commit }, id: number): Promise<void> => {
    await apiRequest(
      'get',
      `/api/sites/${id}/map_layers`,
      {},
      (layers, _) => {
        commit('SET_SITE_LAYER_MAPPING', layers?.site_map_layer || [])
        commit('SET_STATUS', 'OK')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  setActiveMapLayerId: async ({ commit }, id: number): Promise<void> => {
    if (typeof id === 'string') {
      console.debug(
        '%c USING LEGACY MAP TILER SYSTEM! ',
        'background: #000; color: var(--dro-primary)'
      )
    }
    commit('SET_ACTIVE_MAP_LAYER_ID', id)
  },
  switchActiveMapLayer: async ({ commit }): Promise<void> => {
    commit('SWITCH_ACTIVE_MAP_LAYER')
  },
  getSelectedMapLayers: async ({ state, commit }) => {
    const selected = state.siteMapLayerMapping.map(siteLayerMap => {
      return state.allMapLayers.find(layer => {
        return layer.id === siteLayerMap.map_layer_id
      })
    })
    commit('SET_SELECTED_MAP_LAYERS', selected)
  },
  setSelectedMapLayers: async (
    { commit },
    mapLayers: Array<IMapLayer>
  ): Promise<void> => {
    commit('SET_SELECTED_MAP_LAYERS', mapLayers)
  },
  showAddMapLayerDialog: async ({ commit }): Promise<void> => {
    commit('SHOW_ADD_MAP_LAYER_DIALOG')
  },
  hideAddMapLayerDialog: async ({ commit }): Promise<void> => {
    commit('HIDE_ADD_MAP_LAYER_DIALOG')
  },
  setUploadingMapState: async ({ commit }, state: boolean): Promise<void> => {
    commit('SET_UPLOADING_MAP_STATE', state)
  },
  selectMapLayer: ({ commit }, layer: IMapLayer): void => {
    commit('SELECT_MAP_LAYER', layer)
  },
  unselectMapLayer: ({ commit }, layer: IMapLayer): void => {
    commit('UNSELECT_MAP_LAYER', layer)
  },
  setMapZoom: ({ commit }, {zoom, siteZoom}: { zoom: IMapState['mapZoom'], siteZoom: ISite['zoom_level'] }): void => {
    commit('SET_MAP_ZOOM', {zoom, siteZoom})
  },
  setUploadFileId: async ({ commit }, fileId: string): Promise<void> => {
    commit('SET_UPLOAD_FILE_ID', fileId)
  },
  cancelOfflineMapUploadSync: ({}, { fileId, token }): void => {
    const baseURL = process.env.VUE_APP_ROOT_API || 'http://localhost/'
    // this is done this way to make the API call synchronous to make sure
    // that the call is made before the application in unloaded by the browser
    fetch(`${baseURL}api/maps/upload/cancel`, {
      keepalive: true,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-content-id': fileId
      }
    }).then(response => {
      console.log(response)
    })
  },
  cancelOfflineMapUpload: async ({ commit }, fileId: string): Promise<void> => {
    localStorage.setItem('cancel_upload_request', fileId)
    return await apiRequest(
      'post',
      `/api/maps/upload/cancel`,
      {},
      (data, _) => {
        commit('SET_STATUS', 'OK')
        return data
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      },
      {
        headers: {
          'x-content-id': fileId
        }
      }
    )
  }
}
