export const labelMappings = {
  '3dr': '3DR',
  dji: 'DJI',
  anafi: 'ANAFI',
  evo: 'EVO',
  '2s': '2S',
  se: 'SE',
  v2: 'V2',
  rtk: 'RTK',
  '4k': '4K',
  gogo: 'GoGo',
  fpv: 'FPV',
  gopro: 'GoPro',
  xf: 'XF',
  rangepro: 'RangePro',
  x8p: 'X8P',
  swellpro: 'SwellPro',
  h501s: 'H501S',
  powervision: 'PowerVision',
  poweregg: 'PowerEgg',
  freefly: 'FreeFly'
}

export interface IDetectionSubLabel {
  id: number
  name: string
  threat: boolean
}

export interface IDetectionLabel extends IDetectionSubLabel {
  sub_labels: IDetectionSubLabel[]
}

export interface IDetectionLabelFull extends IDetectionLabel {
  created_at: string
  updated_at: string
}

export interface IDetectionLabelsState {
  labels: IDetectionLabel[]
}

export interface IDetectionLabelUpdate {
  label: string
  id: number
}

export interface IDetectionLabelAdd {
  parent_id: number
}
