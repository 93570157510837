import {
  IAGLMaskItem,
  IAGLMaskResponseList,
  IAGLState,
  IRadarAGLMasks
} from '@/store/modules/agl_mask/types'

export default {
  UPDATE_AGLMASK: (state: IAGLState, mask: IAGLMaskItem) => {
    let radarId = mask.radar_id
    const maskObj: IRadarAGLMasks = state.radarAGLMasks

    if (maskObj[radarId]) {
      // Existing radar
      Object.assign(maskObj[radarId], mask)
      return (state.radarAGLMasks = maskObj)
    } else {
      // New radar
      maskObj[radarId] = {
        created_at: '',
        enabled: false,
        id: 0,
        max: 0,
        min: 0,
        radar_id: 0,
        updated_at: ''
      }
      Object.assign(maskObj[radarId], mask)
      state.radarAGLMasks = maskObj
    }
  },
  SET_AGLMASKS: (state, payload: IAGLMaskResponseList) => {
    // Initialise updatedAGLMasks
    const updatedAGLMasks: IRadarAGLMasks = {}

    payload.radar_agl_masks.map(mask => {
      // @ts-ignore
      updatedAGLMasks[mask.radar_id] = {}
      Object.assign(updatedAGLMasks[mask.radar_id], mask)
    })

    state.radarAGLMasks = updatedAGLMasks
  }
}
