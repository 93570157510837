<template>
  <v-navigation-drawer
    mini-variant
    mini-variant-width="60"
    fixed
    clipped
    app
    class="pa-0"
    mobile-break-point="700"
    id="leftNavDrawer"
  >
    <v-toolbar flat class="transparent">
      <v-list class="pa-0">
        <v-tooltip
          right
          v-for="item in navItems"
          :key="item.title"
        >
          <v-list-tile
            slot="activator"
            :to="item.title === 'Cameras' && cameraDisabled(item) ? null : item.link"
            :data-cy="`nav-${item.cy}`"
            @click="clearSentries()"
          >
            <v-list-tile-action style="width: 100%">
              <v-icon 
                :disabled="item.title === 'Cameras' && cameraDisabled(item)"
                :style="item.title === 'Analytics' ? 'transform: rotate(-45deg)' : ''"
              >{{ item.icon }}</v-icon>
            </v-list-tile-action>
          </v-list-tile>
          <span>{{ mapTooltipTitles(item.title) }}</span>
        </v-tooltip>
      </v-list>
    </v-toolbar>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LeftNav',
  props: {
    navItems: Array
  },
  methods: {
    ...mapActions('selection', ['clearSentries']),
    mapTooltipTitles(title) {
      switch (title) {
        case 'Cameras': return 'Optical Systems'
        default: return title;
      }
    },
  },
  computed: {
    ...mapGetters('cameras', ['allCameras']),
    cameraDisabled() {
      return item =>
        (!this.$janus.isConnected || !this.allCameras.length)
    }
  }
}
</script>
<style>
#leftNavDrawer .v-list__tile {
  padding: 0px !important;
}
</style>
