import { ISnackbarState } from '@/store/modules/snackbar/types'

export default {
  showSnackbar: async ({ commit }, options: ISnackbarState) => {
    commit('SHOW_SNACKBAR', options)
  },
  closeSnackbar: async ({ commit }) => {
    commit('CLOSE_SNACKBAR')
  }
}
