import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import { ICameraState } from './types'

const getDefaultState = (): ICameraState => {
  return {
    camerasSet: {},
    activeCameraId: null,
    status: '',
    error: '',
    calibratingCamera: false,
    coturn_cred: null
  }
}

const state = getDefaultState()

const resetState = (state: ICameraState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
