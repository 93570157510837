import { apiRequest, axiosInstance } from '../../utils'
import omit from 'lodash/omit'
import {
  ISiteListResponse,
  ISite,
  ISiteState,
  ESiteMode,
  ISiteResponse
} from '@/store/modules/sites/types'
import { Commit, Dispatch, Getter } from 'vuex'

export default {
  CLEAR_STATUS: ({ commit }: { commit: Commit }) => {
    commit('SET_STATUS', null)
    commit('SET_ERROR', null)
  },
  ACTIVATE_SITE: ({ commit }: { commit: Commit }, id: number) => {
    commit('ACTIVATE_SITE', id)
    localStorage.setItem('active-site-id', id?.toString())
  },
  FETCH_SITES: async ({ commit }: { commit: Commit }) => {
    await apiRequest(
      'get',
      'api/sites',
      {},
      (data: ISiteListResponse) => {
        commit('LOAD_SITES', data.sites)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  CREATE_SITE: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    site: ISite
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'post',
      '/api/sites',
      { site: site },
      (data: ISiteResponse, _: any) => {
        commit('ADD_SITE', data.site)
        commit('SET_STATUS', 'OK')
        commit('ACTIVATE_SITE', data.site.id)
        localStorage.setItem('active-site-id', String(data.site.id))
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_SITE: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    site: ISite
  ) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/api/sites/${site.id}`,
      {},
      (data: ISite, _: any) => {
        commit('REMOVE_SITE', site.id)
        commit('SET_STATUS', 'OK')
        dispatch('UNSELECT_SITE')
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_SITE: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    site: Partial<ISite>
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'put',
      `/api/sites/${site.id}`,
      { site: omit(site, ['id']) },
      (data: any, _: any) => {
        site = data.site
        site.locator_id = data.locator_id
        commit('UPDATE_SITE', site)
        commit('SET_STATUS', 'OK')
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_ERROR`, new Error(data.error))
      }
    )
  },
  UPDATE_SITE_ALERT_SETTINGS: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    site: Partial<ISite>
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `/api/sites/${site.id}/alert_settings`,
      { site_alert_settings: omit(site, ['id']) },
      (data: any, _: any) => {
        commit('UPDATE_SITE_ALERT_SETTINGS', site)
        commit('SET_STATUS', 'OK')
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit(`SET_ERROR`, new Error(data.error))
      }
    )
  },
  UNSELECT_SITE: ({
    commit,
    getters
  }: {
    commit: Commit
    getters: Getter<any, any>
  }) => {
    localStorage.removeItem('active-site-id')
    if (getters['activeSiteId']) {
      commit('DEACTIVATE_SITE')
    }
  },
  FETCH_USER_SITES: async ({ commit }: { commit: Commit }, user_id: number) => {
    return apiRequest(
      'get',
      `/api/users/${user_id}/sites`,
      {},
      (data: any, _: any) => data.site_users,
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  ADD_SITE_ASSOCIATION: async (
    { commit }: { commit: Commit },
    { site_id, user_id, role }: any
  ) => {
    commit('SET_STATUS', 'LOADING')
    apiRequest(
      'post',
      `/api/sites/${site_id}/users`,
      { site_association: { user_id, role } },
      (_data: any, _: any) => {
        commit('SET_STATUS', 'OK')
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_SITE_ASSOCIATION: async (
    { commit }: { commit: Commit },
    { site_id, user_id }: any
  ) => {
    commit('SET_STATUS', 'LOADING')
    apiRequest(
      'delete',
      `/api/sites/${site_id}/users/${user_id}`,
      {},
      (_data: any) => {
        commit('SET_STATUS', 'OK')
      },
      (data: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  TOGGLE_AUTO_JAMMING: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    site: ISite
  ) => {
    dispatch('UPDATE_SITE', { id: site.id, auto_jam: !site.auto_jam })
  },
  SOCKET_SITES_UPDATE: async (
    { commit }: { commit: Commit },
    payload: ISite
  ) => {
    commit('UPDATE_SITE', payload[0])
  },
  SOCKET_SITES_PARTIAL_UPDATE: async (
    { commit }: { commit: Commit },
    payload: ISite
  ) => {
    commit('PARTIAL_UPDATE_SITE', payload)
  },
  START_SIMULATION: async ({ commit }: { commit: Commit }, siteId: number) => {
    await apiRequest(
      'post',
      `/api/sites/${siteId}/simulation`,
      {},
      (_data: any) => {
        commit('SET_STATUS', 'OK')
        commit('SET_SIMULATION_START_LOADING', true)
      },
      (data: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  getNewSiteOrigin: async ({ state, commit }) => {
    if (
      state.newSiteOrigin.latitude !== 0 &&
      state.newSiteOrigin.longitude !== 0
    )
      return

    const controller = new AbortController()
    const timeout = setTimeout(() => {
      controller.abort()
    }, 1500)

    try {
      const response = await fetch('https://ipapi.co/json/', {
        signal: controller.signal
      })
      const { latitude, longitude, timezone } = await response.json()
      commit('SET_NEW_SITE_ORIGIN', { latitude, longitude, timezone })
    } catch {
      console.debug('cannot fetch location')
    } finally {
      clearTimeout(timeout)
    }
  },
  switchSiteMode: async (
    { commit }: { commit: Commit },
    { siteId, mode }: { siteId: number; mode: ESiteMode }
  ) => {
    commit('SET_STATUS', 'LOADING')
    return await apiRequest(
      'patch',
      `/api/sites/${siteId}`,
      { mode },
      (data: any, _: any) => {
        const site = data.site
        commit('UPDATE_SITE_MODE', site)
        commit('SET_STATUS', 'OK')
        return true
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        return false
      }
    )
  },
  toggleAutoJam: async ({
    state,
    commit
  }: {
    state: ISiteState
    commit: Commit
  }) => {
    commit('SET_STATUS', 'LOADING')
    const autoJamStatus = state.sitesSet[state.activeSiteId].auto_jam
    return await apiRequest(
      'patch',
      `/api/sites/${state.activeSiteId}`,
      { auto_jam: !autoJamStatus },
      (data: any, _: any) => {
        commit('UPDATE_AUTO_JAM', data.site)
        commit('SET_STATUS', 'OK')
        return true
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        return false
      }
    )
  }
}
