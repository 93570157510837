


































import Vue from 'vue'
import ActionButtonGroup from './ActionButtonGroup.vue';
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'EffectorActions',
  components: {
    ActionButtonGroup
  },
  computed: {
    ...mapGetters('sites', ['activeSite', 'activeSiteId']),
    ...mapGetters('sensors', ['allCannons']),
    ...mapGetters('rf_sensors', ['dsxJammers']),
    ...mapGetters('detection', ['selectedDetection']),
    combinedEffectors() {
      return this.allCannons.concat(this.dsxJammers)
    },
    hasEffectors(): boolean {
      return this.combinedEffectors.length !== 0
    },
    effectorActivated() {
      return effector => {
        if (effector.model === 'dsx_direct')
          return effector.jamming
        else 
          return effector.power_trigger_engaged
      }
    },
    allEffectorsActivated(): boolean {
      // return true if all effectors with an active band, are activated
      return this.combinedEffectors.filter(effector => 
      (
        !!(effector.gnss_trigger_engaged) ||
        !!(effector.band_24_58_trigger_engaged) ||
        !!(effector.band_433_trigger_engaged) ||
        !!(effector.band_915_trigger_engaged) ||
        !!(effector.band_433_915_trigger_engaged)
      ))
      .every(effector => {
        if (effector.model === 'dsx_direct')
          return effector.jamming
        else 
          return effector.power_trigger_engaged
      })
    },
    anyEffectorActivated(): boolean {
       // return true if any effector is activated
      return this.combinedEffectors.some(effector => {
        if (effector.model === 'dsx_direct')
          return effector.jamming
        else 
          return effector.power_trigger_engaged
      })
    },
    autoJamEnabled(): boolean {
      return this.activeSite.auto_jam
    },
    allEffectorsDisabled() {
      // return true if no effector is online and usable
      // "trigger_engaged" is a bit misleading, means "band is active"
      return !(
        !!this.combinedEffectors.find(c => !!c.gnss_trigger_engaged) ||
        !!this.combinedEffectors.find(c => !!c.band_24_58_trigger_engaged) ||
        !!this.combinedEffectors.find(c => !!c.band_433_trigger_engaged) ||
        !!this.combinedEffectors.find(c => !!c.band_915_trigger_engaged) ||
        !!this.combinedEffectors.find(c => !!c.band_433_915_trigger_engaged)
      )
    },
  },
  methods: {
    ...mapActions('sites', ['toggleAutoJam']),
    ...mapActions('sensors', ['toggleDisruptor', 'toggleAllDisruptors']),
    ...mapActions('rf_sensors', ['toggleDsxDisruptor', 'toggleAllDsxDisruptors']),
    async toggleEffector(effector, command=undefined) {
      if (effector.model === 'dsx_direct')
        await this.toggleDsxDisruptor({ siteId: this.activeSiteId, sensorId: effector.id, command: command })
      else
        await this.toggleDisruptor({ sensorId: effector.id, command: command})
    },
    toggleAllEffectors(command: true | false | undefined): void {
      if (this.dsxJammers.length > 0) this.toggleAllDsxDisruptors({ siteId: this.activeSiteId, command: command })
      if (this.allCannons.length > 0) this.toggleAllDisruptors({ command: command })
    },
    async toggleAutoJamWrapper() {
      // TODO: handle fail in UI
      if (!await this.toggleAutoJam())
        console.log('toggle auto jam failed')
    },
    effectorDisabled(effector) {
      return (effector.status_color !== 'green') || !(
        !!effector.gnss_trigger_engaged ||
        !!effector.band_24_58_trigger_engaged ||
        !!effector.band_433_trigger_engaged ||
        !!effector.band_915_trigger_engaged ||
        !!effector.band_433_915_trigger_engaged
      )
    },
  }
})
