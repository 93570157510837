<template>
  <l-feature-group :visible="visible">
    <l-polygon
      v-if="isSector"
      :color="color"
      :weight="weight"
      :fill-color="fillColor"
      :opacity="1"
      :fill-opacity="fillOpacity"
      :lat-lngs="searchRange"
      :ref="refKey"
      :className="draggable ? 'draggableSector' : ''"
      @drag="draggable && !cancelDragHandler && $_dragSector($event)"
      @dragend="draggable && !cancelDragHandler && $_resetDragAngle($event)"
      @click="draggable && !cancelDragHandler && $_shuffleSector($event)"
    ></l-polygon>
    <l-polygon
      v-if="type === 'radar_zero' && !activePlan"
      :color="color"
      :weight="weight"
      :fill-color="fillColor"
      :opacity="1"
      :fill-opacity="0"
      :lat-lngs="trackRange"
      :ref="`${refKey}-track`"
    ></l-polygon>
    <l-circle
      v-if="isCircle"
      :lat-lng="latlng"
      :radius="1200"
      :color="color"
      :weight="weight"
      :fill-color="fillColor"
      :opacity="1"
      :fill-opacity="fillOpacity"
      :ref="refKey"
    />
    <template v-if="editMode">
      <polygon-fog-of-war
        v-if="isSector"
        :key="`${maskKey}:mask`"
        :lat-lngs="isDefault ? searchRange : totalRange"
        :opacity="0"
        :fill-opacity="0"
        fill-color="black"
        mask="radarSector"
      />
      <circle-fog-of-war
        v-else-if="isCircle"
        :key="`${maskKey}:mask`"
        :lat-lng="latlng"
        :radius="1200"
        :opacity="0"
        :fill-opacity="0"
        fill-color="black"
        mask="radarSector"
      />
      <polygon-fog-of-war
        :key="`${maskKey}:full_mask`"
        :fill-opacity="0"
        :mask-opacity="0.7"
        fill-color="black"
        :opacity="0"
        mask-id="radarSector"
      />
    </template>
  </l-feature-group>
</template>

<script>
import utils from '../utils'
import { icon } from 'leaflet'
import { mapState, mapGetters } from 'vuex'
import { SensorMixin } from '@/components/Mixins'
import { LPolygon, LCircle, LFeatureGroup } from 'vue2-leaflet'
import { PolygonFogOfWar, CircleFogOfWar } from 'vue2-leaflet-fogofwar'

const props = {
  active: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: true
  },
  editMode: {
    type: Boolean,
    default: false
  },
  sensor: {
    type: Object,
    default: () => ({
      latitude: 0,
      longitude: 0,
      direction: 0,
      fixed_az: 0,
      mode: 'fixed',
      reach: 850,
      angle: 90,
      radar_az_max_search: 45,
      radar_az_max_track: 60,
      radar_az_min_search: -45,
      radar_az_min_track: -60,
      radar_type: ''
    })
  },
  draggable: {
    type: Boolean,
    default: false
  },
  cancelDragHandler: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'RadarSectorCoverage',
  data: () => ({
    roseIcon: icon({
      iconUrl: require(`@/assets/compass-rose.png`),
      iconSize: [700, 692],
      iconAnchor: [353, 346]
    })
  }),
  mixins: [SensorMixin],
  components: {
    LPolygon,
    LCircle,
    LFeatureGroup,
    PolygonFogOfWar,
    CircleFogOfWar
  },
  props,
  computed: {
    ...mapGetters('plans', ['activePlan']),
    ...mapState(['system', 'environment']),
    fillOpacity() {
      return this.editMode ? 0.3 : 0.1
    },
    maskKey() {
      return `${this.type}:${(this.sensor && this.sensor.mode) || ''}`
    },
    weight() {
      if (this.active) {
        return 3
      } else if (this.editMode) {
        return 2
      } else {
        return 1
      }
    },
    fillColor() {
      if (this.active) {
        return 'orange'
      } else if (this.editMode) {
        return 'black'
      } else {
        return '#FA001B'
      }
      // return "white";
    },
    color() {
      return this.active || this.editMode ? 'orange' : '#FA001B'
    },
    defaultDegrees() {
      return this.type == 'radar_one' ? 180 : 90
    },
    type() {
      if ('radar_type' in this.sensor) {
        return this.sensor.radar_type
      } else if ('auto_jam' in this.sensor) {
        return 'cannon'
      } else {
        return null
      }
    },
    reach() {
      switch (this.type) {
        case 'radar_zero':
          return this.sensor.reach
        case 'radar_one':
          return 1500
        case 'radar_two':
          return 3500
      }
      return 0
    },
    latlng() {
      return this.sensor && [this.sensor.latitude, this.sensor.longitude]
    },
    dir() {
      const { direction = 0, fixed_az = 0 } = this.sensor || {}
      return this.type === 'radar_one' ? fixed_az || direction : direction
    },
    searchRange() {
      const { radar_az_max_search, radar_az_min_search, latitude, longitude } =
        this.sensor || {}
      return utils.createRange({
        reach: this.reach,
        direction: this.dir,
        min: -(this.isDefault ? this.defaultDegrees / 2 : radar_az_max_search),
        max: this.isDefault ? this.defaultDegrees / 2 : -radar_az_min_search,
        vertex: [latitude, longitude]
      })
    },
    trackRange() {
      return utils.createRange({
        reach: this.reach,
        direction: this.dir,
        min: -this.sensor.radar_az_max_track,
        max: -this.sensor.radar_az_min_track,
        vertex: [this.sensor.latitude, this.sensor.longitude]
      })
    },
    bearingLine() {
      return utils.bearingLine({
        vertex: this.latlng,
        reach: this.reach,
        direction: this.dir
      })
    },
    totalRange() {
      return utils.createRange({
        reach: this.reach,
        direction: this.dir,
        min: Math.min(
          -this.sensor.radar_az_max_track,
          -this.sensor.radar_az_max_search
        ),
        max: Math.max(
          -this.sensor.radar_az_min_track,
          -this.sensor.radar_az_min_search
        ),
        vertex: [this.sensor.latitude, this.sensor.longitude]
      })
    },
    isDefault() {
      return this.activePlan || this.type === 'radar_one'
    },
    isSector() {
      return (
        ['radar_zero', 'radar_two'].includes(this.type) ||
        (this.type === 'radar_one' &&
          this.sensor &&
          this.sensor.mode === 'fixed')
      )
    },
    isCircle() {
      return (
        this.type === 'radar_one' &&
        this.sensor &&
        this.sensor.mode === 'rotating'
      )
    },
    refKey() {
      return `radar-sector-${this.sensor.id}`
    }
  },
  watch: {
    active(isActive) {
      if (isActive && this.$refs[this.refKey]) {
        this.$refs[`${this.refKey}-track`] &&
          utils.bringSVGToTop(this.$refs[`${this.refKey}-track`])
        utils.bringSVGToTop(this.$refs[this.refKey])
      }
    }
  },
  mounted() {
    this.$bus.$on('bringRadarToTop', () => {
      if (this.$refs[this.refKey]) utils.bringSVGToTop(this.$refs[this.refKey])
    })
  },
  beforeDestroy() {
    this.$bus.$off('bringRadarToTop')
  }
}
</script>
