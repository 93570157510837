










































import Vue from 'vue'
import { latLngToMgrs } from '@/utils/utils.js'
import { mapActions, mapState } from 'vuex'
import DetailSection from './SensorFusion/DetailSection.vue'
//@ts-ignore
import MarqueeText from 'vue-marquee-text-component'
import { humanReadableTargetID } from '@/utils/utils'

interface IMarqueeTextProps {
  paused: boolean
  repeat: number
  duration: number
  reverse: boolean
}

const props = {
  alert: {
    type: Object
  },
  site: {
    type: Object,
    default: () => {}
  }
}

export default Vue.extend({
  name: 'AlertCard',
  props,
  components: {
    DetailSection,
    MarqueeText
  },
  computed: {
    ...mapState(['mgrsEnabled']),
    ...mapState('notifications', ['activeAlert']),
    marqueeTextProps(): IMarqueeTextProps {
      return {
        paused: this.alert.zone.name.length <= 8,
        repeat: this.alert.zone.name.length <= 8 ? 1 : 2,
        duration: 15,
        reverse: false
      }
    },
    showContent() {
      if (!this.activeAlert) return false
      return (
        `${this.activeAlert.track.id}-${this.activeAlert.zone.id}` ===
        `${this.alert.track.id}-${this.alert.zone.id}`
      )
    },
    latitude() {
      let lat = this.alert?.track?.last_latitude || this.alert?.track?.lat
      return lat.toFixed(8)
    },
    longitude() {
      let lng = this.alert?.track?.last_longitude || this.alert?.track?.lng
      return lng.toFixed(8)
    },
    mgrs() {
      return latLngToMgrs(this.latitude, this.longitude)
    },
    protocol() {
      return this.alert?.detection?.protocol || null
    },
    vendor() {
      return this.alert?.detection?.vendor || null
    },
    title() {
      return `Detection #${this.alert?.track?.detection_id ||
        (this.alert?.track?.type === "fusion" ? this.alert.track.id.slice(0, 8) : this.alert?.track?.id)}`
    },
    alertSectionDetails() {
      const details = [
        { label: 'Site', value: this.site.name || '-', fullWidth: false },
        { label: 'Zone Detected', value: this.alert.zone.name || '-' }
      ]
      if (this.vendor && this.protocol) {
        details.push(
          { label: 'Vendor', value: this.vendor || '-' },
          { label: 'Protocol', value: this.protocol || '-' }
        )
      }
      if (!this.mgrsEnabled && this.latitude && this.longitude) {
        details.push(
          { label: 'Latitude', value: this.latitude },
          { label: 'Longitude', value: this.longitude }
        )
      }
      else if (this.mgrsEnabled && this.latitude && this.longitude) {
        details.push(
          { label: 'Coordinates', value: this.mgrs, fullWidth: true }
        )
      }
      if (this.alert.zone.note) {
        details.push(
          { label: 'Alert Notes', value: this.alert.zone.note }
        )
      }
      return details
    }
  },
  methods: {
    shortId(target_id: string): string {
      return humanReadableTargetID(target_id)
    },
    ...mapActions('sensors', ['selectDetection']),
    ...mapActions('notifications', ['setActiveAlert']),
    toggleContent() {
      if (this.showContent) {
        this.setActiveAlert(null)
      } else {
        this.setActiveAlert(this.alert)
        this.$emit('display', this.alert)
      }
    }
  }
})
