





































import Vue from 'vue'
import CameraActions from './CameraActions.vue'
import AlertActions from './AlertActions.vue'
// import DetectionActions from './DetectionActions.vue'
import EffectorActions from './EffectorActions.vue'
import MaskActions from './MaskActions.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'QuickActionsSidebar',
  components: {
    AlertActions,
    CameraActions,
    // DetectionActions,
    EffectorActions,
    MaskActions
  },
  data: () => ({
    active: null,
    tabIndexes: {
      detection: 0,
      camera: 1,
      effectors: 2,
      masks: 3
    }
  }),
  methods: {
    ...mapActions('detection', ['selectDetection']),
  },
  computed: {
    ...mapGetters('cameras', ['camerasLength']),
    ...mapGetters('sensors', ['allCannons']),
    ...mapGetters('rf_sensors', ['dsxJammers']),
    ...mapGetters('sites', [ 'activeSiteMode' ]),
    ...mapGetters('users', ['isAuthorized']),
    ...mapGetters('detection', ['selectedDetection']),
    ...mapGetters('sensors', ['hasRadar']),
    ...mapGetters('rf_sensors', ['hasRfSensor']),
    ...mapGetters('notifications', ['selectedAlert']),
    combinedEffectors() {
      return this.allCannons.concat(this.dsxJammers)
    },
    alertsDisabled(): boolean {
      // guest has access
      return false
    },
    cameraDisabled(): boolean {
      return !this.isAuthorized('operator') || this.camerasLength === 0
    },
    effectorsDisabled(): boolean {
      return !this.isAuthorized('operator') || this.combinedEffectors.length === 0
    },
    masksDisabled(): boolean {
      if (!this.isAuthorized('site_manager') || this.activeSiteMode !== 'calibration') return true
      else return !(this.hasRadar || this.hasRfSensor)
    }
  },
  watch: {
    selectedAlert(a, b) {
      if (a !== b) {
        this.active = this.tabIndexes.detection
        this.selectDetection(a)
      }
    },
    selectedDetection(a, b) {
      if (a !== null && a !== b) {
        switch (this.activeSiteMode) {
          case 'calibration':
            this.active = this.tabIndexes.masks
            break
          case 'operational':
          case 'monitoring':
            if (!this.effectorsDisabled)
              this.active = this.tabIndexes.effectors
            else if (!this.cameraDisabled)
              this.active = this.tabIndexes.camera
            break
        }
      }
    }
  }
})
