


























const props = {
  disable: {
    type: Boolean,
    default: false
  },
  includeCancel: {
    type: Boolean,
    default: false
  },
  status: {
    type: String,
    default: null
  },
  error: {
    type: String,
    default: null
  },
  okValue: {
    type: String,
    default: 'Submit'
  },
  doneValue: {
    type: String,
    default: 'Done'
  },
  errorValue: {
    type: String,
    default: 'Failed'
  }
}

export default {
  name: 'd-submit',
  props,
  computed: {
    loading(): boolean {
      return this.status?.toUpperCase() === 'LOADING'
    },
    isDisabled(): boolean {
      return this.disable || this.loading
    },
    valid(): boolean {
      return this.status?.toUpperCase() === 'OK'
    },
    textValue(): string {
      return this.valid ? this.doneValue : this.okValue
    },
    color(): string {
      if (this.status) {
        if (this.status.toUpperCase() === 'OK') {
          return 'success'
        }
      }

      return 'primary'
    }
  },
  methods: {
    submit(): void {
      this.$emit('onSubmit')
      // if (this.status === 'OK') {
      //   return setTimeout(() => this.$emit('onSubmit'), 500)
      // }
    }
  }
}
