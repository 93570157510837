import { Commit, Dispatch } from 'vuex'
import { apiRequest } from '@/store/utils'
import {
  CoturnCredentials,
  ICamera,
  ICameraListResponse,
  ICameraResponse,
  ICameraState
} from '@/store/modules/cameras/types'

export default {
  CLEAR_STATUS: ({ commit }: { commit: Commit }) => {
    commit('SET_STATUS', null)
    commit('SET_ERROR', null)
  },
  FETCH_CAMERAS: async ({ commit }: { commit: Commit }, siteId: number) => {
    await apiRequest(
      'get',
      `/api/sites/${siteId}/cameras`,
      {},
      (data: ICameraListResponse) => {
        commit('LOAD_CAMERAS', data.cameras)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  FETCH_COTURN_CRED: async ({ commit }: { commit: Commit }) => {
    return await apiRequest(
      'get',
      `/api/coturn_auth`,
      {},
      (data: CoturnCredentials) => {
        commit('SET_COTURN_CRED', data)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  CREATE_CAMERA: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; camera: ICamera }
  ) => {
    commit('SET_STATUS', 'LOADING')
    args.camera.edit_type = 'form'
    return await apiRequest(
      'post',
      `/api/sites/${args.siteId}/cameras`,
      { camera: args.camera },
      (data: ICameraResponse, _: any) => {
        commit('SET_STATUS', 'OK')
        commit('ADD_CAMERA', data.camera)
        dispatch('SELECT_CAMERA', data.camera.id)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  UPDATE_CAMERA: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; camera: ICamera }
  ) => {
    commit('SET_STATUS', 'LOADING')
    args.camera.edit_type = 'form'
    return await apiRequest(
      'patch',
      `/api/sites/${args.siteId}/cameras/${args.camera.id}`,
      { camera: args.camera },
      (data: ICameraResponse, _: any) => {
        commit('SET_STATUS', 'OK')
        commit('ADD_CAMERA', data.camera)
        dispatch('SELECT_CAMERA', data.camera.id)
      },
      (data: any, _: any) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  DELETE_CAMERA: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    args: { siteId: number; camera: ICamera }
  ) => {
    commit('SET_STATUS', 'LOADING')
    await apiRequest(
      'delete',
      `/api/sites/${args.siteId}/cameras/${args.camera.id}`,
      {},
      (data, _) => {
        commit('REMOVE_CAMERA', args.camera.id)
        commit('SET_STATUS', 'OK')
        dispatch('UNSELECT_CAMERA')
      },
      (data, _) => {
        commit('SET_STATUS', 'FAILED')
        commit('SET_ERROR', new Error(data.error))
      }
    )
  },
  SELECT_CAMERA: ({ commit }: { commit: Commit }, id: number) => {
    commit('ACTIVATE_CAMERA', id)
  },
  UNSELECT_CAMERA: (
    { commit, state }: { commit: Commit; state: ICameraState },
    id: number
  ) => {
    if (!state.activeCameraId) return
    commit('DEACTIVATE_CAMERA', id)
  },
  CAMERA_TRACK_FUSION: async ({ commit }, payload) => {
    const mergedPayload = Object.assign(
      {
        siteId: null,
        cameraId: null,
        tracked_target_id: null,
        radar_tracked_target: null,
        rf_tracked_target: null
      },
      payload
    )
    const siteId = mergedPayload.siteId
    const cameraId = mergedPayload.cameraId
    delete mergedPayload.siteId
    delete mergedPayload.cameraId
    return apiRequest(
      'patch',
      `/api/sites/${siteId}/cameras/${cameraId}`,
      { camera: { ...mergedPayload, edit_type: 'track' } },
      data => {
        return data
      },
      (error, _) => {
        return new Error(error.error)
      }
    )
  },
  STOP_CAMERA_TRACK_FUSION: async ({ commit }, { siteId, cameraId }) => {
    return apiRequest(
      'patch',
      `/api/sites/${siteId}/cameras/${cameraId}`,
      {
        camera: {
          tracked_target_id: null,
          rf_tracked_target: null,
          radar_tracked_target: null,
          edit_type: 'track'
        }
      },
      data => {
        return data
      },
      (error, _) => {
        return new Error(error.error)
      }
    )
  },
  CAMERA_CONTROL: async ({ commit, rootState }, ptz) => {
    const { camera_id, ...others } = ptz
    if (
      rootState.system.environment === 'ZenithAerotech' &&
      rootState?.drone_mcu_units?.drone_mcu_unit?.id
    ) {
      return await apiRequest(
        'post',
        '/commands.json',
        {
          command: {
            device_id: rootState.drone_mcu_units.drone_mcu_unit.id,
            device_type: 'drone_mcu_unit',
            command: others.command,
            parameters: [
              { key: 'Type', value: 'Camera' },
              ...Object.entries(others.velocity || {}).map(([key, value]) => {
                return { key, value: `${value}` }
              }),
              ...['pantilt', 'zoom']
                .map(key =>
                  others.hasOwnProperty(key)
                    ? { key, value: others[key] ? 'True' : 'False' }
                    : null
                )
                .filter(v => v)
            ]
          }
        },
        _ => {
          commit('SET_STATUS', 'OK')
        },
        (data, _) => {
          commit('SET_STATUS', 'FAILED')
          commit('SET_ERROR', new Error(data.error))
        }
      )
    } else {
      const siteId = rootState.sites.activeSiteId
      return await apiRequest(
        'patch',
        `/api/sites/${siteId}/cameras/${camera_id}`,
        {
          camera: {
            control: JSON.stringify(others),
            edit_type: 'control'
          }
        },
        _ => {
          commit('SET_STATUS', 'OK')
        },
        (data, _) => {
          commit('SET_STATUS', 'FAILED')
          commit('SET_ERROR', new Error(data.error))
        }
      )
    }
  },
  CALIBRATE_CAMERA: ({ commit }, calibrating) => {
    commit('CALIBRATE_CAMERA', calibrating)
  },
  SOCKET_CAMERAS_UPDATE: ({ commit, getters }, updates) => {
    return updates.forEach(({ id, ...update }) => {
      const camera = getters['cameraById'](id)
      if (camera) {
        clearTimeout(camera.timeoutError)
      }
      commit(`UPDATE_CAMERAS`, [{ id, ...update }])
    })
  }
}
