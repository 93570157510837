import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getPersistent } from '../../utils'

const getDefaultState = () => {
  return {
    notificationSort: getPersistent('notificationSort', 'SortTimestamp'),
    fusionNotificationSort: getPersistent('fusionNotificationSort', '0'),
    dismissedDetections: [],
    detections: {},
    alerts: [],
    activeAlert: null,
    icons: {},
    selectedDetection: null,
    deferredAlert: null
  }
}

const state = getDefaultState()

const resetState = state => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
