import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { IAGLState } from '@/store/modules/agl_mask/types'

const getDefaultState = (): IAGLState => {
  return {
    radarAGLMasks: {}
  }
}

const state: IAGLState = getDefaultState()

const resetState = (state: IAGLState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
