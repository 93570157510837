import { ISnackbarState } from '@/store/modules/snackbar/types'

export default {
  SHOW_SNACKBAR: (state: ISnackbarState, options: ISnackbarState) => {
    state.snackbarText = options.snackbarText
    state.snackbarTimeout = options.snackbarTimeout
    state.textClass = options.textClass
    state.buttonClass = options.buttonClass
  },
  CLOSE_SNACKBAR: (state: ISnackbarState) => {
    state.snackbarText = ''
    state.snackbarTimeout = 0
    state.textClass = ''
    state.buttonClass = ''
  }
}
