










import Vue from 'vue'
import AdvancedFilterFormDialog from '@/components/Dialogs/Filters/FilterForm/AdvancedFilterFormDialog.vue'
import {
  EItemTypes,
  IDetectionDataForFilter,
  ISupportedDetection
} from '@/components/Dialogs/Filters/types'
import { IRfFilter } from '@/store/modules/rf_filters/types'
import { mapGetters, mapState } from 'vuex'
import { capitalize, rad2deg } from '@/utils/utils'
import { ESiteMode } from '@/store/modules/sites/types'
import { IValueToleranceData } from '@/components/Dialogs/Filters/FilterForm/AdvancedFilterForm.vue'

interface IExtendedIRfFilter extends IRfFilter {
  aoa: number
  aoa_error: number
}

interface IVendorProtocol {
  vendor: string
  protocol: string
}

export default Vue.extend({
  name: 'AdvancedFilterFormDialogContainer',
  components: { AdvancedFilterFormDialog },
  provide() {
    const providedData = {}
    Object.defineProperty(providedData, 'sensor', {
      enumerable: true,
      get: () => this.sensor
    })

    Object.defineProperty(providedData, 'itemType', {
      enumerable: true,
      get: () => this.itemType
    })

    Object.defineProperty(providedData, 'supportedDetections', {
      enumerable: true,
      get: () => this.supportedDetections
    })

    Object.defineProperty(providedData, 'sensorSelection', {
      enumerable: true,
      get: () => this.sensorSelection
    })

    return {
      providedData
    }
  },
  data() {
    return {
      sensor: null,
      sensorSelection: [],
      itemType: EItemTypes.FILTER,
      detectionData: null,
      showFilterForm: false,
      activeFilter: null
    }
  },
  mounted() {
    this.$emitter.on('openFilterDialog', this.openFilterDialog)
    this.$emitter.on('updated:selectedSensor', this.updatedSelectedSensor)
  },
  beforeDestroy() {
    this.$emitter.off('openFilterDialog', this.openFilterDialog)
    this.$emitter.off('updated:selectedSensor', this.updatedSelectedSensor)
  },
  computed: {
    ...mapGetters('sites', {
      activeSiteMode: 'activeSiteMode'
    }),
    ...mapGetters('rf_sensors', {
      rfSensors: 'rfSensorById',
      dsxSensors: 'dsxSensorById'
    }),
    ...mapState('rf_filters', {
      rfFilters: 'rf_filters'
    }),
    allowReadWrite(): boolean {
      return this.activeSiteMode === ESiteMode.Calibration &&
          Boolean(Object.keys({...this.supportedDetections}).length)
    },
    supportedDetections() {
      let supportedDetections = {
        ...this.sensor?.supported_detections
      }
      delete supportedDetections['Multidrone']
      return supportedDetections
    },
    isDsx(): boolean {
      return this.sensor?.model?.toLowerCase()?.includes('dsx')
    }
  },
  methods: {
    capitalize,
    cancelFormDialog(): void {
      this.showFilterForm = false
      this.activeFilter = null
    },
    getBearingFromDetectionData(): IValueToleranceData {
      // use aoa of contributor
      return {
        value: this.detectionData.DetectionContributions[0].aoa,
        tolerance: this.detectionData.DetectionContributions[0].aoa_error / 2
      }
    },
    getVendorProtocol(): IVendorProtocol {
      const detectionVendor = this.detectionData.vendor
      const detectionProtocol = this.detectionData.protocol
      let sensor

      if (
        this.detectionData.DetectionContributions[0].sensor_type === 'rfSensor'
      ) {
        sensor = this.rfSensors(this.detectionData.DetectionContributions[0].sensor_id)
      } else {
        sensor = this.dsxSensors(this.detectionData.DetectionContributions[0].sensor_id)
      }
      const supportedDetections = sensor?.supported_detections || {}
      delete supportedDetections.Multidrone
      let supportedVendors = new Set()
      let supportedProtocols = new Set()
      Object.values(supportedDetections).forEach(
        (band: ISupportedDetection) => {
          const proto = band?.protocols || []
          proto.forEach((item) => {
            supportedVendors.add(item.vendor)
            supportedProtocols.add(item.protocol)
          })
        }
      )
      if (Array.from(supportedVendors).includes(detectionVendor) && Array.from(supportedProtocols).includes(detectionProtocol)) {
        return {
          vendor: detectionVendor,
          protocol: detectionProtocol
        }
      }
      return null
    },
    createFilterFromData(): IRfFilter {
      const bearingData = this.getBearingFromDetectionData()

      let aoa = null
      let aoa_error = null
      if (bearingData.value) {
        aoa = rad2deg(bearingData.value)
        if (!this.isDsx) {
          if (aoa > 70 || aoa < -70) {
            aoa = null
          }
        }
      }
      if (bearingData.tolerance) {
        aoa_error = rad2deg(bearingData.tolerance)
      }

      const vendorProtocol: IVendorProtocol = this.getVendorProtocol()
      const vendor = vendorProtocol ? vendorProtocol.vendor : null
      const protocol = vendorProtocol ? vendorProtocol.protocol : null

      return {
        active: true,
        bearing: this.isDsx ? Math.round(aoa) : aoa,
        bearing_tolerance: this.isDsx ? 22.5 : aoa_error,
        frequency: this.detectionData?.frequency || null,
        frequency_tolerance: 5000000,
        id: null,
        name: this.draftRfFilterName(),
        protocol: protocol,
        rf_sensor_ids: [this.sensor?.id],
        vendor: vendor,
        whitelist: this.itemType === EItemTypes.WHITELIST || false
      }
    },
    openFilterDialog(payload: IDetectionDataForFilter): void {
      this.sensorSelection = payload.sensorSelection
      this.sensor = payload.sensorSelection[0]
      this.detectionData = payload.detection
      this.itemType = payload.itemType
      this.$set(this, 'activeFilter', this.createFilterFromData())

      this.showFilterForm = true
    },
    updatedSelectedSensor(sensor) {
      this.$set(this, 'sensor', sensor)
      this.activeFilter = {
        ...this.activeFilter,
        rf_sensor_ids: [this.sensor.id]
      }
    },
    draftRfFilterName(): string {
      return (
        this.capitalize(this.itemType) +
        ' #' +
        (this.rfFilters[this.sensor.id]
          ? this.rfFilters[this.sensor.id].length + 1
          : 1)
      )
    }
  },
})
