import Module from './index'

class SentryModule extends Module {
  constructor({ endpoint = 'sentries', formKey = 'sentry' } = {}) {
    super({ endpoint, formKey })
  }

  actions() {
    return {
      ...super.actions(),
      [`FETCH_${this.name}_LOCATORS`]: async ({ commit }, sentry_id) => {
        try {
          const { data } = await this.axiosInstance.get(
            `/sentries/${sentry_id}/locators.json`
          )
          return data
        } catch (err) {
          commit('SET_STATUS', 'FAILED')
          commit('SET_ERROR', err.message)
        }
      }
    }
  }

  getters() {
    return {
      ...super.getters(),
      // return sensorsInSentry
      [`${this.endpoint}InSite`]: state => id =>
        Object.values(state[`${this.endpoint}Set`]).filter(
          ({ site_id }) => site_id == id
        )
    }
  }
}

export default SentryModule
