export default {
  status: state => state.status,
  error: state => state.error,
  plansList: state => state.plansList,
  activePlan: state => state.activePlan,
  activeInstallation: state => state.activeInstallation,
  plansState: state => state.plansState,
  sentry: state => sentryId => {
    const index = state.activePlan.site_plan.installations.findIndex(
      sentry => sentry.id == sentryId
    )
    return state.activePlan.site_plan.installations[index]
  },
  sentryCoords: (state, getters) => sentryId => {
    return getters.sentry(sentryId).coordinates
  },
  sentrySensorData: (state, getters) => (sentryId, sensorType, sensorId) => {
    const index = getters
      .sentry(sentryId)
      .sensors[sensorType].findIndex(s => s.id == sensorId)
    return getters.sentry(sentryId).sensors[sensorType][index]
  }
}
