







import Vue from 'vue'
import { mapGetters } from 'vuex';
import ActionButtonGroup from './ActionButtonGroup.vue';
import { IMaskRequest, IMaskRawData } from '@/store/modules/radar_mask/types';

export default Vue.extend({
  name: 'MaskActions',
  components: {
    ActionButtonGroup
  },
  computed: {
    ...mapGetters('sensors', ['hasRadar', 'radarsSet']),
    ...mapGetters('rf_sensors', ['hasRfSensor', 'dsxSensorsSet', 'rfSensorsSet']),
    ...mapGetters('detection', ['selectedDetection', 'selectedDetectionDetails', 'getSeenContributors', 
      'selectedDetectionHasRfContribution', 'selectedDetectionHasRadarContribution']),
    ...mapGetters('sites', [ 'activeSiteMode' ]),
    radarDisabled(): boolean {
      if (this.activeSiteMode !== 'calibration') return true
      else if (!this.hasRadar || this.selectedDetection === null) return true
      else return !this.selectedDetectionHasRadarContribution
    },
    rfDisabled(): boolean {
      if (this.activeSiteMode !== 'calibration') return true
      else if (!this.hasRfSensor || this.selectedDetection === null) return true
      else return !this.selectedDetectionHasRfContribution
    }
  },
  methods: {
    addRadarMask() {
      const sensor = this.selectedDetectionDetails.DetectionContributions
      const sentry = null
      // if the detection is fused we can't set an elevation reliably so leave it null
      const elevation = sensor.length === 1 ? 
        sensor.map(s => s.sensor_type === 'radar' ? s.elevation : null)[0] ?? null
        : null
      const payload: IMaskRequest = {
        isFusion: true,
        sensor,
        sentry,
        data: {
          speed: this.selectedDetectionDetails.speed,
          latitude: this.selectedDetectionDetails.latitude,
          longitude: this.selectedDetectionDetails.longitude,
          elevation: elevation
        } as IMaskRawData
      }
      this.$emitter.emit('showRadarMaskDialog', payload)
    },
    addWhitelist(): void {
      this.addRfMask('whitelist')
    },
    addFilter(): void {
      this.addRfMask('filter')
    },
    addRfMask(type: 'filter' | 'whitelist'): void {
      const filterDetection = {
        detection: { ...this.selectedDetectionDetails },
        sensorSelection: this.getSeenContributors(this.selectedDetection).map(contributor => {
          if (contributor.type === 'rfSensor') return this.rfSensorsSet[contributor.id]
          if (contributor.type === 'dsx') return this.dsxSensorsSet[contributor.id]
        }),
        itemType: type
      }
      this.$emitter.emit('openFilterDialog', filterDetection)
    },
  }
})
