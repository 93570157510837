import { labelMappings } from '@/store/modules/detection_labels/types'

const formatLabel = (label): string => {
  let newLabel = label.slice()
  Object.keys(labelMappings).forEach(key => {
    newLabel = newLabel
      .replaceAll(new RegExp(key, 'gi'), labelMappings[key])
      .replaceAll('-', ' ')
      .replaceAll('_', ' ')
  })
  return newLabel
}

export default {
  labels: state => state.labels || [],
  formattedLabel: _ => label => {
    return formatLabel(label)
  }
}
