import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { getPersistent } from '@/store/utils'
import { IMapState } from './types'

const toQuadKey = (x: number, y: number, z: number) => {
  var index = ''
  for (var i = z; i > 0; i--) {
    var b = 0
    var mask = 1 << (i - 1)
    if ((x & mask) !== 0) b++
    if ((y & mask) !== 0) b += 2
    index += b.toString()
  }
  return index
}

const getDefaultState = (): IMapState => {
  const isToggledMapEndpoint = [
    'localhost',
    'au-test.droneshield.xyz',
    'au-dev.droneshield.xyz',
    'au-staging.droneshield.xyz',
    'us-staging.droneshield.xyz',
    'us.droneshield.xyz',
    'sandbox.droneshield.xyz',
    'au-release.droneshield.xyz',
    'demo.droneshield.xyz'
  ].includes(window.location.hostname)

  return {
    mapZoom: 12,
    uploadFileId: null,
    uploadingMap: false,
    showAddMapLayerDialog: false,
    mapCancelToken: null,
    mapChunks: [],
    selectedMapLayers: [],
    allMapLayers: getPersistent('allMapLayers', []),
    siteMapLayerMapping: getPersistent('siteMapLayerMapping', []),
    activeMapLayerId: getPersistent('activeMapLayerId', 0),
    compassGraphicDirection: 0,
    activeMapId: null,
    exporting: false,
    status: null,
    searchResult: null,
    activeMapLayers: getPersistent('activeMapLayers', [
      'ESRI World Imagery Satellite',
      'ESRI World Imagery Topology'
    ]),
    mapLayerID: getPersistent('mapLayerID', 1),
    error: null
  }
}

const state = getDefaultState()

const resetState = (state: IMapState) => {
  Object.assign(state, getDefaultState())
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: { ...mutations, resetState }
}
