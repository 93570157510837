import { RfSensorTypes } from '../rf_sensors/types'

export default {
  activateDetection: (state, key) => {
    state.activeKey = key
  },
  activateRFBeam: (state, key) => {
    state.activeBeam = key
  },
  SET_META: (state, meta) => {
    state.lastMeta = meta
  },

  UPDATE_RF_PERF_ESTIMATION: (state, data) => {
    state.rfPerfGrid = data?.data || []
  },

  SET_ZMASKS: (state, { masks }) => {
    // Initialise updatedRadarMasks + Invert Azimuth

    const updatedRadarMasks = {}

    Object.values(state.radarsSet).forEach(radar => {
      updatedRadarMasks[radar.id] = []
    })

    masks.map(mask => {
      const invertedMask = Object.assign(
        {},
        {
          ...mask,
          azimuth_min: -mask.azimuth_max,
          azimuth_max: -mask.azimuth_min
        }
      )

      updatedRadarMasks[mask.radar_id].push(invertedMask)
    })

    state.radarZMasks = updatedRadarMasks
  },

  SET_RMASK: (state, { radar, mask }) => {
    return Object.assign(state['radarsSet'][radar.id], {
      rmask: mask
    })
  },

  UPDATE_RMASK: (state, { radar, mask }) => {
    state['radarsSet'][radar.id].rmask.forEach(m => {
      if (m.id === mask.id) Object.assign(m, mask)
    })
  },

  UPDATE_ZMASK: (state, mask) => {
    // Masks from API have inverted azimuth
    ;[mask.azimuth_min, mask.azimuth_max] = [
      -mask.azimuth_max,
      -mask.azimuth_min
    ]

    var sensorID = mask.radar_id

    if (!(sensorID in state['radarZMasks'])) {
      state['radarZMasks'][sensorID] = []
    }

    const maskObj = state['radarZMasks']

    var rec = maskObj[sensorID]?.find(m => m.id === mask.id)

    return rec
      ? (state['radarZMasks'][sensorID] = maskObj[sensorID].map(m =>
          m.id === mask.id ? mask : m
        ))
      : state['radarZMasks'][sensorID].push(mask)
  },

  DELETE_ZMASK: (state, { mask }) => {
    state.radarZMasks[mask.radar_id] = state.radarZMasks[mask.radar_id].filter(
      m => m.id !== mask.id
    )
  },
  SET_CANNON_QUICKPANEL_STATE: (state, payload) => {
    state.cannonQuickPanelState = payload
  },
  SET_HIGHLIGHTED_CANNON: (state, payload) => {
    state.highlightedCannon = payload
  },
  SET_HIGHLIGHTED_ALL_CANNONS: (state, payload) => {
    state.highlightedAllCannons = payload
  },
  UPDATE_IDENTITIES: (state, identity) => {
    if (identity.type === 'smarthub') {
      if (!state.smarthubs) state.smarthubs = {}
      const { serial, ip } = identity || {}
      if (state.smarthubs[serial]) clearTimeout(state.smarthubs[serial].timeout)
      state.smarthubs = Object.assign({}, state.smarthubs, {
        [serial]: {
          serial,
          ip: `http://${ip}`,
          color: 'green',
          timeout: setTimeout(() => {
            state.smarthubs[serial].color = 'red'
          }, 11000)
        }
      })
      return
    }

    if (!state.identities) state.identities = {}
    const { serial, type, site_id } = identity || {}
    if (state.identities[serial]) clearTimeout(state.identities[serial].timeout)
    state.identities = Object.assign({}, state.identities, {
      [serial]: {
        type,
        site_id,
        serial,
        timeout: setTimeout(() => {
          const { [serial]: rm, ...otherIdentities } = state.identities
          state.identities = otherIdentities
        }, 11000)
      }
    })
  },
  CLEAR_IDENTITIES: state => {
    for (let { timeout } of Object.values(state.identities)) {
      clearTimeout(timeout)
    }
    state.identities = {}
  }
}
